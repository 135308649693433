<template>
  <v-main>
    <!-- Filters -->
    <v-container fluid class="px-0">
      <v-card class="px-0">
        <v-row>
          <v-col md="5" class="pb-0">
            <v-autocomplete solo prepend-inner-icon="mdi-map-marker-right" v-model="selectedStartLocation"
              :items="startLocations" :loading="startLocationLoading" @update:search-input="onSearchStart"
              label="Start Location">
              <template v-slot:prepend-item>
                <v-list-item v-if="startLocationLoading">
                  <v-list-item-content>
                    <v-skeleton-loader class="ma-2">
                      <v-list-item-content>
                        <v-list-item-title>Loading...</v-list-item-title>
                      </v-list-item-content>
                    </v-skeleton-loader>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-autocomplete>
            <!-- {{ fromSearch }} -->
          </v-col>
          <v-col md="5" class="pb-0">
            <v-autocomplete solo prepend-inner-icon="mdi-map-marker-left" v-model="selectedEndLocation"
              :items="endLocations" :loading="endLocationLoading" @update:search-input="onSearchEnd"
              label="End Location">
              <!-- Show skeleton loader when loading is true -->
              <template v-slot:prepend-item>
                <v-list-item v-if="endLocationLoading">
                  <v-list-item-content>
                    <v-skeleton-loader class="ma-2">
                      <v-list-item-content>
                        <v-list-item-title>Loading...</v-list-item-title>
                      </v-list-item-content>
                    </v-skeleton-loader>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-autocomplete>
            <!-- {{ toSearch }} -->
          </v-col>
          <v-col>
            <v-btn class="mx-2 mb-2" color="primary" @click="plotOnMap()">
              Search
            </v-btn>
            <v-btn class="mx-2 mb-2" color="primary" @click="getUser()">
              User
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
      <MapComponent style="position: absolute; top: 86px;" ref="mapComponent" :startLatLng="startLngLat"
        :endLatLng="endLngLat" />
    </v-container>
    <!-- End Filters -->
  </v-main>
</template>

<script>
import MapComponent from "../../components/MapComponent.vue";
import axios from "axios";

export default {
  name: "App",
  data() {
    return {
      fromSearch: "",
      selectedStartLocation: null,
      startLocations: [],
      startLocationLoading: false,
      toSearch: "",
      selectedEndLocation: null,
      endLocations: [],
      endLocationLoading: false,
      directionsLoading: false,
      startLngLat: [73.09071001198555, 19.036705965852587], // Example start coordinates
      endLngLat: [73.06504343716844, 19.030396570451565], // Example end coordinates
      currentLatLng: null, // Example end coordinates
      apicalled: 0,
    };
  },
  components: {
    MapComponent,
  },
  methods: {
    async getUser() {
      const response = await axios.get(`/maps/getUser?user_id=13&date=2024-07-23`);
      let plots = response.data.data || [];
      let current = response.data.current || [];
      this.plotOnMap(plots, current);
    },
    async onSearchStart(search) {
      console.log('called', this.selectedStartLocation);
      if (!search) {
        this.startLocations = [];
        this.startLocationLoading = false;
        return;
      }
      this.fromSearch = search;
      this.startLocationLoading = true;
      try {
        let prevStartLocation = "";
        if (this.selectedStartLocation != null) {
          prevStartLocation = this.startLocations;
        }
        const response = await axios.get(`/maps/autoComplete?input=${search}&prevStartLocation=${prevStartLocation}`);
        if (response.data.predictions != 0) {
          let predictions = response.data.predictions || [];
          let newStartLocations = predictions.map((prediction) => ({
            id: prediction.geometry, // Use a unique identifier
            text: prediction.description,
            value: prediction.geometry.location,
          }));
          // Compare previous and new start locations
          if (this.selectedStartLocation != null) {
            const isSameLocation = prevStartLocation.every((loc, index) => {
              const newLoc = newStartLocations[index];
              return loc.id.location.lng == newLoc.id.location.lng && loc.id.location.lat == newLoc.id.location.lat && loc.text == newLoc.text && loc.value.lat == newLoc.value.lat && loc.value.lng == newLoc.value.lng;
            });
            if (isSameLocation) {
              this.startLocations = newStartLocations;
            } else {
              console.log('Locations match, not updating startLocations.');
            }
          } else {
            this.startLocations = newStartLocations;
          }
        }
      } catch (error) {
        this.startLocations = [];
      } finally {
        this.startLocationLoading = false;
      }
    },
    async onSearchEnd(search) {
      console.log('called', this.selectedEndLocation);
      if (!search) {
        this.endLocations = [];
        this.endLocationLoading = false;
        return;
      }
      this.toSearch = search;
      this.endLocationLoading = true;
      try {
        let prevEndLocation = "";
        if (this.selectedEndLocation != null) {
          prevEndLocation = this.endLocations;
        }
        const response = await axios.get(`/maps/autoComplete?input=${search}`);
        if (response.data.predictions != 0) {
          let predictions = response.data.predictions || [];
          let newEndLocations = predictions.map((prediction) => ({
            id: prediction.geometry, // Use a unique identifier
            text: prediction.description,
            value: prediction.geometry.location,
          }));
          // Compare previous and new start locations
          if (this.selectedEndLocation != null) {
            const isSameLocation = prevEndLocation.every((loc, index) => {
              const newLoc = newEndLocations[index];
              return loc.id.location.lng == newLoc.id.location.lng && loc.id.location.lat == newLoc.id.location.lat && loc.text == newLoc.text && loc.value.lat == newLoc.value.lat && loc.value.lng == newLoc.value.lng;
            });
            if (isSameLocation) {
              this.endLocations = newEndLocations;
            } else {
              console.log('Locations match, not updating endLocations.');
            }
          } else {
            this.endLocations = newEndLocations;
          }
        }
      } catch (error) {
        this.endLocations = [];
      } finally {
        this.endLocationLoading = false;
      }
    },
    async plotOnMap(plotOnMap) {
      let startLatLng = null;
      let endLatLng = null;
      if (plotOnMap != null) {
        startLatLng = `${plotOnMap[0]},${plotOnMap[0]}`;
        endLatLng = `${plotOnMap[plotOnMap.length - 1]},${plotOnMap[plotOnMap.length - 1]}`;
        // endLatLng = `22.7522081,72.8886656`;
        console.log('plotmap', plotOnMap[plotOnMap.length - 1]);
        this.startLngLat = [plotOnMap[0][0], plotOnMap[0][1]];
        this.endLngLat = [plotOnMap[plotOnMap.length - 1][0], plotOnMap[plotOnMap.length - 1][1]];
        // await this.getCurrentLatLong();
        // this.currentLatLng = `22.647741,72.8958299`;
        try {
          // console.log('first api', this.currentLatLng);
          // const responseToCurrent = await axios.get(
          //   `/maps/getDirections?origin=${startLatLng}&destination=${this.currentLatLng}&waypoints=&alternatives=false&steps=false&overview=full&language=en&traffic_metadata=true`
          // );

          // // Call API for the route from start to end location
          // console.log('second api', endLatLng);
          // const responseToEnd = await axios.get(
          //   `/maps/getDirections?origin=${startLatLng}&destination=${endLatLng}&waypoints=&alternatives=false&steps=false&overview=full&language=en&traffic_metadata=true`
          // );

          // Set the routes on the map
          this.$refs.mapComponent.setRoutes(
            plotOnMap,
          );
          // let predictions = response.data.predictions || [];
          // this.startLocations = predictions.map((prediction) => ({
          //   id: prediction.geometry, // Use a unique identifier
          //   text: prediction.description,
          //   value: prediction.geometry.location,
          // }));
        } catch (error) {
          // this.startLocations = [];
        } finally {
          // this.startLocationLoading = false;
        }
      } else {
        startLatLng = `${this.selectedStartLocation.lat},${this.selectedStartLocation.lng}`;
        endLatLng = `${this.selectedEndLocation.lat},${this.selectedEndLocation.lng}`;
        this.startLngLat = [this.selectedStartLocation.lng, this.selectedStartLocation.lat];
        this.endLngLat = [this.selectedEndLocation.lng, this.selectedEndLocation.lat];
        try {
          // Call API for the route from start to end location
          let response = await axios.get(
            `/maps/getDirections?origin=${startLatLng}&destination=${endLatLng}&waypoints=&alternatives=false&steps=false&overview=full&language=en&traffic_metadata=true`
          );

          // Set the routes on the map
          this.$refs.mapComponent.setRoute2(response.data.routes[0].overview_polyline);
          // let predictions = response.data.predictions || [];
          // this.startLocations = predictions.map((prediction) => ({
          //   id: prediction.geometry, // Use a unique identifier
          //   text: prediction.description,
          //   value: prediction.geometry.location,
          // }));
        } catch (error) {
          // this.startLocations = [];
        } finally {
          // this.startLocationLoading = false;
        }
      }
    },
  },
};
</script>
