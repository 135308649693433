<template>
  <v-main>
    <v-container fluid>
      <v-row>
        <v-col md="12">
          <v-card elevation="6" rounded="xxl">
            <v-card-title>
              <h3 class="font-weight-bold" v-text="'Shift Change Requests'"></h3>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col md="6">
                  <v-text-field solo rounded v-model="filter.search_keyword" label="Search ... " color="#e60040"
                    prepend-inner-icon="mdi-magnify" clearable @click:clear="
                (filter.search_keyword = null), (page = 1), getData()
                " @keydown.enter="(page = 1), getData()">
                  </v-text-field>
                </v-col>
                <v-col sm="3">
                  <v-autocomplete solo rounded ref="position" v-model="filter.position_id" :items="positions"
                    label="Position" @input="(page = 1), getData()" prepend-inner-icon="mdi-account"
                    clearable></v-autocomplete>
                </v-col>
                <v-col sm="3">
                  <v-autocomplete ref="status" solo rounded v-model="filter.is_active" :items="statuses" label="Status"
                    @input="(page = 1), getData()" prepend-inner-icon="mdi-toggle-switch" clearable></v-autocomplete>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col md="12">
          <v-card elevation="6" rounded="xxl">
            <v-card-text>
              <section v-if="isLoading" style="height: 560px">
                <v-progress-linear indeterminate color="primary"></v-progress-linear>
              </section>
              <v-simple-table v-else fixed-header height="560" class="striped-table">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Sr No</th>
                      <th class="text-left">Status</th>
                      <th class="text-left">First Name</th>
                      <th class="text-left">Middle Name</th>
                      <th class="text-left">Last Name</th>
                      <th class="text-left">Email</th>
                      <th class="text-left">Phone</th>
                      <th class="text-left">Position</th>
                      <th class="text-right action_th" style="width: 240px;">Action</th>
                    </tr>
                  </thead>
                  <tbody v-if="users.length != 0">
                    <tr v-for="(user, i) in users" :key="`user${i}`">
                      <td style="min-width: 85px">
                        {{ i + 1 }}
                      </td>
                      <td>
                        <v-tooltip bottom v-if="user.is_active == 1">
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip v-bind="attrs" v-on="on" small color="teal" text-color="white">
                              <v-avatar left>
                                <v-icon size="15">mdi-checkbox-marked-circle</v-icon>
                              </v-avatar>
                              Active
                            </v-chip>
                          </template>
                          <span>User is Active</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="user.is_active == 0">
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip v-bind="attrs" v-on="on" small color="red" text-color="white">
                              <v-avatar left>
                                <v-icon size="15">mdi-close-circle</v-icon>
                              </v-avatar>
                              Inactive
                            </v-chip>
                          </template>
                          <span>User is InActive</span>
                        </v-tooltip>
                      </td>
                      <td>{{ user.first_name || "" }}</td>
                      <td>{{ user.middle_name || "" }}</td>
                      <td>{{ user.last_name || "" }}</td>
                      <td>{{ user.email || "" }}</td>
                      <td>{{ user.phone || "" }}</td>
                      <td>
                        {{ user.position ? user.position.name : "" }}
                      </td>

                      <td class="text-right">
                        <v-autocomplete solo rounded ref="position" v-model="user.shift_id" :items="Shifts"
                          label="Position" @input="UpdateUser(user)" prepend-inner-icon="mdi-account"
                          clearable></v-autocomplete>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="12" class="text-center">No data found</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <v-pagination v-model="page" :length="paginationLength">
              </v-pagination>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- Clear Success Dialog Box -->
      <v-dialog v-model="is_deleted_user" width="600">
        <v-card rounded="xxl">
          <v-container>
            <v-row align="center">
              <v-col sm="3" align="center">
                <v-icon large style="color: green; font-size: 90px; width: 200px !important">
                  mdi-delete-empty
                </v-icon>
              </v-col>
              <v-col sm="9" align="center">
                <v-card-text>
                  <v-row>
                    <v-col>
                      <h1 style="color: green">Hurray!</h1>
                      <br />
                      <h3>User deleted successfully.</h3>
                      <br />
                      <v-btn color="success" @click="is_deleted_user = false">
                        OK
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
export default {
  name: "usersPage",
  data() {
    return {
      filter: {
        active: "",
        supervisor_id: "",
        role_id: "",
        position_id: "",
      },
      page: 1,
      serialNoStarting: 1,
      paginationLength: 0,
      count: 0,
      search_keyword: "",
      is_deleted_user: false,
      users: [],
      typeItems: [
        { id: "PENDING", text: "PENDING", value: 1 },
        { id: "APPROVED", text: "APPROVED", value: 0 },
      ],
      statuses: [
        {
          id: 0,
          text: "Select Active Status",
          value: "",
        },
        { id: "ACTIVE", text: "ACTIVE", value: "1" },
        { id: "INACTIVE", text: "INACTIVE", value: "0" },
      ],
      positions: [
        {
          id: 0,
          text: "Select Position",
          value: "",
        },
      ],
      Shifts: [
        {
          id: 0,
          text: "Select Position",
          value: "",
        },
      ],
      isLoading: false,
      isUpdating: false,
    };
  },
  watch: {
    page: "getData",
  },
  mounted() {
    this.getData();
    this.getMasters();
  },
  methods: {
    async getMasters() {
      await axios
        .get(`users/masters`)
        .then((masters) => {
          masters = masters.data;
          //Position
          masters.positions.forEach((position) => {
            this.positions.push({
              id: position.id,
              text: position.name,
              value: position.id,
            });
          });
          masters.ShiftManagementsResponse.forEach((shift) => {
            this.Shifts.push({
              id: shift.id,
              text: shift.shift_name,
              value: shift.id,
            });
          });
        })
        .catch(function (error) {
          console.log(error);
          // redirect to Home Page
          // window.location.href = "/auth/login";
        });
    },
    async getData() {
      this.isLoading = true;
      this.filter.search_keyword = this.filter.search_keyword
        ? this.filter.search_keyword
        : "";
      this.filter.is_active = this.filter.is_active
        ? this.filter.is_active
        : "";
      this.filter.position_id = this.filter.position_id
        ? this.filter.position_id
        : "";
      let query =
        "page=" +
        this.page +
        "&rowsPerPage=" +
        this.rowsPerPage +
        "&role_id=3" +
        "&is_active=" +
        this.filter.is_active +
        "&is_shift_change_request=1" +
        "&position_id=" +
        this.filter.position_id +
        "&search_keyword=" +
        this.filter.search_keyword;
      await axios
        .get(`users?${query}`)
        .then((response) => {
          this.users = response.data.data;
          this.count = response.data.count;
          this.serialNoStarting = (this.page - 1) * this.rowsPerPage;
          this.paginationLength = Math.ceil(this.count / this.rowsPerPage);
        })
        .catch(function (error) {
          console.log(error);
          //   window.location.href = "/auth/login";
        });
      this.isLoading = false;
    },
    async Delete_this(id) {
      this.delete_user = true;
      await axios
        .post(`/users/delete/${this.encryptIt(id)}`)
        .then(() => {
          this.delete_user = false;
          this.is_deleted_user = true;
          this.getData();
        })
        .catch(function (error) {
          console.log(error);
          window.location.href = "/auth/login";
        });
    },
    async UpdateUser(user) {
      let old_json = user;
      this.$set(user, "isUpdating", true);
      user.is_shift_change_request = false;
      try {
        await axios
          .patch(`/users/${this.encryptIt(user.id)}`, user)
          .then(async () => {
            await this.saveUserTimestamp(old_json, user);
            this.getData();
          })
          .catch((error) => {
            // redirect to Home Page
            // window.location.href = "/auth/login";
            console.error("Error fetching data:", error);
          });
      } catch (e) {
        console.log(e);
      } finally {
        // Set isUpdating to false after the request is complete
        this.$set(user, "isUpdating", false);
      }
    },
  },
};
</script>
