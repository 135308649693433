<template>
  <v-main>
    <v-container fluid>
      <v-row>
        <v-col md="12">
          <v-card elevation="6" rounded="xxl">
            <v-card-title>
              <h3 class="font-weight-bold" v-text="isDeletePage() != true ? 'Sample Files' : 'Deleted Modules'"></h3>
              <template v-if="isDeletePage() != true">
                <v-btn class="mx-2 mb-2" fab x-small color="primary" to="/sample-files/create">
                  <v-icon dark> mdi-plus </v-icon>
                </v-btn>
              </template>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col md="6">
                  <v-text-field solo rounded v-model="filter.search_keyword" label="Search ... " color="#e60040"
                    prepend-inner-icon="mdi-magnify" clearable @click:clear="
                (filter.search_keyword = null), (page = 1), getData()
                " @keydown.enter="(page = 1), getData()">
                  </v-text-field>
                </v-col>
                <v-col sm="3">
                  <v-autocomplete ref="status" solo rounded v-model="filter.is_active" :items="statuses" clearable
                    @click:clear="(filter.is_active = null), (page = 1)" label="Status" @input="getData"
                    prepend-inner-icon="mdi-toggle-switch"></v-autocomplete>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="12">
          <v-card elevation="6" rounded="xxl">
            <v-card-text>
              <section v-if="isLoading" style="height: 560px">
                <v-progress-linear indeterminate color="primary"></v-progress-linear>
              </section>
              <v-simple-table v-else fixed-header height="560" class="striped-table">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-start font-weight-bold text-subtitle-2 sr_no">Sr No</th>
                      <th class="text-start font-weight-bold text-subtitle-2">File Name</th>
                      <th class="text-start font-weight-bold text-subtitle-2">File Path</th>
                      <th class="text-right action_th">Action</th>
                    </tr>
                  </thead>
                  <tbody v-if="sampleFiles.length != 0">
                    <tr v-for="(file, i) in sampleFiles" :key="`file_${i}`">
                      <td style="min-width: 85px">
                        {{ i + 1 }}
                      </td>
                      <td>
                        {{ file.file_name || "" }}
                      </td>
                      <td>
                        {{ file.file_path || "" }}
                      </td>
                      <td class="text-right">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" class="ml-2" fab x-small color="primary"
                              :to="`/sample-files/${encryptIt(file.id)}`">
                              <v-icon dark> mdi-pencil </v-icon>
                            </v-btn>
                          </template>
                          <span>Edit this Sample File</span>
                        </v-tooltip>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="7" class="text-center">No data found</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <v-pagination rounded v-model="page" :length="paginationLength">
              </v-pagination>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
export default {
  name: "modulesPage",
  data() {
    return {
      filter: {
        search_keyword: "",
        is_active: "",
      },
      sampleFiles: [],
      statuses: [
        {
          id: 0,
          text: "Select Active Status",
          value: "",
        },
        { id: "ACTIVE", text: "ACTIVE", value: "1" },
        { id: "INACTIVE", text: "INACTIVE", value: "0" },
      ],
      isLoading: false,
    };
  },
  watch: {
    page: "getData",
    $route() {
      this.getData();
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      this.filter.search_keyword = this.filter.search_keyword
        ? this.filter.search_keyword
        : "";
      this.filter.show_deleted = this.$route.query.open_trash ? 1 : 0;
      this.filter.is_active = this.filter.is_active
        ? this.filter.is_active
        : "";
      let query =
        "search_keyword=" +
        this.filter.search_keyword;
      await axios
        .get(`sample_files?${query}`)
        .then((response) => {
          this.sampleFiles = response.data.data;
        })
        .catch(function (error) {
          console.log(error);
          // window.location.href = "/auth/login";
        });
      this.isLoading = false;
    },
  },
};
</script>
