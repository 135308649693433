<template>
  <v-main>
    <v-container fluid>
      <v-card elevation="6" rounded="xxl">
        <v-card-title class="mt-4">
          <v-btn class="mx-2" fab x-small color="primary" @click="$router.back()">
            <v-icon dark> mdi-arrow-left </v-icon> </v-btn>Edit User
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="4">
              <v-autocomplete ref="user" outlined v-model="form.user_id" :items="userItems" label="User"
                prepend-inner-icon="mdi-account-tie-hat-outline" clearable
                :error-messages="errors.user_id"></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-menu v-model="start_date_menu" :close-on-content-click="false" :nudge-right="40"
                transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="form.start_date" label="Start Date" prepend-inner-icon="mdi-calendar" readonly
                    outlined v-bind="attrs" v-on="on" clearable @click:clear="form.start_date = null"
                    :error-messages="errors.start_date"></v-text-field>
                </template>
                <v-date-picker v-model="form.start_date" @change="start_date_menu = false"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col sm="4">
              <v-menu v-model="end_date_menu" :close-on-content-click="false" :nudge-right="40"
                transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="form.end_date" label="End Date" prepend-inner-icon="mdi-calendar" readonly
                    outlined v-bind="attrs" v-on="on" clearable @click:clear="form.end_date = null"
                    :error-messages="errors.end_date"></v-text-field>
                </template>
                <v-date-picker v-model="form.end_date" @change="end_date_menu = false"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col sm="4">
              <v-switch class="mt-0 ml-5" v-model="form.is_active" inset
                :label="`Is Active: ${form.is_active ? 'YES' : 'NO'}`"></v-switch>
            </v-col>
          </v-row>
          <v-divider class="my-5"> </v-divider>
          <v-btn rounded color="primary" :loading="isLoading" :disabled="isLoading" @click="save">
            Update Outlet
          </v-btn>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";

export default {
  name: "OutletEditPage",
  data() {
    return {
      form: {
        is_active: false,
      },
      userItems: [],
      isLoading: false,
      start_date_menu: false,
      end_date_menu: false,
    };
  },
  mounted() {
    this.getMasters();
    this.getData();
  },
  methods: {
    async getMasters() {
      await axios
        .get(`outlet_users/masters`)
        .then((masters) => {
          masters = masters.data;
          //Module
          masters.users.forEach((user) => {
            this.userItems.push({
              id: user.id,
              text: user.user_name,
              value: user.id,
            });
          });
        })
        .catch(function (error) {
          console.log(error);
          // redirect to Home Page
          window.location.href = "/auth/login";
        });
    },
    async getData() {
      this.isLoading = true;
      let encryptedId = this.$route.params.outletUserId;
      console.log(encryptedId);
      axios
        .get(`/outlet_users/${encryptedId}`)
        .then((response) => {
          this.form = response.data.data;
          this.old_json = response.data.data;
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
      this.isLoading = false;
    },
    async save() {
      try {
        this.isLoading = true;
        console.log(this.form);
        await axios
          .patch(`/outlet_users/${this.$route.params.outletUserId}`, this.form)
          .then(async () => {
            await this.saveUserTimestamp(this.old_json, this.form);
            let PageModule = 'UPDATE';
            let PageCode = 'OUTLET USERS';
            const userIP = await this.getUserIp();
            this.saveAuditTrial(userIP, PageModule, PageCode)
            this.$router.push(
              `/outlets/${this.encryptIt(this.form.outlet_id)}/outlet-users`
            );
          })
          .catch((error) => {
            // redirect to Home Page
            window.location.href = "/auth/login";
            console.error("Error fetching data:", error);
          });
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    },
  },
};
</script>
