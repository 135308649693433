<template>
  <v-main>
    <v-container fluid>
      <v-row>
        <v-col md="12">
          <v-card elevation="6" rounded="xxl">
            <v-card-title>
              <h3 class="font-weight-bold" v-text="'Hr Reports'"></h3>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col md="6">
                  <v-text-field solo rounded v-model="filter.search_keyword" label="Search ... " color="#e60040"
                    prepend-inner-icon="mdi-magnify" clearable @click:clear="
                (filter.search_keyword = null), (page = 1), getData()
                " @keydown.enter="(page = 1), getData()">
                  </v-text-field>
                </v-col>
                <v-col sm="3">
                  <v-autocomplete ref="status" solo rounded v-model="filter.is_active" :items="statuses" clearable
                    @click:clear="(filter.is_active = null), (page = 1)" label="Status" @input="getData"
                    prepend-inner-icon="mdi-toggle-switch"></v-autocomplete>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="12">
          <v-card elevation="6" rounded="xxl">
            <v-card-text>
              <v-btn class=" mb-2" color="primary" to="/hr-reports/create">
                Hr Reports <v-icon dark> mdi-plus </v-icon>
              </v-btn>
              <section v-if="isLoading" style="height: 560px">
                <v-progress-linear indeterminate color="primary"></v-progress-linear>
              </section>
              <v-simple-table v-else fixed-header height="560" class="striped-table">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-start font-weight-bold text-subtitle-2 sr_no">Sr No</th>
                      <th class="text-start font-weight-bold text-subtitle-2">Status</th>
                      <th class="text-start font-weight-bold text-subtitle-2" style="min-width: none">Report Name</th>
                      <th class="text-start font-weight-bold text-subtitle-2" style="min-width: none">Table Name</th>
                      <th class="text-right font-weight-bold text-subtitle-2 action_th">Action</th>
                    </tr>
                  </thead>
                  <tbody v-if="HrReports.length != 0">
                    <tr v-for="(report, i) in HrReports" :key="`report_${i}`">
                      <td style="min-width: 85px">
                        {{ i + 1 }}
                      </td>
                      <td>
                        <v-tooltip bottom v-if="report.is_active == 1">
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip v-bind="attrs" v-on="on" small color="teal" text-color="white">
                              <v-avatar left>
                                <v-icon size="15">mdi-checkbox-marked-circle</v-icon>
                              </v-avatar>
                              Active
                            </v-chip>
                          </template>
                          <span>Report is Active</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="report.is_active == 0">
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip v-bind="attrs" v-on="on" small color="red" text-color="white">
                              <v-avatar left>
                                <v-icon size="15">mdi-close-circle</v-icon>
                              </v-avatar>
                              Inactive
                            </v-chip>
                          </template>
                          <span>Report is InActive</span>
                        </v-tooltip>
                      </td>
                      <td>
                        {{ report.report_name || "" }}
                      </td>
                      <td>
                        {{ report.table_name || "" }}
                      </td>
                      <td class="text-right">
                        <template>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn v-bind="attrs" v-on="on" fab x-small class="my-auto" color="error"
                                @click="Delete_this(report.id)">
                                <v-icon dark> mdi-delete </v-icon>
                              </v-btn>
                            </template>
                            <span>Delete this Report</span>
                          </v-tooltip>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn v-bind="attrs" v-on="on" class="ml-2" fab x-small color="primary"
                                :to="`/hr-reports/${encryptIt(report.id)}`">
                                <v-icon dark> mdi-pencil </v-icon>
                              </v-btn>
                            </template>
                            <span>Edit this Report</span>
                          </v-tooltip>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn v-bind="attrs" v-on="on" fab x-small class="ml-2" color="primary" :to="`/hr-reports/${encryptIt(
                report.id
              )}?isViewOnly=true`">
                                <v-icon dark>mdi-eye</v-icon>
                              </v-btn>
                            </template>
                            <span>View Hr Report</span>
                          </v-tooltip>
                        </template>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="7" class="text-center">No data found</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <v-pagination rounded v-model="page" :length="paginationLength">
              </v-pagination>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- Clear Success Dialog Box -->
      <v-dialog v-model="is_deleted_Report" width="600">
        <v-card rounded="xxl">
          <v-container>
            <v-row align="center">
              <v-col sm="3" align="center">
                <v-icon large style="color: green; font-size: 90px; width: 200px !important">
                  mdi-delete-empty
                </v-icon>
              </v-col>
              <v-col sm="9" align="center">
                <v-card-text>
                  <v-row>
                    <v-col>
                      <h1 style="color: green">Hurray!</h1>
                      <br />
                      <h3>Module deleted successfully.</h3>
                      <br />
                      <v-btn color="success" @click="is_deleted_module = false">
                        OK
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
export default {
  name: "HrReportsPage",
  data() {
    return {
      filter: {
        search_keyword: "",
        is_active: "",
      },
      HrReports: [],
      statuses: [
        {
          id: 0,
          text: "Select Active Status",
          value: "",
        },
        { id: "ACTIVE", text: "ACTIVE", value: "1" },
        { id: "INACTIVE", text: "INACTIVE", value: "0" },
      ],
      ExportModuleTypes: [],
      page: 1,
      serialNoStarting: 1,
      paginationLength: 0,
      count: 0,
      excel_fields: {
        "Sr No": "sr_no",
        Name: "name",
      },
      isLoading: false,
      isExcelloading: false,
      delete_module: false,
      is_deleted_Report: false,
    };
  },
  watch: {
    page: "getData",
    $route() {
      this.getData();
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      this.filter.search_keyword = this.filter.search_keyword
        ? this.filter.search_keyword
        : "";
      this.filter.show_deleted = this.$route.query.open_trash ? 1 : 0;
      this.filter.is_active = this.filter.is_active
        ? this.filter.is_active
        : "";
      let query =
        "page=" +
        this.page +
        "&rowsPerPage=" +
        this.rowsPerPage +
        "&show_deleted=" +
        this.filter.show_deleted +
        "&is_active=" +
        this.filter.is_active +
        "&search_keyword=" +
        this.filter.search_keyword;
      await axios
        .get(`/hr_reports?${query}`)
        .then((response) => {
          this.HrReports = response.data.data;
          this.count = response.data.count;
          this.serialNoStarting = (this.page - 1) * this.rowsPerPage;
          this.paginationLength = Math.ceil(this.count / this.rowsPerPage);
        })
        .catch(function (error) {
          console.log(error);
          // window.location.href = "/auth/login";
        });
      this.isLoading = false;
    },
    //Generate Excel
    async generateExcel() {
      let query = "search_keyword=" + this.filter.search_keyword;
      let response = await axios.get(`module_exports?${query}`);
      console.log(response.data.data);
      if (response) {
        window.open(`${response.data.data}`, "_blank");
      }
    },
    async Delete_this(id) {
      this.delete_module = true;
      await axios
        .post(`/HrReports/delete/${this.encryptIt(id)}`)
        .then(() => {
          this.delete_module = false;
          this.is_deleted_module = true;
          this.getData();
        })
        .catch(function (error) {
          console.log(error);
          window.location.href = "/auth/login";
        });
    },
    async Restore_this(id) {
      this.restore_module = true;
      await axios
        .post(`/HrReports/restore/${this.encryptIt(id)}`)
        .then(() => {
          this.restore_module = false;
          this.is_restored_module = true;
          this.getData();
        })
        .catch(function (error) {
          console.log(error);
          window.location.href = "/auth/login";
        });
    },
  },
};
</script>
