<template>
    <v-main>
        <v-container fluid>
            <v-card elevation="6" rounded="xxl">
                <v-card-title class="mt-4 font-weight-bold text-h5">
                    Company Attendance Type
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col sm="4" class="mt-4 font-weight-bold text-subtitle-1">
                            COMPANY ATTENDANCE TYPE <span class="red--text">*</span>
                        </v-col>
                        <v-col sm="6">
                            <v-autocomplete ref="position" outlined v-model="companyform.company_attendance_type"
                                :items="CompanyAttendanceTypes" prepend-inner-icon="mdi-account-tie-outline" clearable
                                :error-messages="errors.company_attendance_type"
                                @change="UpdateCompanyAttendanceTypeData"></v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col sm="4" class="mt-4 font-weight-bold text-subtitle-1">
                            TAKE ATTENDANCE SELFIE <span class="red--text">*</span>
                        </v-col>
                        <v-col sm="6">
                            <v-autocomplete ref="position" outlined v-model="companyform.take_attendance_selfie"
                                :items="Options" prepend-inner-icon="mdi-account-tie-outline" clearable
                                :error-messages="errors.take_attendance_selfie"
                                @change="UpdateCompanyAttendanceTypeData"></v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col sm="4" class="mt-4 font-weight-bold text-subtitle-1">
                            RESTRICTED ATTENDANCE IN OUT OF RANGE <span class="red--text">*</span>
                        </v-col>
                        <v-col sm="6">
                            <v-autocomplete ref="position" outlined
                                v-model="companyform.restricted_attendance_out_of_range" :items="Options"
                                prepend-inner-icon="mdi-account-tie-outline" clearable
                                :error-messages="errors.restricted_attendance_out_of_range"
                                @change="UpdateCompanyAttendanceTypeData"></v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col sm="4" class="mt-4 font-weight-bold text-subtitle-1">
                            MATCH SELFIE WITH REGISTERED FACE (ANDROID ONLY) <span class="red--text">*</span>
                        </v-col>
                        <v-col sm="6">
                            <v-autocomplete ref="position" outlined v-model="companyform.match_selfie_android"
                                :items="Options" prepend-inner-icon="mdi-account-tie-outline" clearable
                                :error-messages="errors.match_selfie_android"
                                @change="UpdateCompanyAttendanceTypeData"></v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col sm="4" class="mt-4 font-weight-bold text-subtitle-1">
                            MATCH SELFIE WITH REGISTERED FACE (IOS ONLY) <span class="red--text">*</span>
                        </v-col>
                        <v-col sm="6">
                            <v-autocomplete ref="position" outlined v-model="companyform.match_selfie_ios"
                                :items="Options" prepend-inner-icon="mdi-account-tie-outline" clearable
                                :error-messages="errors.match_selfie_ios"
                                @change="UpdateCompanyAttendanceTypeData"></v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col sm="4" class="mt-4 font-weight-bold text-subtitle-1 py-0">
                            REGULARIZATIONS LIMIT <span class="red--text">*</span>
                        </v-col>
                        <v-col sm="6">
                            <v-text-field v-model="companyform.regularization_limit" outlined class="py-0"
                                placeholder="Enter Regularization Limit" type="number"
                                @change="UpdateCompanyAttendanceTypeData"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="4">
                            <v-card height="470">
                                <v-card-title style="background-color: #2a5882; color: white;">
                                    <span class="font-weight-bold text-subtitle-1">Mumbai</span>
                                </v-card-title>
                                <!-- <v-card-text> -->
                                <v-simple-table fixed-header height="350">
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th class="text-left">Sr. no</th>
                                                <th class="text-left">Location</th>
                                                <th class="text-left">Lat</th>
                                                <th class="text-left">Long</th>
                                                <th class="text-left">Radius</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(range, i) in MumbaiRange" :key="i">
                                                <td>{{ i + 1 }}</td>
                                                <td>{{ range.identifier }}</td>
                                                <td>{{ range.latitude }}</td>
                                                <td>{{ range.longitude }}</td>
                                                <td>{{ range.radius }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                                <!-- </v-card-text> -->
                                <v-col align="center" cols="12">
                                    <v-btn color="cyan" dark @click="AddRangeDialog = true">
                                        <v-icon size="15">mdi-sticker-check-outline</v-icon> ADD RANGE
                                    </v-btn>
                                </v-col>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-col cols="12" class="px-0">
                        <v-card-title>
                            <span class="mt-4 font-weight-bold text-h5"> Employee Attendance Geo Fencing</span>
                            <v-spacer></v-spacer>
                            <v-btn color="#2a5882" dark class="font-weight-bold"
                                @click="AddUserGeoFencingDialog = true">ADD
                                USER GEO
                                FENCING</v-btn>
                        </v-card-title>
                        <v-simple-table fixed-header>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-start font-weight-bold text-subtitle-1">Sr. no</th>
                                        <th class="text-start font-weight-bold text-subtitle-1">Employee Name</th>
                                        <th class="text-start font-weight-bold text-subtitle-1">Employee Code</th>
                                        <th class="text-start font-weight-bold text-subtitle-1">Department</th>
                                        <th class="text-start font-weight-bold text-subtitle-1">Range</th>
                                        <th class="text-start font-weight-bold text-subtitle-1">Work Location</th>
                                        <th class="text-start font-weight-bold text-subtitle-1">latitude</th>
                                        <th class="text-start font-weight-bold text-subtitle-1">longitude</th>
                                        <th class="text-start font-weight-bold text-subtitle-1">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(range, i) in UserRange" :key="i">
                                        <td>{{ i + 1 }}</td>
                                        <td>{{ range.user.full_name }}</td>
                                        <td>{{ range.user.Employee_code }}</td>
                                        <td>{{ range.user.department }}</td>
                                        <td>{{ range.radius }}</td>
                                        <td>{{ range.identifier }}</td>
                                        <td>{{ range.latitude }}</td>
                                        <td>{{ range.longitude }}</td>
                                        <td> <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn v-bind="attrs" v-on="on" class="ml-2" fab x-small
                                                        color="primary" @click="GetSingleUserData(range.id)">
                                                        <v-icon dark> mdi-pencil </v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Edit User Range</span>
                                            </v-tooltip>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-col>
                    <v-divider class="my-5"> </v-divider>
                    <!-- <v-btn rounded color="primary" :loading="isLoading" :disabled="isLoading" @click="save">
                        Save Module
                    </v-btn> -->
                </v-card-text>
            </v-card>
            <v-dialog v-model="AddRangeDialog" width="600" persistent>
                <v-card>
                    <v-col cols="12">
                        <v-row>
                            <v-col cols="6">
                                RANGE (METER) <span CLASS="red--text">*</span>
                            </v-col>
                            <v-col cols="6">
                                <input type="number" block style="border: 1px solid #00000069; width: 200px;"
                                    class="py-1 pl-1 pr-3 rounded-lg" id="radius" v-model="radius" @input="updateRadius"
                                    min="0" max="5000" step="50" />
                            </v-col>
                            <v-icon color="black" style="border: 1px solid red; position: absolute; right: 0;"
                                @click="closeDialog">mdi-window-close</v-icon>
                        </v-row>
                    </v-col>
                    <v-col cols="12" class="py-0">
                        <vue-google-autocomplete class="py-1 pl-1 pr-3 rounded-lg"
                            style="border: 1px solid #00000069; width: 100%;" id="map" classname="form-control"
                            placeholder="Enter Location" v-model="address" @placechanged="getAddressData"
                            :options="options" />
                    </v-col>
                    <GmapMap :center="center" :zoom="zoom" map-type-id="roadmap" style="width: 100%; height: 500px"
                        @click="updateCenter">
                        <GmapMarker :position="markerPosition" />
                        <GmapCircle :center="markerPosition" :radius="radius" />
                    </GmapMap>
                    <v-col cols="12" align="center">
                        <v-btn color="#2a5882" dark class="font-weight-bold"
                            @click="SaveGeoFence('isCityRange')">ADD</v-btn>
                    </v-col>
                </v-card>
            </v-dialog>
            <v-dialog v-model="AddUserGeoFencingDialog" width="1400" persistent>
                <v-card>
                    <v-col cols="12">
                        <v-row>
                            <v-col cols="6" class="pt-1 pb-0">
                                <label>BRANCH *</label>
                                <v-autocomplete ref="position" outlined v-model="form.branch" :items="Branchs"
                                    prepend-inner-icon="mdi-account-tie-outline" clearable
                                    :error-messages="errors.branch"></v-autocomplete>
                            </v-col>
                            <v-col cols="6" class="pt-1 pb-0">
                                <label>DEPARTMENT *</label>
                                <v-autocomplete ref="position" outlined v-model="form.department" :items="Departments"
                                    prepend-inner-icon="mdi-account-tie-outline" clearable
                                    :error-messages="errors.branch" @change="getMasters"></v-autocomplete>
                            </v-col>
                            <v-col cols="6" class="py-0">
                                <label>USER *</label>
                                <v-autocomplete ref="position" outlined v-model="form.user_id" :items="Users"
                                    prepend-inner-icon="mdi-account-tie-outline" clearable
                                    :error-messages="errors.user_id"></v-autocomplete>
                            </v-col>
                            <v-col cols="6" class="py-0">
                                <label>RANGE(METER) *</label>
                                <v-text-field id="radius" outlined v-model="radius" @input="updateRadius" min="0"
                                    max="5000" step="50">
                                </v-text-field>
                            </v-col>
                            <v-icon color="black" style="border: 1px solid red; position: absolute; right: 0;"
                                @click="closeDialog">mdi-window-close</v-icon>
                        </v-row>
                    </v-col>
                    <v-col cols="12" class="py-0">
                        <vue-google-autocomplete class="py-1 pl-1 pr-3 rounded-lg"
                            style="border: 1px solid #00000069; width: 100%;" id="map" classname="form-control"
                            placeholder="Enter Location" v-model="address" @placechanged="getAddressData"
                            :options="options" />
                    </v-col>
                    <GmapMap :center="center" :zoom="zoom" map-type-id="roadmap" style="width: 100%; height: 500px"
                        @click="updateCenter">
                        <GmapMarker :position="markerPosition" />
                        <GmapCircle :center="markerPosition" :radius="radius" />
                    </GmapMap>
                    <br />
                    <v-col cols="12" align="center" class="py-0">
                        <v-btn color="#2a5882" dark class="font-weight-bold"
                            @click="SaveGeoFence('isUserRange')">ADD</v-btn>
                    </v-col>
                </v-card>
            </v-dialog>
        </v-container>
    </v-main>
</template>

<script>
import axios from "axios";
import Vue from "vue";
import * as VueGoogleMaps from "vue2-google-maps";
import VueGoogleAutocomplete from 'vue-google-autocomplete';

Vue.use(VueGoogleMaps, {
    load: {
        key: "AIzaSyAm0FfE4ijnd7gx8dQ6KHp2rtOWNs9rYZk",
        libraries: "places",
    },
});

export default {
    name: "ModuleCreatePage",
    components: {
        VueGoogleAutocomplete,
    },
    data() {
        return {
            form: {},
            companyform: {
                company_attendance_type: "",
                take_attendance_selfie: "",
                restricted_attendance_out_of_range: "",
                match_selfie_android: "",
                match_selfie_ios: "",
            },
            CompanyAttendanceTypes: [],
            AttendanceSelfies: [],
            Branchs: [],
            Departments: [],
            Options: [
                { id: 1, text: "YES", value: "YES" },
                { id: 2, text: "NO", value: "NO" },],
            isLoading: false,
            AddRangeDialog: false,
            Users: [],
            MumbaiRange: [],
            UserRange: [],
            AddUserGeoFencingDialog: false,
            center: { lat: 19.047321, lng: 73.069908 },
            zoom: 15,
            markerPosition: { lat: 10.0, lng: 10.0 },
            radius: 0,
            address: '',
            create_company_type: '',
            options: {
                types: ['address'],
            },
            user_id: '',
        };
    },
    mounted() {
        this.getMasters();
        this.getData();
        this.getCompanyAttendanceTypeData();
    },
    methods: {
        async save() {
            try {
                this.isLoading = true;
                await axios.post("/modules", this.form);
                this.isLoading = false;
                this.$router.push("/modules");
            } catch (e) {
                console.log(e);
                this.isLoading = false;
            }
        },
        async getMasters() {
            this.form.department = this.form.department ? this.form.department : "";
            this.form.branch = this.form.branch ? this.form.branch : "";
            let query = 'department=' + this.form.department + '&branch=' + this.form.branch;
            await axios
                .get(`users/masters?${query}`)
                .then((masters) => {
                    masters = masters.data;
                    //departments
                    masters.departments.forEach((department) => {
                        this.Departments.push({
                            id: department.id,
                            text: department.description,
                            value: department.description,
                        });
                    });
                    //branchs
                    masters.branchs.forEach((branch) => {
                        this.Branchs.push({
                            id: branch.id,
                            text: branch.description,
                            value: branch.description,
                        });
                    });
                    //users
                    masters.usersResponse.forEach((user) => {
                        this.Users.push({
                            id: user.id,
                            text: user.full_name,
                            value: user.id,
                        });
                    });
                    //settings
                    masters.CompanyAttendanceTypes.forEach((type) => {
                        this.CompanyAttendanceTypes.push({
                            id: type.id,
                            text: type.description,
                            value: type.description,
                        });
                    });
                })
                .catch(function (error) {
                    console.log(error);
                    //   window.location.href = "/auth/login";
                });
        },
        async getData() {
            try {
                this.user_id = this.user_id ? this.user_id : "";
                let response = await axios.get(`geofences?user_id=${this.user_id}`);
                this.MumbaiRange = response.data.geofenceDataMumbai;
                this.UserRange = response.data.geofenceDataUser;
            } catch (error) {
                console.log(error);
            }

        },
        async getCompanyAttendanceTypeData() {
            try {
                let response = await axios.get(`company_attendance_types`);
                let fetchedData = response.data.data;
                this.companyform = {
                    ...this.companyform,
                    ...fetchedData,
                };
                console.log('companyform', this.companyform);
            } catch (error) {
                console.log(error);
            }

        },
        async UpdateCompanyAttendanceTypeData() {
            try {
                await axios.post(`company_attendance_types`, this.companyform);
                let PageModule = 'UPDATED';
                let PageCode = 'COMPANY ATTENDANCE SETTING';
                const userIP = await this.getUserIp();
                this.saveAuditTrial(userIP, PageModule, PageCode)
                this.getCompanyAttendanceTypeData();
            } catch (error) {
                console.log(error);
            }

        },
        async GetSingleUserData(GeofencId) {
            try {
                this.form = [];
                let response = await axios.get(`geofences/${GeofencId}`);
                let fetchedData = response.data.data;
                this.form.branch = response.data.data.user.branch;
                this.form.department = response.data.data.user.department;
                this.form = {
                    ...this.form,
                    ...fetchedData,
                };
                this.markerPosition = { lat: this.form.latitude, lng: this.form.longitude }
                this.radius = this.form.radius;
                this.AddUserGeoFencingDialog = true;
            } catch (error) {
                console.log(error);
            }

        },
        async SaveGeoFence(GeoFenceType) {
            // console.log(';center', this.center);
            // console.log(';zoom', this.zoom);
            // console.log(';markerPosition', this.markerPosition);
            // console.log(';radius', this.radius);
            let payload = {};
            if (GeoFenceType == 'isCityRange') {
                payload = {
                    latitude: this.markerPosition.lat,
                    longitude: this.markerPosition.lng,
                    radius: this.radius,
                    identifier: this.address,
                }
            } else {
                payload = {
                    id: this.form.id ? this.form.id : '',
                    user_id: this.form.user_id,
                    latitude: this.markerPosition.lat,
                    longitude: this.markerPosition.lng,
                    radius: this.radius,
                    identifier: this.address,
                }
            }
            console.log('address', payload);
            try {
                await axios.post('geofences', payload);
                this.AddRangeDialog = false;
                this.AddUserGeoFencingDialog = false;
                this.getMasters();
                this.getData();
            } catch (error) {
                console.log(error);
            }
        },
        getAddressData(addressData) {
            console.log('addressData' , addressData);
            this.address = addressData.route;
            this.center = {
                lat: addressData.latitude,
                lng: addressData.longitude
            };
            this.markerPosition = this.center;
            this.zoom = 14;
        },
        updateCenter(event) {
            this.markerPosition = {
                lat: event.latLng.lat(),
                lng: event.latLng.lng()
            };
            this.center = this.markerPosition;
        },
        updateRadius() {
            // This method is triggered when the radius input value changes.
            this.radius = parseInt(this.radius);
        },
        closeDialog() {
            this.AddUserGeoFencingDialog = false;
            this.AddRangeDialog = false;
            this.markerPosition = { lat: 10.0, lng: 10.0 };
            this.radius = 0;
            this.form.branch = '';
            this.form.department = '';
            this.form.user_id = '';
        }
    }
};
</script>