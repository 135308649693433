<template>
  <v-main>
    <v-container fluid>
      <v-card elevation="6" rounded="xxl">
        <v-card-title class="mt-4">
          <v-btn class="mx-2" fab x-small color="primary" @click="$router.back()">
            <v-icon dark> mdi-arrow-left </v-icon> </v-btn>Add Sample File
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="4">
              <v-text-field v-model="form.file_name" outlined label="File Name"
                prepend-inner-icon="mdi-card-text-outline"></v-text-field>
            </v-col>
            <v-col sm="4">
              <input type="file" id="file" name="file" :ref="`file`" />
              <template v-if="form.file_path != null">
                <v-btn x-small class="mx-2" :href="`${mediaUrl}${form.file_path}`" target="_blank"
                  color="primary">View</v-btn>
                <v-btn x-small class="reset_image my-1" color="red" dark @click="form.file_path = null">Remove</v-btn>
              </template>
            </v-col>
          </v-row>
          <v-divider class="my-5"> </v-divider>
          <v-btn rounded color="primary" :loading="isLoading" :disabled="isLoading" @click="save">
            Update Sample File
          </v-btn>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";
export default {
  name: "ModuleCreatePage",
  data() {
    return {
      form: {
      },
      isLoading: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      let response = await axios.get(`/sample_files/${this.$route.params.FileId}`);
      this.form = response.data.data;
    },
    async save() {
      try {
        this.isLoading = true;
        let response = await axios.post("/sample_files", this.form);
        this.form = response.data.data;
        await this.handleFileUpload();
        this.isLoading = false;
        this.$router.back();
      } catch (e) {
        // alert("error");
        console.log(e);
        this.isLoading = false;
      }
    },
    async handleFileUpload() {
      if (this.$refs.file != null) {
        let attachment = this.$refs.file.files[0];
        let formData = new FormData();
        formData.append("id", this.form.id);
        formData.append("file_path", attachment);
        await axios
          .post("upload_sample_file", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .catch(function (error) {
            console.log(error);
            console.log("FAILURE!!");
          });
      }
    },

  },
};
</script>
