<template>
  <v-main>
    <v-container fluid>
      <v-row>
        <v-col md="12">
          <v-card elevation="6">
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-btn class="mx-2 mb-2" color="primary" to="/user-regularizations/create">
                    Regularization <v-icon dark> mdi-plus </v-icon>
                  </v-btn>
                </v-col>
                <v-col md="6">
                  <v-text-field solo rounded v-model="search_keyword" label="Search ... "
                    prepend-inner-icon="mdi-magnify" @keydown.enter="getData" @change="page = 1"></v-text-field>
                </v-col>
                <v-col sm="3">
                  <v-autocomplete solo rounded ref="employee" v-model="user_id" :items="employees" label="Employee"
                    @input="getData" append-icon="mdi-account"></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col sm="2">
                  <v-autocomplete solo rounded ref="role" v-model="session_type_id" :items="sessionTypes"
                    label="Session Type" @input="getData" append-icon="mdi-account"></v-autocomplete>
                </v-col>
                <v-col sm="2">
                  <v-autocomplete solo rounded ref="status" v-model="active" :items="statuses" label="Employee Status"
                    @input="getData" append-icon="mdi-toggle-switch"></v-autocomplete>
                </v-col>
                <v-col sm="3">
                  <v-menu v-model="month_menu" :close-on-content-click="false" :nudge-right="40"
                    transition="scale-transition" offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field solo rounded v-model="filter.month_year" label="Year - Month"
                        append-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" clearable
                        @click:clear="filter.month_year = null" :error-messages="errors.month"></v-text-field>
                    </template>
                    <v-date-picker v-model="filter.month_year" type="month"
                      @change="(month_menu = false), getData()"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col md="3">
                  <v-pagination v-model="page" :length="paginationLength">
                  </v-pagination>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-progress-linear v-if="isLoading" indeterminate color="primary"></v-progress-linear>
      <v-row v-else>
        <v-col md="12">
          <v-card elevation="6">
            <v-card-text>
              <section v-if="isLoading" style="height: 485px">
                <v-progress-linear indeterminate color="primary"></v-progress-linear>
              </section>
              <!-- <v-card-title v-if="userAttendances.length != 0"><v-spacer></v-spacer><v-btn class="primary mx-4"
                v-if="ExportUserRegularizations.length == 0" @click="generateExcel()"><span v-if="isExcelloading">
                  Generating
                  Excel... </span>
                <span v-else>Generate Excel</span></v-btn>
              <v-btn target="_blank" :href="`${mediaUrl}store-vyu/exports/UserRegularizationsExport.xlsx`" v-else
                class="success mx-4"></v-btn></v-card-title> -->
              <section v-if="isLoading" style="height: 485px">
                <v-progress-linear indeterminate color="primary"></v-progress-linear>
              </section>
              <v-simple-table fixed-header height="485">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-start text-subtitle-2 font-weight-bold">Sr No</th>
                      <th class="text-start text-subtitle-2 font-weight-bold">Status</th>
                      <th class="text-start text-subtitle-2 font-weight-bold">Employee Name</th>
                      <th class="text-start text-subtitle-2 font-weight-bold">Employee Code</th>
                      <th class="text-start text-subtitle-2 font-weight-bold">Date</th>
                      <th class="text-start text-subtitle-2 font-weight-bold">Login Time</th>
                      <th class="text-start text-subtitle-2 font-weight-bold">Logout Time</th>
                      <th class="text-start text-subtitle-2 font-weight-bold">Session Type</th>
                      <th class="text-right text-subtitle-2 font-weight-bold">Action</th>
                    </tr>
                  </thead>
                  <tbody v-if="userAttendances.length != 0">
                    <tr v-for="(uA, i) in userAttendances" :key="`user${i}`">
                      <td>
                        {{ i + 1 }}
                      </td>
                      <td>
                        <v-tooltip bottom v-if="uA.is_regularization_approve == 1">
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip v-bind="attrs" v-on="on" small color="teal" text-color="white">
                              <v-avatar left>
                                <v-icon size="15">mdi-checkbox-marked-circle</v-icon>
                              </v-avatar>
                              Approved
                            </v-chip>
                          </template>
                          <span>Approved Regularization</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="uA.is_regularization_approve == null">
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip v-bind="attrs" v-on="on" small color="orange" text-color="white">
                              <v-avatar left>
                                <v-icon size="15">mdi-checkbox-marked-circle</v-icon>
                              </v-avatar>
                              Pending
                            </v-chip>
                          </template>
                          <span>Pending Regularization</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="uA.is_regularization_approve == 0">
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip v-bind="attrs" v-on="on" small color="red" text-color="white">
                              <v-avatar left>
                                <v-icon size="15">mdi-close-circle</v-icon>
                              </v-avatar>
                              Rejected
                            </v-chip>
                          </template>
                          <span>Rejected Regularization</span>
                        </v-tooltip>
                      </td>
                      <td>{{ uA.user.full_name || "" }}</td>
                      <td>{{ uA.user.employee_code || "" }}</td>
                      <td>{{ uA.date || "" }}</td>
                      <td>{{ uA.login_time | hhmm }}</td>
                      <td>{{ uA.logout_time | hhmm }}</td>
                      <td>{{ uA.session_type.code || "" }}</td>
                      <td class="text-right">
                        <v-btn class="mx-2" fab x-small color="primary"
                          :to="`/user-regularizations/${encryptIt(uA.id)}`">
                          <v-icon dark> mdi-pencil </v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="8" class="text-center">No data found</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
  name: "userRegularizationsPage",
  data() {
    return {
      filter: {
        month_year: "",
        month: "",
        year: "",
      },
      search_keyword: "",
      user_id: "",
      session_type_id: "",
      supervisor_id: "",
      month_menu: false,
      page: 1,
      serialNoStarting: 1,
      paginationLength: 0,
      count: 0,
      employees: [
        {
          id: 0,
          text: "Select Employee",
          value: "",
        },
      ],
      statuses: [
        {
          id: 0,
          text: "Select Approve Status",
          value: "",
        },
        { id: "APPROVED", text: "APPROVED", value: "1" },
        { id: "NOT APPROVED", text: "NOT APPROVED", value: "0" },
        { id: "PENDING", text: "PENDING", value: "null" },
      ],

      supervisors: [
        {
          id: 0,
          text: "Select Supervisors",
          value: "",
        },
      ],
      sessionTypes: [
        {
          id: 0,
          text: "Select Session Type",
          value: "",
        },
      ],

      isLoading: false,
      isExcelloading: false,
      ExportUserRegularizations: [],
      userAttendances: [],
    };
  },
  watch: {
    page: "getData",
    month_menu: "getMonthYear",
  },
  mounted() {
    this.getData();
    this.getMasters();
  },
  methods: {
    async getMasters() {
      let masters = await axios.get(`user_attendances/masters`);
      masters = masters.data;
      // Employees
      masters.employees.forEach((employee) => {
        this.employees.push({
          id: employee.id,
          text: employee.full_name,
          value: employee.id,
        });
      });
      // Session Types
      masters.sessionTypes.forEach((sessionType) => {
        this.sessionTypes.push({
          id: sessionType.id,
          text: sessionType.code,
          value: sessionType.id,
        });
      });
    },
    async getData() {
      this.isLoading = true;
      if (this.filter.month_year) {
        this.filter.year = this.filter.month_year.split("-")[0];
        this.filter.month = this.filter.month_year.split("-")[1];
      }
      this.search_keyword = this.search_keyword ? this.search_keyword : "";
      this.active = this.active ? this.active : "";
      this.supervisorId = this.supervisorId ? this.supervisorId : "";
      this.user_id = this.user_id ? this.user_id : "";
      this.session_type_id = this.session_type_id ? this.session_type_id : "";
      let userAttendances = [];
      let query =
        "page=" +
        this.page +
        "&rowsPerPage=" +
        this.rowsPerPage +
        "&search_keyword=" +
        this.search_keyword +
        "&sessionTypeId=" +
        this.session_type_id +
        "&is_regularization=1" +
        "&is_regularization_approve=" +
        this.active +
        "&month=" +
        this.filter.month +
        "&year=" +
        this.filter.year +
        "&userId=" +
        this.user_id +
        "&supervisorId=" +
        this.supervisor_id;
      userAttendances = await axios.get(`user_attendances?${query}`);
      this.userAttendances = userAttendances.data.data;
      this.count = userAttendances.data.count;
      this.serialNoStarting = (this.page - 1) * this.rowsPerPage;
      this.paginationLength = Math.ceil(this.count / this.rowsPerPage);
      this.isLoading = false;
    },

    async generateExcel() {
      let query =
        "&search_keyword=" +
        this.search_keyword +
        "&sessionTypeId=" +
        this.session_type_id +
        "&attendanceType=" +
        this.attendance_type +
        "&employee_status=" +
        this.active +
        "&month=" +
        this.filter.month +
        "&year=" +
        this.filter.year +
        "&userId=" +
        this.user_id +
        "&supervisorId=" +
        this.supervisor_id;
      let response = await axios.get(`user_Regularizations_Exports?${query}`);
      console.log(response.data.data);
      if (response) {
        window.open(`${response.data.data}`, "_blank");
      }
    },

    getMonthYear() {
      if (this.filter.month_year) {
        this.filter.year = this.filter.month_year.split("-")[0];
        this.filter.month = this.filter.month_year.split("-")[1];

        this.totalDaysInMonth = moment(
          this.filter.month_year,
          "YYYY-M"
        ).daysInMonth();
        console.log(this.totalDaysInMonth);
      }
    },
  },
};
</script>
