<template>
  <v-main>
    <v-container fluid>
      <v-row>
        <v-col md="12">
          <v-card elevation="4">
            <v-card-text>
              <v-row>
                <v-col md="6">
                  <v-text-field
                    v-model="search_keyword"
                    label="Search ... "
                    prepend-inner-icon="mdi-magnify"
                    @keydown.enter="getData"
                    @change="page = 1"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- / Filters -->
      <v-row>
        <v-col md="12">
          <v-card elevation="6">
            <v-progress-linear
              v-if="isLoading"
              indeterminate
              color="primary"
            ></v-progress-linear>
            <v-simple-table fixed-header>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-start text-subtitle-2 font-weight-bold">
                      SR. NO.
                    </th>
                    <th class="text-start text-subtitle-2 font-weight-bold">
                      Company Name
                    </th>
                    <th class="text-start text-subtitle-2 font-weight-bold">
                      Plan
                    </th>
                    <th class="text-start text-subtitle-2 font-weight-bold">
                      Amount
                    </th>
                    <th class="text-start text-subtitle-2 font-weight-bold">
                      Renew Date
                    </th>
                    <th class="text-start text-subtitle-2 font-weight-bold">
                      Expire Date
                    </th>
                    <th class="text-start text-subtitle-2 font-weight-bold">
                      Screen Shot
                    </th>
                    <th class="text-right text-subtitle-2 font-weight-bold">
                      Recieved
                    </th>
                  </tr>
                </thead>
                <tbody v-if="renews.length != 0">
                  <tr v-for="(renew, i) in renews" :key="`renew${i}`">
                    <td>
                      {{ i + 1 }}
                    </td>
                    <td>{{ renew.company.name || "" }}</td>
                    <td>{{ renew.plan || "" }}</td>
                    <td>{{ renew.amount || "" }}</td>
                    <td>{{ renew.renew_date || "" }}</td>
                    <td>{{ renew.expire_date || "" }}</td>
                    <td class="text-right">
                      <v-btn
                        v-if="renew.payment_screen_shot != null"
                        class="text-caption px-1 py-1 rounded-lg"
                        color="success"
                        dark
                        @click="viewImage(renew.id)"
                      >
                        view image
                      </v-btn>
                    </td>
                    <td
                      v-if="renew.is_recieved == null"
                      class="text-right"
                    >
                      <v-btn
                        fab
                        small
                        color="error"
                        @click="
                          handleStatusChange(renew, (renew.is_recieved = false))
                        "
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                      <v-btn
                        fab
                        small
                        color="success"
                        @click="
                          handleStatusChange(renew, (renew.is_recieved = true))
                        "
                      >
                        <v-icon>mdi-check</v-icon>
                      </v-btn>
                    </td>
                    <td v-else class="text-right" width="210">
                      <v-tooltip bottom v-if="renew.is_recieved == 1">
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip
                            v-bind="attrs"
                            v-on="on"
                            small
                            color="teal"
                            text-color="white"
                          >
                            <v-avatar left>
                              <v-icon size="15"
                                >mdi-checkbox-marked-circle</v-icon
                              >
                            </v-avatar>
                            Recieved
                          </v-chip>
                        </template>
                        <span>Payment Recieved</span>
                      </v-tooltip>
                      <v-tooltip bottom v-else>
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip
                            v-bind="attrs"
                            v-on="on"
                            small
                            color="red"
                            text-color="white"
                          >
                            <v-avatar left>
                              <v-icon size="15">mdi-close-circle</v-icon>
                            </v-avatar>
                            Rejected
                          </v-chip>
                        </template>
                        <span>Payment Rejected </span>
                      </v-tooltip>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="4"></td>
                    <td>
                      <v-pagination
                        class="float-right"
                        v-model="page"
                        :length="paginationLength"
                      >
                      </v-pagination>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="9" class="text-center">No data found</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <!--End of Table -->
          </v-card>
        </v-col>
      </v-row>
      <v-dialog width="950" v-model="renewsImageDialog">
        <v-carousel height="600" hide-delimiter-background show-arrows-on-hover>
          <v-carousel-item v-for="(slide, i) in slides" :key="i">
            <v-sheet height="100%">
              <v-row class="fill-height" align="center" justify="center">
                <v-img
                  :src="mediaUrl + slide"
                  contain
                  height="600"
                  max-width="950"
                />
              </v-row>
            </v-sheet>
          </v-carousel-item>
        </v-carousel>
      </v-dialog>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
export default {
  name: "renewsPage",
  data() {
    return {
      filter: {
        month_year: "",
        month: "",
        year: "",
        expense_id: "",
      },
      renews: [],
      page: 1,
      serialNoStarting: 1,
      paginationLength: 0,
      count: 0,
      search_keyword: "",
      month_menu: false,
      isLoading: false,
      isExcelloading: false,
      renewsImageDialog: false,
      slides: [],
      typeItems: [
        { id: "RECIEVED", text: "RECIEVED", value: 1 },
        { id: "NOT RECIEVED", text: "NOT RECIEVED", value: 0 },
        { id: "PENDING", text: "PENDING", value: null },
      ],
    };
  },
  watch: {
    page: "getData",
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      if (this.filter.month_year) {
        this.filter.year = this.filter.month_year.split("-")[0];
        this.filter.month = this.filter.month_year.split("-")[1];
      }
      this.search_keyword = this.search_keyword ? this.search_keyword : "";
      this.filter.user_id = this.filter.user_id ? this.filter.user_id : "";
      let query =
        "page=" +
        this.page +
        "&rowsPerPage=" +
        this.rowsPerPage +
        "&search_keyword=" +
        this.search_keyword +
        "&month=" +
        this.filter.month +
        "&year=" +
        this.filter.year +
        "&user_id=" +
        this.filter.user_id;
      let response = await axios.get(`renews?${query}`);
      this.renews = response.data.data;
      this.count = response.data.count;
      this.serialNoStarting = (this.page - 1) * this.rowsPerPage;
      this.paginationLength = Math.ceil(this.count / this.rowsPerPage);
      this.isLoading = false;
    },
    viewImage(renewtId) {
      const renew = this.renews.find((renew) => renew.id === renewtId);
      if (renew) {
        // Extract image paths
        const imagePaths = [renew.payment_screen_shot];
        this.slides = imagePaths.filter((path) => path);
        this.renewsImageDialog = true;
        console.log(this.slides);
      } else {
        console.log(`renew with ID ${renewtId} not found.`);
      }
    },
    async handleStatusChange(renew) {
      await axios.patch(`renews/${renew.id}`, renew);
      this.getData();
    },
  },
};
</script>
