<template>
  <v-main>
    <v-container fluid>
      <v-card elevation="6" rounded="xxl">
        <v-card-title class="mt-4">
          <v-btn class="mx-2" fab x-small color="primary" @click="$router.back()">
            <v-icon dark> mdi-arrow-left </v-icon> </v-btn>Edit Store
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="4">
              <v-autocomplete ref="beat" outlined v-model="form.beat_id" :items="beatItems" label="Beat"
                prepend-inner-icon="mdi-account-tie-hat-outline" clearable
                :error-messages="errors.beat_id"></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-text-field v-model="form.name" outlined label="Name" prepend-inner-icon="mdi-card-text-outline"
                :error-messages="errors.name"></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field v-model="form.code" outlined label="Code"
                prepend-inner-icon="mdi-card-text-outline"></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-autocomplete ref="classification" outlined v-model="form.classification_id"
                :items="classificationItems" label="Classification" prepend-inner-icon="mdi-account-tie-hat-outline"
                clearable :error-messages="errors.classification_id"></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-autocomplete ref="category" outlined v-model="form.category_id" :items="categoryItems" label="Category"
                prepend-inner-icon="mdi-account-tie-hat-outline" clearable
                :error-messages="errors.category_id"></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-text-field v-model="form.address" outlined label="Address" prepend-inner-icon="mdi-card-text-outline"
                :error-messages="errors.address"></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field v-model="form.proprietor_name" outlined label="Proprietor Name"
                prepend-inner-icon="mdi-card-text-outline"></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field v-model="form.phone" outlined label="Phone"
                prepend-inner-icon="mdi-card-text-outline"></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field v-model="form.gst_no" outlined label="GST No."
                prepend-inner-icon="mdi-card-text-outline"></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field v-model="form.bank_name" outlined label="Bank Name"
                prepend-inner-icon="mdi-card-text-outline"></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field v-model="form.account_no" outlined label="Account No"
                prepend-inner-icon="mdi-card-text-outline"></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field v-model="form.ifsc_code" outlined label="IFSC Code"
                prepend-inner-icon="mdi-card-text-outline"></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field v-model="form.branch" outlined label="Branch"
                prepend-inner-icon="mdi-card-text-outline"></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-switch class="mt-0 ml-5" v-model="form.is_active" inset
                :label="`Is Active: ${form.is_active ? 'YES' : 'NO'}`"></v-switch>
            </v-col>
          </v-row>
          <v-divider class="my-5"> </v-divider>
          <v-btn rounded color="primary" :loading="isLoading" :disabled="isLoading" @click="save">
            Update Store
          </v-btn>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";

export default {
  name: "StoreEditPage",
  data() {
    return {
      form: {
        is_active: false,
      },
      beatItems: [],
      classificationItems: [],
      categoryItems: [],
      isLoading: false,
    };
  },
  mounted() {
    this.getData();
    this.getMasters();
    this.form.created_by_id = this.user.id;
  },
  methods: {
    // async getData() {
    //   this.isLoading = true;
    //   let form = await axios.get(`/stores/${this.$route.params.storeId}`);
    //   this.form = form.data.data;
    //   this.old_json = form.data.data;
    //   this.isLoading = false;
    // },
    async getMasters() {
      await axios
        .get(`stores/masters`)
        .then((masters) => {
          masters = masters.data;
          //Stores
          masters.beats.forEach((beat) => {
            this.beatItems.push({
              id: beat.id,
              text: beat.name,
              value: beat.id,
            });
          });
          //Classifications
          masters.classifications.forEach((classification) => {
            this.classificationItems.push({
              id: classification.id,
              text: classification.name,
              value: classification.id,
            });
          });
          //Categories
          masters.categories.forEach((category) => {
            this.categoryItems.push({
              id: category.id,
              text: category.name,
              value: category.id,
            });
          });
        })
        .catch(function (error) {
          console.log(error);
          // redirect to Home Page
          window.location.href = "/auth/login";
        });
    },
    async getData() {
      this.isLoading = true;
      let encryptedId = this.$route.params.storeId;
      axios
        .get(`/stores/${encryptedId}`)
        .then((response) => {
          this.form = response.data.data;
          this.old_json = response.data.data;
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
      this.isLoading = false;
    },
    async save() {
      try {
        this.isLoading = true;
        console.log(this.form);
        await axios.patch(`/stores/${this.$route.params.storeId}`, this.form);
        await this.saveUserTimestamp(this.old_json, this.form);
        let PageStore = 'UPDATED';
        let PageCode = 'STORES';
        const userIP = await this.getUserIp();
        this.saveAuditTrial(userIP, PageStore, PageCode)
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    },
    async saveUserTimestamp() {
      try {
        let form = {
          from_path: `/store/${this.form.id}`,
          from_name: "StoreEditPage",
          user_id: this.user.id,
          old_json: JSON.stringify(this.old_json),
          new_json: JSON.stringify(this.form),
        };
        await axios.post(`/user_timestamps`, form);
        this.$router.push("/stores");
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
