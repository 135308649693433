<template>
  <v-main>
    <v-container fluid>
      <v-card elevation="6">
        <v-card-title class="mt-4">
          <v-btn class="mr-2" fab x-small color="primary" @click="$router.back()">
            <v-icon dark> mdi-arrow-left </v-icon>
          </v-btn>
          Edit Ticket
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="12">
              <v-textarea v-model="form.description" outlined auto-grow label="Description *"
                prepend-inner-icon="mdi-alert-circle" hint="Describe the issue which is you are facing"
                :error-messages="errors.description"></v-textarea>
            </v-col>
            <v-col sm="4">
              <v-autocomplete v-model="form.type" :items="IssueTypes" outlined label="Select Type *"
                prepend-inner-icon="mdi-buffet" :error-messages="errors.type"></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-autocomplete v-model="form.status" :items="Status" outlined label="Status *"
                prepend-inner-icon="mdi-buffet" :error-messages="errors.status"></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-autocomplete v-model="form.created_by_id" :items="UsersList" outlined label="Create By *"
                prepend-inner-icon="mdi-buffet" :error-messages="errors.created_by_id"></v-autocomplete>
            </v-col>
            <v-col cols="12">
              <span class="text-h5 font-weight-bold">Upload Images</span>
            </v-col>
            <v-col sm="6">
              <b>Image 1 <span class="error--text">*</span></b>
              <br />
              <input type="file" id="file_1" name="file_1" ref="file_1" accept="image/*" />

              <template v-if="form.image_path != null">
                <v-img class="mt-2" v-if="form.image_path != null" :src="mediaUrl + form.image_path" alt="image 1"
                  style="height: 50%; width: 50% !important" />
                <br />
                <v-btn x-small class="mx-2" :href="`${mediaUrl}${form.image_path}`" target="_blank"
                  color="primary">View</v-btn>
                <v-btn x-small class="reset_image my-1" color="red" dark
                  @click="($refs.file_1.value = null), (form.image_path = null)">Remove</v-btn>
              </template>
            </v-col>
            <v-col sm="6">
              <b>Image 2 <span class="error--text">*</span></b>
              <br />
              <input type="file" id="file_2" name="file_2" ref="file_2" accept="image/*" />

              <template v-if="form.image_path_2 != null">
                <v-img class="mt-2" v-if="form.image_path_2 != null" :src="mediaUrl + form.image_path_2" alt="image 2"
                  style="height: 50%; width: 50% !important" />
                <br />
                <v-btn x-small class="mx-2" :href="`${mediaUrl}${form.image_path_2}`" target="_blank"
                  color="primary">View</v-btn>
                <v-btn x-small class="reset_image my-2" color="red" dark @click="
            ($refs.file_2.value = null), (form.image_path_2 = null)
            ">Remove</v-btn>
              </template>
            </v-col>
            <v-col sm="6">
              <b>Image 3 <span class="error--text">*</span></b>
              <br />
              <input type="file" id="file_3" name="file_3" ref="file_3" accept="image/*" />

              <template v-if="form.image_path_3 != null">
                <v-img class="mt-2" v-if="form.image_path_3 != null" :src="mediaUrl + form.image_path_3" alt="image 3"
                  style="height: 50%; width: 50% !important" />
                <br />
                <v-btn x-small class="mx-3" :href="`${mediaUrl}${form.image_path_3}`" target="_blank"
                  color="primary">View</v-btn>
                <v-btn x-small class="reset_image my-2" color="red" dark @click="
            ($refs.file_3.value = null), (form.image_path_3 = null)
            ">Remove</v-btn>
              </template>
            </v-col>
            <v-col sm="6">
              <b>Image 4 <span class="error--text">*</span></b>
              <br />
              <input type="file" id="file_4" name="file_4" ref="file_4" accept="image/*" />

              <template v-if="form.image_path_4 != null">
                <v-img class="mt-2" v-if="form.image_path_4 != null" :src="mediaUrl + form.image_path_4" alt="image 4"
                  style="height: 50%; width: 50% !important" />
                <br />
                <v-btn x-small class="mx-3" :href="`${mediaUrl}${form.image_path_4}`" target="_blank"
                  color="primary">View</v-btn>
                <v-btn x-small class="reset_image my-2" color="red" dark @click="
            ($refs.file_4.value = null), (form.image_path_4 = null)
            ">Remove</v-btn>
              </template>
            </v-col>
            <v-col>
              <v-btn :loading="isLoading" class="float-right" color="primary" dark @click="update">
                Update Ticket
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      form: {
        active: true,
      },
      UsersList: [],
      IssueTypes: [],
      Status: [
        {
          text: "PENDING",
          value: "PENDING",
        },
        {
          text: "IN PROGRESS",
          value: "IN PROGRESS",
        },
        {
          text: "CLOSED",
          value: "CLOSED",
        },
      ],
    };
  },
  mounted() {
    this.getMasters();
    this.getData();
  },
  methods: {
    async getMasters() {
      let masters = await axios.get(`tickets/masters`);
      masters = masters.data;
      // users
      masters.usersResponse.forEach((users) => {
        this.UsersList.push({
          id: users.id,
          text: users.full_name,
          value: users.id,
        });
      });
      // issues
      masters.issue_types.forEach((type) => {
        this.IssueTypes.push({
          id: type.id,
          text: type.description,
          value: type.description,
        });
      });
    },
    async getData() {
      let response = await axios.get(`tickets/${this.$route.params.ticketId}`);
      this.form = response.data.data;
    },
    async update() {
      try {
        this.isLoading = true;
        let response = await axios.patch(`/tickets/${this.$route.params.ticketId}`, this.form);
        let PageModule = 'UPDATED';
        let PageCode = 'TICKETS';
        const userIP = await this.getUserIp();
        this.saveAuditTrial(userIP, PageModule, PageCode)
        this.form = response.data.data;
        await this.handleFileUpload();
        this.isLoading = false;
        this.$router.back();
      } catch (e) {
        // alert("error");
        this.isLoading = false;
      }
    },
    async handleFileUpload() {
      this.isLoading = true;
      let ticketId = this.form.id;
      let formData = new FormData();
      formData.append("id", ticketId);
      formData.append("id", ticketId);
      let ImagePath1 = this.$refs.file_1?.files[0];
      formData.append('image_path1', ImagePath1);
      let ImagePath2 = this.$refs.file_2?.files[0];
      formData.append('image_path2', ImagePath2);
      let ImagePath3 = this.$refs.file_3?.files[0];
      formData.append('image_path3', ImagePath3);
      let ImagePath4 = this.$refs.file_4?.files[0];
      formData.append('image_path4', ImagePath4);
      await axios.post("tickets_image_uploads", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      this.isLoading = false;
    },
  },
};
</script>
