<template>
    <v-main>
        <v-container fluid>
            <v-row>
                <v-col md="12">
                    <v-card elevation="6" rounded="xxl">
                        <v-card-title>
                            <h3 class="font-weight-bold" v-text="'Admins'">
                            </h3>
                            <template>
                                <v-btn class="mx-2 mb-2" fab x-small color="primary" @click="AdminCreateDialog = true">
                                    <v-icon dark> mdi-plus </v-icon>
                                </v-btn>
                            </template>
                        </v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col md="6">
                                    <v-text-field solo rounded v-model="filter.search_keyword" label="Search ... "
                                        color="#e60040" prepend-inner-icon="mdi-magnify" clearable @click:clear="
                                (filter.search_keyword = null), (page = 1), getData()
                                " @keydown.enter="(page = 1), getData()">
                                    </v-text-field>
                                </v-col>
                                <v-col sm="3">
                                    <v-autocomplete ref="status" solo rounded v-model="filter.is_active"
                                        :items="statuses" clearable @click:clear="(filter.is_active = null), (page = 1)"
                                        label="Status" @input="getData"
                                        prepend-inner-icon="mdi-toggle-switch"></v-autocomplete>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col md="12">
                    <v-card elevation="6" rounded="xxl">
                        <v-card-text>
                            <section v-if="isLoading" style="height: 485px">
                                <v-progress-linear indeterminate color="primary"></v-progress-linear>
                            </section>
                            <v-simple-table v-else fixed-header height="485" class="striped-table">
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="text-start text-subtitle-2 font-weight-bold sr_no">Sr No</th>
                                            <th class="text-start text-subtitle-2 font-weight-bold">Full Name</th>
                                            <th class="text-start text-subtitle-2 font-weight-bold">Email</th>
                                            <th class="text-start text-subtitle-2 font-weight-bold">Branch</th>
                                            <th class="text-start text-subtitle-2 font-weight-bold">Department</th>
                                            <th class="text-start text-subtitle-2 font-weight-bold">Grade</th>
                                            <th class="text-right text-subtitle-2 font-weight-bold action_th">Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="users.length != 0">
                                        <tr v-for="(user, i) in users" :key="`user_${i}`">
                                            <td style="min-width: 85px">
                                                {{ i + 1 }}
                                            </td>
                                            <td>
                                                {{ user.full_name || "" }}
                                            </td>
                                            <td>
                                                {{ user.email || "" }}
                                            </td>
                                            <td>
                                                {{ user.branch || "" }}
                                            </td>
                                            <td>
                                                {{ user.department || "" }}
                                            </td>
                                            <td>
                                                {{ user.grade_id != null ? user.grade.description : "" }}
                                            </td>
                                            <td class="text-right">
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn v-bind="attrs" v-on="on" fab x-small class="ml-2"
                                                            color="green" dark
                                                            :to="`/user-permissions/${encryptIt(user.id)}`">
                                                            <v-icon dark> mdi-account </v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>User Wise Permission</span>
                                                </v-tooltip>
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn v-bind="attrs" v-on="on" class="ml-2" fab x-small
                                                            color="primary" @click="singleData(encryptIt(user.id))">
                                                            <v-icon dark> mdi-pencil </v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Edit this asset</span>
                                                </v-tooltip>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody v-else>
                                        <tr>
                                            <td colspan="7" class="text-center">No data found</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                            <v-pagination rounded v-model="page" :length="paginationLength">
                            </v-pagination>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-dialog v-model="AdminCreateDialog" width="900" persistent>
                <v-card>
                    <v-card-title class="text-h5">
                        <span v-if="isSingleView">
                            Edit Admin
                        </span>
                        <span v-else>
                            Create Admin
                        </span>
                        <v-spacer></v-spacer>
                        <v-btn elevation="0" @click="closeDialog()"> <v-icon>mdi-window-close</v-icon> </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col sm="6">
                                <v-text-field v-model="form.full_name" outlined label="Full name"
                                    prepend-inner-icon="mdi-map-marker"
                                    :error-messages="errors.full_name"></v-text-field>
                            </v-col>
                            <v-col sm="6">
                                <v-text-field v-model="form.email" outlined label="Email"
                                    prepend-inner-icon="mdi-map-marker" :error-messages="errors.email"></v-text-field>
                            </v-col>
                            <v-col sm="6">
                                <v-autocomplete ref="position" outlined v-model="form.branch" :items="branchs"
                                    label="Branch *" prepend-inner-icon="mdi-account-tie-outline" clearable
                                    :error-messages="errors.branch"></v-autocomplete>
                            </v-col>
                            <v-col sm="6">
                                <v-autocomplete ref="position" outlined v-model="form.department" :items="Departments"
                                    label="Department *" prepend-inner-icon="mdi-account-tie-outline" clearable
                                    :error-messages="errors.department"></v-autocomplete>
                            </v-col>
                            <v-col sm="6">
                                <v-autocomplete ref="position" outlined v-model="form.grade_id" :items="Grades"
                                    label="Grade *" prepend-inner-icon="mdi-account-tie-outline" clearable
                                    :error-messages="errors.grade"></v-autocomplete>
                            </v-col>
                            <v-col sm="6">
                                <v-btn class="float-end mt-4" :loading="isLoading" color="primary"
                                    @click="save">Save</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-container>
    </v-main>
</template>

<script>
import axios from "axios";
export default {
    name: "assetsPage",
    data() {
        return {
            filter: {
                search_keyword: "",
                is_active: "",
            },
            form: {
                role_id: 2,
                soft_password: 123456,
            },
            users: [],
            branchs: [],
            Departments: [],
            Grades: [],
            statuses: [
                {
                    id: 0,
                    text: "Select Active Status",
                    value: "",
                },
                { id: "ACTIVE", text: "ACTIVE", value: "1" },
                { id: "INACTIVE", text: "INACTIVE", value: "0" },
            ],
            page: 1,
            serialNoStarting: 1,
            paginationLength: 0,
            count: 0,
            CompanyId: null,
            isLoading: false,
            AdminCreateDialog: false,
            isSingleView: false,
        };
    },
    watch: {
        page: "getData",
        $route() {
            this.getData();
        },
    },
    async mounted() {
        await this.getMasters();
        this.getData();
    },
    methods: {
        async getMasters() {
            await axios
                .get(`users/superadmin_masters?company_email=${this.user.email}`)
                .then((masters) => {
                    masters = masters.data;
                    this.CompanyId = masters.companyId;
                    // branchs
                    masters.branchs.forEach((branch) => {
                        this.branchs.push({
                            id: branch.id,
                            text: branch.description,
                            value: branch.description,
                        });
                    });
                    //department
                    masters.departments.forEach((department) => {
                        this.Departments.push({
                            id: department.id,
                            text: department.description,
                            value: department.description,
                        });
                    });
                    //grades
                    masters.grades.forEach((grade) => {
                        this.Grades.push({
                            id: grade.id,
                            text: grade.description,
                            value: grade.id,
                        });
                    });
                })
                .catch(function (error) {
                    console.log(error);
                    // redirect to Home Page
                    // window.location.href = "/auth/login";
                });
        },
        async getData() {
            this.isLoading = true;
            this.filter.search_keyword = this.filter.search_keyword
                ? this.filter.search_keyword
                : "";
            this.filter.show_deleted = this.$route.query.open_trash ? 1 : 0;
            this.filter.is_active = this.filter.is_active
                ? this.filter.is_active
                : "";
            let query =
                "page=" +
                this.page +
                "&rowsPerPage=" +
                this.rowsPerPage +
                "&role_id=2" +
                "&is_active=" +
                this.filter.is_active +
                "&search_keyword=" +
                this.filter.search_keyword;
            await axios
                .get(`users?${query}`, {
                    headers: {
                        'Company-id': this.CompanyId,
                    }
                })
                .then((response) => {
                    this.users = response.data.data;
                    this.count = response.data.count;
                    this.serialNoStarting = (this.page - 1) * this.rowsPerPage;
                    this.paginationLength = Math.ceil(this.count / this.rowsPerPage);
                })
                .catch(function (error) {
                    console.log(error);
                    // window.location.href = "/auth/login";
                });
            this.isLoading = false;
        },
        async singleData(encryptIt) {
            this.form = [];
            this.isSingleView = true;
            await axios
                .get(`users/${encryptIt}`, {
                    headers: {
                        'Company-id': this.CompanyId,
                    }
                })
                .then((response) => {
                    this.form = response.data.data;
                    this.AdminCreateDialog = true;
                })
                .catch(function (error) {
                    console.log(error);
                    // window.location.href = "/auth/login";
                });
            this.isLoading = false;
        },
        async save() {
            this.isLoading = true;
            try {
                await axios.post(`users`, this.form, {
                    headers: {
                        'Company-id': this.CompanyId,
                    }
                });
                this.isLoading = false;
                this.AdminCreateDialog = false;
                this.getData();
            } catch (error) {
                this.isLoading = false;
            }

        },
        closeDialog() {
            this.form = [];
            this.AdminCreateDialog = false;
            this.isSingleView = false;
        }
    },
};
</script>