<template>
  <v-main>
    <v-container fluid>
      <v-card elevation="6" rounded="xxl">
        <v-card-title>
          <v-btn class="mx-2" fab x-small color="primary" @click="$router.back()">
            <v-icon dark> mdi-arrow-left </v-icon>
          </v-btn>
          <span v-if="!isViewOnly"> Edit User </span><span v-else> View User </span>
        </v-card-title>
        <v-card-text>
          <v-col cols="12">
            <v-row style="min-width: 1221px;">
              <v-col xl="2" lg="2" md="4" class="px-0 py-0" style="max-height: 900px; overflow-y: auto">
                <v-col class="p-image py-0" align="center">
                  <div class="avatar-upload">
                    <div class="avatar-edit">
                      <input type="file" ref="ProfileImage" id="imageUpload" accept=".png, .jpg, .jpeg"
                        @change="readURL" />
                      <label for="imageUpload"></label>
                    </div>
                    <div class="avatar-preview">
                      <div :style="{
            backgroundImage: 'url(' + imagePreview + ')',
          }" id="imagePreview"></div>
                    </div>
                  </div>
                </v-col>
                <v-treeview :items="items" class="treeview-class text-subtitle-1" open-all activatable color="black"
                  transition item-key="name" @update:active="userdetails">
                </v-treeview>
              </v-col>
              <v-divider vertical></v-divider>
              <v-col cols="9" style="height: 670px" class="overflow-y-auto">
                <v-scroll-y-transition mode="out-in">
                  <v-row v-if="isGeneralInformation" class="mx-auto">
                    <v-col cols="12">
                      <h1>General information</h1>
                    </v-col>
                    <v-col sm="4">
                      <v-text-field v-model="form.full_name" outlined placeholder="Full Name (as per Aadhar Card)*"
                        prepend-inner-icon="mdi-buffet" :error-messages="errors.full_name"
                        hint="Enter Full Name as per your Aadhar Card"></v-text-field>
                    </v-col>
                    <v-col sm="4">
                      <v-text-field v-model="form.employee_code" outlined placeholder="Enter Employee Code *"
                        prepend-inner-icon="mdi-buffet" :error-messages="errors.employee_code"></v-text-field>
                    </v-col>
                    <v-col sm="4">
                      <v-text-field v-model="form.email" outlined placeholder="Enter Email *"
                        prepend-inner-icon="mdi-email" :error-messages="errors.email"></v-text-field>
                    </v-col>
                    <v-col sm="4">
                      <v-text-field v-model="form.aadhar_no" outlined placeholder="Enter Aadhar number *"
                        prepend-inner-icon="mdi-file-document-arrow-right" :error-messages="errors.aadhar_no" counter
                        maxlength="12"></v-text-field>
                    </v-col>
                    <v-col sm="4">
                      <v-text-field v-model="form.phone" outlined placeholder="Enter Phone Number"
                        prepend-inner-icon="mdi-phone"></v-text-field>
                    </v-col>
                    <v-col xl="2" lg="4" md="4" sm="12" cols="12">
                      <v-menu v-model="doj_menu" :close-on-content-click="false" :nudge-right="40"
                        transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field outlined v-model="form.doj" label="Date Of Joining *"
                            prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" clearable
                            @click:clear="doj = null" :error-messages="errors.doj"></v-text-field>
                        </template>
                        <v-date-picker :id="`doj`" v-model="form.doj" @change="doj_menu = false"></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col xl="2" lg="4" md="4" sm="12" cols="12">
                      <v-menu v-model="dob_menu" :close-on-content-click="false" :nudge-right="40"
                        transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field outlined v-model="form.date_of_birth" label="Date Of Birth *"
                            prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" clearable
                            @click:clear="form.date_of_birth = null"
                            :error-messages="errors.date_of_birth"></v-text-field>
                        </template>
                        <v-date-picker :id="`dob`" v-model="form.date_of_birth" @change="checkAge"></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col sm="4">
                      <v-autocomplete ref="position" outlined v-model="form.position_id" :items="positionItems"
                        label="Position *" prepend-inner-icon="mdi-account-tie-outline" clearable @input="UpdateUpline"
                        :error-messages="errors.position_id"></v-autocomplete>
                    </v-col>
                    <v-col sm="4">
                      <v-autocomplete ref="upline_position" outlined readonly v-model="form.upline_position_id"
                        :items="positionItems" label="Upline Position *"
                        prepend-inner-icon="mdi-account-tie-hat-outline"
                        messages="this will be automatically select based on your position."
                        :error-messages="errors.upline_position_id"></v-autocomplete>
                    </v-col>
                    <v-col sm="4">
                      <v-autocomplete ref="upline_user" outlined v-model="form.upline_user_id" :items="uplineUserItems"
                        label="Upline User" prepend-inner-icon="mdi-account" clearable
                        :error-messages="errors.user_id"></v-autocomplete>
                    </v-col>
                    <v-col xl="4" lg="4" md="4" sm="12" cols="12">
                      <v-text-field v-model="form.pf_no" outlined placeholder="Enter PF No"
                        prepend-inner-icon="mdi-file-document-arrow-right"
                        :error-messages="errors.pf_no"></v-text-field>
                    </v-col>
                    <v-col xl="4" lg="4" md="4" sm="12" cols="12">
                      <v-text-field v-model="form.reference_1_name" outlined placeholder="Enter Reference Name"
                        prepend-inner-icon="mdi-account-child" :error-messages="errors.reference_1_name"></v-text-field>
                    </v-col>
                    <v-col xl="4" lg="4" md="4" sm="12" cols="12">
                      <v-text-field v-model="form.reference_1_phone_no" outlined placeholder="Enter Reference Phone No"
                        prepend-inner-icon="mdi-account-child"
                        :error-messages="errors.reference_1_phone_no"></v-text-field>
                    </v-col>
                    <v-col xl="4" lg="4" md="4" sm="12" cols="12">
                      <v-text-field v-model="form.reference_1_relation" outlined placeholder="Enter Reference Relation"
                        prepend-inner-icon="mdi-account-child"
                        :error-messages="errors.reference_1_relation"></v-text-field>
                    </v-col>
                    <v-col xl="4" lg="4" md="4" sm="12" cols="12">
                      <v-text-field v-model="form.reference_2_name" outlined placeholder="Enter Second Reference Name"
                        prepend-inner-icon="mdi-account-child" :error-messages="errors.reference_2_name"></v-text-field>
                    </v-col>
                    <v-col xl="4" lg="4" md="4" sm="12" cols="12">
                      <v-text-field v-model="form.reference_2_phone_no" outlined
                        placeholder="Enter Second Reference Phone No" prepend-inner-icon="mdi-account-child"
                        :error-messages="errors.reference_2_phone_no"></v-text-field>
                    </v-col>
                    <v-col xl="4" lg="4" md="4" sm="12" cols="12">
                      <v-text-field v-model="form.reference_2_relation" outlined
                        placeholder="Enter Second Reference Relation" prepend-inner-icon="mdi-account-child"
                        :error-messages="errors.reference_2_relation"></v-text-field>
                    </v-col>
                    <v-col xl="4" lg="4" md="4" sm="12" cols="12">
                      <v-text-field v-model="form.esic_no" outlined placeholder="Enter ESIC No"
                        prepend-inner-icon="mdi-file-document-arrow-right"
                        :error-messages="errors.esic_no"></v-text-field>
                    </v-col>
                    <v-col xl="4" lg="4" md="4" sm="12" cols="12">
                      <v-text-field v-model="form.pf_uan_no" outlined placeholder="Enter PF UAN No"
                        prepend-inner-icon="mdi-file-document-arrow-right"
                        :error-messages="errors.pf_uan_no"></v-text-field>
                    </v-col>
                    <v-col xl="4" lg="4" md="4" sm="12" cols="12">
                      <v-text-field v-model="form.pan_no" outlined placeholder="Enter Pan No"
                        prepend-inner-icon="mdi-file-document-arrow-right"
                        :error-messages="errors.pan_no"></v-text-field>
                    </v-col>
                    <v-col xl="4" lg="4" md="4" sm="12" cols="12">
                      <v-text-field v-model="form.insurance_no" outlined placeholder="Enter Insurance No"
                        prepend-inner-icon="mdi-file-document-arrow-right"
                        :error-messages="errors.insurance_no"></v-text-field>
                    </v-col>
                    <v-col sm="4">
                      <v-autocomplete ref="status" outlined label="Device Reset status"
                        v-model="form.is_requested_device_reset" :items="typeItems"
                        prepend-inner-icon="mdi-cellphone-remove"></v-autocomplete>
                    </v-col>
                    <v-col sm="4">
                      <v-autocomplete ref="status" outlined label="Location Reset status"
                        v-model="form.is_requested_location_reset" :items="typeItems"
                        prepend-inner-icon="mdi-map-marker-remove-outline"></v-autocomplete>
                    </v-col>
                    <v-col cols="12">
                      <v-divider class="border-opacity-100"></v-divider>
                    </v-col>
                    <v-col sm="4">
                      <v-autocomplete ref="position" outlined v-model="form.branch" :items="branchs" label="Branch *"
                        prepend-inner-icon="mdi-account-tie-outline" clearable
                        :error-messages="errors.branch"></v-autocomplete>
                    </v-col>
                    <v-col sm="4">
                      <v-autocomplete ref="position" outlined v-model="form.department" :items="Departments"
                        label="Department *" prepend-inner-icon="mdi-account-tie-outline" clearable
                        :error-messages="errors.department"></v-autocomplete>
                    </v-col>
                    <v-col sm="4">
                      <v-autocomplete ref="position" outlined v-model="form.grade_id" :items="Grades" label="Grade *"
                        prepend-inner-icon="mdi-account-tie-outline" clearable
                        :error-messages="errors.grade"></v-autocomplete>
                    </v-col>
                    <v-col sm="4">
                      <v-autocomplete ref="position" outlined v-model="form.designation" :items="Designations"
                        label="Designation *" prepend-inner-icon="mdi-account-tie-outline" clearable
                        :error-messages="errors.designation"></v-autocomplete>
                    </v-col>
                    <v-col sm="4">
                      <v-autocomplete ref="shits" outlined v-model="form.shift_id" :items="UserShifts" label="Shifts *"
                        prepend-inner-icon="mdi-exit-run" clearable :error-messages="errors.shift_id"></v-autocomplete>
                    </v-col>

                    <v-col cols="12">
                      <v-divider class="mt-5 mb-5"> </v-divider>
                      <section v-if="company.is_SUSP_enabled">
                        <template v-if="form.user_devices.length">
                          <v-divider class="my-2"> </v-divider>
                          <h3 class="my-4">List of Device(s) for this User:</h3>
                          <v-row v-for="(device, uD) in form.user_devices" :key="`device${uD}`">
                            <v-col sm="4">
                              <v-text-field v-model="device.imei_no" outlined label="Imei No *"
                                prepend-inner-icon="mdi-phone" :error-messages="errors[`user_devices.${uD}.imei_no`]
            ? errors[`user_devices.${uD}.imei_no`]
            : ``
            "></v-text-field>
                            </v-col>
                            <v-col sm="1">
                              <v-dialog v-model="isDeleteDeviceDialogs[p]" max-width="600px">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn fab class="ml-9" small dark color="red" v-bind="attrs" v-on="on">
                                    <v-icon dark> mdi-close-thick </v-icon>
                                  </v-btn>
                                </template>
                                <v-card elevation="10">
                                  <v-card-title>
                                    <v-icon large color="primary">
                                      mdi-information-outline
                                    </v-icon>
                                    &nbsp; Do you want to remove the report?
                                  </v-card-title>
                                  <v-card-text>
                                    <v-btn color="primary" @click.stop="deleteDevice(device)">
                                      Yes
                                    </v-btn>
                                    &nbsp;
                                    <v-btn color="red" dark @click.stop="isDeleteDeviceDialogs = []">
                                      No
                                    </v-btn>
                                  </v-card-text>
                                </v-card>
                              </v-dialog>
                            </v-col>
                          </v-row>
                        </template>
                        <v-row>
                          <v-col sm="12" class="mb-5">
                            <v-btn small rounded color="primary" @click="Add_Empty_Device">
                              Add Device
                              <v-icon dark> mdi-plus </v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                        <v-divider class="mt-5 mb-5"> </v-divider>
                      </section>
                    </v-col>
                    <v-col cols="12">
                      <section v-if="company.is_SUSL_enabled">
                        <template v-if="form.user_locations.length">
                          <v-divider class="my-2"> </v-divider>
                          <h3 class="my-4">List of Location(s) for this User:</h3>
                          <v-row v-for="(location, uL) in form.user_locations" :key="`location${uL}`">
                            <v-col sm="3">
                              <v-text-field v-model="location.lat" outlined label="Lat *"
                                prepend-inner-icon="mdi-map-marker-radius-outline" :error-messages="errors[`user_locations.${uL}.lat`]
            ? errors[`user_locations.${uL}.lat`]
            : ``
            "></v-text-field>
                            </v-col>
                            <v-col sm="3">
                              <v-text-field v-model="location.long" outlined label="Long *"
                                prepend-inner-icon="mdi-map-marker-radius-outline" :error-messages="errors[`user_locations.${uL}.long`]
            ? errors[`user_locations.${uL}.long`]
            : ``
            "></v-text-field>
                            </v-col>
                            <v-col sm="4">
                              <v-textarea label="Address" auto-grow outlined rows="1" row-height="15"></v-textarea>
                            </v-col>
                            <v-col sm="1">
                              <v-dialog v-model="isDeleteLocationDialogs[p]" max-width="600px">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn fab class="ml-9" small dark color="red" v-bind="attrs" v-on="on">
                                    <v-icon dark> mdi-close-thick </v-icon>
                                  </v-btn>
                                </template>
                                <v-card elevation="10">
                                  <v-card-title>
                                    <v-icon large color="primary">
                                      mdi-information-outline
                                    </v-icon>
                                    &nbsp; Do you want to remove the report?
                                  </v-card-title>
                                  <v-card-text>
                                    <v-btn color="primary" @click.stop="deleteLocation(location)">
                                      Yes
                                    </v-btn>
                                    &nbsp;
                                    <v-btn color="red" dark @click.stop="isDeleteLocationDialogs = []">
                                      No
                                    </v-btn>
                                  </v-card-text>
                                </v-card>
                              </v-dialog>
                            </v-col>
                          </v-row>
                        </template>
                        <v-row>
                          <v-col sm="12" class="mb-5">
                            <v-btn small rounded color="primary" @click="Add_Empty_Location">
                              Add Location
                              <v-icon dark> mdi-plus </v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                        <v-divider class="mt-5 mb-5"> </v-divider>
                      </section>
                    </v-col>
                  </v-row>
                </v-scroll-y-transition>
                <v-scroll-y-transition mode="out-in">
                  <v-row class="mx-auto" v-if="isPersonalInformation">
                    <v-col cols="12">
                      <h1>Personal information</h1>
                    </v-col>
                    <v-col sm="6">
                      <v-textarea v-model="form.current_address" outlined placeholder="Enter Current Address"
                        prepend-inner-icon="mdi-office-building" :error-messages="errors.current_address" auto-grow
                        row-height="25" rows="5" variant="outlined"></v-textarea>
                    </v-col>
                    <v-col sm="6" class="py-0">
                      <v-checkbox v-model="isPermenant" :label="`Permanent Address (  Same as current address )`"
                        class="isparmenent-class mt-0" @change="updatePermanentAddress"></v-checkbox>
                      <v-textarea v-model="form.permanent_address" outlined placeholder="Enter Permenant Address"
                        prepend-inner-icon="mdi-office-building" :error-messages="errors.permanent_address" auto-grow
                        row-height="25" rows="4" variant="outlined"></v-textarea>
                    </v-col>
                    <v-col sm="6">
                      <v-text-field type="number" v-model="form.pincode" outlined placeholder="Enter Pincode"
                        prepend-inner-icon="mdi-office-building" :error-messages="errors.pincode"
                        variant="outlined"></v-text-field>
                    </v-col>
                    <v-col sm="6">
                      <v-text-field type="number" v-model="form.permanent_pincode" outlined
                        placeholder="Enter Permanent Pincode" prepend-inner-icon="mdi-office-building"
                        :error-messages="errors.permanent_pincode" variant="outlined"></v-text-field>
                    </v-col>
                    <v-col sm="4">
                      <v-autocomplete ref="upline_position" outlined v-model="form.blood_group" :items="BloodGroups"
                        label="Blood Group *" prepend-inner-icon="mdi-account-tie-hat-outline"
                        :error-messages="errors.blood_group"></v-autocomplete>
                    </v-col>
                    <v-col sm="4">
                      <v-text-field outlined v-model="form.highest_qualification" label="Highest Qualification *"
                        prepend-inner-icon="mdi-account-tie-hat-outline"
                        :error-messages="errors.highest_qualification"></v-text-field>
                    </v-col>
                  </v-row>
                </v-scroll-y-transition>
                <v-scroll-y-transition mode="out-in">
                  <v-row class="mx-auto" v-if="isKycDetails">
                    <!-- v-if="isKycDetails" -->
                    <v-col cols="12">
                      <h1>KYC Details</h1>
                    </v-col>
                    <v-col cols="12" v-for="(document, i) in form.Userdocuments" :key="`document${i}`">
                      <v-row>
                        <v-col sm="3">
                          <v-autocomplete outlined v-model="document.document_id" :items="Userdocuments"
                            label="Select Document *" prepend-inner-icon="mdi-account-tie-hat-outline"
                            :error-messages="errors.document_id" @change="PlaceholderTitle"></v-autocomplete>
                        </v-col>
                        <v-col sm="5">
                          <v-text-field v-if="Placeholder == 'AADHAR CARD'" v-model="document.document_no" outlined
                            :placeholder="'Enter ' + Placeholder + ' Number'" prepend-inner-icon="mdi-exit-run" counter
                            maxlength="12" :error-messages="errors.document_no" messages="Select Document First."
                            :readonly="!document.document_id"></v-text-field>
                          <v-text-field v-else v-model="document.document_no" outlined counter maxlength="10"
                            :placeholder="'Enter ' + Placeholder + ' Number'" prepend-inner-icon="mdi-exit-run"
                            :error-messages="errors.document_no"></v-text-field>
                        </v-col>
                        <v-col sm="2">
                          <input type="file" :id="'document_image_path_' + (i + 1)"
                            :name="'document_image_path_' + (i + 1)" :ref="'document_image_path_' + (i + 1)"
                            @change="handleDocumentFileChange($event, i)" />
                          <template v-if="document.document_image_path != null">
                            <v-img class="mt-2" v-if="document.document_image_path != null"
                              :src="mediaUrl + document.document_image_path" alt="image 1"
                              style="height: 50%; width: 50% !important" />
                            <br />
                            <v-row class="align-center">
                              <v-btn x-small class="mx-2" :href="`${mediaUrl}${document.document_image_path}`"
                                target="_blank" color="primary">View</v-btn>
                              <v-btn x-small class="reset_image my-1" color="red" dark
                                @click="removeDocumentImage(i)">Remove</v-btn>
                            </v-row>
                          </template>
                        </v-col>
                        <v-col cols="2">
                          <v-btn block height="48" v-if="!isViewOnly" class="ml-9" dark color="primary"
                            @click="deleteDocument(document)">
                            Remove
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col sm="12" class="mb-5">
                      <v-btn small color="primary" @click="Add_Empty_Document">
                        Add Document
                        <v-icon dark> mdi-plus </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-scroll-y-transition>
                <v-scroll-y-transition mode="out-in">
                  <v-row class="mx-auto" v-if="isPaymentInformation">
                    <!-- v-if="isKycDetails" -->
                    <v-col cols="12">
                      <h1>Payment Information</h1>
                    </v-col>
                    <v-col cols="12" v-for="(payment, i) in form.PaymentInformation" :key="`payment${i}`">
                      <v-row>
                        <v-col sm="4">
                          <v-text-field v-model="payment.bank_name" outlined :placeholder="'Enter Bank Name'"
                            prepend-inner-icon="mdi-exit-run" :error-messages="errors.bank_name" auto-grow
                            row-height="25" rows="4" variant="outlined"></v-text-field>
                        </v-col>
                        <v-col sm="4">
                          <v-text-field v-model="payment.account_no" outlined :placeholder="'Enter Account Number'"
                            prepend-inner-icon="mdi-exit-run" :error-messages="errors.account_no" auto-grow
                            row-height="25" rows="4" variant="outlined"></v-text-field>
                        </v-col>
                        <v-col sm="4">
                          <v-text-field v-model="payment.ifsc_code" outlined :placeholder="'Enter IFSC Code'"
                            prepend-inner-icon="mdi-exit-run" :error-messages="errors.ifsc_code"
                            variant="outlined"></v-text-field>
                        </v-col>
                        <v-col sm="2">
                          <input type="file" :id="'cheque_image_' + (i + 1)" :name="'cheque_image_' + (i + 1)"
                            :ref="'cheque_image_' + (i + 1)" @change="handleFileChange($event, i)" />
                          <template v-if="payment.cheque_image_path != null">
                            <v-img class="mt-2" v-if="payment.cheque_image_path != null"
                              :src="mediaUrl + payment.cheque_image_path" alt="image 1"
                              style="height: 50%; width: 50% !important" />
                            <br />
                            <v-row class="align-center">
                              <v-btn x-small class="mx-2" :href="`${mediaUrl}${payment.cheque_image_path}`"
                                target="_blank" color="primary">View</v-btn>
                              <v-btn x-small class="reset_image my-1" color="red" dark
                                @click="removeImage(i)">Remove</v-btn>
                            </v-row>
                          </template>
                        </v-col>
                        <v-col cols="2">
                          <v-btn block height="48" v-if="!isViewOnly" class="ml-9" dark color="primary"
                            @click="deletePayment(payment)">
                            Remove
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col sm="12" class="mb-5">
                      <v-btn small color="primary" @click="Add_Empty_payment">
                        Add Bank Detail
                        <v-icon dark> mdi-plus </v-icon>
                      </v-btn>
                    </v-col>
                    <v-col cols="12">
                      <v-btn class="float-end" v-if="!isViewOnly" :loading="isLoading" :disabled="isLoading" rounded
                        color="primary" :dark="!isLoading" @click="save">
                        Update User
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-scroll-y-transition>
                <v-scroll-y-transition mode="out-in">
                  <v-row class="mx-auto" v-if="isAssets">
                    <v-col cols="12">
                      <h1>Assets</h1>
                    </v-col>
                    <v-col cols="12" v-for="(asset, i) in form.Assets" :key="`payment${i}`">
                      <v-row>
                        <v-col sm="3">
                          <v-autocomplete outlined v-model="asset.asset_type_id" :items="AssetTypes"
                            label="Select Asset Type *" prepend-inner-icon="mdi-account-tie-hat-outline"
                            :error-messages="errors.asset_type_id"></v-autocomplete>
                        </v-col>
                        <v-col sm="4">
                          <v-autocomplete outlined v-model="asset.asset_id" :items="AssetIds" label="Select Asset Id *"
                            prepend-inner-icon="mdi-account-tie-hat-outline"
                            :error-messages="errors.asset_id"></v-autocomplete>
                        </v-col>
                        <v-col cols="2">
                          <v-btn block height="48" v-if="!isViewOnly" class="ml-9" dark color="primary"
                            @click="deleteAsset(asset)">
                            Remove
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col sm="12" class="mb-5">
                      <v-btn small color="primary" @click="Add_Empty_asset">
                        Add Asset
                        <v-icon dark> mdi-plus </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-scroll-y-transition>
              </v-col>
            </v-row>
          </v-col>
        </v-card-text>
        <v-card-actions>
          <v-col cols="12">
            <v-btn class="float-end" :loading="isLoading" :disabled="isLoading" rounded color="primary"
              :dark="!isLoading" @click="save">
              Update User
            </v-btn>
          </v-col>
        </v-card-actions>
        <v-dialog v-model="dialog" max-width="400px">
          <v-card>
            <v-card-title class="headline red--text">Age Verification</v-card-title>
            <v-card-text>
              The age of this user is below 18. Do you want to continue?
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="confirmAge">Yes</v-btn>
              <v-btn color="blue darken-1" text @click="declineAge">No</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";
export default {
  name: "UserEditPage",
  data() {
    return {
      form: {
        user_devices: [],
        user_locations: [],
        Userdocuments: [],
        PaymentInformation: [],
        Assets: [],
      },
      supervisorDownlined: [
        "Field Executive",
        "Merchandiser",
        "Promoter",
        "Van Sales",
      ],
      companyId: "",
      Placeholder: "",
      positionItems: [],
      uplineUserItems: [],
      isDeleteDeviceDialogs: [],
      isDeleteLocationDialogs: [],
      branchs: [],
      Designations: [],
      BloodGroups: [],
      HighestQualifications: [],
      Grades: [],
      Departments: [],
      Userdocuments: [],
      AssetTypes: [],
      AssetIds: [],
      UserShifts: [],
      isLoading: false,
      imagePreview:
        "https://uxwing.com/wp-content/themes/uxwing/download/peoples-avatars/default-profile-picture-grey-male-icon.png", // default image
      isGeneralInformation: false,
      isPersonalInformation: false,
      isKycDetails: false,
      isPaymentInformation: false,
      isAssets: false,
      doj_menu: false,
      doe_menu: false,
      isPermenant: false,
      dob_menu: false,
      // doe_menu: false,
      items: [
        {
          id: 1,
          name: "Employee",
          children: [
            { name: "General information" },
            { name: "Personal information" },
            { name: "KYC Details" },
            { name: "Payment information" },
            { name: "Assets" },
          ],
        },
      ],
      typeItems: [
        { id: "PENDING", text: "PENDING", value: 1 },
        { id: "APPROVED", text: "APPROVED", value: 0 },
      ],
      chequeImages: {},
      documentImages: {},
      dialog: false,
      isViewOnly: false,
      ageBelow18: false,
    };
  },
  mounted() {
    this.isGeneralInformation = true;
    if (this.$route.query.isViewOnly) {
      this.isViewOnly = true;
    }
    this.getMasters();
    this.getData();
    console.log(this.form);
  },
  methods: {
    async getMasters() {
      await axios
        .get(`users/masters`)
        .then((masters) => {
          masters = masters.data;
          //Position
          masters.positions.forEach((position) => {
            this.positionItems.push({
              id: position.id,
              text: position.name,
              value: position.id,
            });
          });
          // branchs
          masters.branchs.forEach((branch) => {
            this.branchs.push({
              id: branch.id,
              text: branch.description,
              value: branch.description,
            });
          });
          //designations
          masters.designations.forEach((designation) => {
            this.Designations.push({
              id: designation.id,
              text: designation.description,
              value: designation.description,
            });
          });
          //blood groupds
          masters.bloodgroups.forEach((blood) => {
            this.BloodGroups.push({
              id: blood.id,
              text: blood.description,
              value: blood.description,
            });
          });
          //department
          masters.departments.forEach((department) => {
            this.Departments.push({
              id: department.id,
              text: department.description,
              value: department.description,
            });
          });
          //grades
          masters.grades.forEach((grade) => {
            this.Grades.push({
              id: grade.id,
              text: grade.description,
              value: grade.id,
            });
          });
          //Documents
          masters.Userdocuments.forEach((userdocument) => {
            this.Userdocuments.push({
              id: userdocument.id,
              text: userdocument.description,
              value: userdocument.id,
            });
          });
          //AssetsType
          masters.AssetTypes.forEach((assetType) => {
            this.AssetTypes.push({
              id: assetType.id,
              text: assetType.description,
              value: assetType.id,
            });
          });
          //AssetsId
          masters.AssetIds.forEach((assetId) => {
            this.AssetIds.push({
              id: assetId.id,
              text: assetId.description,
              value: assetId.id,
            });
          });
          //Shifts
          masters.ShiftManagementsResponse.forEach((shift) => {
            this.UserShifts.push({
              id: shift.id,
              text: shift.shift_name,
              value: shift.id,
            });
          });
        })
        .catch(function (error) {
          console.log(error);
          // redirect to Home Page
          // window.location.href = "/auth/login";
        });
    },
    async getData() {
      this.isLoading = true;
      let encryptedId = this.$route.params.userId;
      axios
        .get(`/users/${encryptedId}`)
        .then((response) => {
          response.data.data.user_documents.forEach((el) => {
            this.form.Userdocuments.push({
              id: el.id,
              document_id: el.document_id,
              document_no: el.document_no,
              document_image_path: el.document_image_path,
            });
          });
          response.data.data.user_payment_details.forEach((el) => {
            this.form.PaymentInformation.push({
              id: el.id,
              bank_name: el.bank_name,
              account_no: el.account_no,
              ifsc_code: el.ifsc_code,
              cheque_image_path: el.cheque_image,
            });
          });
          response.data.data.user_assets.forEach((el) => {
            this.form.Assets.push({
              id: el.id,
              asset_type_id: el.asset_type_id,
              asset_id: el.asset_id,
            });
          });
          const fetchedData = response.data.data;
          this.form = {
            ...this.form,
            ...fetchedData,
          };
          console.log(this.form);
          this.old_json = response.data.data;
          this.getUplineUsers();
        })
        .catch((error) => {
          // redirect to Home Page
          // window.location.href = "/auth/login";
          console.error("Error fetching data:", error);
        });
      this.isLoading = false;
    },
    async save() {
      try {
        this.isLoading = true;
        this.form.LoggedUserRole = this.user.roles[0].name;
        let response = await axios.post(`/users`, this.form);
        this.UserPaymentDetailIds = response.data.PaymentDetailIds;
        this.UserKycDetailIds = response.data.UserKycDetailIds;
        await this.handleFileUpload();
        await this.saveUserTimestamp(this.old_json, this.form);
        let PageModule = "UPDATED";
        let PageCode = "EMPLOYEES";
        const userIP = await this.getUserIp();
        this.saveAuditTrial(userIP, PageModule, PageCode);
        this.$router.push("/users");
      } catch (e) {
        this.isLoading = false;
      }
    },
    Add_Empty_Device() {
      let user_device = {
        imei_no: "",
      };
      this.form.user_devices.push(user_device);
    },
    deleteDevice(user_device) {
      this.form.user_devices.splice(
        this.form.user_devices.indexOf(user_device),
        1
      );
      this.isDeleteDeviceDialogs = [];
    },
    Add_Empty_Location() {
      let user_location = {
        imei_no: "",
      };
      this.form.user_locations.push(user_location);
    },
    Add_Empty_Document() {
      let Userdocument = {
        document_id: "",
        document_no: "",
      };
      this.form.Userdocuments.push(Userdocument);
    },
    Add_Empty_payment() {
      let payment = {
        account_no: "",
        bank_name: "",
        ifsc_code: "",
      };
      this.form.PaymentInformation.push(payment);
    },
    Add_Empty_asset() {
      let asset = {
        asset_type: "",
        asset_id: "",
      };
      this.form.Assets.push(asset);
    },
    deleteAsset(asset) {
      this.form.Assets.splice(this.form.Assets.indexOf(asset), 1);
    },
    deletePayment(payment) {
      this.form.PaymentInformation.splice(
        this.form.PaymentInformation.indexOf(payment),
        1
      );
    },
    deleteDocument(document) {
      this.form.Userdocuments.splice(
        this.form.Userdocuments.indexOf(document),
        1
      );
    },
    deleteLocation(user_location) {
      this.form.user_locations.splice(
        this.form.user_locations.indexOf(user_location),
        1
      );
      this.isDeleteLocationDialogs = [];
    },
    PlaceholderTitle(document_id) {
      let document = this.Userdocuments.find(
        (element) => element.id == document_id
      );
      console.log(document);
      this.Placeholder = document.text;
    },
    UpdateUpline() {
      console.log("Position:" + this.form.position_id);

      let current = this.positionItems.find(
        (position) => position.id == this.form.position_id
      );
      if (
        this.supervisorDownlined.find((position) => position == current.text)
      ) {
        let upline = this.positionItems.find(
          (position) => position.text == "Supervisor"
        );
        if (upline) {
          this.form.upline_position_id = upline ? upline.id : null;
          this.getUplineUsers();
        }
      } else {
        let upline =
          this.positionItems[this.positionItems.indexOf(current) - 1];
        if (upline) {
          this.form.upline_position_id = upline ? upline.id : null;
          this.getUplineUsers();
        }
      }
    },
    async getUplineUsers() {
      this.uplineUserItems = [];
      let query = "position_id=" + this.form.upline_position_id;
      await axios
        .get(`users?${query}`)
        .then((users) => {
          //User
          users.data.data.forEach((user) => {
            this.uplineUserItems.push({
              id: user.id,
              text: user.full_name,
              value: user.id,
            });
          });
        })
        .catch(function (error) {
          console.log(error);
          // redirect to Home Page
          window.location.href = "/auth/login";
        });
    },
    userdetails(node) {
      console.log(node);
      if (node == "General information") {
        this.isGeneralInformation = true;
        this.isPersonalInformation = false;
        this.isPaymentInformation = false;
        this.isKycDetails = false;
        this.isAssets = false;
      }
      if (node == "Personal information") {
        this.isPersonalInformation = true;
        this.isGeneralInformation = false;
        this.isPaymentInformation = false;
        this.isKycDetails = false;
        this.isAssets = false;
      }
      if (node == "KYC Details") {
        this.isKycDetails = true;
        this.isPersonalInformation = false;
        this.isGeneralInformation = false;
        this.isPaymentInformation = false;
        this.isAssets = false;
      }
      if (node == "Payment information") {
        this.isPaymentInformation = true;
        this.isPersonalInformation = false;
        this.isGeneralInformation = false;
        this.isKycDetails = false;
        this.isAssets = false;
      }
      if (node == "Assets") {
        this.isAssets = true;
        this.isPersonalInformation = false;
        this.isGeneralInformation = false;
        this.isKycDetails = false;
        this.isPaymentInformation = false;
      }
    },
    readURL(event) {
      const input = event.target;
      if (input.files && input.files[0]) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.form.image_path = null;
          this.imagePreview = e.target.result;
          console.log(this.imagePreview);
        };
        reader.readAsDataURL(input.files[0]);
      }
    },
    calculateAge(dob) {
      const birthDate = new Date(dob);
      const today = new Date();
      let age = today.getFullYear() - birthDate.getFullYear();
      const monthDifference = today.getMonth() - birthDate.getMonth();

      if (
        monthDifference < 0 ||
        (monthDifference === 0 && today.getDate() < birthDate.getDate())
      ) {
        age--;
      }

      return age;
    },
    checkAge() {
      const age = this.calculateAge(this.form.date_of_birth);
      this.ageBelow18 = age < 18;

      if (this.ageBelow18) {
        this.dialog = true;
      } else {
        this.dob_menu = false;
      }
    },
    confirmAge() {
      this.dialog = false;
      this.dob_menu = false;
    },
    declineAge() {
      this.dialog = false;
      this.form.date_of_birth = null;
    },
    updatePermanentAddress() {
      if (this.isPermenant) {
        this.form.permanent_address = this.form.current_address;
        this.form.permanent_pincode = this.form.pincode;
      } else {
        this.form.permanent_address = "";
        this.form.permanent_pincode = "";
      }
    },
    removeImage(index) {
      this.$refs[`cheque_image_${index + 1}`][0].value = null;
      this.form.PaymentInformation[index].cheque_image_path = null;
      this.$set(this.chequeImages, index, `cheque_image_null`);
    },
    removeDocumentImage(index) {
      console.log(this.documentImages);
      this.$refs[`document_image_path_${index + 1}`][0].value = null;
      this.form.Userdocuments[index].document_image_path = null;
      console.log(this.documentImages);
      this.$set(this.documentImages, index, `document_image_path_null`);
      console.log(this.documentImages);
    },
    handleFileChange(event, index) {
      console.log(this.chequeImages);
      const file = event.target.files[0];
      if (file) {
        this.$set(this.chequeImages, index, file);
      } else {
        // Set the respective index to 'cheque_image_null' when the file is removed
        this.$set(this.chequeImages, index, `cheque_image_null`);
      }
    },
    handleDocumentFileChange(event, index) {
      console.log(this.documentImages);
      const file = event.target.files[0];
      if (file) {
        this.$set(this.documentImages, index, file);
      } else {
        // Set the respective index to 'cheque_image_null' when the file is removed
        this.$set(this.documentImages, index, `document_image_path_null`);
      }
    },
    async handleFileUpload() {
      console.log("eneter");
      this.isLoading = true;
      let formData = new FormData();
      formData.append("userId", this.form.id);

      let ProfileImage = this.$refs.ProfileImage?.files[0];
      if (ProfileImage) {
        formData.append("ProfileImage", ProfileImage);
      }
      console.log("ProfileImage", ProfileImage);
      console.log("this.chequeImages", this.chequeImages);
      this.UserPaymentDetailIds.forEach((payment, index) => {
        if (this.chequeImages[index]) {
          formData.append(`cheque_image_${payment}`, this.chequeImages[index]);
        }
      });
      console.log("this.documentImages", this.documentImages);
      this.UserKycDetailIds.forEach((kyc, index) => {
        if (this.documentImages[index]) {
          formData.append(
            `document_image_path_${kyc}`,
            this.documentImages[index]
          );
        }
      });
      console.log("this.documentImages", this.documentImages);
      // this.form.PaymentInformation.forEach((payment, i) => {
      //   console.log('chequeImageFile', i);
      //   let chequeImageFile = this.$refs[`cheque_image_${i + 1}`]?.files[0];
      //   console.log('chequeImageFile', chequeImageFile);
      //   if (chequeImageFile) {
      //     formData.append(`cheque_image_${i + 1}`, chequeImageFile);
      //   }
      // });
      await axios.post("user_document_upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
  },
};
</script>
