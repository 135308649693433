<template>
  <v-main>
    <v-container fluid>
      <v-card height="850" class="white d-flex align-center" v-if="isLoading">
        <v-col align="center">
          <v-img :src="require('../../assets/loader.gif')" width="70" />
        </v-col>
      </v-card>
      <iframe v-show="!isLoading" width="100%" height="1000" src="https://aaibuzz.com/" frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen
        @load="iframeLoaded"></iframe>
    </v-container>
  </v-main>
</template>

<script>
export default {
  name: 'EmbeddedYouTube',
  data() {
    return {
      isLoading: true
    };
  },
  methods: {
    iframeLoaded() {
      this.isLoading = false;
    }
  }
};
</script>

<style scoped>
iframe {
  border: none;
}
</style>
