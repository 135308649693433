<template>
  <v-main>
    <v-container fluid>
      <v-card elevation="6" rounded="xxl">
        <v-card-title class="mt-4">
          <v-btn class="mx-2" fab x-small color="primary" @click="$router.back()">
            <v-icon dark> mdi-arrow-left </v-icon> </v-btn>Edit Pjp
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="4">
              <v-autocomplete ref="beat" outlined v-model="form.beat_id" :items="beatItems" label="Beat"
                prepend-inner-icon="mdi-account-tie-hat-outline" clearable
                :error-messages="errors.beat_id"></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-autocomplete ref="user" outlined v-model="form.user_id" :items="userItems" label="User"
                prepend-inner-icon="mdi-account-tie-hat-outline" clearable
                :error-messages="errors.user_id"></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-switch class="mt-0 ml-5" v-model="form.is_outlet_visited" inset :label="`Is outlet visited: ${form.is_outlet_visited ? 'YES' : 'NO'
            }`"></v-switch>
            </v-col>
            <v-col sm="4">
              <v-menu v-model="date_menu" :close-on-content-click="false" :nudge-right="40"
                transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="form.date" label="Date" prepend-inner-icon="mdi-calendar" readonly outlined
                    v-bind="attrs" v-on="on" clearable @click:clear="form.date = null"
                    :error-messages="errors.date"></v-text-field>
                </template>
                <v-date-picker v-model="form.date" @change="date_menu = false"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col sm="4">
              <v-textarea label="Description" auto-grow outlined v-model="form.description" rows="1"
                row-height="15"></v-textarea>
            </v-col>
          </v-row>
          <v-divider class="my-5"> </v-divider>
          <section>
            <template v-if="form.pjp_outlets.length">
              <h3 class="my-4">List of Outlet(s) for this Pjp:</h3>
              <v-row v-for="(outlet, pO) in form.pjp_outlets" :key="`outlet${pO}`">
                <v-col sm="4">
                  <v-autocomplete ref="outlet" outlined v-model="outlet.outlet_id" :items="outletItems" label="Outlet"
                    prepend-inner-icon="mdi-store" clearable :error-messages="errors[`pjp_outlets.${pO}.outlet_id`]
            ? errors[`pjp_outlets.${pO}.outlet_id`]
            : ``
            "></v-autocomplete>
                </v-col>
                <v-col sm="1">
                  <v-dialog v-model="isDeleteOutletDialogs[pO]" max-width="600px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn fab class="ml-9" small dark color="red" v-bind="attrs" v-on="on">
                        <v-icon dark> mdi-close-thick </v-icon>
                      </v-btn>
                    </template>
                    <v-card elevation="10">
                      <v-card-title>
                        <v-icon large color="primary">
                          mdi-information-outline
                        </v-icon>
                        &nbsp; Do you want to remove the outlet?
                      </v-card-title>
                      <v-card-text>
                        <v-btn color="primary" @click.stop="deleteOutlet(outlet)">
                          Yes
                        </v-btn>
                        &nbsp;
                        <v-btn color="red" dark @click.stop="isDeleteOutletDialogs = []">
                          No
                        </v-btn>
                      </v-card-text>
                    </v-card>
                  </v-dialog>
                </v-col>
              </v-row>
            </template>
            <v-row>
              <v-col sm="12" class="mb-5">
                <v-btn small rounded color="primary" @click="Add_Empty_Outlet">
                  Add Outlet
                  <v-icon dark> mdi-plus </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-divider class="mt-5 mb-5"> </v-divider>
          </section>
          <v-btn rounded color="primary" :loading="isLoading" :disabled="isLoading" @click="save">
            Update Pjp
          </v-btn>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";

export default {
  name: "PjpEditPage",
  data() {
    return {
      form: {
        is_active: false,
        pjp_outlets: [],
      },
      userItems: [],
      outletItems: [],
      beatItems: [],
      isDeleteOutletDialogs: [],
      isLoading: false,
      date_menu: false,
    };
  },
  mounted() {
    this.getMasters();
    this.getData();
  },
  methods: {
    async getMasters() {
      await axios
        .get(`pjps/masters`)
        .then((masters) => {
          masters = masters.data;
          //Users
          masters.users.forEach((user) => {
            this.userItems.push({
              id: user.id,
              text: user.full_name,
              value: user.id,
            });
          });
          //Outlets
          masters.outlets.forEach((outlet) => {
            this.outletItems.push({
              id: outlet.id,
              text: outlet.name,
              value: outlet.id,
            });
          });
          //Beats
          masters.beats.forEach((beat) => {
            this.beatItems.push({
              id: beat.id,
              text: beat.name,
              value: beat.id,
            });
          });
        })
        .catch(function (error) {
          console.log(error);
          // redirect to Home Page
          window.location.href = "/auth/login";
        });
    },
    async getData() {
      this.isLoading = true;
      let encryptedId = this.$route.params.pjpId;
      axios
        .get(`/pjps/${encryptedId}`)
        .then((response) => {
          this.form = response.data.data;
          this.old_json = response.data.data;
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
      this.isLoading = false;
    },
    async save() {
      try {
        this.isLoading = true;
        console.log(this.form);
        await axios
          .post("/pjps", this.form)
          .then(async () => {
            await this.saveUserTimestamp(this.old_json, this.form);
            let PageModule = 'UPDATED';
            let PageCode = 'PJPS';
            const userIP = await this.getUserIp();
            this.saveAuditTrial(userIP, PageModule, PageCode)
            this.$router.push("/pjps");
          })
          .catch((error) => {
            // redirect to Home Page
            window.location.href = "/auth/login";
            console.error("Error fetching data:", error);
          });
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    },
    Add_Empty_Outlet() {
      let pjp_outlet = {
        outlet_id: "",
      };
      this.form.pjp_outlets.push(pjp_outlet);
    },
    deleteOutlet(pjp_outlet) {
      this.form.pjp_outlets.splice(
        this.form.pjp_outlets.indexOf(pjp_outlet),
        1
      );
      this.isDeleteOutletDialogs = [];
    },
  },
};
</script>
