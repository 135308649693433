<template>
  <v-main>
    <v-container fluid>
      <v-row>
        <v-col md="12">
          <v-card elevation="4">
            <v-card-text>
              <v-row>
                <v-btn class="mt-2 mx-2 text-caption" color="primary" :to="`/tickets/create`">
                  <span> add ticket <v-icon dark> mdi-plus </v-icon></span>
                </v-btn>
              </v-row>
              <v-row>
                <v-col md="6">
                  <v-text-field v-model="search_keyword" label="Search ... " prepend-inner-icon="mdi-magnify"
                    @keydown.enter="getData" @change="page = 1"></v-text-field>
                </v-col>
                <v-col sm="3">
                  <v-menu v-model="month_menu" :close-on-content-click="false" :nudge-right="40"
                    transition="scale-transition" offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="filter.month_year" label="Year - Month" prepend-icon="mdi-calendar"
                        readonly v-bind="attrs" v-on="on" clearable @click:clear="filter.month_year = null"
                        :error-messages="errors.month"></v-text-field>
                    </template>
                    <v-date-picker v-model="filter.month_year" type="month"
                      @change="(month_menu = false), getData()"></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- / Filters -->
      <v-row>
        <v-col md="12">
          <v-card elevation="6">
            <v-card-title v-if="tickets.length > 0"><v-spacer></v-spacer>
              <!-- Download Excel -->
              <v-btn target="_blank" class="primary mx-4" :loading="isExcelloading" @click="generateExcel()">
                Generate Excel</v-btn>
            </v-card-title>
            <v-progress-linear v-if="isLoading" indeterminate color="primary"></v-progress-linear>
            <v-simple-table fixed-header>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-start text-subtitle-2 font-weight-bold">SR. NO.</th>
                    <th class="text-start text-subtitle-2 font-weight-bold" style="min-width: 150px">DATE</th>
                    <th class="text-start text-subtitle-2 font-weight-bold" style="min-width: 600px">
                      DESCRIPTION
                    </th>
                    <th class="text-start text-subtitle-2 font-weight-bold" style="min-width: 150px">CREATED BY</th>
                    <th class="text-start text-subtitle-2 font-weight-bold" style="min-width: 200px">TYPE</th>
                    <th class="text-start text-subtitle-2 font-weight-bold" style="min-width: 150px">STATUS</th>
                    <th class="text-start text-subtitle-2 font-weight-bold">IMAGES</th>
                    <th class="text-start text-subtitle-2 font-weight-bold" style="min-width: 130px">
                      FOLLOW UPS
                    </th>
                    <th class="text-start text-subtitle-2 font-weight-bold">ACTION</th>
                  </tr>
                </thead>
                <tbody v-if="tickets.length != 0">
                  <tr v-for="(ticket, i) in tickets" :key="`ticket${i}`">
                    <td style="min-width: 85px">
                      <v-tooltip bottom v-if="ticket.is_approved == 1">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on" small dark color="green">
                            mdi-circle
                          </v-icon>
                        </template>
                        <span>Active</span>
                      </v-tooltip>
                      <v-tooltip bottom v-if="ticket.is_approved == 0">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on" small dark color="red">
                            mdi-circle
                          </v-icon>
                        </template>
                        <span>In-Active</span>
                      </v-tooltip>
                      &nbsp;
                      {{ i + 1 }}
                    </td>
                    <td>
                      {{
                  ticket.created_at ? ticket.created_at.split(" ")[0] : ""
                }}
                    </td>
                    <td>{{ ticket.description || "" }}</td>
                    <td>
                      {{
                  ticket.created_by_id != null
                    ? ticket.created_by.full_name
                    : ""
                }}
                    </td>
                    <td>{{ ticket.type || "" }}</td>
                    <td>{{ ticket.status || "" }}</td>
                    <td>
                      <v-btn
                        v-if="ticket.image_path != null || ticket.image_path_2 != null || ticket.image_path_3 != null || ticket.image_path_4 != null"
                        class="text-caption px-1 py-1 rounded-lg" color="success" dark @click="viewImage(ticket.id)">
                        view image
                      </v-btn>
                    </td>
                    <td>
                      <v-btn class="mx-2" fab x-small color="primary" :to="`/tickets/${ticket.id}/follow-ups`">
                        <v-icon dark> mdi-plus </v-icon>
                      </v-btn>
                    </td>
                    <td>
                      <v-btn class="mx-2" fab x-small color="primary" :to="`/tickets/${ticket.id}`">
                        <v-icon dark> mdi-pencil </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="4"></td>
                    <td>
                      <v-pagination class="float-right" v-model="page" :length="paginationLength">
                      </v-pagination>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="9" class="text-center">No data found</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <!--End of Table -->
          </v-card>
        </v-col>
      </v-row>
      <v-dialog width="950" v-model="TicketsImageDialog">
        <v-carousel height="600" hide-delimiter-background show-arrows-on-hover>
          <v-carousel-item v-for="(slide, i) in slides" :key="i">
            <v-sheet height="100%">
              <v-row class="fill-height" align="center" justify="center">
                <v-img :src="mediaUrl + slide" contain height="600" max-width="950" />
              </v-row>
            </v-sheet>
          </v-carousel-item>
        </v-carousel>
      </v-dialog>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
export default {
  name: "ticketsPage",
  data() {
    return {
      filter: {
        month_year: "",
        month: "",
        year: "",
        expense_id: "",
      },
      tickets: [],
      page: 1,
      serialNoStarting: 1,
      paginationLength: 0,
      count: 0,
      search_keyword: "",
      month_menu: false,
      isLoading: false,
      isExcelloading: false,
      TicketsImageDialog: false,
      slides: [],
    };
  },
  watch: {
    page: "getData",
    month_menu: "getMonthYear",
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      if (this.filter.month_year) {
        this.filter.year = this.filter.month_year.split("-")[0];
        this.filter.month = this.filter.month_year.split("-")[1];
      }
      this.search_keyword = this.search_keyword ? this.search_keyword : "";
      this.filter.user_id = this.filter.user_id ? this.filter.user_id : "";
      let query =
        "page=" +
        this.page +
        "&rowsPerPage=" +
        this.rowsPerPage +
        "&search_keyword=" +
        this.search_keyword +
        "&month=" +
        this.filter.month +
        "&year=" +
        this.filter.year +
        "&user_id=" +
        this.filter.user_id;
      let response = await axios.get(`tickets?${query}`);
      this.tickets = response.data.data;
      this.count = response.data.count;
      this.serialNoStarting = (this.page - 1) * this.rowsPerPage;
      this.paginationLength = Math.ceil(this.count / this.rowsPerPage);
      this.isLoading = false;
    },
    async generateExcel() {
      this.isExcelloading = true;
      this.search_keyword = this.search_keyword ? this.search_keyword : "";
      let query = "search_keyword=" + this.search_keyword;
      let response = await axios.get(`tickets/export?${query}`);
      window.open(`${response.data.data}`, "_blank");
      this.isExcelloading = false;
      console.log(response);
    },
    getMonthYear() {
      if (this.filter.month_year) {
        this.filter.year = this.filter.month_year.split("-")[0];
        this.filter.month = this.filter.month_year.split("-")[1];
      }
    },
    viewImage(ticketId) {
      const ticket = this.tickets.find((ticket) => ticket.id === ticketId);
      if (ticket) {
        // Extract image paths
        const imagePaths = [
          ticket.image_path,
          ticket.image_path_2,
          ticket.image_path_3,
          ticket.image_path_4,
        ];
        this.slides = imagePaths.filter((path) => path);
        this.TicketsImageDialog = true;
        console.log(this.slides);
      } else {
        console.log(`Ticket with ID ${ticketId} not found.`);
      }
    },
  },
};
</script>
