<template>
  <v-main>
    <v-container fluid>
      <v-row>
        <v-col xl="5" lg="5" md="5" sm="12" cols="12">
          <v-card class="overflow-y-auto" elevation="6" :height="$vuetify.breakpoint.smAndDown ? 'auto' : '600'">
            <v-card-title>
              <span>Total Values ({{ count }}) </span>
              <v-spacer></v-spacer>
              <v-btn class="mx-2 text-caption" color="#6c5dd4" dark @click="createformdialog = true">+ Add
                Value</v-btn>
            </v-card-title>
            <v-progress-linear v-if="isLoading" indeterminate color="primary"></v-progress-linear>
            <v-simple-table fixed-header>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-start font-weight-bold text-subtitle-2">Sr No</th>
                    <th class="text-start font-weight-bold text-subtitle-2">Name</th>
                    <th class="text-center font-weight-bold text-subtitle-2" style="min-width: 130px;">Action</th>
                  </tr>
                </thead>
                <tbody v-if="values.length != 0">
                  <tr v-for="(value, i) in values" :key="`value${i}`">
                    <td style="min-width: 85px">
                      &nbsp;
                      {{ i + 1 }}
                    </td>
                    <td>{{ value.name }}</td>
                    <td class="text-right">
                      <v-btn class="mx-2" fab x-small elevation="0" color="#6c5dd4" dark @click="getValue(value.id)">
                        <v-icon size="15"> mdi-pencil </v-icon>
                      </v-btn>
                      <v-btn class="mx-2" fab x-small elevation="0" :color="SelectRow != i ? '#6c5dd4' : 'success'" dark
                        @click="getValueListData(value.id, i)">
                        <v-icon v-if="SelectRow != i" size="15">
                          mdi-plus
                        </v-icon>
                        <v-icon v-else size="15"> mdi-close </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="6" class="text-center">No data found</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
        <v-col xl="7" lg="7" md="7" sm="12" cols="12">
          <v-card elevation="6">
            <v-card-title> Value List of : {{ ValueName || "" }} </v-card-title>
            <v-simple-table fixed-header :height="$vuetify.breakpoint.smAndDown ? 'auto' : '535'">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left" v-if="!$vuetify.breakpoint.smAndDown">Sr No</th>
                    <th class="text-left" style="min-width: 200px;">Description</th>
                    <th class="text-left" style="min-width: 200px;">Code</th>
                    <th v-if="ValueName == 'GRADE'" class="text-left" style="min-width: 50px;">Image</th>
                    <th class="text-right">Action</th>
                  </tr>
                </thead>
                <tbody v-if="value_id != null">
                  <tr v-for="(valueList, i) in valueLists" :key="`valueList${i}`">
                    <td v-if="!$vuetify.breakpoint.smAndDown">{{ i + 1 }}</td>
                    <td>
                      <v-col class="px-0" cols="12">
                        <v-text-field v-model="valueList.description" outlined dense label="Description" :input="(valueList.description =
            valueList.description.toUpperCase())
            " hide-details="auto" :error-messages="valueList.description == ''
            ? 'this description field is required'
            : ''
            "></v-text-field>
                      </v-col>
                    </td>
                    <td>
                      <v-col class="px-0" cols="12">
                        <v-text-field :error-messages="valueList.code == ''
            ? 'this code field is required'
            : ''
            " v-model="valueList.code" :input="(valueList.code = valueList.code.toUpperCase())
            " outlined dense label="Code" hide-details="auto"></v-text-field>
                      </v-col>
                    </td>
                    <td v-if="ValueName == 'GRADE'" width="50">
                      <v-row>
                        <v-col cols="2" v-if="valueList.id_image_path == null">
                          <input  :class="{ 'mt-10': valueList.id_image_path != null }" type="file"
                            @change="handleFileUpload($event, i)">
                        </v-col>
                        <v-col align="right" cols="2">
                          <template v-if="valueList.id_image_path != null">
                            <v-btn x-small class="mx-2" :href="`${mediaUrl}${valueList.id_image_path}`" target="_blank"
                              color="primary">View</v-btn>
                            <v-btn x-small class="reset_image my-1" color="red" dark
                              @click="valueList.id_image_path == null">Remove</v-btn>
                          </template>
                        </v-col>
                        <v-col v-if="valueList.file != null || valueList.id_image_path">
                          <v-switch v-model="valueList.is_on_id" inset class="ml-4"
                            :label="`Image on Id: ${valueList.is_on_id ? 'YES' : 'NO'}`"></v-switch>
                        </v-col>
                      </v-row>
                    </td>
                    <td class="text-right">
                      <div class="text-center">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" small color="#6c5dd4" icon
                              @click="delete_data(valueList.id)">
                              <v-icon>mdi-delete-outline</v-icon>
                            </v-btn>
                          </template>
                          <span>Delete</span>
                        </v-tooltip>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="value_id != null">
                    <td colspan="4">
                      <v-btn outlined block color="#6c5dd4" @click="addEmptyValueList()">
                        Add New {{ ValueName }}
                      </v-btn>
                    </td>
                  </tr>
                  <tr v-if="value_id != null">
                    <td colspan="2"></td>
                    <td colspan="2">
                      <v-btn block color="#6c5dd4" :dark="!isSaving" :loading="isSaving" :disabled="isSaving"
                        @click="ValueListSave()">
                        Save
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="4" class="text-center">
                      Please Select any value from left
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
      <!-- create form -->
      <v-dialog v-model="createformdialog" max-width="390">
        <v-card class="px-1">
          <v-card-text>
            <label for="">Name * </label>
            <v-text-field v-model="Createform.name" @input="Createform.name = Createform.name.toUpperCase()" outlined
              placeholder="Enter value name *" :error-messages="errors.name"></v-text-field>
            <v-btn :loading="isLoading" color="#6c5dd4" @click="save" :dark="!isLoading" :disabled="isLoading">
              Save Value
            </v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- Edit form -->
      <v-dialog v-model="editformdialog" :loading="isLoading" max-width="390">
        <v-card class="px-1">
          <v-card-text>
            <label for="">Name * </label>
            <v-text-field v-model="Editform.name" @input="Editform.name = Editform.name.toUpperCase()" outlined
              placeholder="Enter value name *" :error-messages="errors.name"></v-text-field>
            <v-btn :loading="isLoading" color="#6c5dd4" :dark="!isLoading" :disabled="isLoading"
              @click="update(Editform.id)">
              Edit Value
            </v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="SaveDialog" width="300">
        <v-card>
          <v-card-text class="d-flex flex-column">
            <v-img alt="Success" :src="require('../../assets/check-gif.gif')" class="check-gif" width="250" />
            <span align="center">
              New Value List Created Successfully
            </span>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";

export default {
  name: "valuesPage",
  data() {
    return {
      count: 0,
      isLoading: false,
      isSaving: false,
      SaveDialog: false,
      createformdialog: false,
      editformdialog: false,
      Createform: {},
      Editform: {},
      values: [],
      valueLists: [],
      value_id: null,
      ValueName: "",
      SelectRow: null,
      files: [], // To store files
    };
  },
  computed: {
    valuelist() {
      return this.valueLists ? this.valueLists : [];
    },
    valueID() {
      return this.value_id ? this.value_id : null;
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      let values = await axios.get(`/values`);
      this.values = values.data.data;
      this.count = values.data.count;
      this.isLoading = false;
    },
    //create value
    async save() {
      try {
        this.isLoading = true;
        await axios.post("/values", this.Createform);
        let PageModule = 'CREATE';
        let PageCode = 'VALUES';
        const userIP = await this.getUserIp();
        this.saveAuditTrial(userIP, PageModule, PageCode)
        this.isLoading = false;
        this.createformdialog = false;
        this.Createform = {};
        this.getData();
      } catch (e) {
        this.isLoading = false;
      }
    },
    // get value id
    async getValue(ValueId) {
      this.Editform = {};
      this.isLoading = true;
      let form = await axios.get(`/values/${ValueId}`);
      this.Editform = form.data.data;
      this.editformdialog = true;
      this.isLoading = false;
    },
    //edit value
    async update(ValueId) {
      try {
        this.isLoading = true;
        await axios.patch(`/values/${ValueId}`, this.Editform);
        let PageModule = 'UPDATED';
        let PageCode = 'VALUES';
        const userIP = await this.getUserIp();
        this.saveAuditTrial(userIP, PageModule, PageCode)
        this.isLoading = false;
        this.editformdialog = false;
        this.getData();
      } catch (e) {
        this.isLoading = false;
      }
    },
    //get value list
    async getValueListData(valueId, index) {
      console.log('VALUEiD', valueId);
      if (valueId != null) {
        this.value_id = valueId;
      }
      this.SelectRow = index;
      console.log(this.value_id);
      let valueLists = await axios.get(`/values/${this.value_id}/value_lists`);
      this.valueLists = valueLists.data.data;
      // if (valueLists.data.count != 0) {
      this.ValueName = this.values.find((value) => value.id == this.value_id).name;
      // }
    },
    addEmptyValueList() {
      this.valueLists.push({
        value_id: this.value_id,
        description: "",
        code: "",
        is_active: 1,
        file: null,
      });
    },
    // save value list
    async ValueListSave() {
      if (this.valueLists.length > 0) {
        this.isSaving = true;
        try {
          let payload = new FormData();
          this.valueLists.forEach((valueList, index) => {
            if (valueList.id) {
              payload.append(`datas[${index}][id]`, valueList.id);
            }
            if (valueList.is_on_id) {
              payload.append(`datas[${index}][is_on_id]`, valueList.is_on_id == true ? '1' : '0');
            }
            payload.append(`datas[${index}][value_id]`, valueList.value_id);
            payload.append(`datas[${index}][description]`, valueList.description);
            payload.append(`datas[${index}][code]`, valueList.code);
            payload.append(`datas[${index}][is_active]`, valueList.is_active);
            if (this.files[index]) {
              payload.append(`datas[${index}][file]`, this.files[index]);
            }
          });

          let response = await axios.post(
            `/values/${this.value_id}/value_lists_multiple`,
            payload
          );
          let PageModule = 'CREATE';
          let PageCode = 'VALUE LISTS';
          const userIP = await this.getUserIp();
          this.saveAuditTrial(userIP, PageModule, PageCode)
          this.valueLists = response.data.data;
          if (this.valueLists) {
            this.SaveDialog = true;
            setTimeout(() => {
              this.SaveDialog = false;
            }, 2500);
          }
          this.isSaving = false;
        } catch (error) {
          console.log(error);
          this.isSaving = false;
        }
        this.isSaving = false;
      }
    },
    handleFileUpload(event, index) {
      const file = event.target.files[0];
      if (file) {
        this.$set(this.files, index, file); // Store the file reference
        this.valueLists[index].file = file.name; // Optionally, store file name in valueLists
        // Also set `id_image_path` to show the switch
        this.$set(this.valueLists[index], 'image_path', URL.createObjectURL(file));
      }
      console.log(this.valueLists);
    },
    // delete value list
    async delete_data(id) {
      let check = confirm("Are you sure you want to delete the data");
      if (check) {
        try {
          this.isLoading = true;
          await axios.post(`value_lists/delete/${id}`);
          this.isLoading = false;
          this.getValueListData();
        } catch (e) {
          console.log(e);
          this.isLoading = false;
        }
      }
    },
  },
};
</script>
<style>
/* .check-gif .v-image__image {
  background-size: inherit !important;
} */
</style>
