<template>
  <v-main>
    <v-container fluid>
      <v-card elevation="6" rounded="xxl">
        <v-card-title class="mt-4">
          <v-btn class="mx-2" fab x-small color="primary" @click="$router.back()">
            <v-icon dark>mdi-arrow-left</v-icon>
          </v-btn>
          Add Hr Report
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="4">
              <v-text-field v-model="form.report_name" outlined label="Report Name"
                prepend-inner-icon="mdi-card-text-outline" :error-messages="errors.report_name"></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-autocomplete ref="position" outlined v-model="form.table_name" :items="Tables"
                label="Select DataBase *" prepend-inner-icon="mdi-account-tie-outline" clearable
                :error-messages="errors.table_name" @change="getMasters"></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <v-card height="600" style="border: 1px solid;">
                <v-simple-table fixed-header height="600" class="striped-table">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th style="min-width: 20px" class="text-start">Sr No</th>
                        <th style="min-width: 170px" class="text-start">Columns</th>
                        <th class="text-start">Select</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(column, i) in TableColumns" :key="i">
                        <td>{{ i + 1 }}</td>
                        <td>{{ column.value }}</td>
                        <td>
                          <v-btn icon @click="toggleColumnSelection(column)">
                            <v-icon>{{ isSelected(column) ? 'mdi-check' : 'mdi-plus' }}</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>
            </v-col>
            <v-col cols="4">
              <v-card height="600" style="border: 1px solid;">
                <v-simple-table fixed-header height="600" class="striped-table">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th style="min-width: 20px" class="text-start">Sr No</th>
                        <th style="min-width: 170px" class="text-start">Header Name</th>
                        <th class="text-start">Column</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(column, i) in SelectedColumns" :key="i">
                        <td>{{ i + 1 }}</td>
                        <td style="max-width: 130px;">
                          <v-text-field outlined dense v-model="column.header_name" class="mt-4"></v-text-field>
                        </td>
                        <td>{{ column.column_name }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>
            </v-col>
          </v-row>
          <v-divider class="my-5"></v-divider>
          <v-btn rounded color="primary" :loading="isLoading" :disabled="isLoading" @click="save">
            Save Report
          </v-btn>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
export default {
  name: "ReportCreatePage",
  data() {
    return {
      form: {
        ReportColumns: [],
        report_name: '',
        table_name: '',
      },
      Tables: [],
      TableColumns: [],
      SelectedColumns: [],
      isLoading: false,
    };
  },
  mounted() {
    this.getMasters();
  },
  methods: {
    async getMasters() {
      this.TableColumns = [];
      this.form.table_name = this.form.table_name != null ? this.form.table_name : "";
      await axios
        .get(`hr_reports/masters?table_name=${this.form.table_name}`)
        .then((masters) => {
          masters = masters.data;
          masters.tables.forEach(table => {
            this.Tables.push({
              text: table,
              value: table
            })
          });
          if (this.form.table_name != null || this.form.table_name != "") {
            masters.columns.forEach(column => {
              this.TableColumns.push({
                text: column,
                value: column
              })
            });
          }
        })
    },
    toggleColumnSelection(column) {
      const index = this.SelectedColumns.findIndex(selected => selected.column_name === column.value);
      if (index > -1) {
        this.SelectedColumns.splice(index, 1);
      } else {
        this.SelectedColumns.push({ header_name: '', column_name: column.value });
      }
    },
    isSelected(column) {
      return this.SelectedColumns.some(selected => selected.column_name === column.value);
    },
    async save() {
      try {
        this.isLoading = true;
        this.form.ReportColumns = this.SelectedColumns;
        await axios.post("/hr_reports", this.form);
        let PageModule = 'CREATE';
        let PageCode = 'HR REPORT WRITER';
        const userIP = await this.getUserIp();
        this.saveAuditTrial(userIP, PageModule, PageCode)
        this.$router.back();
      } catch (e) {
        console.error(e);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style>
.selected-row {
  background-color: red !important;
}
</style>
