<template>
    <v-main>
        <v-container fluid>
            <v-card elevation="6" rounded="xxl">
                <v-card-title>
                    <v-btn class="mx-2" fab x-small color="primary" @click="$router.back()">
                        <v-icon dark> mdi-arrow-left </v-icon> </v-btn>
                    <h1> List of Routes </h1>
                    <v-spacer></v-spacer>
                    <v-col cols="2" class="py-0">
                        <v-btn block @click="handleSubmit" color="primary" :loading="isLoading">Submit</v-btn>
                    </v-col>
                </v-card-title>

                <v-simple-table fixed-header height="800" class="striped-table">
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th style="min-width: 20px" class="text-start font-weight-bold text-subtitle-2">Sr No
                                </th>
                                <th class="text-start font-weight-bold text-subtitle-2">Urls</th>
                                <th class="text-start font-weight-bold text-subtitle-2">Action</th>
                            </tr>
                        </thead>
                        <tbody v-if="filteredRoutes.length != 0">
                            <tr v-for="(route, i) in filteredRoutes" :key="i">
                                <td>{{ i + 1 }}</td>
                                <td>{{ route?.path || route?.url || "" }}</td>
                                <td>
                                    <v-btn @click="toggleAllow(route)" :color="route.is_allowed ? 'success' : 'error'">
                                        {{ route.is_allowed ? 'Allow' : 'Not Allow' }}
                                    </v-btn>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <td colspan="3" class="text-center">No data found</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-card>
        </v-container>
    </v-main>
</template>
<script>
import { routes } from '../../router';
import axios from "axios";

export default {
    data() {
        return {
            routes: [],
            filteredRoutes: [],
        };
    },
    mounted() {
        this.routes = routes;
        this.filterRoutes();
        console.log(this.filteredRoutes);
    },
    methods: {
        async filterRoutes() {
            //         return this.routes[0].children.filter(route => {
            //     return !(route.path.indexOf('create') !== -1 || route.path.indexOf(':') !== -1 || route.path.indexOf('/', 1) !== -1);
            // }).map(route => ({
            //     ...route,
            //     is_allowed: true, // Add an 'is_allowed' property to each route initially set to true
            // }));
            let response = await axios.get(`/user_permissions?user_id=${this.$route.params.userId}`);
            if (response.data.data.length > 0) {
                response = response.data.data;
            } else {
                response = this.routes[0].children.map(route => ({
                    ...route,
                    is_allowed: true, // Add an 'is_allowed' property to each route initially set to true
                }));
            }
            this.filteredRoutes = response;
        },
        toggleAllow(route) {
            route.is_allowed = !route.is_allowed;
        },
        async handleSubmit() {
            try {
                this.isLoading = true;
                const payload = {
                    user_id: this.$route.params.userId, // Assuming userId is available in route params
                    routes: this.filteredRoutes.map(route => ({
                        url: route.path || route.url,
                        is_allowed: route.is_allowed,
                    })),
                };
                await axios.post("/user_permissions", payload);
                this.$router.back();
            } catch (e) {
                // alert("error");
                this.isLoading = false;
            }
        }
    }
};
</script>
