<template>
    <v-container fluid>
        <v-card height="850" class="white d-flex align-center" v-if="isLoading && form.length != 0">
            <v-col align="center">
                <v-img :src="require('../../assets/loader.gif')" width="70" />
            </v-col>
        </v-card>
        <v-row v-else>
            <v-col cols="12">
                <span class="font-weight-bold text-h6 text-color">Today Attendance Status & Pending
                    Status</span>
            </v-col>
            <v-col xl="3" lg="3" md="3" sm="12" cols="12">
                <v-card class="dashboard-card py-0" elevation="1" color="#aafcd9">
                    <v-card-title class="px-2 py-2">
                        <v-icon size="17">mdi-account</v-icon>
                        <span :class="$vuetify.breakpoint.lgAndDown ? 'text-caption ml-3' : 'text-subtitle-2 ml-3'">
                            TOTAL EMPLOYEES : {{ form.TotalUsersCount.length }}</span>
                    </v-card-title>
                </v-card>
            </v-col>
            <v-col xl="3" lg="3" md="3" sm="12" cols="12">
                <v-card class="dashboard-card py-0" elevation="1" color="#aafcd9" @click="Showtable(form.PresentCount)">
                    <v-card-title class="px-2 py-2">
                        <v-icon size="17">mdi-check-circle-outline</v-icon>
                        <span :class="$vuetify.breakpoint.lgAndDown ? 'text-caption ml-3' : 'text-subtitle-2 ml-3'">
                            PRESENT : {{ form.PresentCount.length }}</span>
                    </v-card-title>
                </v-card>
            </v-col>
            <v-col xl="3" lg="3" md="3" sm="12" cols="12">
                <v-card class="dashboard-card" elevation="1" color="#d8d8ff" @click="Showtable(form.LateComingCount)">
                    <v-card-title class="px-2 py-2">
                        <v-icon size="17">mdi-target</v-icon>
                        <span :class="$vuetify.breakpoint.lgAndDown ? 'text-caption ml-3' : 'text-subtitle-2 ml-3'">
                            LATE COMING : {{ form.LateComingCount.length }}</span>
                    </v-card-title>
                </v-card>
            </v-col>
            <v-col xl="3" lg="3" md="3" sm="12" cols="12">
                <v-card class="dashboard-card" elevation="1" color="#f8ccbd" @click="Showtable(form.AbsentCount)">
                    <v-card-title class="px-2 py-2">
                        <v-icon size="17">mdi-target</v-icon>
                        <span :class="$vuetify.breakpoint.lgAndDown ? 'text-caption ml-3' : 'text-subtitle-2 ml-3'">
                            ABSENT : {{ form.AbsentCount.length }}</span>
                    </v-card-title>
                </v-card>
            </v-col>
            <v-col xl="3" lg="3" md="3" sm="12" cols="12">
                <v-card class="dashboard-card" elevation="1" color="#bfecf2" @click="Showtable(form.FullDayLeaveCount)">
                    <v-card-title class="px-2 py-2">
                        <v-icon size="17">mdi-home</v-icon>
                        <span :class="$vuetify.breakpoint.lgAndDown ? 'text-caption ml-3' : 'text-subtitle-2 ml-3'">
                            FULL DAY LEAVE : {{ form.FullDayLeaveCount.length }}</span>
                    </v-card-title>
                </v-card>
            </v-col>
            <v-col xl="3" lg="3" md="3" sm="12" cols="12">
                <router-link to="/user-leaves" class="text-decoration-none">
                    <v-card class="dashboard-card" elevation="1" color="#f8ccbd">
                        <v-card-title class="px-2 py-2">
                            <v-icon size="17">mdi-hand-peace</v-icon>
                            <span :class="$vuetify.breakpoint.lgAndDown ? 'text-caption ml-3' : 'text-subtitle-2 ml-3'">
                                ALL LEAVES REQUEST : {{ form.AllLeaveRequests.length
                                }}</span>
                        </v-card-title>
                    </v-card>
                </router-link>
            </v-col>
            <v-col xl="3" lg="3" md="3" sm="12" cols="12">
                <v-card class="dashboard-card" elevation="1" color="#bfecf2">
                    <v-card-title class="px-2 py-2">
                        <v-icon size="17">mdi-weather-fog</v-icon>
                        <span :class="$vuetify.breakpoint.lgAndDown ? 'text-caption ml-3' : 'text-subtitle-2 ml-3'">
                            ATTN. OUT OF RANGE ({{ getCurrentMonthYear() }}) :
                            0</span>
                    </v-card-title>
                </v-card>
            </v-col>
            <v-col xl="3" lg="3" md="3" sm="12" cols="12">
                <v-card class="dashboard-card" elevation="1" color="#d8d8ff" @click="Showtable(form.PunchOutMissing)">
                    <v-card-title class="px-2 py-2">
                        <v-icon size="17">mdi-clock-out</v-icon>
                        <span :class="$vuetify.breakpoint.lgAndDown ? 'text-caption ml-3' : 'text-subtitle-2 ml-3'">
                            PUNCH OUT MISSING ({{ getCurrentMonthYear() }}) : {{
            form.PunchOutMissing.length }}</span>
                    </v-card-title>
                </v-card>
            </v-col>
            <v-col xl="3" lg="3" md="3" sm="12" cols="12">
                <v-card class="dashboard-card" elevation="1" color="#aafcd9" @click="Showtable(form.pendingExpenses)">
                    <v-card-title class="px-2 py-2">
                        <v-icon size="17">mdi-hub-outline</v-icon>
                        <span :class="$vuetify.breakpoint.lgAndDown ? 'text-caption ml-3' : 'text-subtitle-2 ml-3'">
                            PENDING EXPENSES ({{ getCurrentMonthYear() }}) : {{
            form.pendingExpenses.length }}</span>
                    </v-card-title>
                </v-card>
            </v-col>
            <v-col xl="3" lg="3" md="3" sm="12" cols="12">
                <v-card class="dashboard-card" elevation="1" color="#d8d8ff">
                    <v-card-title class="px-2 py-2">
                        <v-icon size="17">mdi-image-filter-tilt-shift</v-icon>
                        <span :class="$vuetify.breakpoint.lgAndDown ? 'text-caption ml-3' : 'text-subtitle-2 ml-3'">
                            PENDING SHIFT REQUEST : 0</span>
                    </v-card-title>
                </v-card>
            </v-col>
            <v-col xl="3" lg="3" md="3" sm="12" cols="12">
                <router-link to="/device-reset-requests" class="text-decoration-none">
                    <v-card class="dashboard-card" elevation="1" color="#f8ccbd">
                        <v-card-title class="px-2 py-2">
                            <v-icon size="17">mdi-devices</v-icon>
                            <span :class="$vuetify.breakpoint.lgAndDown ? 'text-caption ml-3' : 'text-subtitle-2 ml-3'">
                                DEVICE CHANGE REQUEST : {{
            form.DeviceChangeRequests.length
        }}</span>
                        </v-card-title>
                    </v-card>
                </router-link>
            </v-col>
            <v-col xl="3" lg="3" md="3" sm="12" cols="12">
                <router-link to="/work-from-home-requests" class="text-decoration-none">
                    <v-card class="dashboard-card" elevation="1" color="#bfecf2">
                        <v-card-title class="px-2 py-2">
                            <v-icon size="17">mdi-home-city-outline</v-icon>
                            <span :class="$vuetify.breakpoint.lgAndDown ? 'text-caption ml-3' : 'text-subtitle-2 ml-3'">
                                WFH REQUEST ({{ getCurrentMonthYear() }}) : {{
            form.pendingWfhRequests.length }}</span>
                        </v-card-title>
                    </v-card>
                </router-link>
            </v-col>
            <v-col xl="6" lg="6" md="6" sm="12" cols="12">
                <v-card>
                    <v-card-title>
                        <span class="font-weight-bold">
                            Monthly Attendances Status
                        </span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <apexchart type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
                </v-card>
            </v-col>
            <v-col xl="6" lg="6" md="6" sm="12" cols="12">
                <v-card>
                    <v-card-title>
                        <span class="font-weight-bold">
                            Monthly Leave Status
                        </span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <apexchart type="bar" height="350" :options="chartOptions2" :series="series2"></apexchart>
                </v-card>
            </v-col>
            <v-col xl="6" lg="6" md="6" sm="12" cols="12">
                <v-card height="420">
                    <v-card-title>
                        <span class="font-weight-bold">
                            {{ getCurrentMonthYear() }} Expenses({{ form.expenses.length }})
                        </span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-simple-table fixed-header height="340" class="striped-table">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-start text-subtitle-2 font-weight-bold sr_no">Sr No</th>
                                    <th class="text-start text-subtitle-2 font-weight-bold">Employee Code</th>
                                    <th class="text-start text-subtitle-2 font-weight-bold">Amount</th>
                                    <th class="text-right text-subtitle-2 action_th">Action</th>
                                </tr>
                            </thead>
                            <tbody v-if="form.expenses.length != 0">
                                <tr v-for="(expense, i) in form.expenses" :key="`expense_${i}`">
                                    <td>
                                        {{ i + 1 }}
                                    </td>
                                    <td>{{ expense.user.employee_code || "" }}</td>
                                    <td>{{ expense.amount || "" }}</td>
                                    <td class="text-right">
                                        <span v-if="expense.is_approved == true" class="green white--text rounded-lg"
                                            style="padding: 3px;">Approved</span>

                                        <span v-else-if="expense.is_approved == false"
                                            class="red white--text rounded-lg" style="padding: 3px;">Rejected</span>
                                        <span v-else class="orange white--text rounded-lg"
                                            style="padding: 3px;">Pending</span>

                                    </td>
                                    <!-- <td v-else>Pending</td> -->
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <td colspan="12" class="text-center">No data found</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card>
            </v-col>
            <v-col xl="6" lg="6" md="6" sm="12" cols="12">
                <v-card>
                    <v-card-title>
                        <span class="font-weight-bold">
                            Employee Status ({{ form.PresentCount.length + form.AbsentCount.length }})
                        </span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <apexchart type="donut" height="350" :options="chartOptions3" :series="series3"></apexchart>
                </v-card>
            </v-col>
            <v-col cols="12">
                <v-card>

                    <v-card-title>
                        <span class="font-weight-bold">

                            Employee Present Status ( {{ new Date().getDate().toString().padStart(2, '0') }} {{
            getCurrentMonthYear() }})
                        </span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <apexchart type="bar" height="350" :options="chartOptions4" :series="series4"></apexchart>
                </v-card>
            </v-col>
            <v-col xl="6" lg="6" md="6" sm="12" cols="12">
                <v-card>
                    <v-card-title>
                        <span class="font-weight-bold">

                            Present/Absent Status ( {{ new Date().getDate().toString().padStart(2, '0') }} {{
            getCurrentMonthYear() }})
                        </span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <apexchart type="pie" height="350" :options="chartOptions5" :series="series5"></apexchart>
                </v-card>
            </v-col>
            <v-col xl="6" lg="6" md="6" sm="12" cols="12">
                <v-card>
                    <v-card-title>
                        <span class="font-weight-bold">

                            Present Status ({{ new Date().getDate().toString().padStart(2, '0') }} {{
            getCurrentMonthYear() }})({{ form.PresentStatus.PresentDayOfficeCount
            +
            form.PresentStatus.PresentDayWfhCount }})
                        </span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <apexchart type="pie" height="350" :options="chartOptions6" :series="series6"></apexchart>
                </v-card>
            </v-col>
            <v-col xl="6" lg="6" md="6" sm="12" cols="12">
                <v-card>
                    <v-card-title>
                        <span class="font-weight-bold">
                            Monthly Paid Expenses
                        </span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <apexchart type="bar" height="350" :options="chartOptions7" :series="series7"></apexchart>
                </v-card>
            </v-col>
            <v-col xl="6" lg="6" md="6" sm="12" cols="12">
                <v-card height="430">
                    <v-card-title>
                        <span class="font-weight-bold">
                            Assets ({{ form.Assets.length }})
                        </span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-simple-table fixed-header height="340" class="striped-table">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-start text-subtitle-2 font-weight-bold sr_no">Sr No</th>
                                    <th class="text-start text-subtitle-2 font-weight-bold">Employee Code</th>
                                    <th class="text-start text-subtitle-2 font-weight-bold">Asset Name</th>
                                    <th class="text-start text-subtitle-2 font-weight-bold">Asset Type</th>
                                    <th class="text-start text-subtitle-2 font-weight-bold">Asset Id</th>
                                </tr>
                            </thead>
                            <tbody v-if="form.Assets.length != 0">
                                <tr v-for="(assset, i) in form.Assets" :key="`assset_${i}`">
                                    <td>
                                        {{ i + 1 }}
                                    </td>
                                    <td>{{ assset.user.employee_code || "" }}</td>
                                    <td>{{ assset.name || "" }}</td>
                                    <td>{{ assset.asset_type.description || "" }}</td>
                                    <td>{{ assset.asset.description || "" }}</td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <td colspan="12" class="text-center">No data found</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card>
            </v-col>
            <v-dialog v-model="isTable" width="1300">
                <v-card>
                    <v-simple-table fixed-header height="285" class="striped-table">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-start">Sr No</th>
                                    <th class="text-start">Employee Name</th>
                                    <th class="text-start">Employee Code</th>
                                    <th class="text-start" v-if="isUserTable">Email</th>
                                    <th class="text-start" v-if="isUserTable">Phone</th>
                                    <th class="text-start" v-if="isAttendanceTable">Login Time</th>
                                    <th class="text-start" v-if="isAttendanceTable">Logout Time</th>
                                    <th class="text-start" v-if="isLeaveTable"> From</th>
                                    <th class="text-start" v-if="isLeaveTable">To</th>
                                    <th class="text-start" v-if="isAttendanceTable || isLeaveTable">Session Type</th>
                                    <th class="text-start" v-if="isExpenseTable">Travel Mode</th>
                                    <th class="text-start" v-if="isExpenseTable">Expense Type Mode</th>
                                    <th class="text-start" v-if="isExpenseTable">From</th>
                                    <th class="text-start" v-if="isExpenseTable">To</th>
                                    <th class="text-start" v-if="isExpenseTable">Status</th>
                                    <th class="text-start" v-if="isWfhRequestTable">Date</th>
                                </tr>
                            </thead>
                            <tbody v-if="tables.length != 0">
                                <tr v-for="(table, i) in tables" :key="`table_${i}`">
                                    <td style="min-width: 85px">
                                        {{ i + 1 }}
                                    </td>
                                    <td v-if="isAttendanceTable || isExpenseTable || isLeaveTable || isWfhRequestTable">
                                        {{ table.user ?
            table.user.full_name : "" }}</td>
                                    <td v-else>{{ table.full_name || "" }}</td>
                                    <td v-if="isAttendanceTable || isExpenseTable || isLeaveTable || isWfhRequestTable">
                                        {{ table.user ?
            table.user.employee_code : "" }}</td>
                                    <td v-else>{{ table.employee_code || "" }}</td>
                                    <td v-if="isUserTable">{{ table.email || "" }}</td>
                                    <td v-if="isUserTable">{{ table.phone || "" }}</td>
                                    <td v-if="isAttendanceTable">{{ table.login_time || "" }}</td>
                                    <td v-if="isAttendanceTable">{{ table.logout_time != null ? table.logout_time :
            "Pending" }}</td>
                                    <td v-if="isLeaveTable">{{ table.leave_from_date || "" }}</td>
                                    <td v-if="isLeaveTable">{{ table.leave_to_date || "" }}</td>
                                    <td v-if="isAttendanceTable || isLeaveTable">{{ table.session_type ?
            table.session_type.description : "" }}</td>
                                    <td v-if="isExpenseTable">{{ table.travel_mode.description || "" }}</td>
                                    <td v-if="isExpenseTable">{{ table.expense_type.description || "" }}</td>
                                    <td v-if="isExpenseTable">{{ table.from || "" }}</td>
                                    <td v-if="isExpenseTable">{{ table.to || "" }}</td>
                                    <td v-if="isExpenseTable">{{ table.is_approved != null ? "YES" : "NO" }}</td>
                                    <td v-if="isWfhRequestTable">{{ table.date || "" }}</td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <td colspan="7" class="text-center">No data found</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card>
            </v-dialog>

        </v-row>
    </v-container>
</template>
<script>
// import axios from "axios";
import VueApexCharts from "vue3-apexcharts";
import axios from "axios";

export default {
    name: "DashBoardCount",
    components: {
        apexchart: VueApexCharts,
    },
    data() {
        return {
            form: {},
            tables: {},
            isLoading: false,
            isTable: false,
            isUserTable: false,
            isAttendanceTable: false,
            isLeaveTable: false,
            isExpenseTable: false,
            isWfhRequestTable: false,
            series: [{
                name: 'Attendance',
                data: [],
            }],
            chartOptions: {
                chart: {
                    height: 350,
                    type: 'bar',
                    background: '#ffffff'
                },
                plotOptions: {
                    bar: {
                        borderRadius: 10,
                        dataLabels: {
                            position: 'top', // top, center, bottom
                        },
                    }
                },
                dataLabels: {
                    enabled: true,
                    formatter: function (val) {
                        return "Total " + val;
                    },
                    offsetY: -20,
                    style: {
                        fontSize: '12px',
                        colors: ["#304758"]
                    }
                },
                xaxis: {
                    categories: [],
                    position: 'bottom',
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false
                    },
                    crosshairs: {
                        fill: {
                            type: 'gradient',
                            gradient: {
                                colorFrom: '#D8E3F0',
                                colorTo: '#BED1E6',
                                stops: [0, 100],
                                opacityFrom: 0.4,
                                opacityTo: 0.5,
                            }
                        }
                    },
                    tooltip: {
                        enabled: true,
                    }
                },
                yaxis: {
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false,
                    },
                    labels: {
                        show: false,
                        formatter: function (val) {
                            return val + " count";
                        }
                    }

                },
            },
            series2: [{
                name: 'Leaves',
                data: [],
            }],
            chartOptions2: {
                chart: {
                    height: 350,
                    type: 'bar',
                    background: '#ffffff'
                },
                plotOptions: {
                    bar: {
                        borderRadius: 10,
                        dataLabels: {
                            position: 'top', // top, center, bottom
                        },
                    }
                },
                dataLabels: {
                    enabled: true,
                    formatter: function (val) {
                        return "Total " + val;
                    },
                    offsetY: -20,
                    style: {
                        fontSize: '12px',
                        colors: ["#304758"]
                    }
                },

                xaxis: {
                    categories: [],
                    position: 'bottom',
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false
                    },
                    crosshairs: {
                        fill: {
                            type: 'gradient',
                            gradient: {
                                colorFrom: '#D8E3F0',
                                colorTo: '#BED1E6',
                                stops: [0, 100],
                                opacityFrom: 0.4,
                                opacityTo: 0.5,
                            }
                        }
                    },
                    tooltip: {
                        enabled: true,
                    }
                },
                yaxis: {
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false,
                    },
                    labels: {
                        show: false,
                        formatter: function (val) {
                            return val + " count";
                        }
                    }

                },
            },
            series3: [],
            chartOptions3: {
                chart: {
                    type: 'donut',
                },
                plotOptions: {
                    pie: {
                        donut: {
                            labels: {
                                show: true,
                                total: {
                                    show: true,
                                    label: 'Total',
                                    formatter: function (w) {
                                        let total = w.globals.seriesTotals.reduce((a, b) => a + b, 0);
                                        return total;
                                    }
                                }
                            }
                        }
                    }
                },
                labels: ['Logged in', 'Not Logged In'],
                colors: ['#4CAF50', '#F44336'],
            },
            series4: [{
                name: '',
                data: []
            }, {
                name: '',
                data: []
            }, {
                name: '',
                data: []
            }],
            chartOptions4: {
                chart: {
                    type: 'bar',
                    background: '#ffffff',
                    height: 350
                },
                plotOptions: {
                    bar: {
                        borderRadius: 10,
                        dataLabels: {
                            position: 'top', // top, center, bottom
                        },
                    }
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                },
                xaxis: {
                    categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'],
                },
                yaxis: {
                    title: {
                        text: ''
                    }
                },
                fill: {
                    opacity: 1
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return val
                        }
                    }
                }
            },
            series5: [],
            chartOptions5: {
                chart: {
                    type: 'pie',
                },
                labels: ['Present', 'Absent', 'On Leave'],
                colors: ['#4CAF50', '#F44336', '#0000ff'],
            },
            series6: [44, 55],
            chartOptions6: {
                chart: {
                    type: 'pie',
                },
                labels: ['In Office', 'WFH'],
                colors: ['#4CAF50', '#F44336', '#0000ff'],
            },
            series7: [{
                name: 'Expense',
                data: [],
            }],
            chartOptions7: {
                chart: {
                    height: 350,
                    type: 'bar',
                    background: '#ffffff'
                },
                plotOptions: {
                    bar: {
                        borderRadius: 10,
                        dataLabels: {
                            position: 'top', // top, center, bottom
                        },
                    }
                },
                dataLabels: {
                    enabled: true,
                    formatter: function (val) {
                        return "Total " + val;
                    },
                    offsetY: -20,
                    style: {
                        fontSize: '12px',
                        colors: ["#304758"]
                    }
                },
                xaxis: {
                    categories: [],
                    position: 'bottom',
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false
                    },
                    crosshairs: {
                        fill: {
                            type: 'gradient',
                            gradient: {
                                colorFrom: '#D8E3F0',
                                colorTo: '#BED1E6',
                                stops: [0, 100],
                                opacityFrom: 0.4,
                                opacityTo: 0.5,
                            }
                        }
                    },
                    tooltip: {
                        enabled: true,
                    }
                },
                yaxis: {
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false,
                    },
                    labels: {
                        show: false,
                        formatter: function (val) {
                            return val + " count";
                        }
                    }

                },
            },

        }
    },
    mounted() {
        this.getData();
    },
    methods: {
        async getData() {
            try {

                this.isLoading = true;
                let response = await axios.get(`admin_dashboard`);
                this.form = response.data;
                const months = Object.keys(this.form.monthlyAttendanceCount);
                const attendanceData = Object.values(this.form.monthlyAttendanceCount);
                const LeavesData = Object.values(this.form.monthlyLeaveStatus);
                const EmployeePresentStatus = Object.keys(this.form.EmployeePresentStatus);
                const MonthlyExpense = Object.values(this.form.monthlyExpenseCount);
                const monthlyExpenseCount = Object.keys(this.form.monthlyExpenseCount);
                this.updateAxis(months)
                this.updateMontlyExpenseAxis(monthlyExpenseCount)
                this.series[0].data = attendanceData;
                this.series2[0].data = LeavesData;
                this.series3[0] = this.form.PresentCount.length;
                this.series3[1] = this.form.AbsentCount.length
                this.series5[0] = this.form.PresentCount.length;
                this.series5[1] = this.form.AbsentCount.length
                this.series5[2] = this.form.FullDayLeaveCount.length
                this.series4[0].data = this.form.EmployeePresentStatus.pending
                this.series4[0].name = EmployeePresentStatus[0]
                this.series4[1].data = this.form.EmployeePresentStatus.present
                this.series4[1].name = EmployeePresentStatus[1]
                this.series4[2].data = this.form.EmployeePresentStatus.PunchOutMissing
                this.series4[2].name = EmployeePresentStatus[2]
                this.series6[0] = this.form.PresentStatus.PresentDayOfficeCount
                this.series6[1] = this.form.PresentStatus.PresentDayWfhCount
                this.series7[0].data = MonthlyExpense;
                this.isLoading = false;
            } catch (error) {
                console.log(error);
                this.isLoading = false;
            }
        },
        updateMontlyExpenseAxis(data) {
            this.chartOptions7 = {
                ...this.chartOptions7,
                xaxis: {
                    categories: data
                }
            };
        },
        updateAxis(data) {
            this.chartOptions = {
                ...this.chartOptions,
                xaxis: {
                    categories: data
                }
            };
            this.chartOptions2 = {
                ...this.chartOptions2,
                xaxis: {
                    categories: data
                }
            };
            const currentDate = new Date();
            const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
            const chart4Data = [];
            let date = startOfMonth;

            while (date <= currentDate) {
                const endDate = new Date(date);
                endDate.setDate(date.getDate() + 2);

                let formattedDates = '';
                for (let i = 0; i < 3; i++) {
                    const current = new Date(date);
                    current.setDate(date.getDate() + i);
                    if (current <= currentDate) {
                        const day = String(current.getDate()).padStart(2, '0');
                        const weekday = current.toLocaleDateString('en-US', { weekday: 'short' });
                        formattedDates += `${day} ${weekday}, `;
                    }
                }

                formattedDates = formattedDates.slice(0, -2); // Remove trailing comma and space
                chart4Data.push(`(${formattedDates})`);

                date.setDate(date.getDate() + 3);
            }
            this.chartOptions4 = {
                ...this.chartOptions4,
                xaxis: {
                    categories: chart4Data
                }
            };
        },
        Showtable(table) {
            this.isUserTable = false,
                this.isAttendanceTable = false,
                this.isExpenseTable = false,
                this.isLeaveTable = false,
                this.isWfhRequestTable = false,
                this.tables = [];
            console.log(table);
            if (table == this.form.PresentCount || table == this.form.PunchOutMissing) {
                this.isAttendanceTable = true;
            }
            else if (table == this.form.FullDayLeaveCount
                || table == this.form.AllLeaveRequests) {
                this.isLeaveTable = true;
            }
            else if (table == this.form.LateComingCount || table == this.form.AbsentCount || table == this.form.DeviceChangeRequests) {
                this.isUserTable = true;
            }
            else if (table == this.form.pendingExpenses) {
                this.isExpenseTable = true;
            } else if (table == this.form.pendingWfhRequests) {
                this.isWfhRequestTable = true;
            }
            this.tables = table;
            this.isTable = true;
        },
        getCurrentMonthYear() {
            const currentDate = new Date();
            const monthNames = [
                "January", "February", "March", "April", "May", "June",
                "July", "August", "September", "October", "November", "December"
            ];
            const month = monthNames[currentDate.getMonth()];
            const year = currentDate.getFullYear();
            return `${month} - ${year}`;
        }
    }
}
</script>
<style>
.text-color {
    color: #5164a7;
}
</style>