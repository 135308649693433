<template>
  <v-main>
    <v-container fluid>
      <v-card elevation="6">
        <v-card-title class="mt-4">
          <v-btn
            class="mr-2"
            fab
            x-small
            color="primary"
            @click="$router.back()"
          >
            <v-icon dark> mdi-arrow-left </v-icon> </v-btn
          >Add Attendance
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="4">
              <v-autocomplete
                ref="employee"
                v-model="form.user_id"
                outlined
                :items="employees"
                label="Employee"
                append-icon="mdi-account"
                :error-messages="errors.user_id"
                @input="UpdateUpline"
              ></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-autocomplete
                ref="upline_position"
                outlined
                readonly
                v-model="form.upline_position_id"
                :items="positionItems"
                label="Upline Position *"
                prepend-inner-icon="mdi-account-tie-hat-outline"
                messages="this will be automatically select based on your position."
                :error-messages="errors.upline_position_id"
              ></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-autocomplete
                ref="upline_user"
                outlined
                v-model="form.supervisor_id"
                :items="uplineUserItems"
                label="Upline User"
                prepend-inner-icon="mdi-account"
                clearable
                :error-messages="errors.supervisor_id"
              ></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-menu
                v-model="date_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="form.date"
                    label="Date"
                    append-icon="mdi-calendar"
                    readonly
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    clearable
                    @click:clear="form.date = null"
                    :error-messages="errors.date"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="form.date"
                  @change="date_menu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col sm="4">
              <v-menu
                ref="logintime"
                v-model="login_time_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="time"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="form.login_time"
                    label="Login time"
                    outlined
                    append-icon="mdi-clock-time-nine-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    clearable
                    @click:clear="form.login_time = null"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="login_time_menu"
                  v-model="form.login_time"
                  full-width
                  @click:minute="$refs.logintime.save(form.login_time)"
                ></v-time-picker>
              </v-menu>
            </v-col>
            <v-col sm="4">
              <v-menu
                ref="logouttime"
                v-model="logout_time_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="time"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="form.logout_time"
                    label="Logout time"
                    outlined
                    append-icon="mdi-clock-time-nine-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :disabled="form.login_time ? false : true"
                    clearable
                    @click:clear="form.logout_time = null"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="logout_time_menu"
                  v-model="form.logout_time"
                  full-width
                  @click:minute="$refs.logouttime.save(form.logout_time)"
                ></v-time-picker>
              </v-menu>
            </v-col>
            <v-col sm="4" v-if="form.role_id != '4' && form.role_id != '2'">
              <v-autocomplete
                ref="session type"
                v-model="form.session_type_id"
                :items="sessionTypes"
                outlined
                label="Session Type"
                append-icon="mdi-account"
                :error-messages="errors.session_type_id"
              ></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.remarks"
                outlined
                label="Remark"
                prepend-inner-icon="mdi-card-text-outline"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-divider class="mt-5 mb-5"> </v-divider>

              <v-btn
                class="float-end"
                color="primary"
                :loading="isLoading"
                :dark="!isLoading"
                :disabled="isLoading"
                @click="save"
              >
                Save User Attendance
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";
export default {
  name: "UserCreatePage",
  data() {
    return {
      form: {
        login_time: "",
        logout_time: "",
        attendance_type: "Normal",
      },
      employees: [
        {
          id: 0,
          text: "Select Employee",
          value: "",
        },
      ],
      sessionTypes: [
        {
          id: 0,
          text: "Select Session Type",
          value: "",
        },
      ],
      uplineUserItems: [
        {
          id: 0,
          text: "Select Upline",
          value: "",
        },
      ],
      supervisorDownlined: [
        "Field Executive",
        "Merchandiser",
        "Promoter",
        "Van Sales",
      ],
      positionItems: [],
      isLoading: false,
      date_menu: false,
      login_time_menu: false,
      logout_time_menu: false,
    };
  },
  mounted() {
    if (this.user.roles[0].name == "SUPERVISOR") {
      this.form.supervisor_id = this.user.id;
    }
    this.getMasters();
  },
  methods: {
    async getMasters() {
      let masters = await axios.get(`user_attendances/masters`);
      masters = masters.data;
      // Employees
      masters.employees.forEach((employee) => {
        this.employees.push({
          id: employee.id,
          text: employee.full_name,
          position_id: employee.position_id,
          value: employee.id,
        });
      });
      // SessionType
      masters.sessionTypes.forEach((sessionTypes) => {
        this.sessionTypes.push({
          id: sessionTypes.id,
          text: sessionTypes.code,
          value: sessionTypes.id,
        });
        console.log(this.sessionTypes);
      });
      // Supervisors
      masters.positions.forEach((user) => {
        this.positionItems.push({
          id: user.id,
          text: user.name,
          value: user.id,
        });
      });
    },
    UpdateUpline() {
      let positionId = this.employees.find(
        (employee) => employee.id == this.form.user_id
      ).position_id;
      console.log("Position:" + positionId);

      let current = this.positionItems.find(
        (position) => position.id == positionId
      );
      console.log("current", current);
      if (
        this.supervisorDownlined.find((position) => position == current.text)
      ) {
        let upline = this.positionItems.find(
          (position) => position.text == "Supervisor"
        );
        if (upline) {
          this.form.upline_position_id = upline ? upline.id : null;
          this.getUplineUsers();
        }
      } else {
        let upline =
          this.positionItems[this.positionItems.indexOf(current) - 1];
        if (upline) {
          this.form.upline_position_id = upline ? upline.id : null;
          console.log("upline_position_id", this.form.upline_position_id);
          console.log("positionItems", this.positionItems);
          this.getUplineUsers();
        }
      }
    },
    async getUplineUsers() {
      this.uplineUserItems = [];
      let query = "position_id=" + this.form.upline_position_id;
      await axios
        .get(`users?${query}`)
        .then((users) => {
          //User
          users.data.data.forEach((user) => {
            this.uplineUserItems.push({
              id: user.id,
              text: user.full_name,
              value: user.id,
            });
          });
        })
        .catch(function (error) {
          console.log(error);
          // redirect to Home Page
          window.location.href = "/auth/login";
        });
    },
    async save() {
      try {
        this.isLoading = true;
        await axios.post("/user_attendances", this.form);
        this.isLoading = false;
        // this.$router.push("/user-attendances");
      } catch (e) {
        // alert("error");
        this.isLoading = false;
      }
    },
  },
};
</script>
