<template>
  <v-main>
    <v-container fluid>
      <v-card elevation="6" rounded="xxl">
        <v-card-title class="mt-4">
          <v-btn class="mx-2" fab x-small color="primary" @click="$router.back()">
            <v-icon dark> mdi-arrow-left </v-icon> </v-btn>Add Pjp
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="4">
              <v-autocomplete ref="beat" outlined v-model="form.beat_id" :items="beatItems" label="Beat"
                prepend-inner-icon="mdi-account-tie-hat-outline" clearable
                :error-messages="errors.beat_id"></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-autocomplete ref="user" outlined v-model="form.user_id" :items="userItems" label="User"
                prepend-inner-icon="mdi-account-tie-hat-outline" clearable
                :error-messages="errors.user_id"></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-menu v-model="date_menu" :close-on-content-click="false" :nudge-right="40"
                transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="form.date" label="Date" prepend-inner-icon="mdi-calendar" readonly outlined
                    v-bind="attrs" v-on="on" clearable @click:clear="form.date = null"
                    :error-messages="errors.date"></v-text-field>
                </template>
                <v-date-picker v-model="form.date" @change="date_menu = false"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col sm="4">
              <v-menu ref="Mark in time" v-model="markin_time_menu" :close-on-content-click="false" :nudge-right="40"
                :return-value.sync="time" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field outlined v-model="form.markin_time" label="Mark in time"
                    append-icon="mdi-clock-time-nine-outline" readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-time-picker v-if="markin_time_menu" v-model="form.markin_time" full-width
                  @click:minute="$refs.menu.save(form.markin_time)"></v-time-picker>
              </v-menu>
            </v-col>
            <v-col sm="4">
              <v-menu ref="Mark out time" v-model="markout_time_menu" :close-on-content-click="false" :nudge-right="40"
                :return-value.sync="time" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field outlined v-model="form.markout_time" label="Mark out time"
                    append-icon="mdi-clock-time-nine-outline" readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-time-picker v-if="markout_time_menu" v-model="form.markout_time" full-width
                  @click:minute="$refs.menu.save(time)"></v-time-picker>
              </v-menu>
            </v-col>
            <v-col sm="4">
              <v-textarea label="Description" auto-grow outlined v-model="form.description" rows="1"
                row-height="15"></v-textarea>
            </v-col>
          </v-row>
          <v-divider class="my-5"> </v-divider>
          <section>
            <template v-if="form.pjp_outlets.length">
              <h3 class="my-4">List of Outlet(s) for this Pjp:</h3>
              <v-row v-for="(outlet, pO) in form.pjp_outlets" :key="`outlet${pO}`">
                <v-col sm="4">
                  <v-autocomplete ref="outlet" outlined v-model="outlet.outlet_id" :items="outletItems" label="Outlet"
                    prepend-inner-icon="mdi-store" clearable :error-messages="errors[`pjp_outlets.${pO}.outlet_id`]
            ? errors[`pjp_outlets.${pO}.outlet_id`]
            : ``
            "></v-autocomplete>
                </v-col>
                <v-col sm="1">
                  <v-dialog v-model="isDeleteOutletDialogs[pO]" max-width="600px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn fab class="ml-9" small dark color="red" v-bind="attrs" v-on="on">
                        <v-icon dark> mdi-close-thick </v-icon>
                      </v-btn>
                    </template>
                    <v-card elevation="10">
                      <v-card-title>
                        <v-icon large color="primary">
                          mdi-information-outline
                        </v-icon>
                        &nbsp; Do you want to remove the outlet?
                      </v-card-title>
                      <v-card-text>
                        <v-btn color="primary" @click.stop="deleteOutlet(outlet)">
                          Yes
                        </v-btn>
                        &nbsp;
                        <v-btn color="red" dark @click.stop="isDeleteOutletDialogs = []">
                          No
                        </v-btn>
                      </v-card-text>
                    </v-card>
                  </v-dialog>
                </v-col>
              </v-row>
            </template>
            <v-row>
              <v-col sm="12" class="mb-5">
                <v-btn small rounded color="primary" @click="Add_Empty_Outlet">
                  Add Outlet
                  <v-icon dark> mdi-plus </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-divider class="mt-5 mb-5"> </v-divider>
          </section>
          <v-btn rounded color="primary" :loading="isLoading" :disabled="isLoading" @click="save">
            Save Pjp
          </v-btn>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";
export default {
  name: "PjpCreatePage",
  data() {
    return {
      form: {
        name: "",
        pjp_outlets: [],
      },
      userItems: [],
      outletItems: [],
      beatItems: [],
      isDeleteOutletDialogs: [],
      date_menu: false,
      isLoading: false,
      markin_time_menu: false,
      markout_time_menu: false,
    };
  },
  mounted() {
    this.getMasters();
  },
  methods: {
    async getMasters() {
      await axios
        .get(`pjps/masters`)
        .then((masters) => {
          masters = masters.data;
          //Users
          masters.users.forEach((user) => {
            this.userItems.push({
              id: user.id,
              text: user.full_name,
              value: user.id,
            });
          });
          //Outlets
          masters.outlets.forEach((outlet) => {
            this.outletItems.push({
              id: outlet.id,
              text: outlet.name,
              value: outlet.id,
            });
          });
          //Beats
          masters.beats.forEach((beat) => {
            this.beatItems.push({
              id: beat.id,
              text: beat.name,
              value: beat.id,
            });
          });
        })
        .catch(function (error) {
          console.log(error);
          // redirect to Home Page
          // window.location.href = "/auth/login";
        });
    },
    async save() {
      try {
        this.isLoading = true;
        await axios
          .post("/pjps", this.form)
          .then(async () => {
            await this.saveUserTimestamp(null, this.form);
            let PageModule = 'CREATE';
            let PageCode = 'PJPS';
            const userIP = await this.getUserIp();
            this.saveAuditTrial(userIP, PageModule, PageCode)
            this.isLoading = false;
            this.$router.push("/pjps");
          })
          .catch((error) => {
            // redirect to Home Page
            // window.location.href = "/auth/login";
            console.error("Error fetching data:", error);
            this.isLoading = false;
          });
      } catch (e) {
        // alert("error");
        console.log(e);
        this.isLoading = false;
      }
    },
    Add_Empty_Outlet() {
      let pjp_outlet = {
        outlet_id: "",
      };
      this.form.pjp_outlets.push(pjp_outlet);
    },
    deleteOutlet(pjp_outlet) {
      this.form.pjp_outlets.splice(
        this.form.pjp_outlets.indexOf(pjp_outlet),
        1
      );
      this.isDeleteOutletDialogs = [];
    },
  },
};
</script>
