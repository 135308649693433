<template>
  <v-main>
    <v-container fluid>
      <v-card elevation="6">
        <v-card-title class="mt-4 ml-4">
          <v-btn class="mx-2" fab x-small color="primary" @click="$router.back()">
            <v-icon dark> mdi-arrow-left </v-icon> </v-btn>Edit Sku
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="4">
              <v-text-field v-model="form.name" outlined label="Sku Name *" prepend-inner-icon="mdi-buffet"
                :error-messages="errors.name"></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field v-model="form.hsn_code" outlined label="HSN Code *" prepend-inner-icon="mdi-buffet"
                :error-messages="errors.hsn_code"></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field v-model="form.price" outlined label="Price *" prepend-inner-icon="mdi-email"
                :error-messages="errors.price"></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-autocomplete ref="brand" v-model="form.brand_id" :items="brands" label="Brand"
                prepend-icon="mdi-account" :error-messages="errors.brand_id"></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <input type="file" id="file" name="file" ref="file" accept="image/*" />
            </v-col>
            <v-col sm="4">
              <v-switch class="mt-0 ml-5" v-model="form.is_active" inset
                :label="`Is Active: ${form.is_active ? 'YES' : 'NO'}`"></v-switch>
            </v-col>
          </v-row>
          <v-divider class="mt-5 mb-5"> </v-divider>

          <v-progress-circular v-if="isLoading" indeterminate color="primary"></v-progress-circular>
          <v-btn v-else color="primary" dark @click="save"> Update Sku </v-btn>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";
export default {
  name: "SkuIdPage",
  data() {
    return {
      form: {
        active: true,
      },
      companyId: "",
      brands: [{ id: 0, text: "Select Brand", value: "" }],
      isLoading: false,
    };
  },
  mounted() {
    this.getData();
    this.getMasters();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      let form = await axios.get(`/skus/${this.$route.params.skuId}`);
      this.form = form.data.data;
      this.isLoading = false;
    },
    async getMasters() {
      let masters = await axios.get(`skus/masters`);
      masters = masters.data;
      //Brand
      masters.brands.forEach((brand) => {
        this.brands.push({
          id: brand.id,
          text: brand.description,
          value: brand.id,
        });
      });
    },
    async save() {
      try {
        this.isLoading = true;
        await axios.patch(`/skus/${this.$route.params.skuId}`, this.form);
        await this.handleFileUpload();
        this.isLoading = false;
        this.$router.push("/skus");
      } catch (e) {
        // alert("error");
        this.isLoading = false;
      }
    },
    async handleFileUpload() {
      let image_path = this.$refs.file.files[0];
      const sku_id = this.form.id;
      let formData = new FormData();
      formData.append("id", sku_id);
      formData.append("image_path", image_path);
      await axios
        .post("upload_sku_image", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .catch(function () {
          console.log("FAILURE!!");
        });
    },
  },
};
</script>
