<template>
  <v-main>
    <v-container fluid>
      <v-row>
        <v-col md="12">
          <v-card elevation="6" rounded="xxl">
            <v-card-title>
              <section v-if="isDeletePage() != true">
                <v-btn class="mx-2 mb-2" color="primary" to="/shift-managements/create">
                  Shift Mangements <v-icon dark> mdi-plus </v-icon>
                </v-btn>
              </section>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col md="6">
                  <v-text-field solo rounded v-model="filter.search_keyword" label="Search ... "
                    prepend-inner-icon="mdi-magnify" clearable @click:clear="
                (filter.search_keyword = null), (page = 1), getData()
                " @keydown.enter="(page = 1), getData()"></v-text-field>
                </v-col>
                <v-col sm="3">
                  <v-autocomplete ref="status" solo rounded v-model="filter.is_active" :items="statuses" label="Status"
                    @input="(page = 1), getData()" prepend-inner-icon="mdi-toggle-switch" clearable></v-autocomplete>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col md="12">
          <v-card elevation="6" rounded="xxl">
            <v-card-text>
              <section v-if="isLoading" style="height: 560px">
                <v-progress-linear indeterminate color="primary"></v-progress-linear>
              </section>
              <v-simple-table v-else fixed-header height="560" class="striped-table">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-start text-subtitle-2 font-weight-bold">Sr No</th>
                      <th class="text-start text-subtitle-2 font-weight-bold">Status</th>
                      <th class="text-start text-subtitle-2 font-weight-bold" style="min-width: 150px;">Shift Name</th>
                      <th class="text-start text-subtitle-2 font-weight-bold" style="min-width: 150px;">Shift Start Time</th>
                      <th class="text-start text-subtitle-2 font-weight-bold" style="min-width: 150px;">Shift End Time</th>
                      <th class="text-start text-subtitle-2 font-weight-bold" style="min-width: 210px;">Late Mark After(mins)</th>
                      <th class="text-start text-subtitle-2 font-weight-bold" style="min-width: 210px;">Half Day Before Time</th>
                      <th class="text-center action_th" style="min-width: 210px;">Action</th>
                    </tr>
                  </thead>
                  <tbody v-if="shift_managements.length != 0">
                    <tr v-for="(shift, i) in shift_managements" :key="`shift${i}`">
                      <td style="min-width: 85px">
                        {{ i + 1 }}
                      </td>
                      <td>
                        <v-tooltip bottom v-if="shift.is_active == 1">
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip v-bind="attrs" v-on="on" small color="teal" text-color="white">
                              <v-avatar left>
                                <v-icon size="15">mdi-checkbox-marked-circle</v-icon>
                              </v-avatar>
                              Active
                            </v-chip>
                          </template>
                          <span>User attendance is Active</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="shift.is_active == 0">
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip v-bind="attrs" v-on="on" small color="red" text-color="white">
                              <v-avatar left>
                                <v-icon size="15">mdi-close-circle</v-icon>
                              </v-avatar>
                              Inactive
                            </v-chip>
                          </template>
                          <span>shift is InActive</span>
                        </v-tooltip>
                      </td>
                      <td>{{ shift.shift_name || "" }}</td>
                      <td>{{ shift.start_time || "" }}</td>
                      <td>{{ shift.end_time || "" }}</td>
                      <td>{{ shift.late_mark || "" }}</td>
                      <td>{{ shift.half_day_before_time || "" }}</td>
                      <td class="text-right">
                        <v-btn class="ml-2 text-caption" color="primary" @click="getShiftEmployee(shift.id)">
                          View Employees
                        </v-btn>
                        <template>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn v-bind="attrs" v-on="on" class="ml-2" fab x-small color="primary" :to="`/shift-managements/${encryptIt(
                shift.id
              )}`">
                                <v-icon dark> mdi-pencil </v-icon>
                              </v-btn>
                            </template>
                            <span>Edit this User attendance</span>
                          </v-tooltip>
                        </template>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="12" class="text-center">No data found</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <v-pagination v-model="page" :length="paginationLength">
              </v-pagination>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-dialog v-model="shiftusersDialog">
        <v-card elevation="6" rounded="xxl">
          <v-card-title>
            <v-btn color="primary" @click="AddUsersDialog = true">
              Add Users
            </v-btn>
          </v-card-title>
          <v-card-text>
            <section v-if="isLoading" style="height: 485px">
              <v-progress-linear indeterminate color="primary"></v-progress-linear>
            </section>
            <v-simple-table v-else fixed-header height="485" class="striped-table">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-start font-weight-bold text-subtitle-2">Sr No</th>
                    <th class="text-start font-weight-bold text-subtitle-2">Status</th>
                    <th class="text-start font-weight-bold text-subtitle-2">Employee Name</th>
                    <th class="text-start font-weight-bold text-subtitle-2">Employee Code</th>
                    <th class="text-start font-weight-bold text-subtitle-2">Branch</th>
                    <th class="text-start font-weight-bold text-subtitle-2">Department</th>
                    <th class="text-start font-weight-bold text-subtitle-2">Grade</th>
                    <th class="text-right action_th font-weight-bold text-subtitle-2">Action</th>
                  </tr>
                </thead>
                <tbody v-if="shiftUsers.length != 0">
                  <tr v-for="(user, i) in shiftUsers" :key="`user${i}`">
                    <td style="min-width: 85px">
                      {{ i + 1 }}
                    </td>
                    <td>
                      <v-tooltip bottom v-if="user.is_active == 1">
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip v-bind="attrs" v-on="on" small color="teal" text-color="white">
                            <v-avatar left>
                              <v-icon size="15">mdi-checkbox-marked-circle</v-icon>
                            </v-avatar>
                            Active
                          </v-chip>
                        </template>
                        <span>User attendance is Active</span>
                      </v-tooltip>
                      <v-tooltip bottom v-if="user.is_active == 0">
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip v-bind="attrs" v-on="on" small color="red" text-color="white">
                            <v-avatar left>
                              <v-icon size="15">mdi-close-circle</v-icon>
                            </v-avatar>
                            Inactive
                          </v-chip>
                        </template>
                        <span>user is InActive</span>
                      </v-tooltip>
                    </td>
                    <td>{{ user.full_name || "" }}</td>
                    <td>{{ user.employee_code || "" }}</td>
                    <td>{{ user.branch || "" }}</td>
                    <td>{{ user.department || "" }}</td>
                    <td>{{ user.grade || "" }}</td>
                    <td class="text-right">
                      <v-btn class="ml-2" fab x-small color="primary" @click="RemoveUserFromShift(user.id)">
                        <v-icon dark> mdi-close-thick </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="12" class="text-center">No data found</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-pagination v-model="page" :length="paginationLength">
            </v-pagination>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="AddUsersDialog">
        <v-card elevation="6" rounded="xxl">
          <v-card-title>
            <v-col sm="4" class="py-0">
              <v-autocomplete solo dense ref="position" outlined v-model="filter.designation" :items="Designations"
                label="Designation *" prepend-inner-icon="mdi-account-tie-outline" clearable
                :error-messages="errors.designation"></v-autocomplete>
            </v-col>
            <v-col sm="4" class="py-0">
              <v-autocomplete solo dense ref="position" outlined v-model="filter.grade_id" :items="Grades"
                label="Grade *" prepend-inner-icon="mdi-account-tie-outline" clearable
                :error-messages="errors.grade_id"></v-autocomplete>
            </v-col>
            <v-col sm="4" class="py-0">
              <v-autocomplete solo dense ref="position" outlined v-model="filter.position_id" :items="positions"
                label="Positions *" prepend-inner-icon="mdi-account-tie-outline" clearable
                :error-messages="errors.designation"></v-autocomplete>
            </v-col>
            <v-col sm="4" class="py-0">
              <v-autocomplete solo dense ref="position" outlined v-model="filter.department" :items="Departments"
                label="Departments *" prepend-inner-icon="mdi-account-tie-outline" clearable
                :error-messages="errors.designation"></v-autocomplete>
            </v-col>
            <v-col sm="4" class="py-0">
              <v-autocomplete solo dense ref="position" outlined v-model="filter.branch" :items="Branchs"
                label="Branchs *" prepend-inner-icon="mdi-account-tie-outline" clearable
                :error-messages="errors.designation"></v-autocomplete>
            </v-col>
            <v-col cols="2" class="py-0">
              <v-btn class="mb-5" color="primary" @click="getUsersData">
                Get Users
              </v-btn>
            </v-col>
            <v-col cols="2" class="d-flex justify-end">
              <v-checkbox v-model="selectAll" label="Select All" @change="toggleSelectAll" hide-details></v-checkbox>
            </v-col>
          </v-card-title>
          <v-card-text>
            <section v-if="isLoading" style="height: 485px">
              <v-progress-linear indeterminate color="primary"></v-progress-linear>
            </section>
            <v-simple-table v-else fixed-header height="485" class="striped-table">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-start font-weight-bold text-subtitle-2">Sr No</th>
                    <th class="text-start font-weight-bold text-subtitle-2">Status</th>
                    <th class="text-start font-weight-bold text-subtitle-2">Employee Name</th>
                    <th class="text-start font-weight-bold text-subtitle-2">Employee Code</th>
                    <th class="text-start font-weight-bold text-subtitle-2">Branch</th>
                    <th class="text-start font-weight-bold text-subtitle-2">Department</th>
                    <th class="text-start font-weight-bold text-subtitle-2">Grade</th>
                    <th class="text-right action_th font-weight-bold text-subtitle-2">Action</th>
                  </tr>
                </thead>
                <tbody v-if="users.length != 0">
                  <tr v-for="(user, i) in users" :key="`user${i}`">
                    <td style="min-width: 85px">
                      {{ i + 1 }}
                    </td>
                    <td>
                      <v-tooltip bottom v-if="user.is_active == 1">
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip v-bind="attrs" v-on="on" small color="teal" text-color="white">
                            <v-avatar left>
                              <v-icon size="15">mdi-checkbox-marked-circle</v-icon>
                            </v-avatar>
                            Active
                          </v-chip>
                        </template>
                        <span>User attendance is Active</span>
                      </v-tooltip>
                      <v-tooltip bottom v-if="user.is_active == 0">
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip v-bind="attrs" v-on="on" small color="red" text-color="white">
                            <v-avatar left>
                              <v-icon size="15">mdi-close-circle</v-icon>
                            </v-avatar>
                            Inactive
                          </v-chip>
                        </template>
                        <span>user is InActive</span>
                      </v-tooltip>
                    </td>
                    <td>{{ user.full_name || "" }}</td>
                    <td>{{ user.employee_code || "" }}</td>
                    <td>{{ user.branch || "" }}</td>
                    <td>{{ user.department || "" }}</td>
                    <td>{{ user.grade ? user.grade.description : "" }}</td>
                    <td class="text-right">
                      <v-checkbox class="table-check-box" style="margin-top: 0px !important" v-model="selectedUsers"
                        :value="user" hide-details></v-checkbox>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="6"></td>
                    <td colspan="2">
                      <v-btn block color="primary" @click="submitSelectedUsers">Submit</v-btn>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="12" class="text-center">No data found</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-pagination v-model="Userpage" :length="UserpaginationLength">
            </v-pagination>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="isRemoveUserDialog" width="600">
        <v-card rounded="xxl">
          <v-container>
            <v-row align="center">
              <v-col sm="3" align="center">
                <v-icon large style="color: green; font-size: 90px; width: 200px !important">
                  mdi-delete-empty
                </v-icon>
              </v-col>
              <v-col sm="9" align="center">
                <v-card-text>
                  <v-row>
                    <v-col>
                      <h1 style="color: green">Hurray!</h1>
                      <br />
                      <h3>User Remove From Shift Successfully.</h3>
                      <br />
                      <v-btn color="success" @click="isRemoveUserDialog = false">
                        OK
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
export default {
  name: "shift_managementsPage",
  data() {
    return {
      filter: {
        active: "",
        search_keyword: "",
        designation: "",
        position_id: "",
        department: "",
        branch: "",
      },
      page: 1,
      serialNoStarting: 1,
      paginationLength: 0,
      count: 0,
      Userpage: 1,
      UserserialNoStarting: 1,
      UserpaginationLength: 0,
      Usercount: 0,
      shift_managements: [],
      users: [],
      shiftUsers: [],
      Designations: [],
      positions: [],
      Departments: [],
      Branchs: [],
      selectedUsers: [],
      Grades: [],
      selectAll: false,
      statuses: [
        {
          id: 0,
          text: "Select Active Status",
          value: "",
        },
        { id: "ACTIVE", text: "ACTIVE", value: "1" },
        { id: "INACTIVE", text: "INACTIVE", value: "0" },
      ],
      isLoading: false,
      shiftusersDialog: false,
      AddUsersDialog: false,
      isRemoveUserDialog: false,
    };
  },
  watch: {
    page: "getData",
    Userpage: "getUsersData",
    $route() {
      this.getData();
    },
  },
  mounted() {
    this.getData();
    this.getMasters();
  },
  methods: {
    async getMasters() {
      await axios
        .get(`users/masters`)
        .then((masters) => {
          masters = masters.data;
          //Position
          masters.positions.forEach((position) => {
            this.positions.push({
              id: position.id,
              text: position.name,
              value: position.id,
            });
          });
          //designation
          masters.designations.forEach((designation) => {
            this.Designations.push({
              id: designation.id,
              text: designation.description,
              value: designation.description,
            });
          });
          //departments
          masters.departments.forEach((department) => {
            this.Departments.push({
              id: department.id,
              text: department.description,
              value: department.description,
            });
          });
          //branchs
          masters.branchs.forEach((branch) => {
            this.Branchs.push({
              id: branch.id,
              text: branch.description,
              value: branch.description,
            });
          });
          //grades
          masters.grades.forEach((grade) => {
            this.Grades.push({
              id: grade.id,
              text: grade.description,
              value: grade.id,
            });
          });
        })
        .catch(function (error) {
          console.log(error);
          //   window.location.href = "/auth/login";
        });
    },
    async getData() {
      this.users = [];
      this.shiftUsers = [];
      console.log("Get Data");
      this.isLoading = true;
      this.filter.search_keyword = this.filter.search_keyword
        ? this.filter.search_keyword
        : "";
      this.filter.is_active = this.filter.is_active
        ? this.filter.is_active
        : "";
      let query =
        "page=" +
        this.page +
        "&rowsPerPage=" +
        this.rowsPerPage +
        "&is_active=" +
        this.filter.is_active +
        "&search_keyword=" +
        this.filter.search_keyword;
      await axios
        .get(`shift_managements?${query}`)
        .then((response) => {
          this.shift_managements = response.data.data;
          this.count = response.data.count;
          this.serialNoStarting = (this.page - 1) * this.rowsPerPage;
          this.paginationLength = Math.ceil(this.count / this.rowsPerPage);
        })
        .catch(function (error) {
          console.log(error);
          // window.location.href = "/auth/login";
        });
      this.isLoading = false;
    },
    getShiftEmployee(ShiftId) {
      this.shiftUsers = [];
      this.CurrentShiftId = ShiftId;
      let ShiftUsers = this.shift_managements.find((el) => el.id == ShiftId);
      this.shiftUsers = ShiftUsers.users;
      this.shiftusersDialog = true;
      console.log("ShiftUsers", ShiftUsers);
    },
    async getUsersData() {
      console.log("Get Data");
      this.isLoading = true;
      this.filter.position_id = this.filter.position_id
        ? this.filter.position_id
        : "";
      this.filter.position_id = this.filter.position_id
        ? this.filter.position_id
        : "";
      this.filter.designation = this.filter.designation
        ? this.filter.designation
        : "";
      this.filter.department = this.filter.department
        ? this.filter.department
        : "";
      this.CurrentShiftId = this.CurrentShiftId ? this.CurrentShiftId : "";
      this.filter.branch = this.filter.branch ? this.filter.branch : "";
      let query =
        "page=" +
        this.Userpage +
        "&rowsPerPage=" +
        this.rowsPerPage +
        "&role_id=3" +
        "&position_id=" +
        this.filter.position_id +
        "&designation=" +
        this.filter.designation +
        "&department=" +
        this.filter.department +
        "&grade_id=" +
        this.filter.grade_id +
        "&CurrentShiftId=" +
        this.CurrentShiftId +
        "&branch=" +
        this.filter.branch;
      await axios
        .get(`users?${query}`)
        .then((response) => {
          this.users = response.data.data;
          this.Usercount = response.data.count;
          this.UserserialNoStarting = (this.page - 1) * this.rowsPerPage;
          this.UserpaginationLength = Math.ceil(
            this.Usercount / this.rowsPerPage
          );
        })
        .catch(function (error) {
          console.log(error);
          // window.location.href = "/auth/login";
        });
      this.isLoading = false;
    },
    toggleSelectAll() {
      if (this.selectAll) {
        this.selectedUsers = [...this.users];
      } else {
        this.selectedUsers = [];
      }
    },
    async submitSelectedUsers() {
      console.log("submitSelectedUsers", this.selectedUsers);
      const selectedUserData = this.selectedUsers.map((user) => ({
        user_id: user.id,
        shift_id: this.CurrentShiftId,
      }));
      await axios
        .post(`/update_multiple_user_shift`, selectedUserData)
        .then(() => {
          this.shiftusersDialog = false;
          this.AddUsersDialog = false;
          this.getData();
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    async RemoveUserFromShift(userid) {
      await axios
        .post(`/remove_user_shift?user_id=${userid}`)
        .then(() => {
          this.isRemoveUserDialog = true;
          this.getData();
          this.shiftusersDialog = false;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    async Delete_this(id) {
      this.delete_user_attendance = true;
      await axios
        .post(`/shift_managements/delete/${this.encryptIt(id)}`)
        .then(() => {
          this.delete_user_attendance = false;
          this.is_deleted_user_attendance = true;
          this.getData();
        })
        .catch(function (error) {
          console.log(error);
          window.location.href = "/auth/login";
        });
    },
  },
};
</script>
<style>
.table-check-box .v-input__slot {
  justify-content: end;
}
</style>
