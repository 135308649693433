<template>
  <v-main>
    <v-container fluid>
      <v-row>
        <v-col md="12">
          <v-card elevation="6" rounded="xxl">
            <v-card-title>
              <h3 class="font-weight-bold" v-text="isDeletePage() != true ? 'Pjps' : 'Deleted Pjps'"></h3>
              <template v-if="isDeletePage() != true">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" class="mx-2 mb-2" fab x-small color="primary" to="/pjps/create">
                      <v-icon dark> mdi-plus </v-icon>
                    </v-btn>
                  </template>
                  <span>Create Pjp</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" class="mx-2 mb-2" fab x-small color="primary"
                      to="/pjps/copy-pjp-references">
                      <v-icon dark> mdi-content-duplicate </v-icon>
                    </v-btn>
                  </template>
                  <span>Copy Pjp from References</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" class="mx-2 mb-2" fab x-small color="primary"
                      to="/pjps/copy-pjp-prev-month">
                      <!-- @click="show_copy_pjp_prev_month = true" -->
                      <v-icon dark> mdi-content-duplicate </v-icon>
                    </v-btn>
                  </template>
                  <span>Copy Pjp from Previous Month</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" class="mx-2 mb-2" fab x-small color="primary" to="/pjps/upload">
                      <v-icon dark> mdi-upload </v-icon>
                    </v-btn>
                  </template>
                  <span>Upload Pjp</span>
                </v-tooltip>
              </template>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col md="6">
                  <v-text-field solo rounded v-model="filter.search_keyword" label="Search ... " color="#e60040"
                    prepend-inner-icon="mdi-magnify" clearable @click:clear="
                (filter.search_keyword = null), (page = 1), getData()
                " @keydown.enter="(page = 1), getData()">
                  </v-text-field>
                </v-col>
                <v-col sm="3">
                  <v-menu v-model="month_year_menu" :close-on-content-click="false" :nudge-right="40"
                    transition="scale-transition" offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="filter.month_year" label="Year - Month" prepend-inner-icon="mdi-calendar"
                        readonly solo rounded v-bind="attrs" v-on="on" clearable @input="getData"
                        @click:clear="(filter.month_year = null), (page = 1)"
                        :error-messages="errors.month_year"></v-text-field>
                    </template>
                    <v-date-picker v-model="filter.month_year" type="month"
                      @change="(month_year_menu = false), (page = 1)" @input="getData"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col sm="3">
                  <v-autocomplete ref="status" solo rounded v-model="filter.is_active" :items="statuses" clearable
                    @click:clear="(filter.is_active = null), (page = 1)" label="Status" @input="getData"
                    prepend-inner-icon="mdi-toggle-switch"></v-autocomplete>
                </v-col>
                <v-col sm="3">
                  <v-autocomplete ref="User" solo rounded v-model="filter.user_id" :items="userItems" clearable
                    @click:clear="(filter.user_id = null), (page = 1)" label="User" @input="getData"
                    prepend-inner-icon="mdi-account"></v-autocomplete>
                </v-col>
                <v-col sm="3">
                  <v-autocomplete ref="Beat" solo rounded v-model="filter.beat_id" :items="beatItems" clearable
                    @click:clear="(filter.beat_id = null), (page = 1)" label="Beat" @input="getData"
                    prepend-inner-icon="mdi-account"></v-autocomplete>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="12">
          <v-card elevation="6" rounded="xxl">
            <v-card-text>
              <section v-if="isLoading" style="height: 560px">
                <v-progress-linear indeterminate color="primary"></v-progress-linear>
              </section>
              <v-simple-table v-else fixed-header height="560" class="striped-table">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-start text-subtitle-2 font-weight-bold sr_no">Sr No</th>
                      <th class="text-start text-subtitle-2 font-weight-bold">Status</th>
                      <th class="text-start text-subtitle-2 font-weight-bold" style="min-width: 100px;">Beat</th>
                      <th class="text-start text-subtitle-2 font-weight-bold" style="min-width: 100px">User</th>
                      <th class="text-start text-subtitle-2 font-weight-bold" style="min-width: 150px;">Date</th>
                      <th class="text-start text-subtitle-2 font-weight-bold" style="min-width: 150px;">Mark In Time
                      </th>
                      <th class="text-start text-subtitle-2 font-weight-bold" style="min-width: 150px;">Mark In Address
                      </th>
                      <th class="text-start text-subtitle-2 font-weight-bold" style="min-width: 150px;">Mark Out Time
                      </th>
                      <th class="text-start text-subtitle-2 font-weight-bold" style="min-width: 150px;">Mark Out Address
                      </th>
                      <th class="text-start text-subtitle-2 font-weight-bold" style="min-width: none">
                        Description
                      </th>
                      <th class="text-center text-subtitle-2 font-weight-bold action_th" style="min-width: 150px;">
                        Action</th>
                    </tr>
                  </thead>
                  <tbody v-if="pjps.length != 0">
                    <tr v-for="(pjp, i) in pjps" :key="`pjp_${i}`" :class="{
                'table-row-even': i % 2 === 0,
                'table-row-odd': i % 2 !== 0,
                'table-row-last': pjps.length - 1 == i,
              }">
                      <td style="min-width: 85px">
                        {{ i + 1 }}
                      </td>
                      <td>
                        <v-tooltip bottom v-if="pjp.is_active == 1">
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip v-bind="attrs" v-on="on" small color="teal" text-color="white">
                              <v-avatar left>
                                <v-icon size="15">mdi-checkbox-marked-circle</v-icon>
                              </v-avatar>
                              Active
                            </v-chip>
                          </template>
                          <span>Pjp is Active</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="pjp.is_active == 0">
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip v-bind="attrs" v-on="on" small color="red" text-color="white">
                              <v-avatar left>
                                <v-icon size="15">mdi-close-circle</v-icon>
                              </v-avatar>
                              Inactive
                            </v-chip>
                          </template>
                          <span>Pjp is InActive</span>
                        </v-tooltip>
                      </td>
                      <td>{{ pjp.beat.name || "" }}</td>
                      <td>{{ pjp.user.full_name || "" }}</td>
                      <td>{{ pjp.date || "" }}</td>
                      <td>{{ pjp.markin_time || "" }}</td>
                      <td>{{ pjp.markin_address || "" }}</td>
                      <td>{{ pjp.markout_time || "" }}</td>
                      <td>{{ pjp.markout_address || "" }}</td>
                      <td>{{ pjp.description || "" }}</td>
                      <td class="text-center">
                        <template v-if="isDeletePage() != true">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn v-bind="attrs" v-on="on" fab x-small class="my-auto" color="error"
                                @click="Delete_this(pjp.id)">
                                <v-icon dark> mdi-delete </v-icon>
                              </v-btn>
                            </template>
                            <span>Delete this Pjp</span>
                          </v-tooltip>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn v-bind="attrs" v-on="on" fab x-small class="ml-2" color="primary"
                                :to="`/pjps/${encryptIt(pjp.id)}/expenses?pjp=${pjp.name}`">
                                <v-icon dark> mdi-cash </v-icon>
                              </v-btn>
                            </template>
                            <span>View Pjps Expense</span>
                          </v-tooltip>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn v-bind="attrs" v-on="on" class="ml-2" fab x-small color="primary"
                                :to="`/pjps/${encryptIt(pjp.id)}`">
                                <v-icon dark> mdi-pencil </v-icon>
                              </v-btn>
                            </template>
                            <span>Edit this Pjp</span>
                          </v-tooltip>
                        </template>
                        <template v-else>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn v-bind="attrs" v-on="on" fab x-small class="my-auto" color="error"
                                @click="Restore_this(pjp.id)">
                                <v-icon dark> mdi-delete-restore </v-icon>
                              </v-btn>
                            </template>
                            <span>Restore this pjp</span>
                          </v-tooltip>
                        </template>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="8" class="text-center">No data found</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <v-pagination rounded v-model="page" :length="paginationLength">
              </v-pagination>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- Clear Success Dialog Box -->
      <v-dialog v-model="is_deleted_pjp" width="600">
        <v-card rounded="xxl">
          <v-container>
            <v-row align="center">
              <v-col sm="3" align="center">
                <v-icon large style="color: green; font-size: 90px; width: 200px !important">
                  mdi-delete-empty
                </v-icon>
              </v-col>
              <v-col sm="9" align="center">
                <v-card-text>
                  <v-row>
                    <v-col>
                      <h1 style="color: green">Hurray!</h1>
                      <br />
                      <h3>Pjp deleted successfully.</h3>
                      <br />
                      <v-btn color="success" @click="is_deleted_pjp = false">
                        OK
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
      <!-- Copy Pjp From Previous Month Dialog Box -->
      <v-dialog v-model="show_copy_pjp_prev_month" width="600">
        <v-card rounded="xxl">
          <v-card-text>
            <v-row> Copy Pjp from Previous Month </v-row>
            <v-row>
              <v-col>
                <h1 style="color: green">Hurray!</h1>
                <br />
                <h3>Pjp deleted successfully.</h3>
                <br />
                <v-btn color="success" @click="is_deleted_pjp = false">
                  OK
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- Restore Success Dialog Box -->
      <v-dialog v-model="is_restored_pjp" width="600">
        <v-card rounded="xxl">
          <v-container>
            <v-row align="center">
              <v-col sm="3" align="center">
                <v-icon large style="color: green; font-size: 90px; width: 200px !important">
                  mdi-delete-restore
                </v-icon>
              </v-col>
              <v-col sm="9" align="center">
                <v-card-text>
                  <v-row>
                    <v-col>
                      <h1 style="color: green">Hurray!</h1>
                      <br />
                      <h3>Pjp restored successfully.</h3>
                      <br />
                      <v-btn color="success" @click="is_restored_pjp = false">
                        OK
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
import moment from "moment";
export default {
  name: "pjpsPage",
  data() {
    return {
      filter: {
        search_keyword: "",
        is_active: "",
        show_deleted: false,
      },
      pjps: [],
      statuses: [
        { id: "ACTIVE", text: "ACTIVE", value: "1" },
        { id: "INACTIVE", text: "INACTIVE", value: "0" },
      ],
      page: 1,
      serialNoStarting: 1,
      paginationLength: 0,
      count: 0,
      userItems: [],
      beatItems: [],
      isLoading: false,
      show_copy_pjp_prev_month: false,
      isExcelloading: false,
      delete_pjp: false,
      month_year_menu: false,
      is_deleted_pjp: false,
      restore_pjp: false,
      is_restored_pjp: false,
    };
  },
  watch: {
    page: "getData",
    $route() {
      this.filter.month_year = moment().format("YYYY-MM");
      this.getData();
      this.getMasters();
    },
  },
  mounted() {
    this.filter.month_year = moment().format("YYYY-MM");
    this.getData();
    this.getMasters();
  },
  methods: {
    async getMasters() {
      await axios
        .get(`pjps/masters`)
        .then((masters) => {
          masters = masters.data;
          //Users
          masters.users.forEach((user) => {
            this.userItems.push({
              id: user.id,
              text: user.user_name,
              value: user.id,
            });
          });
          //Beats
          masters.beats.forEach((beat) => {
            this.beatItems.push({
              id: beat.id,
              text: beat.name,
              value: beat.id,
            });
          });
        })
        .catch(function (error) {
          console.log(error);
          // redirect to Home Page
          window.location.href = "/auth/login";
        });
    },
    async getData() {
      this.isLoading = true;
      this.filter.search_keyword = this.filter.search_keyword
        ? this.filter.search_keyword
        : "";
      this.filter.show_deleted = this.$route.query.open_trash ? 1 : 0;
      this.filter.is_active = this.filter.is_active
        ? this.filter.is_active
        : "";
      this.filter.month_year = this.filter.month_year
        ? this.filter.month_year
        : "";
      this.filter.user_id = this.filter.user_id ? this.filter.user_id : "";
      this.filter.beat_id = this.filter.beat_id ? this.filter.beat_id : "";
      let query =
        "page=" +
        this.page +
        "&rowsPerPage=" +
        this.rowsPerPage +
        "&is_active=" +
        this.filter.is_active +
        "&show_deleted=" +
        this.filter.show_deleted +
        "&month_year=" +
        this.filter.month_year +
        "&user_id=" +
        this.filter.user_id +
        "&beat_id=" +
        this.filter.beat_id +
        "&search_keyword=" +
        this.filter.search_keyword;
      await axios
        .get(`pjps?${query}`)
        .then((response) => {
          this.pjps = response.data.data;
          this.count = response.data.count;
          this.serialNoStarting = (this.page - 1) * this.rowsPerPage;
          this.paginationLength = Math.ceil(this.count / this.rowsPerPage);
        })
        .catch(function (error) {
          console.log(error);
          window.location.href = "/auth/login";
        });
      this.isLoading = false;
    },
    //Generate Excel
    async generateExcel() {
      let query = "search_keyword=" + this.filter.search_keyword;
      let response = await axios.get(`pjp_exports?${query}`);
      console.log(response.data.data);
      if (response) {
        window.open(`${response.data.data}`, "_blank");
      }
    },
    async Delete_this(id) {
      this.delete_pjp = true;
      await axios
        .post(`/pjps/delete/${this.encryptIt(id)}`)
        .then(() => {
          this.delete_pjp = false;
          this.is_deleted_pjp = true;
          this.getData();
        })
        .catch(function (error) {
          console.log(error);
          window.location.href = "/auth/login";
        });
    },
    async Restore_this(id) {
      this.restore_pjp = true;
      await axios
        .post(`/pjps/restore/${this.encryptIt(id)}`)
        .then(() => {
          this.restore_pjp = false;
          this.is_restored_pjp = true;
          this.getData();
        })
        .catch(function (error) {
          console.log(error);
          window.location.href = "/auth/login";
        });
    },
  },
};
</script>
