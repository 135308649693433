<template>
    <v-main>
      <v-container fluid>
        <!-- Existing content -->
        <v-row>
          <v-col md="12">
            <v-card elevation="6">
              <v-card-text>
                <span class="text-h5 font-weight-bold" v-text="`Pjp Expense`"></span>
                <v-row class="mt-3">
                  <v-col md="3">
                    <v-text-field solo rounded v-model="search_keyword" label="Search ..."
                      prepend-inner-icon="mdi-magnify" @keydown.enter="getData" @change="page = 1"></v-text-field>
                  </v-col>
                  <v-col sm="3">
                    <v-autocomplete solo rounded ref="expense" v-model="expense_type_id" :items="expenseTypes"
                      label="Expense Type" @input="getData" append-icon="mdi-cash"></v-autocomplete>
                  </v-col>
                  <v-col sm="3">
                    <v-autocomplete solo rounded ref="employee" v-model="user_id" :items="employees" label="Employee"
                      @input="getData" append-icon="mdi-account"></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col sm="3">
                    <v-autocomplete solo rounded ref="approve" v-model="isApproved" :items="statuses"
                      label="Approve Status" @input="getData" append-icon="mdi-toggle-switch"></v-autocomplete>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-progress-linear v-if="isLoading" indeterminate color="primary"></v-progress-linear>
        <v-row v-else>
          <v-col md="12">
            <v-card elevation="6">
              <v-simple-table fixed-header height="485">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-start text-subtitle-2 font-weight-bold">Sr No</th>
                      <th class="text-start text-subtitle-2 font-weight-bold">Employee Name</th>
                      <th class="text-start text-subtitle-2 font-weight-bold">Status</th>
                      <th class="text-start text-subtitle-2 font-weight-bold">Expense type Mode</th>
                      <th class="text-start text-subtitle-2 font-weight-bold">Amount</th>
                      <th class="text-center text-subtitle-2 font-weight-bold" style="width: 200px;">Action</th>
                    </tr>
                  </thead>
                  <tbody v-if="pjpExpenses.length != 0">
                    <tr v-for="(pjpExpense, i) in pjpExpenses" :key="`pjpExpense${i}`">
                      <td>{{ i + 1 }}</td>
                      <td>{{ pjpExpense.user.full_name || "" }}</td>
                      <td>
                        <v-tooltip bottom v-if="pjpExpense.is_approved == 1">
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip v-bind="attrs" v-on="on" small color="teal" text-color="white">
                              <v-avatar left>
                                <v-icon size="15">mdi-checkbox-marked-circle</v-icon>
                              </v-avatar>
                              Approved
                            </v-chip>
                          </template>
                          <span>Approved Expense</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="pjpExpense.is_approved == null">
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip v-bind="attrs" v-on="on" small color="orange" text-color="white">
                              <v-avatar left>
                                <v-icon size="15">mdi-checkbox-marked-circle</v-icon>
                              </v-avatar>
                              Pending
                            </v-chip>
                          </template>
                          <span>Pending Expense</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="pjpExpense.is_approved == 0">
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip v-bind="attrs" v-on="on" small color="red" text-color="white">
                              <v-avatar left>
                                <v-icon size="15">mdi-close-circle</v-icon>
                              </v-avatar>
                              Rejected
                            </v-chip>
                          </template>
                          <span>Rejected Expense</span>
                        </v-tooltip>
                      </td>
                      <td>{{ pjpExpense.expense_type != null ? pjpExpense.expense_type.code : "" }}</td>
                      <td>{{ pjpExpense.amount || "" }}</td>
                      <td class="text-center">
                        <v-autocomplete :disabled="pjpExpense.is_approved != null" solo ref="status"
                          class="autocomplete-expense mt-3" v-model="pjpExpense.is_approved" :items="typeItems"
                          :loading="isUpdating" @input="handleStatusChange(pjpExpense)"
                          prepend-inner-icon="mdi-toggle-switch"></v-autocomplete>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="9" class="text-center">No data found</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <v-pagination v-model="page" :length="paginationLength">
              </v-pagination>
            </v-card>
          </v-col>
        </v-row>
        <!-- Decline Reason Dialog -->
        <v-dialog v-model="isDeclineDialogVisible" max-width="600px" persistent>
          <v-card>
            <v-card-title>
              <span class="headline">Decline Reason</span>
            </v-card-title>
            <v-card-text>
              <v-textarea outlined v-model="declineReason" label="Reason for Decline" rows="3"></v-textarea>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="submitDeclineReason">Submit</v-btn>
              <v-btn color="blue darken-1" text @click="cancelDecline">Cancel</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-container>
    </v-main>
  </template>
  
  <script>
  import axios from "axios";
  export default {
    name: "pjpExpensesPage",
    data() {
      return {
        isApproved: "",
        expense_type_id: "",
        user_id: "",
        page: 1,
        serialNoStarting: 1,
        paginationLength: 0,
        count: 0,
        search_keyword: "",
        employees: [
          {
            id: 0,
            text: "Select Employee",
            value: "",
          },
        ],
        typeItems: [
          { id: "PENDING", text: "PENDING", value: null },
          { id: "REJECTED", text: "REJECTED", value: 0 },
          { id: "APPROVED", text: "APPROVED", value: 1 },
        ],
        statuses: [
          {
            id: 0,
            text: "Select Approve Status",
            value: "",
          },
          { id: "APPROVED", text: "APPROVED", value: "1" },
          { id: "NOT APPROVED", text: "NOT APPROVED", value: "0" },
          { id: "PENDING", text: "PENDING", value: "null" },
        ],
        expenseTypes: [
          {
            id: 0,
            text: "Select Expense Type",
            value: "",
          },
        ],
        date_menu: false,
        isLoading: false,
        isUpdating: false,
        isExcelloading: false,
        isDeclineDialogVisible: false,
        declineReason: '',
        selectedPjpExpense: null,
        ExportPjps: [],
        Export_pjpExpenses: [],
        pjpExpenses: [],
      };
    },
    watch: {
      page: "getData",
    },
    mounted() {
      this.getData();
      this.getMasters();
    },
    methods: {
      async getMasters() {
        let masters = await axios.get(`pjp_expenses/masters`);
        masters = masters.data;
        // Employees
        masters.employees.forEach((employee) => {
          this.employees.push({
            id: employee.id,
            text: employee.full_name,
            value: employee.id,
          });
        });
        // Expense Type
        masters.expense_types.forEach((expenseType) => {
          this.expenseTypes.push({
            id: expenseType.id,
            text: expenseType.code,
            value: expenseType.id,
          });
        });
      },
      async getData() {
        this.isLoading = true;
        this.search_keyword = this.search_keyword ? this.search_keyword : "";
        this.user_id = this.user_id ? this.user_id : "";
        this.expense_type_id = this.expense_type_id ? this.expense_type_id : "";
        this.isApproved = this.isApproved ? this.isApproved : "";
        let pjpExpenses = [];
        let query =
          "page=" +
          this.page +
          "&rowsPerPage=" +
          this.rowsPerPage +
          "&search_keyword=" +
          this.search_keyword +
          "&is_approved=" +
          this.isApproved +
          "&expenseTypeId=" +
          this.expense_type_id +
          "&is_direct_expense=1" +
          "&userId=" +
          this.user_id;
        pjpExpenses = await axios.get(`pjp_expenses?${query}`);
        this.pjpExpenses = pjpExpenses.data.data;
        this.count = pjpExpenses.data.count;
        this.serialNoStarting = (this.page - 1) * this.rowsPerPage;
        this.paginationLength = Math.ceil(this.count / this.rowsPerPage);
        this.isLoading = false;
      },
  
      async UpdatePjpexpense(pjpExpense) {
        if (pjpExpense.is_approved == 0) {
          this.selectedPjpExpense = pjpExpense;
          this.isDeclineDialogVisible = true;
        } else {
          await this.submitUpdatePjpexpense(pjpExpense);
        }
      },
      async submitUpdatePjpexpense(pjpExpense) {
        let old_json = pjpExpense;
        this.$set(pjpExpense, "isUpdating", true);
        try {
          await axios
            .patch(`/pjp_expenses/${this.encryptIt(pjpExpense.id)}`, pjpExpense)
            .then(async () => {
              await this.saveUserTimestamp(old_json, pjpExpense);
              this.getData();
            })
            .catch((error) => {
              console.error("Error fetching data:", error);
            });
        } catch (e) {
          console.log(e);
        } finally {
          this.$set(pjpExpense, "isUpdating", false);
        }
      },
      async Delete_this(id) {
        let check = confirm("Are you sure you want to delete the data");
        if (check) {
          try {
            this.isLoading = true;
            await axios.delete(`pjp_expenses/${id}`);
            this.isLoading = false;
            this.getData();
          } catch (e) {
            this.isLoading = false;
          }
        }
      },
      submitDeclineReason() {
        if (this.selectedPjpExpense) {
          this.selectedPjpExpense.decline_reason = this.declineReason;
          this.submitUpdatePjpexpense(this.selectedPjpExpense);
          this.isDeclineDialogVisible = false;
          this.declineReason = '';
          this.selectedPjpExpense = null;
        }
      },
      cancelDecline() {
        this.isDeclineDialogVisible = false;
        this.declineReason = '';
        this.selectedPjpExpense = null;
        this.getData();
      },
      handleStatusChange(pjpExpense) {
        if (pjpExpense.is_approved == 0) {
          this.selectedPjpExpense = pjpExpense;
          this.isDeclineDialogVisible = true;
        } else {
          this.UpdatePjpexpense(pjpExpense);
        }
      }
    },
  };
  </script>
  