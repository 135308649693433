<template>
  <v-main>
    <v-container fluid>
      <v-row>
        <v-col md="12">
          <v-card elevation="6" rounded="xxl">
            <v-card-title>
              <h3 class="font-weight-bold" v-text="'Pjp References'"></h3>
              <v-btn class="mx-2 mb-2" fab x-small color="primary" to="/pjp-references/create">
                <v-icon dark> mdi-plus </v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col md="6">
                  <v-text-field solo rounded v-model="filter.search_keyword" label="Search ... " color="#e60040"
                    prepend-inner-icon="mdi-magnify" clearable @click:clear="
                (filter.search_keyword = null), (page = 1), getData()
                " @keydown.enter="(page = 1), getData()">
                  </v-text-field>
                </v-col>
                <v-col sm="3">
                  <v-autocomplete ref="User" solo rounded v-model="filter.user_id" :items="userItems" clearable
                    @click:clear="(filter.user_id = null), (page = 1)" label="User" @input="getData"
                    prepend-inner-icon="mdi-account"></v-autocomplete>
                </v-col>
                <v-col sm="3">
                  <v-autocomplete ref="status" solo rounded v-model="filter.is_active" :items="statuses" clearable
                    @click:clear="(filter.is_active = null), (page = 1)" label="Status" @input="getData"
                    prepend-inner-icon="mdi-toggle-switch"></v-autocomplete>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="12">
          <v-card elevation="6" rounded="xxl">
            <v-card-text>
              <section v-if="isLoading" style="height: 560px">
                <v-progress-linear indeterminate color="primary"></v-progress-linear>
              </section>
              <v-simple-table v-else fixed-header height="560" class="striped-table">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left sr_no">Sr No</th>
                      <th class="text-left">Status</th>
                      <th class="text-left" style="min-width: none">Name</th>
                      <th class="text-left" style="min-width: none">
                        Location
                      </th>
                      <th class="text-left" style="min-width: none">User</th>
                      <th class="text-left" style="min-width: none">Day</th>
                      <th class="text-left" style="min-width: none">Week</th>
                      <th class="text-right action_th">Action</th>
                    </tr>
                  </thead>
                  <tbody v-if="pjp_references.length != 0">
                    <tr v-for="(pjp_reference, i) in pjp_references" :key="`pjp_reference_${i}`" :class="{
                'table-row-even': i % 2 === 0,
                'table-row-odd': i % 2 !== 0,
                'table-row-last': pjp_references.length - 1 == i,
              }">
                      <td style="min-width: 85px">
                        {{ i + 1 }}
                      </td>
                      <td>
                        <v-tooltip bottom v-if="pjp_reference.is_active == 1">
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip v-bind="attrs" v-on="on" small color="teal" text-color="white">
                              <v-avatar left>
                                <v-icon size="15">mdi-checkbox-marked-circle</v-icon>
                              </v-avatar>
                              Active
                            </v-chip>
                          </template>
                          <span>Pjp Reference is Active</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="pjp_reference.is_active == 0">
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip v-bind="attrs" v-on="on" small color="red" text-color="white">
                              <v-avatar left>
                                <v-icon size="15">mdi-close-circle</v-icon>
                              </v-avatar>
                              Inactive
                            </v-chip>
                          </template>
                          <span>Pjp Reference is InActive</span>
                        </v-tooltip>
                      </td>
                      <td>{{ pjp_reference.name || "" }}</td>
                      <td>{{ pjp_reference.location || "" }}</td>
                      <td>{{ pjp_reference.user.user_name || "" }}</td>
                      <td>{{ pjp_reference.day || "" }}</td>
                      <td>{{ pjp_reference.week || "" }}</td>
                      <td class="text-right">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" fab x-small class="my-auto" color="error"
                              @click="Delete_this(pjp_reference.id)">
                              <v-icon dark> mdi-delete </v-icon>
                            </v-btn>
                          </template>
                          <span>Delete this Pjp Reference</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" class="ml-2" fab x-small color="primary" :to="`/pjp-references/${encryptIt(
                pjp_reference.id
              )}`">
                              <v-icon dark> mdi-pencil </v-icon>
                            </v-btn>
                          </template>
                          <span>Edit this Pjp Reference</span>
                        </v-tooltip>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="8" class="text-center">No data found</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <v-pagination rounded v-model="page" :length="paginationLength">
              </v-pagination>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- Clear Success Dialog Box -->
      <v-dialog v-model="is_deleted_pjp_reference" width="600">
        <v-card rounded="xxl">
          <v-container>
            <v-row align="center">
              <v-col sm="3" align="center">
                <v-icon large style="color: green; font-size: 90px; width: 200px !important">
                  mdi-delete-empty
                </v-icon>
              </v-col>
              <v-col sm="9" align="center">
                <v-card-text>
                  <v-row>
                    <v-col>
                      <h1 style="color: green">Hurray!</h1>
                      <br />
                      <h3>Pjp Reference deleted successfully.</h3>
                      <br />
                      <v-btn color="success" @click="is_deleted_pjp_reference = false">
                        OK
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
export default {
  name: "PjpReferencesPage",
  data() {
    return {
      filter: {
        search_keyword: "",
        is_active: "",
        user_id: "",
      },
      pjp_references: [],
      userItems: [],
      statuses: [
        {
          id: 0,
          text: "Select Active Status",
          value: "",
        },
        { id: "ACTIVE", text: "ACTIVE", value: "1" },
        { id: "INACTIVE", text: "INACTIVE", value: "0" },
      ],
      page: 1,
      serialNoStarting: 1,
      paginationLength: 0,
      count: 0,
      isLoading: false,
      delete_pjp_reference: false,
      is_deleted_pjp_reference: false,
    };
  },
  watch: {
    page: "getData",
  },
  mounted() {
    this.getMasters();
    this.getData();
  },
  methods: {
    async getMasters() {
      await axios
        .get(`pjp_references/masters`)
        .then((masters) => {
          masters = masters.data;
          //Users
          masters.users.forEach((user) => {
            this.userItems.push({
              id: user.id,
              text: user.full_name,
              value: user.id,
            });
          });
          //Outlets
          masters.outlets.forEach((outlet) => {
            this.outletItems.push({
              id: outlet.id,
              text: outlet.name,
              value: outlet.id,
            });
          });
        })
        .catch(function (error) {
          console.log(error);
          // redirect to Home Page
          window.location.href = "/auth/login";
        });
    },
    async getData() {
      this.isLoading = true;
      this.filter.search_keyword = this.filter.search_keyword
        ? this.filter.search_keyword
        : "";
      this.filter.is_active = this.filter.is_active ? this.filter.is_active : "";
      this.filter.user_id = this.filter.user_id ? this.filter.user_id : "";
      let query =
        "page=" +
        this.page +
        "&rowsPerPage=" +
        this.rowsPerPage +
        "&is_active=" +
        this.filter.is_active +
        "&user_id=" +
        this.filter.user_id +
        "&search_keyword=" +
        this.filter.search_keyword;
      await axios
        .get(`pjp_references?${query}`)
        .then((response) => {
          this.pjp_references = response.data.data;
          this.count = response.data.count;
          this.serialNoStarting = (this.page - 1) * this.rowsPerPage;
          this.paginationLength = Math.ceil(this.count / this.rowsPerPage);
        })
        .catch(function (error) {
          console.log(error);
          window.location.href = "/auth/login";
        });
      this.isLoading = false;
    },
    async Delete_this(id) {
      this.delete_pjp_reference = true;
      await axios
        .post(`/pjp_references/delete/${this.encryptIt(id)}`)
        .then(() => {
          this.delete_pjp_reference = false;
          this.is_deleted_pjp_reference = true;
          this.getData();
        })
        .catch(function (error) {
          console.log(error);
          window.location.href = "/auth/login";
        });
    },
  },
};
</script>
