<template>
  <div>
    <v-navigation-drawer v-model="drawer" app>
      <div class="d-flex align-center justify-center">
        <router-link to="/">
          <v-img alt="Vuetify Name" class="mt-1" contain min-width="50" :src="company.logo_path
      ? `${mediaUrl + company.logo_path}`
      : require('../../assets/sapp-only-logo.png')
      " width="70" height="50" /></router-link>
      </div>
      <v-divider></v-divider>

      <v-list nav dense>
        <!-- aDMIN -->
        <v-list-group no-action :value="false">
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon size="25">mdi-shield-crown</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="text-subtitle-2">
              ({{
      roleName == "SUPER ADMIN" && company.id != null
        ? "ADMIN"
        : roleName
    }})</v-list-item-title>
          </template>

          <v-list-item v-if="roleName == 'ADMIN' ||
      (roleName == 'SUPER ADMIN' && company.id != null)
      " :to="`/companies/${encryptIt(this.company.id)}`">
            <v-list-item-icon>
              <v-icon size="25">mdi-domain</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Company</v-list-item-title>
          </v-list-item>
          <v-list-item to="/notifications" @click="hide_bell = true">
            <v-list-item-icon>
              <v-icon size="25">mdi-bell</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Notifications</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <!-- Companies -->
        <v-list-item v-if="roleName == 'SUPER ADMIN' && user.id == 1" :to="`/companies`" class="v-list-item">
          <v-list-item-icon>
            <v-icon size="25"> mdi-factory </v-icon>
          </v-list-item-icon>
          <v-list-item-title class="text-subtitle-1">Companies</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="roleName == 'SUPER ADMIN' && user.id == 1" :to="`/renew-requests`" class="v-list-item">
          <v-list-item-icon>
            <v-icon size="25"> mdi-autorenew </v-icon>
          </v-list-item-icon>
          <v-list-item-title class="text-subtitle-1">
            <v-badge v-if="newRenewCount != 0" style="position: absolute; right: 57px; top: 0px" color="green"
              :content="newRenewCount">
            </v-badge>
            Renew Requests</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="roleName == 'SUPER ADMIN' && company.id == null" :to="`/admins`" class="v-list-item">
          <v-list-item-icon>
            <v-icon size="25"> mdi-factory </v-icon>
          </v-list-item-icon>
          <v-list-item-title class="text-subtitle-1">Admins</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="roleName == 'SUPER ADMIN' && user.id != 1 && company.id == null" @click="showPricing"
          class="v-list-item">
          <v-list-item-icon>
            <v-icon size="25"> mdi-autorenew </v-icon>
          </v-list-item-icon>
          <v-list-item-title class="text-subtitle-1">Renew</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="roleName == 'SUPER ADMIN' && user.id == 1" :to="`/sample-files`" class="v-list-item">
          <v-list-item-icon>
            <v-icon size="25"> mdi-file-outline </v-icon>
          </v-list-item-icon>
          <v-list-item-title class="text-subtitle-1">Sample Files</v-list-item-title>
        </v-list-item>
        <!-- Attendance -->
        <v-list-group no-action :value="false" v-if="roleName == 'ADMIN' ||
      (roleName == 'SUPER ADMIN' && company.id != null)
      ">
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon size="25">mdi-store-marker-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="text-subtitle-1">Attendance</v-list-item-title>
          </template>

          <v-list-item :to="`/user-attendances`">
            <v-list-item-icon>
              <v-icon>mdi-timer-marker </v-icon>
            </v-list-item-icon>
            <v-list-item-title>Attendances</v-list-item-title>
          </v-list-item>
          <v-list-item to="/attendance-settings">
            <v-list-item-icon>
              <v-icon>mdi-timer-marker </v-icon>
            </v-list-item-icon>
            <v-list-item-title>Attendance Setting</v-list-item-title>
          </v-list-item>
          <v-list-item :to="`/work-from-home-requests`">
            <v-list-item-icon>
              <v-icon>mdi-home-city-outline </v-icon>
            </v-list-item-icon>
            <v-list-item-title>WFH Requests</v-list-item-title>
          </v-list-item>
          <v-list-item :to="`/user-regularizations`">
            <v-list-item-icon>
              <v-icon>mdi-auto-mode </v-icon>
            </v-list-item-icon>
            <v-list-item-title>Regularizations</v-list-item-title>
          </v-list-item>
          <v-list-item :to="`/user-leaves`">
            <v-list-item-icon>
              <v-icon>mdi-exit-run</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Leaves</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <!-- users -->
        <v-list-group no-action :value="false" v-if="roleName == 'ADMIN' ||
      (roleName == 'SUPER ADMIN' && company.id != null)
      ">
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon size="25">mdi-account-group</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="text-subtitle-1">Users</v-list-item-title>
          </template>
          <v-list-item v-if="allowedUrls.includes(`/users`) ||
      roleName == 'SUPER ADMIN' ||
      NotallowedUrls.length == 0
      " :to="`/users`">
            <v-list-item-icon>
              <v-icon>mdi-account-multiple-outline </v-icon>
            </v-list-item-icon>
            <v-list-item-title>Employee masters</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="allowedUrls.includes(`/users/create`) ||
      roleName == 'SUPER ADMIN' ||
      NotallowedUrls.length == 0
      " :to="`/users/create`">
            <v-list-item-icon>
              <v-icon>mdi-folder-plus</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Employee Create</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="allowedUrls.includes(`/timestamps`) ||
      roleName == 'SUPER ADMIN' ||
      NotallowedUrls.length == 0
      " :to="`/timestamps`">
            <v-list-item-icon>
              <v-icon>mdi-store-marker</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Employee Tracking</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="allowedUrls.includes(`/users`) ||
      roleName == 'SUPER ADMIN' ||
      NotallowedUrls.length == 0
      " :to="`/users?exit_user=true`">
            <v-list-item-icon>
              <v-icon>mdi-account-arrow-left</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Exit Employee</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="allowedUrls.includes(`/leave-balance`) ||
      roleName == 'SUPER ADMIN' ||
      NotallowedUrls.length == 0
      " :to="`/leave-balance`">
            <v-list-item-icon>
              <v-icon>mdi-scale-balance</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Leave Balance</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="allowedUrls.includes(`/shift-managements`) ||
      roleName == 'SUPER ADMIN' ||
      NotallowedUrls.length == 0
      " :to="`/shift-managements`">
            <v-list-item-icon>
              <v-icon>mdi-exit-run</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Shift Managements</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <!-- Pjps -->
        <v-list-group no-action :value="false"
          v-if="roleName == 'ADMIN' || (roleName == 'SUPER ADMIN' && company.id != null) || (roleName == 'ADMIN' && NotallowedUrls.length == 0)">
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon size="25">mdi-wallet-travel</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="text-subtitle-1">Pjps</v-list-item-title>
          </template>

          <v-list-item
            v-if="allowedUrls.includes(`/pjps`) || roleName == 'SUPER ADMIN' || (roleName == 'ADMIN' && NotallowedUrls.length == 0)"
            :to="`/pjps`">
            <v-list-item-icon>
              <v-icon>mdi-table-of-contents </v-icon>
            </v-list-item-icon>
            <v-list-item-title>List</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="allowedUrls.includes(`/pjp-references`) || roleName == 'SUPER ADMIN' || NotallowedUrls.length == 0"
            :to="`/pjp-references`">
            <v-list-item-icon>
              <v-icon>mdi-content-duplicate </v-icon>
            </v-list-item-icon>
            <v-list-item-title>References</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="allowedUrls.includes(`/outlets`) || roleName == 'SUPER ADMIN' || NotallowedUrls.length == 0"
            :to="`/outlets`">
            <v-list-item-icon>
              <v-icon>mdi-store-marker </v-icon>
            </v-list-item-icon>
            <v-list-item-title>Outlets</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <!-- SKUs -->
        <v-list-group no-action :value="false"
          v-if="roleName == 'ADMIN' || (roleName == 'SUPER ADMIN' && company.id != null) || (roleName == 'ADMIN' && NotallowedUrls.length == 0)">
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon size="25">mdi-wallet-travel</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="text-subtitle-1">Skus</v-list-item-title>
          </template>
          <v-list-item v-if="roleName == 'SUPER ADMIN' || (roleName == 'ADMIN' && NotallowedUrls.length == 0)"
            :to="`/skus`">
            <v-list-item-icon>
              <v-icon>mdi-table-of-contents </v-icon>
            </v-list-item-icon>
            <v-list-item-title>List</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <!-- Beats -->
        <v-list-group no-action :value="false"
          v-if="roleName == 'ADMIN' || (roleName == 'SUPER ADMIN' && company.id != null) || (roleName == 'ADMIN' && NotallowedUrls.length == 0)">
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon size="25">mdi-wallet-travel</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="text-subtitle-1">Beats</v-list-item-title>
          </template>

          <v-list-item
            v-if="allowedUrls.includes(`/beats`) || roleName == 'SUPER ADMIN' || (roleName == 'ADMIN' && NotallowedUrls.length == 0)"
            :to="`/beats`">
            <v-list-item-icon>
              <v-icon>mdi-table-of-contents </v-icon>
            </v-list-item-icon>
            <v-list-item-title>List</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="allowedUrls.includes(`/stores`) || roleName == 'SUPER ADMIN' || (roleName == 'ADMIN' && NotallowedUrls.length == 0)"
            :to="`/stores`">
            <v-list-item-icon>
              <v-icon>mdi-store-marker </v-icon>
            </v-list-item-icon>
            <v-list-item-title>Store</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="allowedUrls.includes(`/categories`) || roleName == 'SUPER ADMIN' || (roleName == 'ADMIN' && NotallowedUrls.length == 0)"
            :to="`/categories`">
            <v-list-item-icon>
              <v-icon>mdi-store-marker </v-icon>
            </v-list-item-icon>
            <v-list-item-title>Category</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="allowedUrls.includes(`/classifications`) || roleName == 'SUPER ADMIN' || (roleName == 'ADMIN' && NotallowedUrls.length == 0)"
            :to="`/classifications`">
            <v-list-item-icon>
              <v-icon>mdi-store-marker </v-icon>
            </v-list-item-icon>
            <v-list-item-title>classifications</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <!-- Reports -->
        <v-list-group no-action :value="false" v-if="allowedUrls.includes(`/reports`) ||
      (roleName == 'SUPER ADMIN' && company.id != null) ||
      (roleName == 'ADMIN' && NotallowedUrls.length == 0)
      ">
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon size="25">mdi-file-chart</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="text-subtitle-1">
              Reports
            </v-list-item-title>
          </template>
          <v-list-item :to="`/reports/${i}?report=${report.report_name}&table_name=${report.table_name}`"
            v-for="(report, i) in HrReports" :key="i">
            <v-list-item-icon>
              <v-icon>mdi-chart-timeline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ report.report_name }}</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <!-- Settings -->
        <v-list-group no-action :value="false" v-if="roleName == 'ADMIN' ||
      (roleName == 'SUPER ADMIN' && company.id != null) ||
      (roleName == 'ADMIN' && NotallowedUrls.length == 0)
      ">
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon size="25">mdi-cogs</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="text-subtitle-1">Settings</v-list-item-title>
          </template>
          <v-list-item v-if="allowedUrls.includes(`/tasks`) ||
      roleName == 'SUPER ADMIN' ||
      NotallowedUrls.length == 0
      " :to="`/tasks`">
            <v-list-item-icon>
              <v-icon size="25">mdi-arrow-expand-all</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Tasks</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="allowedUrls.includes(`/assets`) ||
      roleName == 'SUPER ADMIN' ||
      NotallowedUrls.length == 0
      " :to="`/assets`">
            <v-list-item-icon>
              <v-icon>mdi-chart-timeline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Assets</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="allowedUrls.includes(`/hr-reports`) ||
      roleName == 'SUPER ADMIN' ||
      NotallowedUrls.length == 0
      " :to="`/hr-reports`">
            <v-list-item-icon>
              <v-icon>mdi-laptop</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Hr Report Writer</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="allowedUrls.includes(`/tickets`) ||
      roleName == 'SUPER ADMIN' ||
      NotallowedUrls.length == 0
      " :to="`/tickets`">
            <v-list-item-icon>
              <v-icon>mdi-ticket</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Tickets</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="allowedUrls.includes(`/upload-salaries`) ||
      roleName == 'SUPER ADMIN' ||
      NotallowedUrls.length == 0
      " :to="`/upload-salaries`">
            <v-list-item-icon>
              <v-icon>mdi-cash-register</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Salary Slip Upload</v-list-item-title>
          </v-list-item>
          <v-list-item @click="SupportTeams">
            <v-list-item-icon>
              <v-icon>mdi-ticket</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Support Team</v-list-item-title>
          </v-list-item>
          <v-list-item @click="AuditTrialDialog = true">
            <v-list-item-icon>
              <v-icon>mdi-robot-industrial</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Audit Trial</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="allowedUrls.includes(`/values`) ||
      roleName == 'SUPER ADMIN' ||
      NotallowedUrls.length == 0
      " :to="`/values`">
            <v-list-item-icon>
              <v-icon size="25">mdi-chart-box-multiple</v-icon>
            </v-list-item-icon>
            <v-list-item-title> Value / Value-List</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="allowedUrls.includes(`/device-reset-requests`) ||
      roleName == 'SUPER ADMIN' ||
      NotallowedUrls.length == 0
      " :to="`/device-reset-requests`">
            <v-list-item-icon>
              <v-icon>mdi-cellphone-remove</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Device Reset</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="allowedUrls.includes(`/location-reset-requests`) ||
      roleName == 'SUPER ADMIN' ||
      NotallowedUrls.length == 0
      " :to="`/location-reset-requests`">
            <v-list-item-icon>
              <v-icon>mdi-map-marker-remove-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Location Reset</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="allowedUrls.includes(`/modules`) ||
      roleName == 'SUPER ADMIN' ||
      NotallowedUrls.length == 0
      " :to="`/modules`">
            <v-list-item-icon>
              <v-icon size="25">mdi-arrow-expand-all</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Modules</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="allowedUrls.includes(`/permissions`) ||
      roleName == 'SUPER ADMIN' ||
      NotallowedUrls.length == 0
      " :to="`/permissions`">
            <v-list-item-icon>
              <v-icon size="25">mdi-arrow-expand-all</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Permissions</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="allowedUrls.includes(`/positions`) ||
      roleName == 'SUPER ADMIN' ||
      NotallowedUrls.length == 0
      " :to="`/positions`">
            <v-list-item-icon>
              <v-icon size="25">mdi-arrow-expand-all</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Position</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="allowedUrls.includes(`/companies`) ||
      roleName == 'SUPER ADMIN' ||
      NotallowedUrls.length == 0
      " :to="`/companies/${encryptIt(this.company.id)}`">
            <v-list-item-icon>
              <v-icon size="25">mdi-arrow-expand-all</v-icon>
            </v-list-item-icon>
            <v-list-item-title>My Profile</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <!-- Trash -->
        <v-list-group no-action :value="false">
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon size="25">mdi-delete</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="text-subtitle-1">Trash</v-list-item-title>
          </template>
          <v-list-item :to="`/users?open_trash=true`">
            <v-list-item-icon>
              <v-icon size="25">mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Users</v-list-item-title>
          </v-list-item>
          <v-list-item :to="`/outlets?open_trash=true`">
            <v-list-item-icon>
              <v-icon size="25">mdi-store</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Outlets</v-list-item-title>
          </v-list-item>
          <v-list-item :to="`/pjps?open_trash=true`">
            <v-list-item-icon>
              <v-icon size="25">mdi-map-marker-path</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Pjps</v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item>
            <v-list-item-icon> </v-list-item-icon>
            <v-list-item-title>Settings</v-list-item-title>
          </v-list-item>
          <v-list-item :to="`/modules?open_trash=true`" link>
            <v-list-item-icon>
              <v-icon size="25">mdi-arrow-expand-all</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Modules</v-list-item-title>
          </v-list-item>
          <v-list-item :to="`/permissions?open_trash=true`" link>
            <v-list-item-icon>
              <v-icon size="25">mdi-arrow-expand-all</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Permissions</v-list-item-title>
          </v-list-item>
          <v-list-item :to="`/positions?open_trash=true`" link>
            <v-list-item-icon>
              <v-icon size="25">mdi-arrow-expand-all</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Position</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-list-item v-if="roleName == 'SUPER ADMIN' || roleName == 'ADMIN'" :to="`/direct-expenses`"
          class="v-list-item">
          <v-list-item-icon>
            <v-icon size="25"> mdi-cash </v-icon>
          </v-list-item-icon>
          <v-list-item-title class="text-subtitle-1">Direct Expense</v-list-item-title>
        </v-list-item>
        <!-- User Timestamps -->
        <v-list-item v-if="roleName == 'SUPER ADMIN'" :to="`/user-timestamps`" class="v-list-item">
          <v-list-item-icon>
            <v-icon size="25"> mdi-clock-time-eight </v-icon>
          </v-list-item-icon>
          <v-list-item-title class="text-subtitle-1">User Timestamps</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="roleName == 'SUPER ADMIN' || roleName == 'ADMIN'" :to="`/external-site`" class="v-list-item">
          <v-list-item-icon>
            <v-icon size="25"> mdi-sitemap </v-icon>
          </v-list-item-icon>
          <v-list-item-title class="text-subtitle-1">External Site</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="roleName == 'SUPER ADMIN' || roleName == 'ADMIN'" :to="`/map`" class="v-list-item">
          <v-list-item-icon>
            <v-icon size="25"> mdi-map </v-icon>
          </v-list-item-icon>
          <v-list-item-title class="text-subtitle-1">Map</v-list-item-title>
        </v-list-item>
      </v-list>

      <template v-slot:append>
        <div class="pa-2">
          <v-btn class="primary" block color="white" text @click="logout">
            Logout
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <v-app-bar app color="#7d33ff">
      <v-app-bar-nav-icon @click="drawer = !drawer" color="white"></v-app-bar-nav-icon>
      <span :class="{
      'text-subtitle-1': $vuetify.breakpoint.lgAndDown,
      'text-body': !$vuetify.breakpoint.lgAndDown,
    }" class="my-auto font-weight-bold white--text" style="min-width: max-content">
        Hey,
        <span class="ml-2 pb-3">
          {{ user.user_name != null ? user.user_name : user.email }}
          {{ company.name ? "[ " + company.name + " ]" : "" }}
        </span>
      </span>
      <v-container :class="{
      'ml-3': $vuetify.breakpoint.lgAndDown,
      'ml-15': !$vuetify.breakpoint.lgAndDown,
    }" class="mt-5" fluid v-if="roleName == 'SUPER ADMIN'">
        <v-switch color="green" @change="switch_to(company.id)" :loading="isSwitching" v-model="is_acting_admin"
          class="switch-label" :label="`${is_acting_admin ? 'ADMIN' : 'SUPERADMIN'}`"></v-switch>
      </v-container>
    </v-app-bar>

    <router-view />
    <!-- Clear Success Dialog Box -->
    <v-dialog v-model="show_company_dialog" width="990">
      <v-card rounded="xxl">
        <v-card-title>
          <h1>Switch to Admin</h1>
        </v-card-title>
        <v-card-subtitle class="my-1">
          Please select the company whose admin portal you'd like to switch to.
        </v-card-subtitle>
        <v-card-text>
          <v-row>
            <v-col md="6">
              <v-text-field solo rounded v-model="form.search_keyword" label="Search ... " clearable color="#e60040"
                @click:clear="(form.search_keyword = null), getCompanies()" prepend-inner-icon="mdi-magnify"
                @keydown.enter="getCompanies" @change="page = 1">
              </v-text-field>
            </v-col>
          </v-row>
          <v-progress-linear v-if="isLoading" indeterminate color="primary"></v-progress-linear>
          <v-row v-else>
            <v-col md="12">
              <v-simple-table fixed-header height="285" class="striped-table">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Sr No</th>
                      <th class="text-left">Status</th>
                      <th class="text-left" style="min-width: none">Name</th>
                      <th class="text-left">Email</th>
                      <th class="text-left">Admin Name</th>
                      <th class="text-right action_th">Action</th>
                    </tr>
                  </thead>
                  <tbody v-if="companies.length != 0">
                    <tr v-for="(company, i) in companies" :key="`company_${i}`" :class="{
      'table-row-even': i % 2 === 0,
      'table-row-odd': i % 2 !== 0,
      'table-row-last': companies.length - 1 == i,
    }">
                      <td style="min-width: 85px">
                        {{ i + 1 }}
                      </td>
                      <td>
                        <v-tooltip bottom v-if="company.is_active == 1">
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip v-bind="attrs" v-on="on" small color="teal" text-color="white">
                              <v-avatar left>
                                <v-icon size="15">mdi-checkbox-marked-circle</v-icon>
                              </v-avatar>
                              Active
                            </v-chip>
                          </template>
                          <span>Company is Active</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="company.is_active == 0">
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip v-bind="attrs" v-on="on" small color="red" text-color="white">
                              <v-avatar left>
                                <v-icon size="15">mdi-cross-circle</v-icon>
                              </v-avatar>
                              Inactive
                            </v-chip>
                          </template>
                          <span>Company is InActive</span>
                        </v-tooltip>
                      </td>
                      <td>
                        <v-avatar rounded>
                          <v-img alt="Vuetify Name" class="mt-1" contain min-width="35" :src="company.logo_path
      ? `${mediaUrl + company.logo_path}`
      : require('../../assets/sapp-only-logo.png')
      " width="35" height="35" />
                        </v-avatar>
                        &nbsp;
                        {{ company.name || "" }}
                      </td>
                      <td>{{ company.email || "" }}</td>
                      <td>{{ company.admin_name || "" }}</td>
                      <td class="text-right">
                        <v-btn v-if="company.companies != null" class="ml-2" fab x-small color="primary"
                          @click="changeCompany(company.companies[0].id)">
                          <v-icon dark> mdi-account-switch-outline </v-icon>
                        </v-btn>
                        <v-btn v-else class="ml-2" fab x-small color="primary" @click="changeCompany(company.id)">
                          <v-icon dark> mdi-account-switch-outline </v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="7" class="text-center">No data found</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- //support team dialog -->
    <v-dialog v-model="isSupportTeam" width="850">
      <v-card rounded="xxl">
        <v-card-title> Edit Support Team Details </v-card-title>
        <v-card-text>
          <v-row>
            <v-col md="12">
              <v-text-field v-model="support_form.phone" outlined placeholder="Enter Phone number"
                prepend-inner-icon="mdi-exit-run" :error-messages="errors.phone"></v-text-field>
            </v-col>
            <v-col md="12">
              <v-text-field v-model="support_form.email" outlined placeholder="Enter email"
                prepend-inner-icon="mdi-exit-run" :error-messages="errors.email"></v-text-field>
            </v-col>
            <v-col md="12">
              <v-textarea v-model="support_form.address" outlined placeholder="Enter address"
                prepend-inner-icon="mdi-exit-run" :error-messages="errors.address"></v-textarea>
            </v-col>
            <v-col>
              <v-btn class="float-end" @click="SaveSupport" :loading="isSupportLoading" color="primary">Save</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- //expire dialog -->
    <v-dialog width="450" v-model="isGoingToExpired">
      <v-card>
        <v-card-title>
          <v-col cols="4" class="pl-0">
            <v-icon size="100" color="red">mdi-alert</v-icon>
          </v-col>
          <v-col cols="8" class="text-h6">
            <v-col cols="12">
              Your Subscription Will <br />
              Expired on {{ company.expire_date }}
            </v-col>
            <v-col cols="12" class="text-subtitle-2">
              you will not able to logged in <br />
              after <span class="text-h6">{{ LeftDays }}</span> Days
            </v-col>
          </v-col>
          <v-col>
            <v-btn class="float-end ml-3" @click="isGoingToExpired = false" color="primary">
              ok
            </v-btn>
            <v-btn class="float-end" color="success" @click="showPricing">
              Renew
            </v-btn>
          </v-col>
        </v-card-title>
      </v-card>
    </v-dialog>
    <!-- //pricing dialog -->
    <v-dialog width="450" v-model="PricingDialog">
      <v-card>
        <v-card-title> Select Your Plans </v-card-title>
        <!-- <v-card-title> -->
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-start">Price</th>
                <th class="text-start">Months</th>
                <th class="text-start">Get</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(plan, index) in pricingPlans" :key="index">
                <td>
                  <v-chip style="
                      font-weight: 500;
                      color: white;
                      background-color: #e600406e;
                    ">
                    <span>{{ plan.discountedPrice }}(INR)</span> <br />
                    <span style="text-decoration: line-through" class="ml-2">{{
      plan.originalPrice
    }}</span>
                  </v-chip>
                </td>
                <td>{{ plan.months }}</td>
                <td>
                  <v-btn rounded small color="black" dark @click="addPlan(plan)">
                    Get Started
                    <v-icon color="white" size="15" class="ml-2">mdi-arrow-top-right-thin</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <!-- </v-card-title> -->
      </v-card>
    </v-dialog>
    <!-- //bank details dialog -->
    <v-dialog v-model="BankDetailsDialog" width="450" persistent>
      <v-card>
        <v-card-title>
          <span class="text-subtitle-2">Note : After Transfering the Amount Please Click on Success <br />
            and upload screen shot</span>
          <v-col cols="12" class="text-subtitle-1">
            <v-col cols="12" class="py-0 px-0 d-flex">
              <v-col cols="5" class="py-0 px-0">Bank Name </v-col><v-col cols="1" class="py-0 px-0">:</v-col><v-col
                cols="6" class="py-0 px-0">Federal Bank</v-col>
            </v-col>
            <v-col cols="12" class="py-0 px-0 d-flex">
              <v-col cols="5" class="py-0 px-0"> Account Number </v-col><v-col cols="1"
                class="py-0 px-0">:</v-col><v-col cols="6" class="py-0 px-0">9180-1004-8546-123</v-col>
            </v-col>
            <v-col cols="12" class="py-0 px-0 d-flex">
              <v-col cols="5" class="py-0 px-0"> IFS code </v-col><v-col cols="1" class="py-0 px-0">:</v-col><v-col
                cols="6" class="py-0 px-0">UTIB0012406</v-col>
            </v-col>
            <v-col cols="12" class="py-0 px-0 d-flex">
              <v-col cols="5" class="py-0 px-0"> Name </v-col><v-col cols="1" class="py-0 px-0">:</v-col><v-col cols="6"
                class="py-0 px-0">Aaibuzz pvt lmt!</v-col>
            </v-col>
            <v-col cols="12" class="py-0 px-0 d-flex">
              <v-col cols="5" class="py-0 px-0"> Branch </v-col><v-col cols="1" class="py-0 px-0">:</v-col><v-col
                cols="6" class="py-0 px-0">Airoli</v-col>
            </v-col>
            <v-col cols="12">
              <v-col sm="4">
                <input type="file" id="file" name="file" :ref="`file`" @change="onFileChange" />
                <v-btn x-small class="reset_image my-1" color="red" dark
                  @click="($refs.file.value = null), (image_url = null)">Remove</v-btn>

                <div id="preview" v-if="image_url" class="mt-5">
                  <label for="">Image Preview</label>
                  <br />
                  <img :src="image_url" height="300" width="200" />
                </div>
              </v-col>
            </v-col>
          </v-col>
          <v-col>
            <v-btn class="float-end ml-3" color="success" :loading="isRenewLoading" @click="renew"
              :disabled="!image_url">Success</v-btn>
            <v-btn class="float-end" color="primary" @click="BankDetailsDialog = false">
              Later
            </v-btn>
          </v-col>
        </v-card-title>
      </v-card>
    </v-dialog>
    <!-- //success dialog -->
    <v-dialog v-model="SuccessDialog" width="300">
      <v-card>
        <v-card-text class="d-flex flex-column">
          <v-img alt="Success" :src="require('../../assets/check-gif.gif')" class="check-gif" width="250" />
          <span align="center">
            Submitted Successfully your plan will be renew within 1 and 2
            working days
          </span>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- //Aduit Trial dialog -->
    <v-dialog v-model="AuditTrialDialog" width="1200">
      <v-card>
        <v-card-title class="text-h5"> Audit Trials </v-card-title>
        <AuditTrial :valueToPass="AduitTrialCalled" />
      </v-card>
    </v-dialog>
  </div>
</template>

<script type="text/javascript">
import { mapActions } from "vuex";
import axios from "axios";
import store from "@/store";
import AuditTrial from "../audit-trials/index.vue";

export default {
  data() {
    return {
      form: {
        search_keyword: "",
      },
      support_form: {},
      hide_bell: false,
      drawer: true,
      is_acting_admin: false,
      companyName: "",
      selectedCompanyId: "",
      LeftDays: "",
      company_data: {},
      companies: [],
      HrReports: [],
      SelectedPlan: [],
      CurrentUserPermissions: [],
      allowedUrls: [],
      NotallowedUrls: [],
      is_company_user: false,
      isLoading: false,
      isSwitching: false,
      show_company_dialog: false,
      isGoingToExpired: false,
      isSupportTeam: false,
      image_url: "",
      newRenewCount: 0,
      isSupportLoading: false,
      AuditTrialDialog: false,
      AduitTrialCalled: true,
      BankDetailsDialog: false,
      PricingDialog: false,
      SuccessDialog: false,
      isRenewLoading: false,
      pricingPlans: [
        { discountedPrice: "4000", originalPrice: "6000", months: "1 month" },
        { discountedPrice: "6000", originalPrice: "8000", months: "2 months" },
        { discountedPrice: "8000", originalPrice: "10000", months: "3 months" },
      ],
    };
  },
  components: {
    AuditTrial,
  },
  mounted() {
    this.getCompanies();
    let company = store.getters["auth/company"];
    if (Object.keys(company).length > 0) {
      this.selectedCompanyId = company.id;
      if (this.roleName == "SUPER ADMIN" && this.company.id != null) {
        this.is_acting_admin = true;
        console.log(this.is_acting_admin);
      }
      if (company.expire_date != null && this.user.id != "SUPER ADMIN") {
        const expireDate = new Date(company.expire_date);
        const currentDate = new Date();
        const oneMonthInMilliseconds = 30 * 24 * 60 * 60 * 1000;
        const oneDayInMilliseconds = 24 * 60 * 60 * 1000; // One day in milliseconds
        const difference = expireDate.getTime() - currentDate.getTime();
        this.LeftDays = Math.ceil(difference / oneDayInMilliseconds);
        this.isGoingToExpired = difference <= oneMonthInMilliseconds;
        console.log("isGoingToExpired", this.isGoingToExpired);
      }
      this.is_company_user = company ? true : false;
    }
    this.getRenewData();
    if (this.company.id != null) {
      this.getReports();
      if (this.roleName != "SUPER ADMIN") {
        this.getLoggedInUserPermissionUrl();
      }
    }
    console.log("acting admin", this.is_acting_admin);
  },
  methods: {
    ...mapActions({
      logOut: "auth/logOut",
    }),
    logout() {
      if (this.roleName == "SUPER ADMIN") {
        store.dispatch("auth/setCompany", "");
      }
      this.logOut();
      location.replace("/");
    },
    isActingAdmin() {
      let response = false;
      if (this.roleName == "SUPER ADMIN" && this.company.id != null) {
        response = true;
      }
      return response;
    },
    // Scroll back to the 1st validation error Message
    scrollToSection(selector) {
      // Example: smooth scroll to the specific section
      const element = document.querySelector(selector);
      const elementRect = element.getBoundingClientRect();
      const absoluteElementTop = elementRect.top + window.pageYOffset;
      const middle = absoluteElementTop - window.innerHeight / 2;
      window.scrollTo({
        top: middle,
        behavior: "smooth",
      });
      console.log(selector);
    },
    async SaveSupport() {
      try {
        this.isSupportLoading = true;
        await axios.post("/support_teams", this.support_form);
        this.isSupportLoading = false;
      } catch (error) {
        console.log(error);
        this.isSupportLoading = false;
      }
    },
    async SupportTeams() {
      try {
        this.support_form = [];
        this.isSupportLoading = true;
        this.isSupportTeam = true;
        let response = await axios.get(`/support_teams/1`);
        if (response.data.data != null) {
          this.support_form = response.data.data;
        }
        this.isSupportLoading = false;
      } catch (error) {
        console.log(error);
        this.isSupportLoading = false;
      }
    },
    async getCompanies() {
      this.isLoading = true;
      this.form.search_keyword = this.form.search_keyword
        ? this.form.search_keyword
        : "";
      this.form.is_active = this.form.is_active ? this.form.is_active : "";
      this.form.role_id = this.company.id != 1 ? 2 : "";
      let companies = [];
      let query =
        "search_keyword=" +
        this.form.search_keyword +
        "&role_id=" +
        this.form.role_id +
        "&is_active=" +
        this.form.is_active;
      // if (this.company.id != 1 || this.company.id == null) {
      //   companies = await axios.get(`users?${query}`);
      // } else {
      companies = await axios.get(`companies?${query}`);
      // }
      this.companies = companies.data.data;
      console.log("this.companies", this.companies);
      this.count = companies.data.count;
      this.isLoading = false;
    },
    changeCompany(companyId) {
      this.selectedCompanyId = companyId;
      // if (this.user.id != 1) {
      //   this.selectedCompanyId = this.companies.find((s) => s.email == this.user.email).id;
      // } else {
      // }
      let company = this.companies.find((s) => s.id == this.selectedCompanyId);
      if (company) {
        console.log(company);
        store.dispatch("auth/setCompany", company);
        this.is_company_user = true;
      } else {
        console.log("null company");
        store.dispatch("auth/setCompany", "");
        this.is_company_user = false;
        this.isSwitching = false;
      }
      console.log(company);
      window.location.href = "/auth/login";
      this.isSwitching = false;
      this.show_company_dialog = false;
      // this.$router.push("/auth/login");
    },
    switch_to(companyId = "") {
      this.isSwitching = true;
      console.log(this.is_acting_admin);
      if (this.is_acting_admin == true) {
        console.log("CompanyID " + companyId);
        console.log("Already SuperAdmin Switching to Admin");
        if (this.user.id == 1) {
          this.show_company_dialog = true;
        } else {
          let CompanyId = this.companies.find(
            (s) => s.email == this.user.email
          ).id;
          this.changeCompany(CompanyId);
        }
      } else {
        console.log("Already Acting Admin Switching to SuperAdmin");
        this.selectedCompanyId = null;
        this.changeCompany();
      }
    },
    async getReports() {
      await axios
        .get(`/hr_reports`)
        .then((response) => {
          this.HrReports = response.data.data;
        })
        .catch(function (error) {
          console.log(error);
          // window.location.href = "/auth/login";
        });
    },
    async getLoggedInUserPermissionUrl() {
      await axios
        .get(`/user_permissions?user_id=${this.encryptIt(this.user.id)}`)
        .then((response) => {
          this.CurrentUserPermissions = response.data.data;
          let allowedPermissions = this.CurrentUserPermissions.filter(
            (permission) => permission.is_allowed
          );
          let NotallowedPermissions = this.CurrentUserPermissions.filter(
            (permission) => permission.is_allowed == false
          );
          this.allowedUrls = allowedPermissions.map(
            (permission) => permission.url
          );
          this.NotallowedUrls = NotallowedPermissions.map(
            (permission) => permission.url
          );
          console.log("NotallowedUrls", this.NotallowedUrls);
          console.log("path", this.$route.path);
          if (this.NotallowedUrls.includes(this.$route.path)) {
            window.location.href = "/home";
          }
          // console.log('allowedUrls', allowedUrls);
        })
        .catch(function (error) {
          console.log(error);
          // window.location.href = "/auth/login";
        });
    },
    //renew
    async renew() {
      this.isRenewLoading = true;
      let response = await axios.post("renews", this.SelectedPlan);
      this.RenewId = response.data.data.id;
      await this.handleFileUpload();
      this.BankDetailsDialog = false;
      this.isRenewLoading = false;
      this.SuccessDialog = true;
    },
    addPlan(plan) {
      console.log(plan);
      this.SelectedPlan = plan;
      this.PricingDialog = false;
      this.BankDetailsDialog = true;
    },
    onFileChange() {
      this.warningImageLoader = false;
      this.file = this.$refs.file?.files[0];
      let reader = new FileReader();
      reader.readAsDataURL(this.file);
      reader.onload = (evt) => {
        let img = new Image();
        img.onload = () => {
          this.imageLoader = false;
          this.image_url = URL.createObjectURL(this.file);
          this.imageErrors = "";
        };
        img.src = evt.target.result;
      };

      reader.onerror = (evt) => {
        console.error(evt);
      };
      // this.image_url = URL.createObjectURL(this.file);
    },
    async handleFileUpload() {
      if (this.$refs.file != null) {
        let attachment = this.$refs.file.files[0];
        let formData = new FormData();
        formData.append("id", this.RenewId);
        formData.append("payment_screenshot", attachment);
        await axios
          .post("upload_renew_payment_screenshot", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .catch(function (error) {
            console.log(error);
            console.log("FAILURE!!");
          });
      }
    },
    showPricing() {
      this.isGoingToExpired = false;
      this.PricingDialog = true;
    },
    async getRenewData() {
      let response = await axios.get(`renews?is_pending_renew=true`);
      this.newRenewCount = response.data.count;
    },
  },
};
</script>
<style scoped>
.nav-text {
  text-decoration: none;
  color: black;
  font-weight: bold;
  font-size: 20px;
}

.v-application--is-ltr .v-list--dense.v-list--nav .v-list-group--no-action>.v-list-group__items>.v-list-item {
  padding: 0 8px 0 20px;
}

.slide-from-right {
  position: absolute;
  top: 40px;
  white-space: nowrap;
  /* Ensures the text stays on one line */
  animation: slideFromRight 10s linear infinite;
}

@keyframes slideFromRight {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}
</style>
<style>
.switch-label .theme--light.v-label {
  color: white !important;
}

.v-btn {
  text-transform: none !important;
}
</style>
