<template>
  <v-main>
    <v-container fluid>
      <v-card elevation="6" rounded="xxl">
        <v-card-title class="mt-4">
          <v-btn class="mx-2" fab x-small color="primary" @click="$router.back()">
            <v-icon dark> mdi-arrow-left </v-icon> </v-btn>Edit Asset
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="4">
              <v-text-field v-model="form.name" outlined label="Name"
                prepend-inner-icon="mdi-card-text-outline"></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field v-model="form.price" outlined label="Price"
                prepend-inner-icon="mdi-card-text-outline"></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-autocomplete outlined v-model="form.asset_type_id" :items="AssetTypes" label="Select Asset Type *"
                prepend-inner-icon="mdi-account-tie-hat-outline"
                :error-messages="errors.asset_type_id"></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-autocomplete outlined v-model="form.asset_id" :items="AssetIds" label="Select Asset Id *"
                prepend-inner-icon="mdi-account-tie-hat-outline" :error-messages="errors.asset_id"></v-autocomplete>
            </v-col>
            <v-col sm="12" class="py-0">
              <v-col sm="4" class="px-0 py-0">
                <label class="font-weight-bold">Assign To</label>
                <v-autocomplete outlined v-model="form.user_id" :items="Employees" label="Select Select User *"
                  prepend-inner-icon="mdi-account" :error-messages="errors.asset_id"></v-autocomplete>
              </v-col>
            </v-col>
            <v-col cols="12">
              <v-row>
                <v-col sm="2">
                  <label class="font-weight-bold" for="">Upload Image</label>
                  <input type="file" id="image_path" name="image_path" ref="image_path" />
                  <template v-if="form.image_path != null">
                    <v-img class="mt-2" v-if="form.image_path != null" :src="mediaUrl + form.image_path" alt="image 1"
                      style="height: 50%; width: 50% !important" />
                    <br />
                    <v-btn x-small class="mx-2" :href="`${mediaUrl}${form.image_path}`" target="_blank"
                      color="primary">View</v-btn>
                    <v-btn x-small class="reset_image my-1" color="red" dark
                      @click="form.image_path = null">Remove</v-btn>
                  </template>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-divider class="my-5"> </v-divider>
          <v-btn rounded color="primary" :loading="isLoading" :disabled="isLoading" @click="save">
            Edit Asset
          </v-btn>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";

export default {
  name: "ModuleEditPage",
  data() {
    return {
      form: {
        is_active: false,
      },
      Employees: [],
      AssetTypes: [],
      AssetIds: [],
      isLoading: false,
    };
  },
  mounted() {
    this.getMasters();
    this.getData();
  },
  methods: {
    // async getData() {
    //   this.isLoading = true;
    //   let form = await axios.get(`/modules/${this.$route.params.moduleId}`);
    //   this.form = form.data.data;
    //   this.old_json = form.data.data;
    //   this.isLoading = false;
    // },
    async getMasters() {
      await axios
        .get(`users/masters`)
        .then((masters) => {
          masters = masters.data;
          //users
          masters.usersResponse.forEach((assetType) => {
            this.Employees.push({
              id: assetType.id,
              text: assetType.full_name,
              value: assetType.id,
            });
          });
          //AssetsType
          masters.AssetTypes.forEach((assetType) => {
            this.AssetTypes.push({
              id: assetType.id,
              text: assetType.description,
              value: assetType.id,
            });
          });
          //AssetsId
          masters.AssetIds.forEach((assetId) => {
            this.AssetIds.push({
              id: assetId.id,
              text: assetId.description,
              value: assetId.id,
            });
          });
        })
        .catch(function (error) {
          console.log(error);
          // redirect to Home Page
          // window.location.href = "/auth/login";
        });
    },
    async getData() {
      this.isLoading = true;
      let encryptedId = this.$route.params.assetsId;
      axios
        .get(`/user_assets/${encryptedId}`)
        .then((response) => {
          this.form = response.data.data;
          this.old_json = response.data.data;
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
      this.isLoading = false;
    },
    async save() {
      try {
        this.isLoading = true;
        console.log(this.form);
        let encryptedId = this.$route.params.assetsId;
        await axios.patch(`/user_assets/${encryptedId}`, this.form);
        await this.handleFileUpload();
        await this.saveUserTimestamp(this.old_json, this.form);
        let PageModule = 'UPDATED';
        let PageCode = 'ASSET';
        const userIP = await this.getUserIp();
        this.saveAuditTrial(userIP, PageModule, PageCode)
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    },
    async handleFileUpload() {
      this.isLoading = true;
      let formData = new FormData();
      formData.append('AssetId', this.form.id);
      let image_path = this.$refs.image_path?.files[0];
      if (image_path) {
        formData.append("image_path", image_path);
      }
      await axios.post("user_asset_upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
    async saveUserTimestamp() {
      try {
        let form = {
          from_path: `/assets/${this.form.id}`,
          from_name: "AssetIdPage",
          user_id: this.user.id,
          old_json: JSON.stringify(this.old_json),
          new_json: JSON.stringify(this.form),
        };
        await axios.post(`/user_timestamps`, form);
        this.$router.back();
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
