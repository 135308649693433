<template>
  <div id="map" style="width: 100%; height: 90vh"></div>
</template>

<script>
import maplibregl from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";

export default {
  name: "MapComponent",
  data() {
    return {
      map: null,
      routeCompleted: [],
      routeRemaining: [],
      UpdatedendLatLng: null,
    };
  },
  props: {
    startLatLng: {
      type: Array,
      required: true,
    },
    endLatLng: {
      type: Array,
      required: true,
    },
  },
  mounted() {
    this.initializeMap();
    window.Echo.channel("timestamp-channel").listen(".timestamp-updated", (event) => {
      this.routeCompleted.push([event.user_timestamp.longitude, event.user_timestamp.latitude]);
      this.UpdatedendLatLng = [event.user_timestamp.longitude, event.user_timestamp.latitude];
      if (this.map.getLayer("routeCompleted")) {
        this.map.removeLayer("routeCompleted");
      }
      if (this.map.getSource("routeCompleted")) {
        this.map.removeSource("routeCompleted");
      }
      // this.map.setCenter(this.startLatLng);
      if (this.UpdatedendLatLng != null) {
        this.map.setCenter(this.UpdatedendLatLng);
      } else {
        this.map.setCenter(this.endLatLng);
      }
      this.map.addSource("routeCompleted", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              geometry: {
                type: "LineString",
                coordinates: this.routeCompleted,
              },
              properties: {},
            },
          ],
        },
      });
      this.map.addLayer({
        id: "routeCompleted",
        type: "line",
        source: "routeCompleted",
        layout: {
          "line-cap": "round",
          "line-join": "round",
        },
        paint: {
          "line-color": "#000000", // black completed route
          "line-width": 6,
        },
      });
      this.addAnimatedMarker();
    });
  },
  watch: {
    startLatLng: "updateMap",
    endLatLng: "updateMap",
  },
  methods: {
    addQueryParam(url, param) {
      if (url.includes("?")) {
        // URL already has a query string
        return `${url}&${param}`;
      } else if (url.includes("=") || url.includes("&")) {
        // URL has query syntax but no '?'
        return `${url}?${param}`;
      } else {
        // URL has no query parameters
        return `${url}?${param}`;
      }
    },
    initializeMap() {
      // Initialize the MapLibre GL map
      this.map = new maplibregl.Map({
        container: "map", // ID of the HTML element where the map will be rendered
        style:
          "https://api.olamaps.io/tiles/vector/v1/styles/default-light-standard/style.json", // URL to a MapLibre style JSON
        center: this.startLatLng, // Initial map center in [longitude, latitude]
        zoom: 15, // Initial zoom level
        transformRequest: (url, resourceType) => {
          url = this.addQueryParam(
            url,
            "api_key=2fHF7VzxqDHiYRtjpJr45JCiDl5rj2B9sVjYzAZ8"
          );
          return { url, resourceType };
        },
      });
      // Add navigation controls
      this.map.addControl(
        new maplibregl.NavigationControl({
          visualizePitch: true,
        }),
        "top-left"
      );

      //   // Add a marker (example)
      //   new maplibregl.Marker()
      //     .setLngLat([73.06498979298813, 19.030406712910327])
      //     .setPopup(new maplibregl.Popup().setText("Hello, MapLibre!"))
      //     .addTo(this.map);
      // this.map.on("load", () => {
      //   this.updateMap();
      // });
    },
    updateMap() {
      console.log('hre i am');
      if (!this.map) return;
      if (this.routeCompleted.length > 0) {
        if (this.map.getLayer("routeCompleted")) {
          this.map.removeLayer("routeCompleted");
        }
        if (this.map.getSource("routeCompleted")) {
          this.map.removeSource("routeCompleted");
        }
        // this.map.setCenter(this.startLatLng);
        if (this.UpdatedendLatLng != null) {
          this.map.setCenter(this.UpdatedendLatLng);
        } else {
          this.map.setCenter(this.endLatLng);
        }
        this.map.addSource("routeCompleted", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: [
              {
                type: "Feature",
                geometry: {
                  type: "LineString",
                  coordinates: this.routeCompleted,
                },
                properties: {},
              },
            ],
          },
        });
        this.map.addLayer({
          id: "routeCompleted",
          type: "line",
          source: "routeCompleted",
          layout: {
            "line-cap": "round",
            "line-join": "round",
          },
          paint: {
            "line-color": "#000000", // black completed route
            "line-width": 6,
          },
        });
      } else {
        console.log('in else')
        if (this.route) {
          console.log('in route')
          // Remove the previous route if it exists
          if (this.map.getLayer("route")) {
            this.map.removeLayer("route");
          }
          if (this.map.getSource("route")) {
            this.map.removeSource("route");
          }
        }

        // Set map center to startLatLng
        this.map.setCenter(this.startLatLng);
      }
      console.log('in else')
      if (this.route) {
        console.log('in route')
        this.map.addSource("route", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: [
              {
                type: "Feature",
                geometry: {
                  type: "LineString",
                  coordinates: this.route,
                },
                properties: {},
              },
            ],
          },
        });

        this.map.addLayer({
          id: "route",
          type: "line",
          source: "route",
          layout: {
            "line-cap": "round",
            "line-join": "round",
          },
          paint: {
            "line-color": "#ff0000",
            "line-width": 4,
          },
        });
      }
      this.addMarker(this.startLatLng, "Start", "", "startMarker");
      console.log('this.startLatLng', this.startLatLng);
      console.log('this.endLatLng', this.endLatLng);
      if (this.route) {
        this.addMarker(this.endLatLng, "End", "", "endMarker");
      }
      if (this.routeCompleted.length > 0) {
        this.addAnimatedMarker();
      }
    },
    addMarker(lngLat, label, imageUrl, markerType) {
      console.log('lngLat', lngLat);
      if (!lngLat || lngLat.length < 2) {
        console.error("Invalid coordinates for marker:", lngLat);
        return;
      }
      if (this[markerType]) {
        this[markerType].remove();
      }
      const popupContent = `
        <div style="display: flex; align-items: center;">
          <img src="${imageUrl}" alt="${label}" style="width: 50px; height: 50px; margin-right: 10px;">
          <span>${label}</span>
        </div>
      `;

      const marker = new maplibregl.Marker()
        .setLngLat(lngLat)
        .setPopup(new maplibregl.Popup({ offset: 25 }).setHTML(popupContent))
        .addTo(this.map);

      this[markerType] = marker;
    },
    decodePolyline(encoded) {
      let points = [];
      let index = 0, len = encoded.length;
      let lat = 0, lng = 0;

      while (index < len) {
        let b, shift = 0, result = 0;
        do {
          b = encoded.charCodeAt(index++) - 63;
          result |= (b & 0x1f) << shift;
          shift += 5;
        } while (b >= 0x20);
        let dlat = ((result & 1) ? ~(result >> 1) : (result >> 1));
        lat += dlat;

        shift = 0;
        result = 0;
        do {
          b = encoded.charCodeAt(index++) - 63;
          result |= (b & 0x1f) << shift;
          shift += 5;
        } while (b >= 0x20);
        let dlng = ((result & 1) ? ~(result >> 1) : (result >> 1));
        lng += dlng;

        points.push([lng / 1E5, lat / 1E5]);
      }
      return points;
    },
    setRoutes(completedRouteEncoded) {
      this.routeCompleted = completedRouteEncoded;
      this.updateMap();
    },
    setRoute2(encodedPolyline) {
      this.route = this.decodePolyline(encodedPolyline);
      this.updateMap();
    },
    addAnimatedMarker() {
      if (this.animatedMarker) {
        this.animatedMarker.remove();
      }
      // Path to your car icon image
      const carIconUrl = require('@/assets/car-icon.png');

      // Create and add the animated marker with the car icon
      this.animatedMarker = new maplibregl.Marker({
        color: 'blue', // You can also remove this if you use a custom image
        element: this.createCustomMarkerElement(carIconUrl)
      })
        .setLngLat(this.startLatLng)
        .addTo(this.map);

      this.progress = 0; // Reset progress
      this.animateMarker();
    },
    createCustomMarkerElement(iconUrl) {
      const element = document.createElement('div');
      element.className = 'custom-marker';
      element.style.backgroundImage = `url(${iconUrl})`;
      console.log(element);
      element.style.backgroundSize = 'contain';
      element.style.width = '80px'; // Adjust size as needed
      element.style.height = '80px'; // Adjust size as needed
      return element;
    },
    // animateMarker() {
    //   if (!this.animatedMarker || this.routeCompleted.length === 0) return;
    //   let start = null;
    //   let end = null;
    //   if (this.UpdatedendLatLng != null) {
    //     start = this.endLatLng;
    //     end = this.UpdatedendLatLng;
    //   } else {
    //     start = this.startLatLng;
    //     end = this.endLatLng;
    //   }
    //   const duration = 5000; // 5 seconds for animation
    //   const startTime = performance.now();

    //   const animate = (time) => {
    //     if (this.progress < 1 && this.UpdatedendLatLng != null) {
    //       const elapsed = time - startTime;
    //       const progress = Math.min(elapsed / duration, 1);

    //       const currentLat = start[1] + (end[1] - start[1]) * progress;
    //       const currentLng = start[0] + (end[0] - start[0]) * progress;

    //       this.animatedMarker.setLngLat([currentLng, currentLat]);
    //       this.animationFrameId = requestAnimationFrame(animate);
    //     } else {
    //       cancelAnimationFrame(this.animationFrameId);
    //     }
    //   };
    //   // if (this.map.on('load')) {
    //   animate();
    //   // }
    // }
    animateMarker() {
      if (!this.animatedMarker || this.routeCompleted.length === 0) return;

      const stepSize = 0.005; // Adjust for animation speed
      const animate = () => {
        if (this.progress < 1) {
          this.progress += stepSize;
          let totalSteps = null;
          if (this.UpdatedendLatLng != null) {
            totalSteps = this.routeCompleted.length - 1;
          } else {
            totalSteps = this.routeCompleted.length - 1;
          }

          const currentStep = this.progress * totalSteps;

          const index = Math.floor(currentStep);
          const t = currentStep - index; // Fractional part for interpolation

          const startPoint = this.routeCompleted[index];
          const endPoint = this.routeCompleted[index + 1] || startPoint;

          const lng = startPoint[0] + (endPoint[0] - startPoint[0]) * t;
          const lat = startPoint[1] + (endPoint[1] - startPoint[1]) * t;

          this.animatedMarker.setLngLat([lng, lat]);
          this.animationFrameId = requestAnimationFrame(animate);
        } else {
          cancelAnimationFrame(this.animationFrameId);
        }
      };

      // if (this.map.on('load')) {
      animate();
      // }
    }
  },
};
</script>

<style scoped>
#map {
  position: absolute;
  top: 120px;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.custom-marker {
  background-image: url('../assets/car-icon.png');
  background-size: contain;
  width: 32px;
  /* Adjust size as needed */
  height: 32px;
  /* Adjust size as needed */
}
</style>