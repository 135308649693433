<template>
  <v-main>
    <v-container fluid>
      <v-card>
        <v-card-title class="mt-4">
          <v-btn
            class="mr-2"
            fab
            x-small
            color="primary"
            @click="$router.back()"
          >
            <v-icon dark> mdi-arrow-left </v-icon>
          </v-btn>
          Edit {{ $route.query.type }}
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <vue-editor v-model="form.letter" />
            </v-col>

            <v-col v-if="$route.query.type == 'offer letter'" cols="4">
              <label for="">Start Date</label>
              <v-menu
                v-model="start_date_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="form.start_date"
                    label="Start Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    clearable
                    @click:clear="form.start_date = null"
                    :error-messages="errors.date"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="form.start_date"
                  @change="start_date_menu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col v-if="$route.query.type == 'offer letter'" cols="4">
              <label for="">End Date</label>
              <v-menu
                v-model="end_date_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="form.end_date"
                    label="End Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    clearable
                    @click:clear="form.end_date = null"
                    :error-messages="errors.date"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="form.end_date"
                  @change="end_date_menu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col v-if="$route.query.type == 'offer letter'" cols="4">
              <label for="">STC Issue Date</label>
              <v-menu
                v-model="stc_date_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="form.stc_issue_date"
                    label="Stc Issue Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    clearable
                    @click:clear="form.stc_issue_date = null"
                    :error-messages="errors.date"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="form.stc_issue_date"
                  @change="stc_date_menu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12">
              <label for="">Upload PDF</label> <br />
              <input type="file" id="file_1" name="file_1" ref="file_1" /> <br>
              <template v-if="form.letter_path != null">
                <v-btn
                  x-small
                  class="mx-3"
                  :href="`${mediaUrl}${form.letter_path}`"
                  target="_blank"
                  color="primary"
                  >View</v-btn
                >
                <v-btn
                  x-small
                  class="reset_image my-2"
                  color="red"
                  dark
                  @click="
                    ($refs.file_1.value = null), (form.letter_path = null)
                  "
                  >Remove</v-btn
                >
              </template>
            </v-col>
            <v-col>
              <v-btn :loading="isLoading" color="primary" class="float-right" @click="update()">
                update
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";
import { VueEditor } from "vue2-editor";

export default {
  name: "LetterCreatePage",
  components: {
    VueEditor,
  },
  data() {
    return {
      form: {},
      letter_path: "",
      start_date_menu: false,
      end_date_menu: false,
      stc_date_menu: false,
      isLoading: false
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      let response = await axios.get(`/letters/${this.$route.params.letterId}`);
      this.form = response.data.data;
      this.isLoading = false;
    },
    async update() {
      this.isLoading = true;
      try {
        let letter = await axios.patch(`/letters/${this.$route.params.letterId}`, this.form);
        this.form = letter.data.data;
        await this.handleFileUpload();
        this.isLoading = false;
        this.$router.back();
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      }
    },
    async handleFileUpload() {
      let letter_path = this.$refs.file_1.files[0];
      const id = this.form.id;
      let formData = new FormData();
      formData.append("id", id);
      formData.append("letter_path", letter_path);
      await axios
        .post("upload_letters", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .catch(function () {
          console.log("FAILURE!!");
        });
    },
  },
};
</script>
