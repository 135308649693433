<template>
  <v-main>
    <v-container fluid>
      <v-row>
        <v-col md="12">
          <v-card elevation="6" rounded="xxl" color="#f1f2f6">
            <v-card-title>
              <h3 class="font-weight-bold" v-text="'Leave Balance List'"></h3>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="3 mr-2 mt-2">
                  <v-card class="pa-2 customer-card-2 rounded-xl" outlined tile>
                    <v-row style="
                        justify-content: space-between;
                        align-items: center;
                        padding: 4px 16px;
                      ">
                      <v-card-title v-if="isLoading" class="text-h5 title">
                        <v-progress-circular indeterminate color="#0b4bff"></v-progress-circular>
                      </v-card-title>
                      <v-card-title v-else class="text-h5 title" v-text="totalAppliedLeave"></v-card-title>
                      <v-icon class="icon" rounded>mdi-account-multiple-outline</v-icon>
                    </v-row>
                    <v-card-subtitle class="subtitle" v-text="'Total Applied Leave'"></v-card-subtitle>
                  </v-card>
                </v-col>
                <v-col cols="3 mr-2 mt-2">
                  <v-card class="pa-2 customer-card-2 rounded-xl" outlined tile>
                    <v-row style="
                        justify-content: space-between;
                        align-items: center;
                        padding: 4px 16px;
                      ">
                      <v-card-title v-if="isLoading" class="text-h5 title">
                        <v-progress-circular indeterminate color="#0b4bff"></v-progress-circular>
                      </v-card-title>
                      <v-card-title v-else class="text-h5 title" v-text="totalApprovedLeave"></v-card-title>
                      <v-icon class="icon" rounded>mdi-account-check-outline</v-icon>
                    </v-row>
                    <v-card-subtitle class="subtitle" v-text="'Total Approved Leave'"></v-card-subtitle>
                  </v-card>
                </v-col>
                <v-col cols="3 mr-2 mt-2">
                  <v-card class="pa-2 customer-card-2 rounded-xl" outlined tile>
                    <v-row style="
                        justify-content: space-between;
                        align-items: center;
                        padding: 4px 16px;
                      ">
                      <v-card-title v-if="isLoading" class="text-h5 title">
                        <v-progress-circular indeterminate color="#0b4bff"></v-progress-circular>
                      </v-card-title>
                      <v-card-title v-else class="text-h5 title" v-text="totalPendingLeave"></v-card-title>
                      <v-icon class="icon" rounded>mdi-account-cancel-outline</v-icon>
                    </v-row>
                    <v-card-subtitle class="subtitle" v-text="'Total Pendings Leave'"></v-card-subtitle>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="12">
          <v-card elevation="6" rounded="xxl">
            <v-card-title>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-autocomplete style="width: 100px" ref="user" class="mr-2" v-model="filter.user_id" solo dense outlined
                :items="Employees" label="User" prepend-inner-icon="mdi-account-tie-hat-outline" clearable
                :error-messages="errors.user_id" @change="getData"></v-autocomplete>
              <v-text-field solo dense v-model="filter.search_keyword" label="Search ... " color="#e60040"
                prepend-inner-icon="mdi-magnify" clearable @click:clear="
                (filter.search_keyword = null), (page = 1), getData()
                " @keydown.enter="(page = 1), getData()">
              </v-text-field>
            </v-card-title>
            <v-card-text>
              <section v-if="isLoading" style="height: 560px">
                <v-progress-linear indeterminate color="primary"></v-progress-linear>
              </section>
              <v-simple-table v-else fixed-header height="560" class="striped-table">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-start text-subtitle-2 font-weight-bold sr_no">Sr No</th>
                      <th class="text-start text-subtitle-2 font-weight-bold">Employee Name</th>
                      <th class="text-start text-subtitle-2 font-weight-bold">Employee Code</th>
                      <th class="text-start text-subtitle-2 font-weight-bold">Total Applied Leave</th>
                      <th class="text-start text-subtitle-2 font-weight-bold">Total Approve Leave</th>
                      <th class="text-start text-subtitle-2 font-weight-bold">Total Pending Leave</th>
                      <th class="text-right text-subtitle-2 font-weight-bold action_th">Action</th>
                    </tr>
                  </thead>
                  <tbody v-if="leave_balance.length != 0">
                    <tr v-for="(balance, i) in leave_balance" :key="`balance_${i}`">
                      <td style="min-width: 85px">
                        {{ i + 1 }}
                      </td>
                      <td>
                        {{ balance.user.full_name || "" }}
                      </td>
                      <td>
                        {{ balance.user.employee_code || "" }}
                      </td>
                      <td>
                        {{ balance.applied_leave_count || "0" }}
                      </td>
                      <td>
                        {{ balance.total_approved_leave || "0" }}
                      </td>
                      <td>
                        {{ balance.total_pending_leave || "0" }}
                      </td>
                      <td class="text-right">
                        <template v-if="isDeletePage() != true">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn v-bind="attrs" v-on="on" class="ml-2" fab x-small color="primary"
                                @click="getData(balance.user_id)">
                                <v-icon dark> mdi-eye-outline </v-icon>
                              </v-btn>
                            </template>
                            <span>View balance</span>
                          </v-tooltip>
                        </template>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="7" class="text-center">No data found</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <v-pagination rounded v-model="page" :length="paginationLength">
              </v-pagination>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- Clear Success Dialog Box -->
      <v-dialog v-model="is_deleted_module" width="600">
        <v-card rounded="xxl">
          <v-container>
            <v-row align="center">
              <v-col sm="3" align="center">
                <v-icon large style="color: green; font-size: 90px; width: 200px !important">
                  mdi-delete-empty
                </v-icon>
              </v-col>
              <v-col sm="9" align="center">
                <v-card-text>
                  <v-row>
                    <v-col>
                      <h1 style="color: green">Hurray!</h1>
                      <br />
                      <h3>Module deleted successfully.</h3>
                      <br />
                      <v-btn color="success" @click="is_deleted_module = false">
                        OK
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
      <!-- Restore Success Dialog Box -->
      <v-dialog v-model="is_restored_module" width="600">
        <v-card rounded="xxl">
          <v-container>
            <v-row align="center">
              <v-col sm="3" align="center">
                <v-icon large style="color: green; font-size: 90px; width: 200px !important">
                  mdi-delete-restore
                </v-icon>
              </v-col>
              <v-col sm="9" align="center">
                <v-card-text>
                  <v-row>
                    <v-col>
                      <h1 style="color: green">Hurray!</h1>
                      <br />
                      <h3>Module restored successfully.</h3>
                      <br />
                      <v-btn color="success" @click="is_restored_module = false">
                        OK
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
export default {
  name: "leave_balancePage",
  data() {
    return {
      filter: {
        search_keyword: "",
        is_active: "",
        user_id: "",
      },
      leave_balance: [],
      statuses: [
        {
          id: 0,
          text: "Select Active Status",
          value: "",
        },
        { id: "ACTIVE", text: "ACTIVE", value: "1" },
        { id: "INACTIVE", text: "INACTIVE", value: "0" },
      ],
      ExportModuleTypes: [],
      Employees: [
        {
          id: 0,
          text: "Select Active Status",
          value: "",
        },
      ],
      page: 1,
      serialNoStarting: 1,
      paginationLength: 0,
      count: 0,
      excel_fields: {
        "Sr No": "sr_no",
        Name: "name",
      },
      isLoading: false,
      totalAppliedLeave: "",
      totalApprovedLeave: "",
      totalPendingLeave: "",
      isExcelloading: false,
      delete_module: false,
      is_deleted_module: false,
    };
  },
  watch: {
    page: "getData",
    $route() {
      this.getData();
    },
  },
  mounted() {
    this.getMasters();
    this.getData();
  },
  methods: {
    async getMasters() {
      await axios
        .get(`users/masters`)
        .then((masters) => {
          masters = masters.data;
          //Position
          masters.usersResponse.forEach((user) => {
            this.Employees.push({
              id: user.id,
              text: user.full_name,
              value: user.id,
            });
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    async getData(UserId) {
      this.isLoading = true;
      this.filter.search_keyword = this.filter.search_keyword
        ? this.filter.search_keyword
        : "";
      this.filter.show_deleted = this.$route.query.open_trash ? 1 : 0;
      this.filter.is_active = this.filter.is_active
        ? this.filter.is_active
        : "";
      if (UserId != null) {
        this.filter.user_id = UserId;
      } else {
        this.filter.user_id = this.filter.user_id ? this.filter.user_id : "";
      }
      let query =
        "page=" +
        this.page +
        "&rowsPerPage=" +
        this.rowsPerPage +
        "&user_id=" +
        this.filter.user_id +
        "&search_keyword=" +
        this.filter.search_keyword;
      await axios
        .get(`users/leave_balance?${query}`)
        .then((response) => {
          this.leave_balance = response.data.data;
          this.totalAppliedLeave = response.data.totalAppliedLeave;
          this.totalApprovedLeave = response.data.totalApprovedLeave;
          this.totalPendingLeave = response.data.totalPendingLeave;
          this.count = response.data.count;
          this.serialNoStarting = (this.page - 1) * this.rowsPerPage;
          this.paginationLength = Math.ceil(this.count / this.rowsPerPage);
        })
        .catch(function (error) {
          console.log(error);
          window.location.href = "/auth/login";
        });
      this.isLoading = false;
    },
    //Generate Excel
    async generateExcel() {
      let query = "search_keyword=" + this.filter.search_keyword;
      let response = await axios.get(`module_exports?${query}`);
      console.log(response.data.data);
      if (response) {
        window.open(`${response.data.data}`, "_blank");
      }
    },
    async Delete_this(id) {
      this.delete_module = true;
      await axios
        .post(`/leave_balance/delete/${this.encryptIt(id)}`)
        .then(() => {
          this.delete_module = false;
          this.is_deleted_module = true;
          this.getData();
        })
        .catch(function (error) {
          console.log(error);
          window.location.href = "/auth/login";
        });
    },
    async Restore_this(id) {
      this.restore_module = true;
      await axios
        .post(`/leave_balance/restore/${this.encryptIt(id)}`)
        .then(() => {
          this.restore_module = false;
          this.is_restored_module = true;
          this.getData();
        })
        .catch(function (error) {
          console.log(error);
          window.location.href = "/auth/login";
        });
    },
  },
};
</script>
<style>
.customer-card-2 .title {
  color: #252c58;
  font-size: 32px !important;
  font-weight: 500;
}

.customer-card-2 .subtitle {
  color: #4a4f74;
}

.customer-card-2 {
  border-radius: 11px !important;
}

.customer-card-2 .icon {
  background: #f2f4fa;
  border-radius: 50px;
  width: 50px;
  height: 49px;
  color: #0b4bff;
}
</style>
