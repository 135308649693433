<template>
  <v-main>
    <v-container fluid>
      <v-row>
        <v-col md="12">
          <v-card elevation="6" rounded="lg">
            <v-card-title>
              <h3 class="font-weight-bold" v-text="''"></h3>

            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col md="6">
                  <v-text-field solo rounded v-model="form.search_keyword" label="Search ... " clearable color="#e60040"
                    @click:clear="
                (form.search_keyword = null), (page = 1), getData()
                " prepend-inner-icon="mdi-magnify" @keydown.enter="getData" @change="page = 1">
                  </v-text-field>
                </v-col>
                <v-col sm="3">
                  <v-autocomplete ref="status" solo rounded v-model="form.is_active" :items="statuses" clearable
                    @click:clear="
                (form.is_active = null), (page = 1), getData()
                " label="Status" @input="getData" prepend-inner-icon="mdi-toggle-switch"></v-autocomplete>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col md="12">
          <v-card elevation="6" rounded="lg">
            <v-card-title v-if="companies.length != 0">
              <v-btn class="mx-2 mb-2 font-weight-bold" color="primary" to="/companies/create">
                Companies <v-icon dark class="ml-1"> mdi-plus-circle-outline </v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn class="primary mx-4" :loading="isExcelloading" :dark="!isExcelloading" :disabled="isExcelloading"
                @click="generateExcel()">
                <span class="font-weight-bold">Generate Excel</span>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <section v-if="isLoading" style="height: 560px">
                <v-progress-linear indeterminate color="primary"></v-progress-linear>
              </section>
              <v-simple-table v-else fixed-header height="560" class="striped-table">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-start font-weight-bold text-subtitle-2">Sr No</th>
                      <th class="text-start font-weight-bold text-subtitle-2">Status</th>
                      <th class="text-start font-weight-bold text-subtitle-2" style="min-width: none">Name</th>
                      <th class="text-start font-weight-bold text-subtitle-2">Email</th>
                      <th class="text-start font-weight-bold text-subtitle-2">Admin Name</th>
                      <th class="text-start font-weight-bold text-subtitle-2">Phone</th>
                      <th class="text-start font-weight-bold text-subtitle-2" style="min-width: 150px;">Address</th>
                      <th class="text-right font-weight-bold text-subtitle-2 action_th">Action</th>
                    </tr>
                  </thead>
                  <tbody v-if="companies.length != 0">
                    <tr v-for="(company, i) in companies" :key="`company_${i}`" :class="{
                'table-row-even': i % 2 === 0,
                'table-row-odd': i % 2 !== 0,
                'table-row-last': companies.length - 1 == i,
              }">
                      <td style="min-width: 85px">
                        {{ i + 1 }}
                      </td>
                      <td>
                        <v-tooltip bottom v-if="company.is_active == 1">
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip v-bind="attrs" v-on="on" small color="teal" text-color="white">
                              <v-avatar left>
                                <v-icon size="15">mdi-checkbox-marked-circle</v-icon>
                              </v-avatar>
                              Active
                            </v-chip>
                          </template>
                          <span>Company is Active</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="company.is_active == 0">
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip v-bind="attrs" v-on="on" small color="red" text-color="white">
                              <v-avatar left>
                                <v-icon size="15">mdi-close-circle</v-icon>
                              </v-avatar>
                              Inactive
                            </v-chip>
                          </template>
                          <span>Company is InActive</span>
                        </v-tooltip>
                      </td>
                      <td>
                        <v-avatar rounded>
                          <v-img alt="Vuetify Name" class="mt-1" contain min-width="35" :src="company.logo_path
                ? `${mediaUrl + company.logo_path}`
                : require('../../assets/sapp-only-logo.png')
                " width="35" height="35" />
                        </v-avatar>
                        &nbsp;
                        {{ company.name || "" }}
                      </td>
                      <td>{{ company.email || "" }}</td>
                      <td>{{ company.admin_name || "" }}</td>
                      <td>{{ company.phone || "" }}</td>
                      <td>
                        {{ company.address || "" }}
                      </td>

                      <td class="text-right">
                        <v-btn class="ml-2" fab x-small color="primary" :to="`/companies/${encryptIt(company.id)}`">
                          <v-icon dark> mdi-pencil </v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="7" class="text-center">No data found</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <v-pagination rounded v-model="page" :length="paginationLength">
              </v-pagination>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
export default {
  name: "companiesPage",
  data() {
    return {
      form: {
        is_active: "",
      },
      page: 1,
      serialNoStarting: 1,
      paginationLength: 0,
      count: 0,
      search_keyword: "",
      companies: [],
      statuses: [
        {
          id: 0,
          text: "Select Active Status",
          value: "",
        },
        { id: "ACTIVE", text: "ACTIVE", value: "1" },
        { id: "INACTIVE", text: "INACTIVE", value: "0" },
      ],
      isLoading: false,
      isExcelloading: false,
      ExportCompanies: [],
      Export_companies: [],
      delete_position: false,
      is_deleted_position: false,
    };
  },
  watch: {
    page: "getData",
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      this.form.search_keyword = this.form.search_keyword
        ? this.form.search_keyword
        : "";
      this.form.is_active = this.form.is_active ? this.form.is_active : "";
      let query =
        "page=" +
        this.page +
        "&rowsPerPage=" +
        this.rowsPerPage +
        "&search_keyword=" +
        this.form.search_keyword +
        "&is_active=" +
        this.form.is_active;
      await axios
        .get(`companies?${query}`)
        .then((response) => {
          this.companies = response.data.data;
          this.count = response.data.count;
          this.serialNoStarting = (this.page - 1) * this.rowsPerPage;
          this.paginationLength = Math.ceil(this.count / this.rowsPerPage);
        })
        .catch(function (error) {
          console.log(error);
          window.location.href = "/auth/login";
        });
      this.isLoading = false;
    },
    async generateExcel() {
      this.isExcelloading = true;
      this.form.search_keyword = this.form.search_keyword
        ? this.form.search_keyword
        : "";
      this.form.is_active = this.form.is_active ? this.form.is_active : "";
      let response = [];
      let query =
        "is_active=" +
        this.form.is_active +
        "&search_keyword=" +
        this.form.search_keyword;
      response = await axios
        .get(`/companies/excel_export?${query}`)
        .then((response) => window.open(`${response.data.data}`, "_blank"));
      if (response) {
        console.log(response);
      }
      this.isExcelloading = false;
    },
    async Delete_this(id) {
      this.delete_company = true;
      await axios
        .post(`/companies/delete/${this.encryptIt(id)}`)
        .then(() => {
          this.delete_company = false;
          this.is_deleted_company = true;
          this.getData();
        })
        .catch(function (error) {
          console.log(error);
          window.location.href = "/auth/login";
        });
    },
  },
};
</script>
<style>
.search_field .v-input__slot {
  min-height: 41px !important;
}

.search_field .v-input__prepend-inner {
  margin-top: 9px !important;
}
</style>