import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";

import router from "./router";
import store from "./store";

require("@/store/subscriber");

require("./mixin.js");
require("./axios.js");
require('./filters.js')

Vue.config.productionTip = false;

import JsonExcel from "vue-json-excel";
Vue.component("downloadExcel", JsonExcel);

import CryptoJS from "crypto-js";
window.CryptoJS = CryptoJS;
// Vue.component("CryptoJS", CryptoJS);

// import Echo from 'laravel-echo'

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//   broadcaster: 'pusher',
//   key: process.env.VUE_APP_WEBSOCKETS_KEY,
//   cluster: process.env.VUE_APP_WEBSOCKETS_CLUSTER,
//   wsHost: process.env.VUE_APP_WEBSOCKETS_SERVER,
//   wsPort: 6001,
//   forceTLS: false,
//   disableStats: true
// });
import Echo from 'laravel-echo'

window.Pusher = require('pusher-js');
const key = '4bfbabb3638428d76b43';
const cluster = 'ap2';
const host = '192.168.0.179';
window.Echo = new Echo({
  broadcaster: 'pusher',
  key: key,
  cluster: cluster,
  wsHost: host,
  wsPort: 6001,
  forceTLS: false,
  disableStats: true
});

store.dispatch("auth/attempt", localStorage.getItem("token")).then(() => {
  new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
  }).$mount("#app");
});
