<template>
  <v-main>
    <v-container fluid>
      <!-- Existing content -->
      <v-row>
        <v-col md="12">
          <v-card elevation="6">
            <v-card-text>
              <span class="text-h5 font-weight-bold" v-text="`Work From Home Requests `"></span>
              <!-- <v-btn class="mx-2 mb-2" fab x-small color="primary" :to="`/pjps/${$route.params.pjpId}/expenses/create`">
                <v-icon dark> mdi-plus </v-icon>
              </v-btn> -->
              <v-row class="mt-3">
                <v-col md="3">
                  <v-text-field solo rounded v-model="search_keyword" label="Search ..."
                    prepend-inner-icon="mdi-magnify" @keydown.enter="getData" @change="page = 1"></v-text-field>
                </v-col>
                <v-col sm="3">
                  <v-autocomplete solo rounded ref="approve" v-model="isApproved" :items="statuses"
                    label="Approve Status" @input="getData" append-icon="mdi-toggle-switch" clearable></v-autocomplete>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-progress-linear v-if="isLoading" indeterminate color="primary"></v-progress-linear>
      <v-row v-else>
        <v-col md="12">
          <v-card elevation="6">
            <v-simple-table fixed-header height="485">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-start font-weight-bold text-subtitle-2">Sr No</th>
                    <th class="text-start font-weight-bold text-subtitle-2">Employee Name</th>
                    <th class="text-start font-weight-bold text-subtitle-2">Status</th>
                    <th class="text-start font-weight-bold text-subtitle-2">Employee Code</th>
                    <th class="text-start font-weight-bold text-subtitle-2">Date</th>
                    <th class="text-start font-weight-bold text-subtitle-2" style="width: 200px;">Reason</th>
                    <th class="text-center font-weight-bold text-subtitle-2">Action</th>
                  </tr>
                </thead>
                <tbody v-if="wfh_requests.length != 0">
                  <tr v-for="(wfh, i) in wfh_requests" :key="`wfh${i}`">
                    <td>{{ i + 1 }}</td>
                    <td>{{ wfh.user.full_name || "" }}</td>
                    <td>
                      <v-tooltip bottom v-if="wfh.is_approved == 1">
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip v-bind="attrs" v-on="on" small color="teal" text-color="white">
                            <v-avatar left>
                              <v-icon size="15">mdi-checkbox-marked-circle</v-icon>
                            </v-avatar>
                            Approved
                          </v-chip>
                        </template>
                        <span>Approved Wfh</span>
                      </v-tooltip>
                      <v-tooltip bottom v-if="wfh.is_approved == null">
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip v-bind="attrs" v-on="on" small color="orange" text-color="white">
                            <v-avatar left>
                              <v-icon size="15">mdi-checkbox-marked-circle</v-icon>
                            </v-avatar>
                            Pending
                          </v-chip>
                        </template>
                        <span>Pending Wfh</span>
                      </v-tooltip>
                      <v-tooltip bottom v-if="wfh.is_approved == 0">
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip v-bind="attrs" v-on="on" small color="red" text-color="white">
                            <v-avatar left>
                              <v-icon size="15">mdi-close-circle</v-icon>
                            </v-avatar>
                            Rejected
                          </v-chip>
                        </template>
                        <span>Rejected Wfh</span>
                      </v-tooltip>
                    </td>
                    <td>{{ wfh.user_id ? wfh.user.employee_code : "" }}</td>
                    <td>{{ wfh.date || "" }}</td>
                    <td>{{ wfh.reason || "" }}</td>
                    <td class="text-right" width="210">
                      <v-autocomplete solo ref="status" :disabled="wfh.is_approved != null"
                        class="autocomplete-expense mt-3" v-model="wfh.is_approved" :items="typeItems"
                        :loading="isUpdating" dense @input="handleStatusChange(wfh)"
                        prepend-inner-icon="mdi-toggle-switch"></v-autocomplete>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="9" class="text-center">No data found</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-pagination v-model="page" :length="paginationLength">
            </v-pagination>
          </v-card>
        </v-col>
      </v-row>
      <v-dialog v-model="isDeclineDialogVisible" max-width="600px" persistent>
        <v-card>
          <v-card-title>
            <span class="headline">Decline Reason</span>
          </v-card-title>
          <v-card-text>
            <v-textarea outlined v-model="declineReason" label="Reason for Decline" rows="3"></v-textarea>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="submitDeclineReason">Submit</v-btn>
            <v-btn color="blue darken-1" text @click="cancelDecline">Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
export default {
  name: "pjpExpensesPage",
  data() {
    return {
      isApproved: "",
      user_id: "",
      page: 1,
      serialNoStarting: 1,
      paginationLength: 0,
      count: 0,
      search_keyword: "",
      typeItems: [
        { id: "PENDING", text: "PENDING", value: null },
        { id: "REJECTED", text: "REJECTED", value: 0 },
        { id: "APPROVED", text: "APPROVED", value: 1 },
      ],
      statuses: [
        {
          id: 0,
          text: "Select Approve Status",
          value: "",
        },
        { id: "APPROVED", text: "APPROVED", value: "1" },
        { id: "NOT APPROVED", text: "NOT APPROVED", value: "0" },
        { id: "PENDING", text: "PENDING", value: "null" },
      ],
      wfh_requests: [],
      date_menu: false,
      isLoading: false,
      isUpdating: false,
      selectedWfhRequest: null,
      isDeclineDialogVisible: false,
      declineReason: '',
    };
  },
  watch: {
    page: "getData",
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      this.search_keyword = this.search_keyword ? this.search_keyword : "";
      this.isApproved = this.isApproved ? this.isApproved : "";
      let wfhRequests = [];
      let query =
        "page=" +
        this.page +
        "&rowsPerPage=" +
        this.rowsPerPage +
        "&search_keyword=" +
        this.search_keyword +
        "&is_approved=" +
        this.isApproved;
      wfhRequests = await axios.get(`wfh_requests?${query}`);
      this.wfh_requests = wfhRequests.data.data;
      this.count = wfhRequests.data.count;
      this.serialNoStarting = (this.page - 1) * this.rowsPerPage;
      this.paginationLength = Math.ceil(this.count / this.rowsPerPage);
      this.isLoading = false;
    },

    async UpdateWfhRequests(wfh) {
      if (wfh.is_approved == 0) {
        this.selectedWfhRequest = wfh;
        this.isDeclineDialogVisible = true;
      } else {
        await this.submitUpdateWfhRequests(wfh);
      }
    },
    async submitUpdateWfhRequests(wfh) {
      let old_json = wfh;
      this.$set(wfh, "isUpdating", true);
      try {
        await axios
          .patch(`/wfh_requests/${this.encryptIt(wfh.id)}`, wfh)
          .then(async () => {
            await this.saveUserTimestamp(old_json, wfh);
            let PageModule = 'UPDATED';
            let PageCode = 'WFH REQUESTS';
            const userIP = await this.getUserIp();
            this.saveAuditTrial(userIP, PageModule, PageCode)
            this.getData();
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      } catch (e) {
        console.log(e);
      } finally {
        this.$set(wfh, "isUpdating", false);
      }
    },
    async Delete_this(id) {
      let check = confirm("Are you sure you want to delete the data");
      if (check) {
        try {
          this.isLoading = true;
          await axios.delete(`pjp_expenses/${id}`);
          this.isLoading = false;
          this.getData();
        } catch (e) {
          this.isLoading = false;
        }
      }
    },
    submitDeclineReason() {
      if (this.selectedWfhRequest) {
        this.selectedWfhRequest.decline_reason = this.declineReason;
        this.submitUpdateWfhRequests(this.selectedWfhRequest);
        this.isDeclineDialogVisible = false;
        this.declineReason = '';
        this.selectedWfhRequest = null;
      }
    },
    cancelDecline() {
      this.isDeclineDialogVisible = false;
      this.declineReason = '';
      this.selectedWfhRequest = null;
      this.getData();
    },
    async handleStatusChange(wfh) {
      if (wfh.is_approved == 0) {
        this.selectedWfhRequest = wfh;
        this.isDeclineDialogVisible = true;
      } else {
        await this.submitUpdateWfhRequests(wfh);
      }
    }
  },
};
</script>
