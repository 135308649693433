<template>
  <v-main>
    <v-container fluid>
      <v-card elevation="6">
        <v-card-title class="mt-4 ">
          <v-btn class="mr-2" fab x-small color="primary" @click="$router.back()">
            <v-icon dark> mdi-arrow-left </v-icon> </v-btn>Add Wfh Request
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="4">
              <v-autocomplete ref="employee" v-model="form.user_id" :items="employees" outlined label="Employee"
                append-icon="mdi-account"></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-menu v-model="wfh_menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field outlined v-model="form.date" label="Date *" prepend-inner-icon="mdi-calendar" readonly
                    v-bind="attrs" v-on="on" clearable @click:clear="date = null"
                    :error-messages="errors.date"></v-text-field>
                </template>
                <v-date-picker :id="`date`" v-model="form.date" @change="wfh_menu = false"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="7">
              <v-textarea label="Write a Reason" outlined v-model="form.raeson" :error-messages="errors.raeson">
              </v-textarea>
            </v-col>
          </v-row>
          <v-divider class="mt-5 mb-5"> </v-divider>

          <v-progress-circular v-if="isLoading" indeterminate color="primary"></v-progress-circular>
          <v-btn v-else color="primary" dark @click="save">
            Save PJP Expense
          </v-btn>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";
export default {
  name: "UserCreatePage",
  data() {
    return {
      form: {

      },
      employees: [
        {
          id: 0,
          text: "Select Employee",
          value: "",
        },
      ],
      wfh_menu: false,
      isLoading: false,
    };
  },
  mounted() {
    this.getMasters();
    // this.getData();
  },
  methods: {
    async getMasters() {
      let masters = await axios.get(`wfh_requests/masters`);
      masters = masters.data;
      // Employees
      masters.employees.forEach((employee) => {
        this.employees.push({
          id: employee.id,
          text: employee.full_name,
          value: employee.id,
        });
      });
    },
    async save() {
      try {
        this.isLoading = true;
        console.log(this.form);
        await axios.post("/wfh_requests", this.form);
        await this.saveUserTimestamp(null, this.form);
        let PageModule = 'CREATED';
        let PageCode = 'WFH REQUESTS';
        const userIP = await this.getUserIp();
        this.saveAuditTrial(userIP, PageModule, PageCode)
        this.isLoading = false;
        // this.$router.back();
      } catch (e) {
        // alert("error");
        this.isLoading = false;
      }
    },
  },
};
</script>
