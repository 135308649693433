<template>
  <v-container fluid>
    <v-card height="600">
      <v-row>
        <v-col cols="3" class="pr-0">
          <v-treeview
            v-model="tree"
            :items="items"
            activatable
            item-key="name"
            open-on-click
            @update:active="Audittrials"
          >
          </v-treeview>
        </v-col>
        <v-divider vertical style="height: 550px"></v-divider>
        <v-col cols="9" class="pt-0 pl-1">
          <v-btn
            @click="downloadPdf"
            color="primary"
            v-if="showtrials.length != 0"
            style="position: absolute; z-index: 14; bottom: 20px; right: 20px"
            >Download PDF</v-btn
          >
          <v-btn
            @click="DateRangeDialog = true"
            color="primary"
            style="position: absolute; z-index: 14; bottom: 20px; right: 200px"
            >Filter</v-btn
          >
          <v-simple-table fixed-header height="535">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-start">Sr No</th>
                  <th class="text-start">Code</th>
                  <th class="text-start">Last Modified</th>
                  <th class="text-start">Current Status</th>
                  <th class="text-start">User Role</th>
                  <th class="text-start">IP Address</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(trial, i) in showtrials" :key="i">
                  <td>{{ i + 1 }}</td>
                  <td>{{ trial.code || "" }}</td>
                  <td>{{ trial.updated_at || "" }}</td>
                  <td>{{ trial.current_status || "" }}</td>
                  <td>
                    {{ trial.user != null ? trial.user.roles[0].name : "" }}
                  </td>
                  <td>{{ trial.ip_address || "" }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
      <v-dialog v-model="DateRangeDialog">
        <v-card>
          <v-card-title>
            <v-col sm="3">
              <v-menu
                v-model="from_date_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    outlined
                    v-model="form.from_date"
                    label="From date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    clearable
                    @click:clear="form.from_date = null"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="form.from_date"
                  @change="from_date_menu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col sm="3">
              <v-menu
                v-model="to_date_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    outlined
                    v-model="form.to_date"
                    label="To date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    clearable
                    @click:clear="form.to_date = null"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="form.to_date"
                  @change="to_date_menu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col sm="3" class="py-0">
                <v-btn color="primary" @click="getData" class="mb-5">Get</v-btn>
            </v-col>
          </v-card-title>
        </v-card>
      </v-dialog>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import jsPDF from "jspdf";
import "jspdf-autotable";

export default {
  props: {
    valueToPass: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      form: {
        from_date: "",
        to_date: "",
      },
      trials: [],
      showtrials: [],
      tree: [],
      items: [],
      isLoading: false,
      from_date_menu: false,
      to_date_menu: false,
      DateRangeDialog: false,
    };
  },
  watch: {
    valueToPass: function () {
      this.getData();
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      try {
        this.showtrials = [];
        this.isLoading = true;
        this.form.from_date = this.form.from_date ? this.form.from_date : "";
        this.form.to_date = this.form.to_date ? this.form.to_date : "";
        this.selectedAuditTrialCode = (this.form.from_date && this.form.to_date) ? this.selectedAuditTrialCode : '';
        let query = 'from_date=' + this.form.from_date + '&to_date=' + this.form.to_date + '&code=' + this.selectedAuditTrialCode;
        let response = await axios.get(`/audit_trials?${query}`);
        this.trials = response.data.data;
        if(!this.form.from_date &&  !this.form.to_date){
            response.data.data.forEach((element) => {
                if (
                    element.code &&
                    !this.items.some((item) => item.name === element.code)
                ) {
                    this.items.push({ name: element.code });
                }
            });
        }else{
            this.DateRangeDialog = false;
            this.Audittrials(this.selectedAuditTrialCode);
        }
        console.log("this", this.items);
        this.isLoading = false;
      } catch (error) {
        console.log(error);
        this.isLoading = false;
      }
    },
    Audittrials(node) {
      console.log(node);
      this.selectedAuditTrialCode = node;
      this.showtrials = [];
      this.trials.forEach((element) => {
        if (element.code == node) {
          this.showtrials.push(element);
        }
      });
      console.log("showtrials", this.showtrials);
    },
    downloadPdf() {
      const doc = new jsPDF();
      const rows = this.showtrials.map((trial, index) => [
        index + 1,
        trial.code || "",
        trial.updated_at || "",
        trial.current_status || "",
        trial.user != null ? trial.user.roles[0].name : "",
        trial.ip_address || "",
      ]);
      doc.autoTable({
        head: [
          [
            "Sr No",
            "Code",
            "Last Modified",
            "Current Status",
            "User Role",
            "IP Address",
          ],
        ],
        body: rows,
      });
      doc.save("audit_trails.pdf");
    },
  },
};
</script>

<style>
.v-treeview-node__label {
  color: black !important;
  font-weight: 500 !important;
}
</style>
