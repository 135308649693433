<template>
  <v-main>
    <v-container fluid>
      <!-- Filters -->
      <v-row>
        <v-col md="12">
          <v-card elevation="6">
            <v-card-title>
              <span class="text-h5 font-weight-bold" v-text="$route.query.report"></span>
            </v-card-title>
            <v-card-text>

              <v-row>
                <v-col sm="4" v-if="$route.query.table_name == 'users'">
                  <v-autocomplete solo v-model="filter.department" :items="DepartMents" label="All Departments"
                    append-icon="mdi-account" :error-messages="errors.department"></v-autocomplete>
                </v-col>
                <v-col sm="4" v-if="$route.query.table_name == 'users'">
                  <v-autocomplete solo v-model="filter.branch" :items="Branchs" label="All Branchs"
                    append-icon="mdi-account" :error-messages="errors.branch"></v-autocomplete>
                </v-col>
                <v-col sm="4" v-if="$route.query.table_name == 'pjps'">
                  <v-autocomplete solo v-model="filter.is_outlet_visited" :items="Visits" label="is Visited"
                    append-icon="mdi-account" :error-messages="errors.is_outlet_visited"></v-autocomplete>
                </v-col>
                <v-col sm="4" v-if="$route.query.table_name != 'users'">
                  <v-autocomplete solo v-model="filter.is_approved" :items="Statuses" label="Status"
                    append-icon="mdi-account" :error-messages="errors.is_approved"></v-autocomplete>
                </v-col>
                <v-col sm="4" v-if="$route.query.table_name == 'user_attendances'">
                  <v-autocomplete solo v-model="filter.user_id" :items="Employees" label="Select User"
                    append-icon="mdi-account" :error-messages="errors.user_id"></v-autocomplete>
                </v-col>
                <v-col sm="4" v-if="$route.query.table_name == 'users'">
                  <v-autocomplete solo v-model="filter.is_active" :items="Statuses2" label="Status"
                    append-icon="mdi-account" :error-messages="errors.is_approved"></v-autocomplete>
                </v-col>
                <v-col sm="3" v-if="$route.query.table_name != 'users'">
                  <v-menu v-model="month_menu" :close-on-content-click="false" :nudge-right="40"
                    transition="scale-transition" offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field solo v-model="filter.month_year" label="Year - Month" append-icon="mdi-calendar"
                        readonly v-bind="attrs" v-on="on" clearable @click:clear="filter.month_year = null"
                        :error-messages="errors.month"></v-text-field>
                    </template>
                    <v-date-picker v-model="filter.month_year" type="month"
                      @change="month_menu = false"></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-btn color="primary" @click="getReports">Get Report</v-btn>
                </v-col>
                <v-spacer></v-spacer>
                <!-- <v-col md="3">
                  <v-pagination v-model="page" :length="paginationLength"></v-pagination>
                </v-col> -->
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="12">
          <v-card elevation="6" rounded="xxl">
            <v-card-title>
              <v-spacer></v-spacer>
              <v-btn-toggle v-if="reportTypeResponse.length != 0" v-model="buttons" color="deep-purple accent-3">
                <v-btn value="left" @click="downloadExcel">
                  <span class="hidden-sm-and-down">Download Excel</span>
                </v-btn>
                <v-btn value="center" @click="downloadPDF">
                  <span class="hidden-sm-and-down">Download Pdf</span>
                </v-btn>
              </v-btn-toggle>
            </v-card-title>
            <v-card-text>
              <section v-if="isLoading" style="height: 560px">
                <v-progress-linear indeterminate color="primary"></v-progress-linear>
              </section>
              <v-simple-table v-else fixed-header height="560" class="striped-table">
                <template v-slot:default>
                  <thead>
                    <tr v-if="$route.query.report.includes('Monthly Attendance')">
                      <th style="min-width: 80px">Sr no</th>
                      <th style="min-width: 150px" class="text-start text-subtitle-2 font-weight-bold"
                        v-for="header in dynamicHeaders" :key="header.column_name">
                        {{ header.header_name }}
                      </th>
                      <th v-for="c in totalDaysInMonth" :key="`days${c}`">
                        {{ c }}
                      </th>
                    </tr>
                    <tr v-else>
                      <th style="min-width: 80px">Sr no</th>
                      <th style="min-width: 150px" class="text-start text-subtitle-2 font-weight-bold"
                        v-for="header in dynamicHeaders" :key="header.column_name">
                        {{ header.header_name }}
                      </th>
                    </tr>
                  </thead>
                  <tbody v-if="reportTypeResponse.length != 0 &&
                $route.query.report.includes('Monthly Attendance')
                ">
                    <tr v-for="(user, i) in reportTypeResponse" :key="`user_${i}`">
                      <td>{{ i + 1 }}</td>
                      <td v-for="header in dynamicHeaders" :key="header.column_name">
                        {{
                header.column_name.includes("_id") ||
                  header.column_name.includes("is_") ||
                  header.column_name.includes("is_leave_approved") ||
                  header.column_name.includes("is_outlet_visited") ||
                  header.column_name.includes("is_approved")
                  ? getRelationShipIdName(
                    header.column_name,
                    user[header.column_name],
                    header.header_name
                  )
                  : user[header.column_name]
              }}
                        <!-- {{ header.column_name == 'employee_code' ? user[header.column_name] : 'no' }} -->
                      </td>
                      <td v-for="c in totalDaysInMonth" :key="`days${c}`">
                        <span v-if="user.attendances[c]">
                          {{
                user.attendances[c].session_type.description
                  .charAt(0)
                  .toUpperCase()
              }}
                        </span>
                        <span v-else>A</span>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else-if="reportTypeResponse.length != 0 &&
                !$route.query.report.includes('Monthly Attendance')
                ">
                    <tr v-for="(user, i) in reportTypeResponse" :key="`user_${i}`">
                      <td>{{ i + 1 }}</td>
                      <td v-for="header in dynamicHeaders" :key="header.column_name">
                        {{
                header.column_name.includes("_id") ||
                  header.column_name.includes("is_") ||
                  header.column_name.includes("is_leave_approved") ||
                  header.column_name.includes("is_outlet_visited") ||
                  header.column_name.includes("is_approved")
                  ? getRelationShipIdName(
                    header.column_name,
                    user[header.column_name],
                    header.header_name
                  )
                  : user[header.column_name]
              }}
                        <!-- {{ header.column_name == 'employee_code' ? user[header.column_name] : 'no' }} -->
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td v-if="$route.query.report.includes('Monthly Attendance')
                " :colspan="`${7 + totalDaysInMonth}`" class="text-center">
                        No data found
                      </td>
                      <td colspan="7" class="text-center">No data found</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <v-pagination rounded v-model="page" :length="paginationLength"></v-pagination>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
import moment from "moment";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import jsPDF from "jspdf";
import "jspdf-autotable";

export default {
  name: "reportsPage",
  data() {
    return {
      filter: {
        month_year: "",
        month: "",
        year: "",
        expense_id: "",
        user_id: "",
        branch: "",
        department: "",
        is_approved: "",
        is_outlet_visited: "",
        is_active: "",
      },
      page: 1,
      serialNoStarting: 1,
      paginationLength: 0,
      count: 0,
      search_keyword: "",
      month_menu: false,
      dynamicHeaders: [],
      Employees: [],
      companyId: "",
      totalDaysInMonth: "",
      query_name: "",
      isLoading: false,
      isExcelloading: false,
      buttons: "justify",
      reports: [],
      DepartMents: [
        {
          id: 0,
          text: "Select Dapertment",
          value: "",
        },
      ],
      Branchs: [
        {
          id: 0,
          text: "All Branch",
          value: "",
        },
      ],
      Visits: [
        {
          id: 0,
          text: "Select Visited",
          value: "",
        },
        {
          id: 1,
          text: "Visited",
          value: "1",
        },
        {
          id: 2,
          text: "Not Visited",
          value: "0",
        },
      ],
      Statuses: [
        {
          id: 0,
          text: "Select Status",
          value: "",
        },
        {
          id: 1,
          text: "Approved",
          value: "1",
        },
        {
          id: 2,
          text: "Not Approved",
          value: "0",
        },
        {
          id: 3,
          text: "Pending",
          value: "null",
        },
      ],
      Statuses2: [
        {
          id: 0,
          text: "Select Status",
          value: "",
        },
        {
          id: 1,
          text: "Active",
          value: "1",
        },
        {
          id: 2,
          text: "In-Active",
          value: "0",
        },
      ],
      reportTypeResponse: [],
    };
  },
  watch: {
    page: "getData",
    month_menu: "getMonthYear",
    "$route.query.report": {
      handler() {
        if (!this.$route.query.report.includes("Monthly Attendance")) {
          this.filter.year = '';
          this.filter.month = '';
          this.filter.is_approved = '';
          this.filter.is_active = '';
          this.filter.is_outlet_visited = '';
          this.filter.user_id = '';
          this.filter.department = '';
          this.filter.branch = '';
          this.getReports();
        } else {
          this.reportTypeResponse = [];
          this.dynamicHeaders = [];
        }
        this.getMasters();
      },
      immediate: true,
    },
  },
  mounted() {
    this.data_base = this.$route.query.table_name;
    if (!this.$route.query.report.includes("Monthly Attendance")) {
      this.getReports();
    } else {
      this.reportTypeResponse = [];
      this.dynamicHeaders = [];
    }
    this.getMasters();
  },
  methods: {
    async getMasters() {
      let masters = await axios.get(`users/masters`);
      masters = masters.data;
      // BRanchs
      masters.departments.forEach((department) => {
        this.DepartMents.push({
          id: department.id,
          text: department.description,
          value: department.description,
        });
      });
      masters.branchs.forEach((branch) => {
        this.Branchs.push({
          id: branch.id,
          text: branch.description,
          value: branch.description,
        });
      });
      masters.usersResponse.forEach((user) => {
        this.Employees.push({
          id: user.id,
          text: user.full_name,
          value: user.id,
        });
      });
    },
    async getReports() {
      this.reports = [];
      this.reportTypeResponse = [];
      this.dynamicHeaders = [];
      this.isLoading = true;
      if (this.filter.month_year) {
        this.filter.year = this.filter.month_year.split("-")[0];
        this.filter.month = this.filter.month_year.split("-")[1];
      }
      this.search_keyword = this.search_keyword ? this.search_keyword : "";
      console.log("report", this.$route.query.report);
      this.session_type =
        this.$route.query.report.includes("Leave") ||
          this.$route.query.report.includes("leave")
          ? "LEAVE"
          : "";
      this.filter.is_regularization =
        this.$route.query.report.includes("Regularization") ||
          this.$route.query.report.includes("regularization")
          ? "1"
          : "";
      this.filter.is_punchout_missing =
        this.$route.query.report.includes("punchout") ||
          this.$route.query.report.includes("Punchout")
          ? "1"
          : "";
      this.filter.is_leave_balance =
        this.$route.query.report.includes("Balance") ||
          this.$route.query.report.includes("balance")
          ? "1"
          : "";
      this.filter.is_approved = this.filter.is_approved
        ? this.filter.is_approved
        : "";
      this.filter.is_active = this.filter.is_active
        ? this.filter.is_active
        : "";
      this.filter.is_outlet_visited = this.filter.is_outlet_visited
        ? this.filter.is_outlet_visited
        : "";
      this.filter.user_id = this.filter.user_id ? this.filter.user_id : "";
      this.filter.department = this.filter.department
        ? this.filter.department
        : "";
      this.filter.branch = this.filter.branch ? this.filter.branch : "";
      let query =
        // "page=" +
        // this.page +
        // "&rowsPerPage=" +
        // this.rowsPerPage +
        "&search_keyword=" +
        this.search_keyword +
        "&month=" +
        this.filter.month +
        "&year=" +
        this.filter.year +
        "&user_id=" +
        this.filter.user_id +
        "&department=" +
        this.filter.department +
        "&branch=" +
        this.filter.branch +
        "&is_active=" +
        this.filter.is_active +
        "&session_type=" +
        this.session_type +
        "&is_approved=" +
        this.filter.is_approved +
        "&is_leave_balance=" +
        this.filter.is_leave_balance +
        "&user_id=" +
        this.filter.user_id +
        "&is_regularization=" +
        this.filter.is_regularization +
        "&is_punchout_missing=" +
        this.filter.is_punchout_missing +
        "&is_outlet_visited=" +
        this.filter.is_outlet_visited +
        "&table_name=" +
        this.$route.query.table_name +
        "&report_name=" +
        this.$route.query.report;
      await axios
        .get(`hr_reports?${query}`)
        .then((reports) => {
          this.reports = reports.data.data;
          this.reportTypeResponse = reports.data.reportTypeResponse;
          this.dynamicHeaders = this.reports[0].hr_reports;
          this.reports = this.reports.map((report) => {
            let processedReport = {
              id: report.id,
              is_active: report.is_active,
              report_name: report.report_name,
              table_name: report.table_name,
            };
            report.hr_reports.forEach((hr) => {
              processedReport[hr.column_name] = hr.header_name;
            });
            return processedReport;
          });
          // this.count = this.reportTypeResponse.length;
          // this.serialNoStarting = (this.page - 1) * this.rowsPerPage;
          // this.paginationLength = Math.ceil(this.count / this.rowsPerPage);
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    async downloadExcel() {
      console.log("excel");
      // Create a workbook and a worksheet
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Reports");

      // Define header row and its styling
      const staticHeaders = [
        "Sr no",
        ...this.dynamicHeaders.map((header) => header.header_name),
      ];
      const dayHeaders = Array.from(
        { length: this.totalDaysInMonth },
        (_, i) => `Day ${i + 1}`
      );

      const headers = [...staticHeaders, ...dayHeaders];

      const headerRow = worksheet.addRow(headers);

      // Apply styling to the header row
      headerRow.eachCell((cell) => {
        cell.font = { bold: true };
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFFF00" }, // Yellow background
        };
        cell.border = {
          top: { style: "thin", color: { argb: "FF000000" } },
          left: { style: "thin", color: { argb: "FF000000" } },
          bottom: { style: "thin", color: { argb: "FF000000" } },
          right: { style: "thin", color: { argb: "FF000000" } },
        };
      });

      // Add data rows
      this.reportTypeResponse.forEach((user, index) => {
        const row = [index + 1];
        this.dynamicHeaders.forEach((header) => {
          const value =
            header.column_name.includes("_id") ||
              header.column_name.includes("is_") ||
              header.column_name.includes("is_leave_approved") ||
              header.column_name.includes("is_outlet_visited") ||
              header.column_name.includes("is_approved")
              ? this.getRelationShipIdName(
                header.column_name,
                user[header.column_name],
                header.header_name
              )
              : user[header.column_name];
          row.push(value);
        });
        // Add attendance data for each day
        for (let i = 1; i <= this.totalDaysInMonth; i++) {
          const attendance = user.attendances[i]
            ? user.attendances[i].session_type.description
              .charAt(0)
              .toUpperCase()
            : "A";
          row.push(attendance);
        }
        worksheet.addRow(row);
      });

      // Export the Excel file
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "reports.xlsx");
    },
    downloadPDF() {
      console.log("pdf");
      const doc = new jsPDF();
      const headers = [
        ["Sr no", ...this.dynamicHeaders.map((header) => header.header_name)],
      ];
      const data = this.reportTypeResponse.map((user, index) => {
        const row = [index + 1];
        this.dynamicHeaders.forEach((header) => {
          row.push(user[header.column_name]);
        });
        return row;
      });

      doc.autoTable({
        head: headers,
        body: data,
        headStyles: {
          fillColor: [255, 255, 0], // Yellow background for headers
          textColor: 0,
          lineWidth: 0.1,
          lineColor: 0,
          halign: "center",
        },
        bodyStyles: {
          halign: "center",
        },
      });

      doc.save("reports.pdf");
    },
    async UpdateApprovalStatus(type) {
      if (type == "APPROVE") {
        this.isApproving = true;
      } else {
        this.isDenying = true;
      }
      let filtered_expense = [];
      this.reports.forEach((expense) => {
        if (expense.is_selected == 1) {
          expense.is_approved = type == "APPROVE" ? 1 : 0;
          filtered_expense.push(expense);
        }
      });
      let payload = {
        datas: filtered_expense,
      };
      await axios
        .post(`/reports/update_approval_status`, payload)
        .then(() => { });
      this.isApproving = false;
      this.isDenying = false;
    },
    selectAll() {
      this.reports.forEach((expense) => {
        expense.is_selected = this.is_select_all;
      });
    },
    getMonthYear() {
      if (this.filter.month_year) {
        this.filter.year = this.filter.month_year.split("-")[0];
        this.filter.month = this.filter.month_year.split("-")[1];

        this.totalDaysInMonth = moment(
          this.filter.month_year,
          "YYYY-M"
        ).daysInMonth();
        console.log(this.totalDaysInMonth);
      }
    },
    getRelationShipIdName(column, id, header) {
      console.log(header);
      if (column == "position_id") {
        const position = this.reportTypeResponse.find(
          (response) => response.position && response.position.id == id
        );
        return position ? position.position.name : "";
      }
      if (column == "asset_id") {
        const assetId = this.reportTypeResponse.find(
          (response) => response.asset && response.asset.id == id
        );
        return assetId ? assetId.asset.description : "";
      }
      if (column == "asset_type_id") {
        const assetType = this.reportTypeResponse.find(
          (response) => response.asset_type && response.asset_type.id == id
        );
        return assetType ? assetType.asset_type.description : "";
      }
      if (column == "session_type_id") {
        const session = this.reportTypeResponse.find(
          (response) => response.session_type && response.session_type.id == id
        );
        return session ? session.session_type.description : "";
      }
      if (column == "shift_id") {
        const shift = this.reportTypeResponse.find(
          (response) => response.shift && response.shift.id == id
        );
        return shift ? shift.shift.shift_name : "";
      }
      if (column == "user_id") {
        if (column == "user_id") {
          const user = this.reportTypeResponse.find(
            (response) => response.user && response.user.id == id
          );
          if (header != null && header.includes('code') || header.includes('Code') || header.includes('Employee Code')) {
            return user ? user.user.employee_code : "";
          } else if (header != null && header.includes('branch') || header.includes('Branch')) {
            return user ? user.user.branch : "";
          } else if (header != null && header.includes('department') || header.includes('Department')) {
            return user ? user.user.department : "";
          } else if (header != null && header.includes('designation') || header.includes('Designation')) {
            return user ? user.user.designation : "";
          }
          else {
            return user ? user.user.full_name : "";
          }
        }
      }
      if (column == "leave_type_id") {
        const leaves = this.reportTypeResponse.find(
          (response) => response.leave_type && response.leave_type.id == id
        );
        return leaves ? leaves.leave_type.description : "";
      }
      if (column == "leave_type_id") {
        const leaves = this.reportTypeResponse.find(
          (response) => response.leave_type && response.leave_type.id == id
        );
        return leaves ? leaves.leave_type.description : "";
      }
      if (column == "expense_type_id") {
        const expense = this.reportTypeResponse.find(
          (response) => response.expense_type && response.expense_type.id == id
        );
        return expense ? expense.expense_type.description : "";
      }
      if (column == "travel_mode_id") {
        const travel = this.reportTypeResponse.find(
          (response) => response.travel_mode && response.travel_mode.id == id
        );
        return travel ? travel.travel_mode.description : "";
      }
      if (column == "pjp_id") {
        const travel = this.reportTypeResponse.find(
          (response) => response.travel_mode && response.travel_mode.id == id
        );
        return travel ? travel.travel_mode.description : "";
      }
      if (column == "is_leave_approved") {
        if (id == true) {
          return "Approved";
        } else if (id == false) {
          return "Rejected";
        } else {
          return "Pending";
        }
      }
      if (column == "is_regularization") {
        if (id == true) {
          return "YES";
        } else {
          return "NO";
        }
      }
      if (column == "is_regularization_approve") {
        if (id == true) {
          return "Approved";
        } else if (id == false) {
          return "Rejected";
        } else {
          return "Pending";
        }
      }
      if (column == "is_approved") {
        if (id == true) {
          return "Approved";
        } else if (id == false) {
          return "Rejected";
        } else {
          return "Pending";
        }
      }
      if (column == "is_late_mark") {
        if (id == true) {
          return "YES";
        } else {
          return "NO";
        }
      }
      if (column == "is_half_day") {
        if (id == true) {
          return "YES";
        } else {
          return "NO";
        }
      }
      if (column == "is_outlet_visited") {
        if (id == true) {
          return "Visited";
        } else if (id == false) {
          return "Not Visited";
        }
      }
    },
  },
};
</script>
