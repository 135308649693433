/* eslint-disable */
import axios from "axios";

export default {
  namespaced: true,
  state: {
    token: "",
    user: {},
    company: {},
    roleName: "",
  },
  getters: {
    authenticated(state) {
      return state.token ? true : false;
    },
    user(state) {
      return state.user;
    },
    company(state) {
      return state.company;
    },
    roleName(state) {
      return state.user.roles[0].name;
    },
  },
  mutations: {
    SET_TOKEN(state, data) {
      state.token = data;
    },
    SET_USER(state, data) {
      state.user = data;
      let userCompanies = data ? data.companies : "";
      state.company = userCompanies.length ? userCompanies[0] : {};
    },
    SET_COMPANY(state, company) {
      state.company = company;
      localStorage.setItem("company", JSON.stringify(company)); // This is when the Super Admin Selects a Company from the dropdown
      if (company != null && company.id) {
        axios.defaults.headers.common["company-id"] = company.id;
      } else axios.defaults.headers.common["company-id"] = null;
    },
  },
  actions: {
    async logIn({ dispatch }, credentails) {
      const response = await axios.post("/login", credentails);
      await dispatch("attempt", response.data.token);
    },
    async attempt({ state, commit }, token) {
      if (token) commit("SET_TOKEN", token);

      if (!state.token) return;

      try {
        const response = await axios.get("/me");
        commit("SET_USER", response.data.data);
        var company = JSON.parse(localStorage.getItem("company"));
        if (company) commit("SET_COMPANY", company);
      } catch (e) {
        commit("SET_TOKEN", null);
        commit("SET_USER", null);
        commit("SET_COMPANY", null);
      }
    },
    logOut({ commit }) {
      commit("SET_TOKEN", null);
      commit("SET_USER", null);
    },
    setCompany({ commit }, company) {
      commit("SET_COMPANY", company);
    },
  },
};
