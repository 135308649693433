<template>
  <v-main>
    <v-container fluid>
      <v-row>
        <v-col md="12">
          <v-card elevation="2">
            <v-card-text>
              <v-row>
                <v-col md="3">
                  <v-text-field outlined v-model="search_keyword" label="Search ... " append-inner-icon="mdi-magnify"
                    @keydown.enter="getData" @change="page = 1"></v-text-field>
                </v-col>
                <v-col md="3">
                  <v-autocomplete outlined ref="designation" v-model="form.designation_id" :items="designations"
                    label="Designation" @input="getData" append-icon="mdi-account" clearable></v-autocomplete>
                </v-col>
                <v-col sm="3">
                  <v-autocomplete outlined v-model="users" ref="supervisor" :items="Employees" label="Employees"
                    @change="() => (selectedUser = users)" append-icon="mdi-account" clearable></v-autocomplete>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col md="12">
          <v-card elevation="12">
            <Timestamp :selectedUser="selectedUser"></Timestamp>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
import Timestamp from "./timestamp.vue";
import * as VueGoogleMaps from "vue2-google-maps";

export default {
  name: "usersPage",
  data() {
    return {
      form: {
        active: "",
        supervisor_id: "",
        role_id: "",
        designation_id: "",
      },
      page: 1,
      serialNoStarting: 1,
      paginationLength: 0,
      count: 0,
      search_keyword: "",
      users: [],
      statuses: [
        {
          id: 0,
          text: "Select Active Status",
          value: "",
        },
        { id: "ACTIVE", text: "ACTIVE", value: "1" },
        { id: "INACTIVE", text: "INACTIVE", value: "0" },
      ],
      roles: [
        {
          id: 0,
          text: "Select Role",
          value: "",
        },
      ],
      Employees: [
        {
          id: 0,
          text: "Select Supervisors",
          value: "",
        },
      ],
      designations: [
        {
          id: 0,
          text: "Select Designation",
          value: "",
        },
      ],
      isLoading: false,
      isExcelloading: false,
      ExportUsers: [],
      Export_users: [],
      excel_fields: {
        "Sr No": "sr_no",
        Name: "name",
        Email: "email",
        Phone: "phone",
        Supervisor: "supervisor",
        "Is Active": "status",
      },
      selectedUser: "",
    };
  },
  watch: {
    page: "getData",
  },
  components: {
    Timestamp,
  },
  computed: {
    google: VueGoogleMaps.gmapApi,
  },
  mounted() {
    this.getData();
    this.getMasters();
  },
  methods: {
    async getMasters() {
      let masters = await axios.get(`users/masters`);
      masters = masters.data;
      // Roles
      // masters.roles.forEach((role) => {
      //   this.roles.push({
      //     id: role.id,
      //     text: role.name,
      //     value: role.id,
      //   });
      // });
      // Supervisors
      masters.usersResponse.forEach((Employee) => {
        this.Employees.push({
          id: Employee.id,
          text: Employee.full_name,
          value: Employee,
        });
      });
      //Designation
      masters.designations.forEach((designation) => {
        this.designations.push({
          id: designation.id,
          text: designation.description,
          value: designation.id,
        });
      });
    },
    async getData() {
      console.log(this.form.supervisor_id);
      this.isLoading = true;
      this.search_keyword = this.search_keyword ? this.search_keyword : "";
      this.active = this.active ? this.active : "";
      this.role_id = this.role_id ? this.role_id : "";
      let users = [];
      let query =
        "page=" +
        this.page +
        "&rowsPerPage=" +
        this.rowsPerPage +
        "&search_keyword=" +
        this.search_keyword +
        "&is_active=" +
        this.form.active +
        "&roleId=" +
        this.form.role_id +
        "&supervisorId=" +
        this.form.supervisor_id +
        "&designationId=" +
        this.form.designation_id;
      console.log(query);
      users = await axios.get(`users?${query}`);
      this.users = users.data.data;
      // console.log(this.users);
      this.count = users.data.count;
      this.serialNoStarting = (this.page - 1) * this.rowsPerPage;
      this.paginationLength = Math.ceil(this.count / this.rowsPerPage);
      this.isLoading = false;
    },
    //  async Delete_this(id) {
    //   let check = confirm("Are you sure you want to delete the data");
    //   if (check) {
    //     try {
    //       this.isLoading = true;
    //       await axios.delete(`users/${id}`);
    //       this.isLoading = false;
    //       this.getData();
    //     } catch (e) {
    //       this.isLoading = false;
    //     }
    //   }
    // },

    async addToExcelData() {
      this.isExcelLoading = true;
      this.title = "Company Lists";
      let Export_users = [];
      this.search_keyword = this.search_keyword ? this.search_keyword : "";
      let query =
        "search_keyword=" +
        this.search_keyword +
        "&is_active=" +
        this.form.active +
        "&roleId=" +
        this.form.role_id;
      Export_users = await axios.get(`users?${query}`);
      this.Export_users = Export_users.data.data;

      this.Export_users.forEach((user) => {
        this.ExportUsers.push({
          sr_no: user.sr_no,
          name: user.name ? user.name.toUpperCase() : "",
          email: user.email ? user.email.toUpperCase() : "",
          phone: user.phone ? user.phone : "",

          supervisor: user.supervisor_id
            ? user.supervisor.name.toUpperCase()
            : "",
          status: user.active == 1 ? "YES" : "NO",
        });
      });
      this.isExcelLoading = false;
    },
    async generateExcel() {
      this.isExcelloading = true;
      let query =
        "search_keyword=" +
        this.search_keyword +
        "&is_active=" +
        this.form.active +
        "&roleId=" +
        this.form.role_id;

      try {
        let response = await axios.get(`usersExports?${query}`);
        if (response && response.data && response.data.data) {
          window.open(`${response.data.data}`, "_blank");
        } else {
          console.error("Invalid response data:", response.data);
        }
      } catch (error) {
        console.error("Error fetching Excel data:", error);
      }
      this.isExcelloading = false;
    },
  },
};
</script>
