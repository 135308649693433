<template>
  <v-container fill-height>
    <v-row align="center" justify="center" dense>
      <v-col align-self="center" md="6" sm="6" lg="6" xl="4" class="py-auto">
        <v-card class="mx-auto" color="px-8  grey lighten-4" rounded="lg" elevation="0"
          :height="$vuetify.breakpoint.mdAndDown ? '29pc' : '500px'">
          <v-card-title class="text-h4 mb-1 font-weight-bold">
            <v-col cols="12" class="py-0" align-self="center">
              <v-img :src="is_company_user && logo_path != null
            ? `${mediaUrl + logo_path}`
            : require('../../assets/sapp-logo.png')
            " alt="SAPP" class="mx-auto" width="120"></v-img>
            </v-col>
            <!-- Welcome{{ is_company_user ? " back" : ""
            }} -->
          </v-card-title>
          <!-- <v-card-subtitle class="font-weight-bold">Welcome{{ is_company_user ? " back" : "" }}, Please enter
            your details.</v-card-subtitle> -->
          <v-card-subtitle class="font-weight-bold">ENTER MOBILE/EMAIL
            .</v-card-subtitle>
          <v-card-text>
            <v-text-field v-model="form.email" outlined solo class="rounded-lg" label="Email/Phone"
              placeholder="Enter a Mobile Number or email address" clearable @click:clear="form.email = null"
              prepend-inner-icon="mdi-email" :error-messages="errors.email"></v-text-field>
            <v-text-field v-model="form.password" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword ? 'text' : 'password'" outlined solo class="rounded-lg" @keypress.enter="login"
              :error-messages="errors.password" label="Password" placeholder="Enter password"
              prepend-inner-icon="mdi-lock" @click:append="showPassword = !showPassword"></v-text-field>
            <v-btn :loading="isLoading" :disabled="isLoading" :dark="!isLoading" color="primary" block :rounded="lg"
              @click="login">
              Login
            </v-btn>
            <v-btn text color="primary" align="right" to="/forgot-password">
              Forgot password?
            </v-btn>
          </v-card-text>
        </v-card>
        <!-- <span>{{ errorMsg }}</span> -->
      </v-col>
    </v-row>
    <v-dialog v-model="isExpired" width="450">
      <v-card>
        <v-card-title>
          <v-col cols="4" class="pl-0">
            <v-icon size="100" color="red">mdi-alert</v-icon>
          </v-col>
          <v-col cols="8" class="text-h6">
            <v-col cols="12">
              Your Subscription Expired
            </v-col>
            <v-col cols="12" class="text-subtitle-2">
              Please Contact Support Team for Assistance via email <b> abc@gmail.com</b>
            </v-col>
          </v-col>
          <v-col>
            <v-btn class="float-end ml-3" @click="isExpired = false" color="primary"> ok </v-btn>
            <v-btn class="float-end" color="success"> Renew </v-btn>
          </v-col>
        </v-card-title>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import axios from "axios";

export default {
  name: "LoginPage",
  data() {
    return {
      form: {
        email: "",
        password: "",
        portal: true,
      },
      SupportTeam: [],
      loggedInRole: "",
      logo_path: "../../assets/sapp-logo.png",
      is_company_user: false,
      showPassword: false,
      isLoading: false,
      isExpired: false,
    };
  },
  mounted() {
    if (localStorage.getItem("company-id").length) {
      let companyId = localStorage.getItem("company-id");
      this.getCompany(companyId);
      console.log(companyId);
    }
  },
  methods: {
    async getCompany(companyId) {
      await axios
        .get(`/get_company/${this.encryptIt(companyId)}`)
        .then((company) => {
          company = company.data.data;
          this.logo_path = company.logo_path;
          this.is_company_user = true;
        })
        .catch((error) => {
          this.logo_path = "../../assets/sapp-logo.png";
          console.error("Error fetching data:", error);
        });
    },
    ...mapActions({
      logIn: "auth/logIn",
    }),
    async login() {
      this.isLoading = true;
      if (!this.form.email.includes('@')) {
        this.form.phone = this.form.email;
        this.form.email = '';
      }
      await this.logIn(this.form)
        .then(() => {
          this.isLoading = false;
          location.reload("/");
        })
        .catch(() => {
          // console.log('response', response);
          console.log('entered');
          // if (response.data) {
          // this.isExpired = true;
          // } else {
          alert("User can't login in portal");
          // }
          this.isLoading = false;
        });
      this.isLoading = false;
      console.log(this.isLoading);
    },
  },
};
</script>
