<template>
  <v-main>
    <v-container fluid>
      <v-card elevation="6" rounded="xxl">
        <v-card-title class="mt-4">
          <v-btn class="mx-2" fab x-small color="primary" @click="$router.back()">
            <v-icon dark> mdi-arrow-left </v-icon> </v-btn>Add Permission
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="4">
              <v-autocomplete ref="module" v-model="form.module_id" :items="moduleItems" outlined label="Module *"
                prepend-inner-icon="mdi-account" :error-messages="errors.module_id"></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-text-field v-model="form.permission_name" outlined label="Name"
                prepend-inner-icon="mdi-card-text-outline" :error-messages="errors.permission_name"></v-text-field>
            </v-col>
          </v-row>
          <v-divider class="my-5"> </v-divider>
          <v-btn rounded color="primary" :loading="isLoading" :disabled="isLoading" @click="save">
            Save Permission
          </v-btn>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";
export default {
  name: "PermissionCreatePage",
  data() {
    return {
      form: {
        module_id: "",
        permission_name: "",
      },
      moduleItems: [],
      isLoading: false,
    };
  },
  mounted() {
    this.getMasters();
  },
  methods: {
    async getMasters() {
      await axios
        .get(`permissions/masters`)
        .then((masters) => {
          masters = masters.data;
          //Module
          masters.modules.forEach((module) => {
            this.moduleItems.push({
              id: module.id,
              text: module.name,
              value: module.id,
            });
          });
        })
        .catch(function (error) {
          console.log(error);
          // redirect to Home Page
          window.location.href = "/auth/login";
        });
    },
    async save() {
      try {
        this.isLoading = true;
        await axios.post("/permissions", this.form);
        this.isLoading = false;
        await this.saveUserTimestamp(null, this.form);
        this.$router.push("/permissions");
      } catch (e) {
        // alert("error");
        console.log(e);
        this.isLoading = false;
      }
    },
  },
};
</script>
