<template>
  <v-main>
    <v-container fluid>
      <v-card elevation="6">
        <v-card-title class="mt-4">
          <v-btn class="mr-2" fab x-small color="primary" @click="$router.back()">
            <v-icon dark> mdi-arrow-left </v-icon> </v-btn>Add Shift
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="4">
              <v-text-field v-model="form.shift_name" outlined label="Shift Name"
                prepend-inner-icon="mdi-card-text-outline"></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-menu ref="logintime" v-model="start_time_menu" :close-on-content-click="false" :nudge-right="40"
                transition="scale-transition" offset-y max-width="290px" min-width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="form.start_time" label="Start time" outlined
                    append-icon="mdi-clock-time-nine-outline" readonly v-bind="attrs" v-on="on" clearable
                    @click:clear="form.start_time = null"></v-text-field>
                </template>
                <v-time-picker v-if="start_time_menu" v-model="form.start_time" full-width
                  @click:minute="$refs.logintime.save(form.start_time)"></v-time-picker>
              </v-menu>
            </v-col>
            <v-col sm="4">
              <v-menu ref="logouttime" v-model="end_time_menu" :close-on-content-click="false" :nudge-right="40"
                transition="scale-transition" offset-y max-width="290px" min-width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="form.end_time" label="End time" outlined
                    append-icon="mdi-clock-time-nine-outline" readonly v-bind="attrs" v-on="on"
                    :disabled="form.start_time ? false : true" clearable
                    @click:clear="form.end_time = null"></v-text-field>
                </template>
                <v-time-picker v-if="end_time_menu" v-model="form.end_time" full-width
                  @click:minute="$refs.logouttime.save(form.end_time)"></v-time-picker>
              </v-menu>
            </v-col>
            <v-col sm="4">
              <v-text-field v-model="form.late_mark" outlined label="Late Mark After (Enter in Minutes)"
                prepend-inner-icon="mdi-card-text-outline" messages="(Enter in Minutes)"></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-menu ref="halfdaytime" v-model="half_day_time_menu" :close-on-content-click="false" :nudge-right="40"
                transition="scale-transition" offset-y max-width="290px" min-width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="form.half_day_before_time" label="Half Day Before Time" outlined
                    append-icon="mdi-clock-time-nine-outline" readonly v-bind="attrs" v-on="on" clearable
                    @click:clear="form.half_day_before_time = null"></v-text-field>
                </template>
                <v-time-picker v-if="half_day_time_menu" v-model="form.half_day_before_time" full-width @click:minute="
            $refs.halfdaytime.save(form.half_day_before_time)
            "></v-time-picker>
              </v-menu>
            </v-col>
            <v-col sm="4">
              <v-autocomplete ref="position" outlined v-model="form.week_off_days" :items="weekDays" multiple
                prepend-inner-icon="mdi-account-tie-outline" clearable placeholder="Select Week Off the Shift"
                :error-messages="errors.week_off_days" @change="SelectedWeekOff(form.week_off_days)"></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-autocomplete ref="position" outlined v-model="form.odd_week_off" :items="SelectedWeekOffDays"
                prepend-inner-icon="mdi-account-tie-outline" clearable placeholder="Odd Week Off"
                :error-messages="errors.odd_week_off"></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-autocomplete ref="position" outlined v-model="form.even_week_off" :items="SelectedWeekOffDays"
                prepend-inner-icon="mdi-account-tie-outline" clearable placeholder="Even Week Off"
                :error-messages="errors.even_week_off"></v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-divider class="mt-5 mb-5"> </v-divider>
              <v-btn class="float-end" color="primary" :loading="isLoading" :dark="!isLoading" :disabled="isLoading"
                @click="save">
                Save Shift
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";
export default {
  name: "UserCreatePage",
  data() {
    return {
      form: {
        start_time: "",
        end_time: "",
        half_day_before_time: "",
      },
      positionItems: [],
      weekDays: [],
      SelectedWeekOffDays: [],
      isLoading: false,
      date_menu: false,
      start_time_menu: false,
      end_time_menu: false,
      half_day_time_menu: false,
    };
  },
  mounted() {
    this.getMasters();
  },
  // watch: {
  //   'form.week_day_offs'(newVal) {
  //     if (newVal.length > 2) {
  //       this.errors.week_day_offs = 'You can only select up to 2 days.';
  //       // Revert the selection to the previous state
  //       this.form.week_day_offs = newVal.slice(0, 2);
  //     } else {
  //       this.errors.week_day_offs = '';
  //     }
  //   },
  // },
  methods: {
    async getMasters() {
      let masters = await axios.get(`shift_managements/masters`);
      masters = masters.data;
      // Employees
      masters.weekDays.forEach((week) => {
        this.weekDays.push({
          id: week.id,
          text: week.description,
          value: week.description,
        });
      });
    },

    async save() {
      try {
        this.isLoading = true;
        await axios.post("/shift_managements", this.form);
        await this.saveUserTimestamp(null, this.form);
        let PageModule = 'CREATE';
        let PageCode = 'SHIFT MANAGEMENTS';
        const userIP = await this.getUserIp();
        this.saveAuditTrial(userIP, PageModule, PageCode)
        this.isLoading = false;
        this.$router.push("/shift-managements");
      } catch (e) {
        // alert("error");
        this.isLoading = false;
      }
    },
    SelectedWeekOff(weekoff) {
      this.SelectedWeekOffDays = weekoff;
      console.log(this.SelectedWeekOffDays);
    }
  },
};
</script>
