<template>
  <v-main>
    <v-container fluid>
      <v-card elevation="6">
        <v-card-title class="mt-4">
          <v-btn class="mr-2" fab x-small color="primary" @click="$router.back()">
            <v-icon dark> mdi-arrow-left </v-icon> </v-btn>Add Task
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="4">
              <v-autocomplete ref="employee" v-model="form.assign_to_id" outlined :items="employees" label="Employee"
                append-icon="mdi-account" :error-messages="errors.assign_to_id" @input="UpdateUpline"></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-menu v-model="date_menu" :close-on-content-click="false" :nudge-right="40"
                transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="form.date" label="Date" append-icon="mdi-calendar" readonly outlined
                    v-bind="attrs" v-on="on" clearable @click:clear="form.date = null"
                    :error-messages="errors.date"></v-text-field>
                </template>
                <v-date-picker v-model="form.date" @change="date_menu = false"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col sm="4">
              <v-autocomplete v-model="form.status" :items="Status" outlined label="Status *"
                prepend-inner-icon="mdi-buffet" :error-messages="errors.status"></v-autocomplete>
            </v-col>
            <v-col cols="7">
              <v-textarea label="Write a Task" outlined v-model="form.task" :error-messages="errors.task">
              </v-textarea>
            </v-col>
            <v-col cols="12">
              <v-divider class="mt-5 mb-5"> </v-divider>

              <v-btn class="float-end" color="primary" :loading="isLoading" :dark="!isLoading" :disabled="isLoading"
                @click="save">
                Save Task
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";
export default {
  name: "UserCreatePage",
  data() {
    return {
      form: {
      },
      employees: [
        {
          id: 0,
          text: "Select Employee",
          value: "",
        },
      ],
      Status: [
        {
          text: "PENDING",
          value: "PENDING",
        },
        {
          text: "IN PROGRESS",
          value: "IN PROGRESS",
        },
        {
          text: "COMPLETED",
          value: "COMPLETED",
        },
      ],
      isLoading: false,
      date_menu: false,
    };
  },
  mounted() {
    this.getMasters();
  },
  methods: {
    async getMasters() {
      let masters = await axios.get(`user_attendances/masters`);
      masters = masters.data;
      // Employees
      masters.employees.forEach((employee) => {
        this.employees.push({
          id: employee.id,
          text: employee.full_name,
          position_id: employee.position_id,
          value: employee.id,
        });
      });
    },
    async save() {
      try {
        this.isLoading = true;
        await axios.post("/tasks", this.form);
        let PageModule = 'CREATE';
        let PageCode = 'TASKS';
        const userIP = await this.getUserIp();
        this.saveAuditTrial(userIP, PageModule, PageCode)
        this.isLoading = false;
        this.$router.back();
      } catch (e) {
        // alert("error");
        this.isLoading = false;
      }
    },
  },
};
</script>
