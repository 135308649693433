<template>
  <v-main>
    <v-container fluid>
      <v-row>
        <v-col md="12">
          <v-card elevation="6" rounded="xxl" style="background: #e7e7e7">
            <v-card-title>
              <h3
                class="font-weight-bold"
                :class="$vuetify.breakpoint.smAndDown ? 'text-h6' : ''"
                v-text="
                  isDeletePage() != true
                    ? 'User Attendances'
                    : 'Deleted user_attendances'
                "
              ></h3>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="WeeklyDialog = true"
                >View Weekly</v-btn
              >
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col xl="6" lg="6" md="6" sm="12" cols="12">
                  <v-text-field
                    solo
                    v-model="filter.search_keyword"
                    label="Search ... "
                    prepend-inner-icon="mdi-magnify"
                    clearable
                    @click:clear="
                      (filter.search_keyword = null), (page = 1), getData()
                    "
                    @keydown.enter="(page = 1), getData()"
                  ></v-text-field>
                </v-col>
                <v-col xl="3" lg="3" md="3" sm="12" cols="12">
                  <v-autocomplete
                    solo
                    ref="position"
                    v-model="filter.user_id"
                    :items="Employees"
                    label="Employees"
                    @input="page = 1"
                    prepend-inner-icon="mdi-account"
                    clearable
                  ></v-autocomplete>
                </v-col>
                <v-col xl="3" lg="3" md="3" sm="12" cols="12">
                  <v-autocomplete
                    ref="status"
                    solo
                    v-model="filter.is_active"
                    :items="statuses"
                    label="Status"
                    @input="page = 1"
                    prepend-inner-icon="mdi-toggle-switch"
                    clearable
                  ></v-autocomplete>
                </v-col>
                <v-col xl="2" lg="2" md="2" sm="6" cols="5">
                  <v-menu
                    v-model="month_menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="filter.month_year"
                        solo
                        label="Year - Month"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        clearable
                        @click:clear="filter.month_year = null"
                        :error-messages="errors.month"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="filter.month_year"
                      type="month"
                      @change="month_menu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col>
                  <v-btn color="primary" @click="getData()"
                    >Get Attendance</v-btn
                  >
                </v-col>
                <v-spacer></v-spacer>
                <v-col
                  cols="6"
                  v-if="user_attendances.length != 0"
                  class="py-0 px-0 d-flex align-center"
                >
                  <span class="mr-4">
                    <span
                      style="
                        background: indigo;
                        width: 10px;
                        height: 10px;
                        display: inline-block;
                        border-radius: 50%;
                        margin-right: 5px;
                      "
                    ></span>
                    Present
                  </span>
                  <span class="mr-4">
                    <span
                      style="
                        background: red;
                        width: 10px;
                        height: 10px;
                        display: inline-block;
                        border-radius: 50%;
                        margin-right: 5px;
                      "
                    ></span>
                    Absent
                  </span>
                  <span class="mr-4">
                    <span
                      style="
                        background: yellow;
                        width: 10px;
                        height: 10px;
                        display: inline-block;
                        border-radius: 50%;
                        margin-right: 5px;
                      "
                    ></span>
                    LEAVE
                  </span>
                  <span class="mr-4">
                    <span
                      style="
                        background: cyan;
                        width: 10px;
                        height: 10px;
                        display: inline-block;
                        border-radius: 50%;
                        margin-right: 5px;
                      "
                    ></span>
                    Half Day
                  </span>
                  <span class="mr-4">
                    <span
                      style="
                        background: green;
                        width: 10px;
                        height: 10px;
                        display: inline-block;
                        border-radius: 50%;
                        margin-right: 5px;
                      "
                    ></span>
                    Week Off
                  </span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col md="12">
          <v-card
            elevation="6"
            rounded="xxl"
            style="background: #e7e7e7"
            :height="user_attendances.length == 0 ? 485 : 'auto'"
          >
            <!-- <v-card-title>
                <v-btn-toggle v-model="buttons" color="deep-purple accent-3">
                  <v-btn value="left" @click="CardView('Table')">
                    <span class="hidden-sm-and-down">Table</span>
                  </v-btn>
                  <v-btn value="center" @click="CardView('DashBoard')">
                    <span class="hidden-sm-and-down">DashBoard</span>
                  </v-btn>
                </v-btn-toggle>
              </v-card-title> -->
            <v-dialog v-model="WeeklyDialog">
              <v-card>
                <v-card-title> Weekly Attendances </v-card-title>
                <v-simple-table fixed-header height="485" class="striped-table">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-start text-subtitle-2 font-weight-bold">
                          Sr No
                        </th>
                        <th class="text-start text-subtitle-2 font-weight-bold">
                          Status
                        </th>
                        <th class="text-start text-subtitle-2 font-weight-bold">
                          Date
                        </th>
                        <th class="text-start text-subtitle-2 font-weight-bold">
                          Login Time
                        </th>
                        <th class="text-start text-subtitle-2 font-weight-bold">
                          Logout Time
                        </th>
                        <th class="text-start text-subtitle-2 font-weight-bold">
                          Hour
                        </th>
                        <th class="text-start text-subtitle-2 font-weight-bold">
                          Session Type
                        </th>
                        <th class="text-start text-subtitle-2 font-weight-bold">
                          Supervisor
                        </th>
                      </tr>
                    </thead>
                    <tbody v-if="weekly_attendances.length != 0">
                      <tr
                        v-for="(user_attendance, i) in weekly_attendances"
                        :key="`user_attendance${i}`"
                      >
                        <td style="min-width: 85px">
                          {{ i + 1 }}
                        </td>
                        <td>
                          <v-tooltip
                            bottom
                            v-if="user_attendance.is_active == 1"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-chip
                                v-bind="attrs"
                                v-on="on"
                                small
                                color="teal"
                                text-color="white"
                              >
                                <v-avatar left>
                                  <v-icon size="15"
                                    >mdi-checkbox-marked-circle</v-icon
                                  >
                                </v-avatar>
                                Active
                              </v-chip>
                            </template>
                            <span>User attendance is Active</span>
                          </v-tooltip>
                          <v-tooltip
                            bottom
                            v-if="user_attendance.is_active == 0"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-chip
                                v-bind="attrs"
                                v-on="on"
                                small
                                color="red"
                                text-color="white"
                              >
                                <v-avatar left>
                                  <v-icon size="15">mdi-close-circle</v-icon>
                                </v-avatar>
                                Inactive
                              </v-chip>
                            </template>
                            <span>user_attendance is InActive</span>
                          </v-tooltip>
                        </td>
                        <td>{{ user_attendance.date || "" }}</td>
                        <td>{{ user_attendance.login_time || "" }}</td>
                        <td>{{ user_attendance.logout_time || "" }}</td>
                        <td>
                          {{
                            getLoginHour(
                              user_attendance.login_time,
                              user_attendance.logout_time
                            ) + " hours"
                          }}
                        </td>
                        <td>{{ user_attendance.session_type.code || "" }}</td>
                        <td>
                          {{
                            user_attendance.supervisor
                              ? user_attendance.supervisor.user_name
                              : ""
                          }}
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-else>
                      <tr>
                        <td colspan="12" class="text-center">No data found</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <v-pagination v-model="page" :length="paginationLength">
                </v-pagination>
              </v-card>
            </v-dialog>

            <v-card-text v-if="isDashBoard">
              <section
                v-if="user_attendances.length == 0 && !isLoading"
                align="center"
              >
                <span>No Data Select User and Month</span>
              </section>
              <v-col v-if="isLoading" align="center">
                <v-img :src="require('../../assets/loader.gif')" width="70" />
              </v-col>
              <section v-if="user_attendances.length != 0">
                <v-row>
                  <v-col
                    xl="8"
                    lg="8"
                    md="8"
                    sm="12"
                    cols="12"
                    style="height: 600px"
                    class="overflow-auto"
                  >
                    <v-row>
                      <v-col
                        class="px-0 py-0 d-flex justify-center"
                        xl="4"
                        lg="4"
                        md="4"
                        sm="12"
                        cols="12"
                      >
                        <apexchart
                          ref="chart"
                          width="400"
                          type="radialBar"
                          :options="chartOptions"
                          :series="series"
                        />
                      </v-col>
                      <v-col
                        xl="4"
                        lg="4"
                        md="4"
                        sm="12"
                        cols="12"
                        class="d-flex justify-center align-center"
                      >
                        <v-card
                          class="pa-2 customer-card-2 rounded-xl"
                          outlined
                          tile
                        >
                          <v-row
                            style="
                              justify-content: space-between;
                              align-items: center;
                              padding: 4px 16px;
                            "
                          >
                            <v-card-title
                              v-if="isLoading"
                              class="text-h5 title"
                            >
                              <v-progress-circular
                                indeterminate
                                color="#0b4bff"
                              ></v-progress-circular>
                            </v-card-title>
                            <v-card-title
                              v-else
                              class="text-h5 title"
                              v-text="workingDays"
                            ></v-card-title>
                            <v-col cols="4">
                              <v-img
                                contain
                                :src="
                                  require('../../assets/attendance_calender_dashboard.png')
                                "
                              />
                            </v-col>
                          </v-row>
                          <v-card-subtitle
                            class="font-weight-bold"
                            v-text="'Working'"
                          ></v-card-subtitle>
                        </v-card>
                      </v-col>
                      <v-col
                        xl="4"
                        lg="4"
                        md="4"
                        sm="12"
                        cols="12"
                        class="d-flex justify-center align-center"
                      >
                        <v-card
                          class="pa-2 customer-card-2 rounded-xl"
                          outlined
                          tile
                        >
                          <v-row
                            style="
                              justify-content: space-between;
                              align-items: center;
                              padding: 4px 16px;
                            "
                          >
                            <v-card-title
                              v-if="isLoading"
                              class="text-h5 title"
                            >
                              <v-progress-circular
                                indeterminate
                                color="#0b4bff"
                              ></v-progress-circular>
                            </v-card-title>
                            <v-card-title
                              v-else
                              class="text-h5 title"
                              v-text="presentCount"
                            ></v-card-title>
                            <v-col cols="4">
                              <v-img
                                contain
                                min-width="35"
                                :src="
                                  require('../../assets/attendance_dashboard_check.png')
                                "
                              />
                            </v-col>
                          </v-row>
                          <v-card-subtitle
                            class="font-weight-bold"
                            v-text="'Present'"
                          ></v-card-subtitle>
                        </v-card>
                      </v-col>
                      <v-col xl="4" lg="4" md="4" sm="12" cols="12">
                        <v-card
                          class="pa-2 customer-card-2 rounded-xl"
                          outlined
                          tile
                        >
                          <v-row
                            style="
                              justify-content: space-between;
                              align-items: center;
                              padding: 4px 16px;
                            "
                          >
                            <v-card-title
                              v-if="isLoading"
                              class="text-h5 title"
                            >
                              <v-progress-circular
                                indeterminate
                                color="#0b4bff"
                              ></v-progress-circular>
                            </v-card-title>
                            <v-card-title
                              v-else
                              class="text-h5 title"
                              v-text="absentCount"
                            ></v-card-title>
                            <v-col cols="4">
                              <v-img
                                contain
                                min-width="35"
                                :src="
                                  require('../../assets/attendance_dashboard_cross.png')
                                "
                              />
                            </v-col>
                          </v-row>
                          <v-card-subtitle
                            class="font-weight-bold"
                            v-text="'Absent Days'"
                          ></v-card-subtitle>
                        </v-card>
                      </v-col>
                      <v-col xl="4" lg="4" md="4" sm="12" cols="12">
                        <v-card
                          class="pa-2 customer-card-2 rounded-xl"
                          outlined
                          tile
                        >
                          <v-row
                            style="
                              justify-content: space-between;
                              align-items: center;
                              padding: 4px 16px;
                            "
                          >
                            <v-card-title
                              v-if="isLoading"
                              class="text-h5 title"
                            >
                              <v-progress-circular
                                indeterminate
                                color="#0b4bff"
                              ></v-progress-circular>
                            </v-card-title>
                            <v-card-title
                              v-else
                              class="text-h5 title"
                              v-text="RegularizationPending"
                            ></v-card-title>
                            <v-col cols="4">
                              <v-img
                                contain
                                min-width="35"
                                :src="
                                  require('../../assets/attendance_dashboard_pending.png')
                                "
                              />
                            </v-col>
                          </v-row>
                          <v-card-subtitle
                            class="font-weight-bold"
                            v-text="'Attendance Pending'"
                          ></v-card-subtitle>
                        </v-card>
                      </v-col>
                      <v-col xl="4" lg="4" md="4" sm="12" cols="12">
                        <v-card
                          class="pa-2 customer-card-2 rounded-xl"
                          outlined
                          tile
                        >
                          <v-row
                            style="
                              justify-content: space-between;
                              align-items: center;
                              padding: 4px 16px;
                            "
                          >
                            <v-card-title
                              v-if="isLoading"
                              class="text-h5 title"
                            >
                              <v-progress-circular
                                indeterminate
                                color="#0b4bff"
                              ></v-progress-circular>
                            </v-card-title>
                            <v-card-title
                              v-else
                              class="text-h5 title"
                              v-text="RegularizationRejected"
                            ></v-card-title>
                            <v-col cols="4">
                              <v-img
                                contain
                                min-width="35"
                                :src="
                                  require('../../assets/attendance_dashboard_cross.png')
                                "
                              />
                            </v-col>
                          </v-row>
                          <v-card-subtitle
                            class="font-weight-bold"
                            v-text="'Rejected Attendance'"
                          ></v-card-subtitle>
                        </v-card>
                      </v-col>
                      <v-col xl="4" lg="4" md="4" sm="12" cols="12">
                        <v-card
                          class="pa-2 customer-card-2 rounded-xl"
                          outlined
                          tile
                        >
                          <v-row
                            style="
                              justify-content: space-between;
                              align-items: center;
                              padding: 4px 16px;
                            "
                          >
                            <v-card-title
                              v-if="isLoading"
                              class="text-h5 title"
                            >
                              <v-progress-circular
                                indeterminate
                                color="#0b4bff"
                              ></v-progress-circular>
                            </v-card-title>
                            <v-card-title
                              v-else
                              class="text-h5 title"
                              v-text="isHalfDay"
                            ></v-card-title>
                            <v-col cols="4">
                              <v-img
                                contain
                                min-width="35"
                                :src="
                                  require('../../assets/attendance_dashboard_half_day.png')
                                "
                              />
                            </v-col>
                          </v-row>
                          <v-card-subtitle
                            class="font-weight-bold"
                            v-text="'Half Day Leave'"
                          ></v-card-subtitle>
                        </v-card>
                      </v-col>
                      <v-col xl="4" lg="4" md="4" sm="12" cols="12">
                        <v-card
                          class="pa-2 customer-card-2 rounded-xl"
                          outlined
                          tile
                        >
                          <v-row
                            style="
                              justify-content: space-between;
                              align-items: center;
                              padding: 4px 16px;
                            "
                          >
                            <v-card-title
                              v-if="isLoading"
                              class="text-h5 title"
                            >
                              <v-progress-circular
                                indeterminate
                                color="#0b4bff"
                              ></v-progress-circular>
                            </v-card-title>
                            <v-card-title
                              v-else
                              class="text-h5 title"
                              v-text="LateIn"
                            ></v-card-title>
                            <v-col cols="4">
                              <v-img
                                contain
                                min-width="35"
                                :src="
                                  require('../../assets/attendance_dashboard_late_in.png')
                                "
                              />
                            </v-col>
                          </v-row>
                          <v-card-subtitle
                            class="font-weight-bold"
                            v-text="'Late in'"
                          ></v-card-subtitle>
                        </v-card>
                      </v-col>
                      <v-col xl="4" lg="4" md="4" sm="12" cols="12">
                        <v-card
                          class="pa-2 customer-card-2 rounded-xl"
                          outlined
                          tile
                        >
                          <v-row
                            style="
                              justify-content: space-between;
                              align-items: center;
                              padding: 4px 16px;
                            "
                          >
                            <v-card-title
                              v-if="isLoading"
                              class="text-h5 title"
                            >
                              <v-progress-circular
                                indeterminate
                                color="#0b4bff"
                              ></v-progress-circular>
                            </v-card-title>
                            <v-card-title
                              v-else
                              class="text-h5 title"
                              v-text="EarlyOut"
                            ></v-card-title>
                            <v-col cols="4">
                              <v-img
                                contain
                                min-width="35"
                                :src="
                                  require('../../assets/attendance_dashboard_early_out.png')
                                "
                              />
                            </v-col>
                          </v-row>
                          <v-card-subtitle
                            class="font-weight-bold"
                            v-text="'Early Out'"
                          ></v-card-subtitle>
                        </v-card>
                      </v-col>
                      <v-col xl="4" lg="4" md="4" sm="12" cols="12">
                        <v-card
                          class="pa-2 customer-card-2 rounded-xl"
                          outlined
                          tile
                        >
                          <v-row
                            style="
                              justify-content: space-between;
                              align-items: center;
                              padding: 4px 16px;
                            "
                          >
                            <v-card-title
                              v-if="isLoading"
                              class="text-h5 title"
                            >
                              <v-progress-circular
                                indeterminate
                                color="#0b4bff"
                              ></v-progress-circular>
                            </v-card-title>
                            <v-card-title
                              v-else
                              class="text-h5 title"
                              v-text="Holidays"
                            ></v-card-title>
                            <v-col cols="4">
                              <v-img
                                contain
                                min-width="35"
                                :src="
                                  require('../../assets/attendance_dashboard_holiday.png')
                                "
                              />
                            </v-col>
                          </v-row>
                          <v-card-subtitle
                            class="font-weight-bold"
                            v-text="'Holidays'"
                          ></v-card-subtitle>
                        </v-card>
                      </v-col>
                      <v-col xl="4" lg="4" md="4" sm="12" cols="12">
                        <v-card
                          class="pa-2 customer-card-2 rounded-xl"
                          outlined
                          tile
                        >
                          <v-row
                            style="
                              justify-content: space-between;
                              align-items: center;
                              padding: 4px 16px;
                            "
                          >
                            <v-card-title
                              v-if="isLoading"
                              class="text-h5 title"
                            >
                              <v-progress-circular
                                indeterminate
                                color="#0b4bff"
                              ></v-progress-circular>
                            </v-card-title>
                            <v-card-title
                              v-else
                              class="text-h5 title"
                              v-text="PunchOutMissing"
                            ></v-card-title>
                            <v-col cols="4">
                              <v-img
                                contain
                                min-width="35"
                                :src="
                                  require('../../assets/attendance_dashboard_punchout_missing.png')
                                "
                              />
                            </v-col>
                          </v-row>
                          <v-card-subtitle
                            class="font-weight-bold"
                            v-text="'Punch Out Missing'"
                          ></v-card-subtitle>
                        </v-card>
                      </v-col>
                      <v-col xl="4" lg="4" md="4" sm="12" cols="12">
                        <v-card
                          class="pa-2 customer-card-2 rounded-xl"
                          outlined
                          tile
                        >
                          <v-row
                            style="
                              justify-content: space-between;
                              align-items: center;
                              padding: 4px 16px;
                            "
                          >
                            <v-card-title
                              v-if="isLoading"
                              class="text-h5 title"
                            >
                              <v-progress-circular
                                indeterminate
                                color="#0b4bff"
                              ></v-progress-circular>
                            </v-card-title>
                            <v-card-title
                              v-else
                              class="text-h5 title"
                              v-text="FullDayLeave"
                            ></v-card-title>
                            <v-col cols="4">
                              <v-img
                                contain
                                min-width="35"
                                :src="
                                  require('../../assets/attendance_dashboard_full_leave.png')
                                "
                              />
                            </v-col>
                          </v-row>
                          <v-card-subtitle
                            class="font-weight-bold"
                            v-text="'Full Day Leave'"
                          ></v-card-subtitle>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col
                    xl="4"
                    lg="4"
                    md="4"
                    sm="12"
                    cols="12"
                    style="height: 530px"
                  >
                    <v-calendar
                      ref="calendar"
                      v-model="focus"
                      color="primary"
                      :events="events"
                      class="rounded-xl"
                      :event-color="getEventColor"
                      :type="type"
                      @click:event="showEvent"
                      @click:more="viewDay"
                    >
                      <template v-slot:day="{ day, outside, attrs }">
                        <div v-bind="attrs" v-show="!outside">
                          <span>{{ day.day }}</span>
                        </div>
                      </template>
                    </v-calendar>
                  </v-col>
                </v-row>
              </section>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- Clear Success Dialog Box -->
      <v-dialog v-model="is_deleted_user_attendance" width="600">
        <v-card rounded="xxl">
          <v-container>
            <v-row align="center">
              <v-col sm="3" align="center">
                <v-icon
                  large
                  style="color: green; font-size: 90px; width: 200px !important"
                >
                  mdi-delete-empty
                </v-icon>
              </v-col>
              <v-col sm="9" align="center">
                <v-card-text>
                  <v-row>
                    <v-col>
                      <h1 style="color: green">Hurray!</h1>
                      <br />
                      <h3>user_attendance deleted successfully.</h3>
                      <br />
                      <v-btn
                        color="success"
                        @click="is_deleted_user_attendance = false"
                      >
                        OK
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
      <!-- Restore Success Dialog Box -->
      <v-dialog v-model="is_restored_user_attendance" width="600">
        <v-card rounded="xxl">
          <v-container>
            <v-row align="center">
              <v-col sm="3" align="center">
                <v-icon
                  large
                  style="color: green; font-size: 90px; width: 200px !important"
                >
                  mdi-delete-restore
                </v-icon>
              </v-col>
              <v-col sm="9" align="center">
                <v-card-text>
                  <v-row>
                    <v-col>
                      <h1 style="color: green">Hurray!</h1>
                      <br />
                      <h3>user_attendance restored successfully.</h3>
                      <br />
                      <v-btn
                        color="success"
                        @click="is_restored_user_attendance = false"
                      >
                        OK
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
import VueApexCharts from "vue3-apexcharts";

export default {
  name: "user_attendancesPage",
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      filter: {
        active: "",
        supervisor_id: "",
        role_id: "",
        position_id: "",
        show_deleted: false,
        month_year: "",
        month: "",
        user_id: "",
        year: "",
      },
      page: 1,
      serialNoStarting: 1,
      paginationLength: 0,
      count: 0,
      workingDays: 0,
      presentCount: 0,
      absentCount: 0,
      RegularizationPending: 0,
      isHalfDay: 0,
      LateIn: 0,
      EarlyOut: 0,
      totalHoursForMonth: 0,
      totalHoursWorked: 0,
      PunchOutMissing: 0,
      FullDayLeave: 0,
      Holidays: 0,
      is_deleted_user_attendance: false,
      RegularizationRejected: 0,
      restore_user_attendance: false,
      is_restored_user_attendance: false,
      month_menu: false,

      user_attendances: [],
      statuses: [
        {
          id: 0,
          text: "Select Active Status",
          value: "",
        },
        { id: "ACTIVE", text: "ACTIVE", value: "1" },
        { id: "INACTIVE", text: "INACTIVE", value: "0" },
      ],
      Employees: [
        {
          id: 0,
          text: "Select Employees",
          value: "",
        },
      ],
      isLoading: false,
      isExcelloading: false,
      isTable: false,
      isDashBoard: false,
      weekly_attendances: [],
      Exportuser_attendances: [],
      Export_user_attendances: [],
      excel_fields: {
        "Sr No": "sr_no",
        Name: "name",
        Email: "email",
        Phone: "phone",
        Supervisor: "supervisor",
        "Is Active": "status",
      },
      series: [],
      chartOptions: {
        chart: {
          type: "radialBar",
        },
        labels: [],
      },
      focus: new Date().toISOString().substr(0, 10),
      type: "month",
      typeToLabel: {
        month: "Month",
        week: "Week",
        day: "Day",
        "4day": "4 Days",
      },
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      WeeklyDialog: false,
      events: [],
      names: ["Present", "Absent", "Half Day", "Leave"],
    };
  },
  watch: {
    totalHoursForMonth(newVal) {
      this.chartOptions.labels = [];
      this.chartOptions.labels.push(`Total Hours: ${newVal}`);
      // Force re-render of the chart options to ensure the changes are picked up
      this.chartOptions = { ...this.chartOptions };
    },
    page: "getData",
    $route() {
      this.getData();
    },
  },
  mounted() {
    this.isDashBoard = true;
    this.getMasters();
  },
  methods: {
    async getMasters() {
      await axios
        .get(`user_attendances/masters`)
        .then((masters) => {
          masters = masters.data;
          //Position
          masters.employees.forEach((employe) => {
            this.Employees.push({
              id: employe.id,
              text: employe.full_name,
              value: employe.id,
            });
          });
        })
        .catch(function (error) {
          console.log(error);
          // window.location.href = "/auth/login";
        });
    },
    async getData() {
      console.log("Get Data");
      this.user_attendances = [];
      this.filter.show_deleted = this.$route.query.open_trash ? 1 : 0;
      this.isLoading = true;
      this.filter.search_keyword = this.filter.search_keyword
        ? this.filter.search_keyword
        : "";
      this.filter.user_id = this.filter.user_id ? this.filter.user_id : "";
      if (this.filter.month_year) {
        this.filter.year = this.filter.month_year.split("-")[0];
        this.filter.month = this.filter.month_year.split("-")[1];
        this.updateCalendarFocus(this.filter.year, this.filter.month);
      }
      this.filter.user_id = this.filter.user_id ? this.filter.user_id : "";
      this.filter.is_active = this.filter.is_active
        ? this.filter.is_active
        : "";
      let query =
        "page=" +
        this.page +
        "&rowsPerPage=" +
        this.rowsPerPage +
        "&userId=" +
        this.filter.user_id +
        "&month=" +
        this.filter.month +
        "&year=" +
        this.filter.year +
        "&role_id=3" +
        "&isAttendanceDashBoard=true" +
        "&isAttendance=true" +
        "&show_deleted=" +
        this.filter.show_deleted +
        "&userId=" +
        this.filter.user_id +
        "&is_active=" +
        this.filter.is_active +
        "&search_keyword=" +
        this.filter.search_keyword;
      await axios
        .get(`user_attendances?${query}`)
        .then((response) => {
          this.workingDays = response.data.workingDays;
          this.presentCount = response.data.presentCount;
          this.absentCount = response.data.absentCount;
          this.RegularizationPending = response.data.RegularizationPending;
          this.RegularizationRejected = response.data.RegularizationRejected;
          this.isHalfDay = response.data.isHalfDay;
          this.LateIn = response.data.LateIn;
          this.EarlyOut = response.data.EarlyOut;
          this.PunchOutMissing = response.data.PunchOutMissing;
          this.FullDayLeave = response.data.FullDayLeave;
          this.Holidays = response.data.Holidays;
          this.totalHoursForMonth = response.data.totalHoursForMonth;
          this.totalHoursWorked = response.data.totalHoursWorked;
          this.user_attendances = response.data.data;
          this.series = [];
          this.series.push(response.data.totalHoursWorkedpercentage);
          // Update the entire chartOptions object
          const events = [];
          const today = new Date();
          const startOfWeek = new Date(today);
          startOfWeek.setDate(today.getDate() - today.getDay());
          const endOfWeek = new Date(today);
          endOfWeek.setDate(today.getDate() - today.getDay() + 6);

          // Filter the user_attendances to include only the current week's data
          this.weekly_attendances = this.user_attendances.filter(
            (attendance) => {
              const attendanceDate = new Date(attendance.date);
              return (
                attendanceDate >= startOfWeek && attendanceDate <= endOfWeek
              );
            }
          );
          let firstDayOfMonth = null;
          if (this.filter.year != null && this.filter.month != null) {
            firstDayOfMonth = new Date(
              this.filter.year,
              this.filter.month - 1,
              1
            );
          } else {
            firstDayOfMonth = new Date(
              today.getFullYear(),
              today.getMonth(),
              1
            );
          }
          const userShift = this.user_attendances[0]?.user?.shift || {}; // Ensure shift exists
          const weekOffDays = userShift.week_off_days || [];
          console.log("weekOffDays", weekOffDays);
          const oddWeekOff = userShift.odd_week_off || [];
          const evenWeekOff = userShift.even_week_off || [];
          // Map week days to corresponding numbers
          const dayMap = {
            SUNDAY: 0,
            MONDAY: 1,
            TUESDAY: 2,
            WEDNESDAY: 3,
            THURSDAY: 4,
            FRIDAY: 5,
            SATURDAY: 6,
          };
          // Convert week off days to numbers for comparison
          // const weekOffDaysNumbers = weekOffDaysToUse.map(day => dayMap[day]);

          // Iterate through all dates from the start of the month to the current date
          for (
            let date = new Date(firstDayOfMonth);
            date <= today;
            date.setDate(date.getDate() + 1)
          ) {
            const dayOfWeek = date.getDay();
            const dayName = Object.keys(dayMap).find(
              (key) => dayMap[key] === dayOfWeek
            );
            const weekNumber = Math.ceil(date.getDate() / 7);
            const isOddWeek = weekNumber % 2 == 1;
            console.log(isOddWeek);
            let isHoliday = false;
            if (isOddWeek && oddWeekOff.includes(dayName)) {
              isHoliday = true;
            } else if (!isOddWeek && evenWeekOff.includes(dayName)) {
              isHoliday = true;
            } else if (
              (oddWeekOff.length == 0 &&
              evenWeekOff.length == 0) &&
              weekOffDays.includes(dayName)
            ) {
              console.log("single week off");
              isHoliday = true;
            }
            const isLeave = this.user_attendances.some(
              (item) =>
                new Date(item.date).getDate() === date.getDate() &&
                item.session_type.description == "LEAVE"
            );

            if (isLeave) {
              continue; // Skip this date if it's a leave day
            }

            const isAbsent = !this.user_attendances.some(
              (item) => new Date(item.date).getDate() === date.getDate()
            );
            let color = "red";

            if (isAbsent) {
              color = "red"; // If absent, set color to purple
            }
            if (isHoliday) {
              color = "green"; // If it's a holiday, set color to green
            }
            if (isAbsent) {
              events.push({
                name: "Absent",
                start: new Date(date),
                end: new Date(date),
                color: color,
                timed: true,
              });
            }
          }
          this.user_attendances.forEach((item) => {
            const name = item.user.full_name; // Assuming you want the user's full name
            const startDateTime = `${item.date}T${item.login_time}`;
            const endDateTime = `${item.date}T${item.logout_time}`;
            const start = new Date(startDateTime);
            const end = new Date(endDateTime);
            let color;
            // Example condition: if the user's name contains "testuser", set the color to "indigo", otherwise, set it to "blue"
            if (
              item.session_type.description == "PRESENT" &&
              item.is_half_day == false
            ) {
              console.log("entered present");
              color = "indigo";
            } else if (item.session_type.description == "LEAVE") {
              color = "yellow";
            } else if (item.session_type.description == "ABSENT") {
              color = "red";
            } else if (item.is_half_day == true) {
              color = "cyan";
            }
            // Push the extracted data as an event
            events.push({
              name: name,
              start: start,
              end: end,
              color: color,
              timed: true,
            });

            // Check if leave attendance has a range of dates and is approved
            if (
              item.leave_from_date &&
              item.leave_to_date &&
              item.is_leave_approved &&
              new Date(item.leave_from_date) <= today &&
              new Date(item.leave_to_date) >= firstDayOfMonth
            ) {
              events.push({
                name: "Approved Leave",
                start: new Date(item.leave_from_date),
                end: new Date(item.leave_to_date),
                color: "yellow", // Change color as needed
                timed: true,
              });
            }
          });

          this.events = events;

          this.count = response.data.count;
          this.serialNoStarting = (this.page - 1) * this.rowsPerPage;
          this.paginationLength = Math.ceil(this.count / this.rowsPerPage);
        })
        .catch(function (error) {
          console.log(error);
          // window.location.href = "/auth/login";
        });
      this.isLoading = false;
    },
    updateChartDataLabels(newLabels) {
      this.chartOptions.labels = newLabels;
      this.$refs.chart.updateOptions({
        labels: newLabels,
      });
    },
    CardView(view) {
      if (view == "Table") {
        this.isDashBoard = false;
        this.isTable = true;
      }
      if (view == "DashBoard") {
        this.isTable = false;
        this.isDashBoard = true;
      }
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = "";
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    getLoginHour(loginTime, logoutTime) {
      if (!loginTime || !logoutTime) {
        return "";
      }
      const [loginHours, loginMinutes] = loginTime.split(":").map(Number);
      const [logoutHours, logoutMinutes] = logoutTime.split(":").map(Number);
      const loginDate = new Date();
      loginDate.setHours(loginHours, loginMinutes, 0, 0);
      const logoutDate = new Date();
      logoutDate.setHours(logoutHours, logoutMinutes, 0, 0);
      const diff = logoutDate - loginDate;
      const hours = (diff / (1000 * 60 * 60)).toFixed(2); // Convert to hours and round to 2 decimal places
      return hours;
    },
    async Delete_this(id) {
      this.delete_user_attendance = true;
      await axios
        .post(`/user_attendances/delete/${this.encryptIt(id)}`)
        .then(() => {
          this.delete_user_attendance = false;
          this.is_deleted_user_attendance = true;
          this.getData();
        })
        .catch(function (error) {
          console.log(error);
          window.location.href = "/auth/login";
        });
    },
    async addToExcelData() {
      this.isExcelLoading = true;
      this.title = "Company Lists";
      let Export_user_attendances = [];
      this.search_keyword = this.search_keyword ? this.search_keyword : "";
      let query =
        "search_keyword=" +
        this.search_keyword +
        "&is_active=" +
        this.filter.active +
        "&roleId=" +
        this.filter.role_id;
      Export_user_attendances = await axios.get(`user_attendances?${query}`);
      this.Export_user_attendances = Export_user_attendances.data.data;

      this.Export_user_attendances.forEach((user_attendance) => {
        this.Exportuser_attendances.push({
          sr_no: user_attendance.sr_no,
          name: user_attendance.name ? user_attendance.name.toUpperCase() : "",
          email: user_attendance.email
            ? user_attendance.email.toUpperCase()
            : "",
          phone: user_attendance.phone ? user_attendance.phone : "",

          supervisor: user_attendance.supervisor_id
            ? user_attendance.supervisor.name.toUpperCase()
            : "",
          status: user_attendance.active == 1 ? "YES" : "NO",
        });
      });
      this.isExcelLoading = false;
    },
    updateCalendarFocus(year, month) {
      // This method sets the 'focus' date to the first day of the selected year-month.
      this.focus = `${year}-${month}-01`;
    },
    async generateExcel() {
      this.isExcelLoading = true;
      this.filter.search_keyword = this.filter.search_keyword
        ? this.filter.search_keyword
        : "";
      this.filter.is_active = this.filter.is_active
        ? this.filter.is_active
        : "";
      this.filter.user_id = this.filter.user_id ? this.filter.user_id : "";
      let response = [];
      let query =
        "role_id=3" +
        "&companyId=" +
        this.company.id +
        "&is_active=" +
        this.filter.is_active +
        "&user_id=" +
        this.filter.user_id +
        "&search_keyword=" +
        this.filter.search_keyword;
      response = await axios
        .get(`/user_attendances/excel_export?${query}`)
        .then((response) => window.open(`${response.data.data}`, "_blank"));
      if (response) {
        console.log(response);
      }
      this.isExcelloading = false;
    },
    isDeletePage() {
      let response = false;
      if (this.$route.query.open_trash) {
        response = true;
      }
      return response;
    },
    async Restore_this(id) {
      this.restore_user_attendance = true;
      await axios
        .post(`/user_attendances/restore/${this.encryptIt(id)}`)
        .then(() => {
          this.restore_user_attendance = false;
          this.is_restored_user_attendance = true;
          this.getData();
        })
        .catch(function (error) {
          console.log(error);
          window.location.href = "/auth/login";
        });
    },
    dayClick(day) {
      console.log(day);
      // Handle day click event here
    },
  },
};
</script>
<style>
.v-outside {
  visibility: hidden !important;
}
</style>
