<template>
  <v-main>
    <v-container fluid>
      <v-card elevation="6">
        <v-card-title class="mt-4 ">
          <v-btn class="mr-2" fab x-small color="primary" @click="$router.back()">
            <v-icon dark> mdi-arrow-left </v-icon> </v-btn>Add Regularization
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-row>
                <v-col sm="4">
                  <v-autocomplete outlined ref="employee" v-model="form.user_id" :items="employees" label="Employee"
                    append-icon="mdi-account" :error-messages="errors.user_id"></v-autocomplete>
                </v-col>
                <v-col sm="4">
                  <v-menu v-model="date_menu" :close-on-content-click="false" :nudge-right="40"
                    transition="scale-transition" offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field outlined v-model="form.date" label="Date" append-icon="mdi-calendar" readonly
                        v-bind="attrs" v-on="on" clearable @click:clear="form.date = null"
                        :error-messages="errors.date"></v-text-field>
                    </template>
                    <v-date-picker v-model="form.date" @change="date_menu = false"></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-col>
            <v-col sm="4">
              <v-menu ref="login time" v-model="login_time_menu" :close-on-content-click="false" :nudge-right="40"
                :return-value.sync="time" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field outlined v-model="form.login_time" label="Login time"
                    append-icon="mdi-clock-time-nine-outline" readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-time-picker v-if="login_time_menu" v-model="form.login_time" full-width
                  @click:minute="$refs.menu.save(time)"></v-time-picker>
              </v-menu>
            </v-col>
            <v-col sm="4">
              <v-menu ref="logout time" v-model="logout_time_menu" :close-on-content-click="false" :nudge-right="40"
                :return-value.sync="time" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field outlined v-model="form.logout_time" label="Logout time"
                    append-icon="mdi-clock-time-nine-outline" readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-time-picker v-if="logout_time_menu" v-model="form.logout_time" full-width
                  @click:minute="$refs.menu.save(time)"></v-time-picker>
              </v-menu>
            </v-col>
            <v-col sm="4">
              <v-autocomplete outlined ref="session type" v-model="form.session_type_id" :items="sessionTypes"
                label="Session Type" append-icon="mdi-account"
                :error-messages="errors.session_type_id"></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-autocomplete outlined ref="session type" v-model="form.remarks" :items="regularizationReasonTypes"
                label="Remark" append-icon="mdi-card-text-outline"
                :error-messages="errors.session_type_id"></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-switch v-model="form.is_regularization_approve" inset :label="`Is Approved: ${form.is_regularization_approve == 1 ? 'YES' : 'NO'
            }`"></v-switch>
            </v-col>
          </v-row>
          <v-divider class="mt-5 mb-5"> </v-divider>

          <v-progress-circular v-if="isLoading" indeterminate color="primary"></v-progress-circular>
          <v-btn v-else color="primary" dark @click="save">
            Update User Regularisations
          </v-btn>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";
export default {
  name: "UserEditPage",
  data() {
    return {
      form: {
        is_regularization_approve: false,
      },
      employees: [
        {
          id: 0,
          text: "Select Employee",
          value: "",
        },
      ],
      sessionTypes: [
        {
          id: 0,
          text: "Select Session Type",
          value: "",
        },
      ],
      supervisors: [
        {
          id: 0,
          text: "Select Supervisors",
          value: "",
        },
      ],
      regularizationReasonTypes: [
        {
          id: 0,
          text: "Select Regularization Type",
          value: "",
        },
      ],
      isLoading: false,
      date_menu: false,
      login_time_menu: false,
      logout_time_menu: false,
    };
  },
  mounted() {
    this.getData();
    this.getMasters();
  },
  methods: {
    async getMasters() {
      let masters = await axios.get(`user_attendances/masters`);
      masters = masters.data;
      // Employees
      masters.employees.forEach((employee) => {
        this.employees.push({
          id: employee.id,
          text: employee.full_name,
          value: employee.id,
        });
      });
      // Session Types
      masters.sessionTypes.forEach((sessionType) => {
        this.sessionTypes.push({
          id: sessionType.id,
          text: sessionType.code,
          value: sessionType.id,
        });
      });
      // User regularizationTypes
      masters.regularizationReasonTypes.forEach((Regularization) => {
        this.regularizationReasonTypes.push({
          id: Regularization.id,
          text: Regularization.code,
          value: Regularization.code,
        });
      });
    },
    async getData() {
      this.isLoading = true;
      let encryptedId = this.$route.params.userRegularizationId;
      let form = await axios.get(
        `/user_attendances/${encryptedId}`
      );
      this.form = form.data.data;
      this.old_json = form.data.data;
      this.isLoading = false;
    },
    async save() {
      try {
        this.isLoading = true;
        console.log(this.form);
        let encryptedId = this.$route.params.userRegularizationId;
        await axios.patch(
          `/user_attendances/${encryptedId}`,
          this.form
        );
        this.isLoading = false;
        await this.saveUserTimestamp(this.old_json, this.form);
        let PageModule = 'UPDATED';
        let PageCode = 'REGULARIZATOINS';
        const userIP = await this.getUserIp();
        this.saveAuditTrial(userIP, PageModule, PageCode)
        this.$router.push("/user-regularizations");
      } catch (e) {
        this.isLoading = false;
      }
    },
  },
};
</script>
