<template>
  <v-main>
    <v-container fluid>
      <v-row>
        <v-col md="12">
          <v-card elevation="6" rounded="xxl">
            <v-card-title>
              <h3
                class="font-weight-bold"
                v-text="isDeletePage() != true ? '' : 'Deleted Users'"
              ></h3>
              <section v-if="isDeletePage() != true">
                <v-btn class="mx-2 mb-2" color="primary" to="/users/create">
                  Users <v-icon dark> mdi-plus </v-icon>
                </v-btn>
                <v-btn
                  class="mx-2 mb-2"
                  fab
                  x-small
                  color="primary"
                  to="/users/upload"
                >
                  <v-icon dark> mdi-upload </v-icon>
                </v-btn>
              </section>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col md="6">
                  <v-text-field
                    solo
                    rounded
                    v-model="filter.search_keyword"
                    label="Search ... "
                    prepend-inner-icon="mdi-magnify"
                    clearable
                    @click:clear="
                      (filter.search_keyword = null), (page = 1), getData()
                    "
                    @keydown.enter="(page = 1), getData()"
                  ></v-text-field>
                </v-col>
                <v-col sm="3">
                  <v-autocomplete
                    solo
                    rounded
                    ref="position"
                    v-model="filter.position_id"
                    :items="positions"
                    label="Position"
                    @input="(page = 1), getData()"
                    prepend-inner-icon="mdi-account"
                    clearable
                  ></v-autocomplete>
                </v-col>
                <v-col sm="3">
                  <v-autocomplete
                    ref="status"
                    solo
                    rounded
                    v-model="filter.is_active"
                    :items="statuses"
                    label="Status"
                    @input="(page = 1), getData()"
                    prepend-inner-icon="mdi-toggle-switch"
                    clearable
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col md="12">
          <v-card elevation="6">
            <!-- <v-card-title v-if="users.length != 0">
              <v-spacer></v-spacer>
              <v-btn rounded class="primary mx-4" @click="generateExcel()" v-if="isDeletePage() != true">
                <span v-if="isExcelloading"> Generating Excel... </span>
                <span v-else>Generate Excel</span>
              </v-btn>
            </v-card-title> -->
            <v-card-text>
              <section v-if="isLoading" style="height: 560px">
                <v-progress-linear
                  indeterminate
                  color="primary"
                ></v-progress-linear>
              </section>
              <v-simple-table
                v-else
                fixed-header
                height="560"
                class="striped-table"
              >
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th
                        style="min-width: 150px"
                        class="text-start text-subtitle-2 font-weight-bold"
                      >
                        Sr No
                      </th>
                      <!-- <th style="min-width: 150px" class="text-start font-weight-bold">Status</th> -->
                      <th
                        style="min-width: 150px"
                        class="text-start text-subtitle-2 font-weight-bold"
                      >
                        Employee Code
                      </th>
                      <th
                        style="min-width: 150px"
                        class="text-start text-subtitle-2 font-weight-bold"
                      >
                        Employee Name
                      </th>
                      <th
                        style="min-width: 150px"
                        class="text-start text-subtitle-2 font-weight-bold"
                      >
                        Email
                      </th>
                      <th
                        style="min-width: 150px"
                        class="text-start text-subtitle-2 font-weight-bold"
                      >
                        Phone
                      </th>
                      <th
                        style="min-width: 150px"
                        class="text-start text-subtitle-2 font-weight-bold"
                      >
                        Position
                      </th>
                      <th
                        style="min-width: 150px"
                        class="text-start text-subtitle-2 font-weight-bold"
                      >
                        Branch
                      </th>
                      <th
                        style="min-width: 150px"
                        class="text-start text-subtitle-2 font-weight-bold"
                      >
                        Department
                      </th>
                      <th
                        style="min-width: 150px"
                        class="text-start text-subtitle-2 font-weight-bold"
                      >
                        Grade
                      </th>
                      <th
                        style="min-width: 150px"
                        class="text-start text-subtitle-2 font-weight-bold"
                      >
                        Designation
                      </th>
                      <th
                        style="min-width: 150px"
                        class="text-start text-subtitle-2 font-weight-bold"
                      >
                        Date Of Joining
                      </th>
                      <th
                        style="min-width: 150px"
                        class="text-start text-subtitle-2 font-weight-bold"
                      >
                        Date Of Exit
                      </th>
                      <th
                        style="min-width: 400px"
                        class="text-center text-subtitle-2 font-weight-bold action_th"
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody v-if="users.length != 0">
                    <tr v-for="(user, i) in users" :key="`user${i}`">
                      <td
                        style="min-width: 85px"
                        :style="{ color: user.is_active ? '' : 'red' }"
                      >
                        {{ i + 1 }}
                      </td>
                      <td :style="{ color: user.is_active ? '' : 'red' }">
                        {{ user.employee_code || "" }}
                      </td>
                      <td :style="{ color: user.is_active ? '' : 'red' }">
                        {{ user.full_name || "" }}
                      </td>
                      <td :style="{ color: user.is_active ? '' : 'red' }">
                        {{ user.email || "" }}
                      </td>
                      <td :style="{ color: user.is_active ? '' : 'red' }">
                        {{ user.phone || "" }}
                      </td>
                      <!-- <td>{{ user.employee_code || "" }}</td> -->
                      <td :style="{ color: user.is_active ? '' : 'red' }">
                        {{ user.position_name ? user.position_name : "" }}
                      </td>
                      <td :style="{ color: user.is_active ? '' : 'red' }">
                        {{ (user.branch || "").toLowerCase() }}
                      </td>
                      <td :style="{ color: user.is_active ? '' : 'red' }">
                        {{ (user.department || "").toLowerCase() }}
                      </td>
                      <td :style="{ color: user.is_active ? '' : 'red' }">
                        {{
                          (user.grade
                            ? user.grade.description
                            : ""
                          ).toLowerCase()
                        }}
                      </td>
                      <td :style="{ color: user.is_active ? '' : 'red' }">
                        {{ (user.designation || "").toLowerCase() }}
                      </td>
                      <td :style="{ color: user.is_active ? '' : 'red' }">
                        {{ user.doj || "" }}
                      </td>
                      <td :style="{ color: user.is_active ? '' : 'red' }">
                        {{ user.doe || "" }}
                      </td>

                      <td class="text-center"  style="min-width: 400px">
                        <template v-if="isDeletePage() != true">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-bind="attrs"
                                v-on="on"
                                fab
                                x-small
                                class="my-auto"
                                color="error"
                                @click="Delete_this(user.id)"
                              >
                                <v-icon dark> mdi-delete </v-icon>
                              </v-btn>
                            </template>
                            <span>Delete this user</span>
                          </v-tooltip>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-bind="attrs"
                                v-on="on"
                                fab
                                x-small
                                class="ml-2"
                                color="primary"
                                :to="`/salaries/${encryptIt(user.id)}`"
                              >
                                <v-icon dark>mdi-cash</v-icon>
                              </v-btn>
                            </template>
                            <span>Salary Slip</span>
                          </v-tooltip>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-bind="attrs"
                                v-on="on"
                                fab
                                x-small
                                class="ml-2"
                                color="primary"
                                :to="`/users/${encryptIt(
                                  user.id
                                )}?isViewOnly=true`"
                              >
                                <v-icon dark>mdi-eye</v-icon>
                              </v-btn>
                            </template>
                            <span>View Employee</span>
                          </v-tooltip>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-bind="attrs"
                                v-on="on"
                                class="ml-2"
                                @click="downloadIdCard(user)"
                                :loading="isCardLoading[user.id]"
                                fab
                                x-small
                                color="primary"
                                target="_blank"
                              >
                                <v-icon
                                  >mdi-card-account-details-outline</v-icon
                                >
                              </v-btn>
                            </template>
                            <span>Download Id Card</span>
                          </v-tooltip>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-bind="attrs"
                                v-on="on"
                                class="ml-2"
                                fab
                                x-small
                                color="primary"
                                :to="`/users/${encryptIt(user.id)}`"
                              >
                                <v-icon dark> mdi-pencil </v-icon>
                              </v-btn>
                            </template>
                            <span>Edit this user</span>
                          </v-tooltip>
                        </template>
                        <template v-else>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-bind="attrs"
                                v-on="on"
                                fab
                                x-small
                                class="my-auto"
                                color="error"
                                @click="Restore_this(user.id)"
                              >
                                <v-icon dark> mdi-delete-restore </v-icon>
                              </v-btn>
                            </template>
                            <span>Restore this user</span>
                          </v-tooltip>
                        </template>
                        <!-- <div class="text-center ml-3"> -->
                          <v-menu stransition="slide-x-transition" bottom left>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                color="primary"
                                class="px-0 ml-2"
                                fab
                                x-small
                                dark
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon>mdi-newspaper</v-icon>
                              </v-btn>
                            </template>
                            <v-list>
                              <v-list-item
                                :to="`/letters/${user.id}?type=offer letter`"
                              >
                                <v-list-item-title class="text-caption"
                                  >Offer Letter</v-list-item-title
                                >
                              </v-list-item>
                              <v-list-item
                                :to="`/letters/${user.id}?type=appointment letter`"
                              >
                                <v-list-item-title class="text-caption"
                                  >Appointment Letter</v-list-item-title
                                >
                              </v-list-item>
                              <v-list-item
                                :to="`/letters/${user.id}?type=experience letter`"
                              >
                                <v-list-item-title class="text-caption"
                                  >Experience Letter</v-list-item-title
                                >
                              </v-list-item>
                              <v-list-item
                                :to="`/letters/${user.id}?type=promotion letter`"
                              >
                                <v-list-item-title class="text-caption"
                                  >Promotion Letter</v-list-item-title
                                >
                              </v-list-item>
                              <v-list-item
                                :to="`/letters/${user.id}?type=renewal letter`"
                              >
                                <v-list-item-title class="text-caption"
                                  >Renewal Letter</v-list-item-title
                                >
                              </v-list-item>
                              <v-list-item
                                :to="`/letters/${user.id}?type=incremental letter`"
                              >
                                <v-list-item-title class="text-caption"
                                  >Incremental Letter</v-list-item-title
                                >
                              </v-list-item>
                              <v-list-item
                                :to="`/letters/${user.id}?type=warning letter`"
                              >
                                <v-list-item-title class="text-caption"
                                  >Warning Letter</v-list-item-title
                                >
                              </v-list-item>
                              <v-list-item
                                :to="`/letters/${user.id}?type=termination letter`"
                              >
                                <v-list-item-title class="text-caption"
                                  >Termination Letter</v-list-item-title
                                >
                              </v-list-item>
                              <v-list-item
                                :to="`/letters/${user.id}?type=full_and_final letter`"
                              >
                                <v-list-item-title class="text-caption"
                                  >Full and Final Letter</v-list-item-title
                                >
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        <!-- </div> -->
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="12" class="text-center">No data found</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <v-pagination v-model="page" :length="paginationLength">
              </v-pagination>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- Clear Success Dialog Box -->
      <v-dialog v-model="is_deleted_user" width="600">
        <v-card rounded="xxl">
          <v-container>
            <v-row align="center">
              <v-col sm="3" align="center">
                <v-icon
                  large
                  style="color: green; font-size: 90px; width: 200px !important"
                >
                  mdi-delete-empty
                </v-icon>
              </v-col>
              <v-col sm="9" align="center">
                <v-card-text>
                  <v-row>
                    <v-col>
                      <h1 style="color: green">Hurray!</h1>
                      <br />
                      <h3>User deleted successfully.</h3>
                      <br />
                      <v-btn color="success" @click="is_deleted_user = false">
                        OK
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
      <!-- Restore Success Dialog Box -->
      <v-dialog v-model="is_restored_user" width="600">
        <v-card rounded="xxl">
          <v-container>
            <v-row align="center">
              <v-col sm="3" align="center">
                <v-icon
                  large
                  style="color: green; font-size: 90px; width: 200px !important"
                >
                  mdi-delete-restore
                </v-icon>
              </v-col>
              <v-col sm="9" align="center">
                <v-card-text>
                  <v-row>
                    <v-col>
                      <h1 style="color: green">Hurray!</h1>
                      <br />
                      <h3>User restored successfully.</h3>
                      <br />
                      <v-btn color="success" @click="is_restored_user = false">
                        OK
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
// import html2canvas from 'html2canvas';
// import jsPDF from 'jspdf';
export default {
  name: "usersPage",
  data() {
    return {
      filter: {
        active: "",
        supervisor_id: "",
        role_id: "",
        position_id: "",
        show_deleted: false,
      },
      page: 1,
      serialNoStarting: 1,
      paginationLength: 0,
      count: 0,
      is_deleted_user: false,
      restore_user: false,
      is_restored_user: false,
      isCardLoading: {},
      users: [],
      statuses: [
        {
          id: 0,
          text: "Select Active Status",
          value: "",
        },
        { id: "ACTIVE", text: "ACTIVE", value: "1" },
        { id: "INACTIVE", text: "INACTIVE", value: "0" },
      ],
      positions: [
        {
          id: 0,
          text: "Select Position",
          value: "",
        },
      ],
      isLoading: false,
      isExcelloading: false,
      ExportUsers: [],
      Export_users: [],
      excel_fields: {
        "Sr No": "sr_no",
        Name: "name",
        Email: "email",
        Phone: "phone",
        Supervisor: "supervisor",
        "Is Active": "status",
      },
    };
  },
  watch: {
    page: "getData",
    $route() {
      this.getData();
    },
  },
  mounted() {
    this.getData();
    this.getMasters();
  },
  methods: {
    async getMasters() {
      await axios
        .get(`users/masters`)
        .then((masters) => {
          masters = masters.data;
          //Position
          masters.positions.forEach((position) => {
            this.positions.push({
              id: position.id,
              text: position.name,
              value: position.id,
            });
          });
        })
        .catch(function (error) {
          console.log(error);
          // window.location.href = "/auth/login";
        });
    },
    async getData() {
      this.filter.show_deleted = this.$route.query.open_trash ? 1 : 0;
      this.isLoading = true;
      this.filter.search_keyword = this.filter.search_keyword
        ? this.filter.search_keyword
        : "";
      this.filter.is_active = this.filter.is_active
        ? this.filter.is_active
        : "";
      if (this.$route.query.exit_user) {
        this.filter.is_active = this.$route.query.exit_user ? 0 : "";
      }
      this.filter.position_id = this.filter.position_id
        ? this.filter.position_id
        : "";
      let query =
        "page=" +
        this.page +
        "&rowsPerPage=" +
        this.rowsPerPage +
        "&role_id=3" +
        "&show_deleted=" +
        this.filter.show_deleted +
        "&is_active=" +
        this.filter.is_active +
        "&position_id=" +
        this.filter.position_id +
        "&search_keyword=" +
        this.filter.search_keyword;
      await axios
        .get(`users/search_via_SP?${query}`)
        .then((response) => {
          this.users = response.data.data;
          this.count = response.data.count;
          this.serialNoStarting = (this.page - 1) * this.rowsPerPage;
          this.paginationLength = Math.ceil(this.count / this.rowsPerPage);
        })
        .catch(function (error) {
          console.log(error);
          // window.location.href = "/auth/login";
        });
      this.isLoading = false;
    },
    async Delete_this(id) {
      this.delete_user = true;
      await axios
        .post(`/users/delete/${this.encryptIt(id)}`)
        .then(() => {
          this.delete_user = false;
          this.is_deleted_user = true;
          this.getData();
        })
        .catch(function (error) {
          console.log(error);
          window.location.href = "/auth/login";
        });
    },
    async addToExcelData() {
      this.isExcelLoading = true;
      this.title = "Company Lists";
      let Export_users = [];
      this.search_keyword = this.search_keyword ? this.search_keyword : "";
      let query =
        "search_keyword=" +
        this.search_keyword +
        "&is_active=" +
        this.filter.active +
        "&roleId=" +
        this.filter.role_id;
      Export_users = await axios.get(`users?${query}`);
      this.Export_users = Export_users.data.data;

      this.Export_users.forEach((user) => {
        this.ExportUsers.push({
          sr_no: user.sr_no,
          name: user.name ? user.name.toUpperCase() : "",
          email: user.email ? user.email.toUpperCase() : "",
          phone: user.phone ? user.phone : "",

          supervisor: user.supervisor_id
            ? user.supervisor.name.toUpperCase()
            : "",
          status: user.active == 1 ? "YES" : "NO",
        });
      });
      this.isExcelLoading = false;
    },
    async generateExcel() {
      this.isExcelLoading = true;
      this.filter.search_keyword = this.filter.search_keyword
        ? this.filter.search_keyword
        : "";
      this.filter.is_active = this.filter.is_active
        ? this.filter.is_active
        : "";
      this.filter.position_id = this.filter.position_id
        ? this.filter.position_id
        : "";
      let response = [];
      let query =
        "role_id=3" +
        "&companyId=" +
        this.company.id +
        "&is_active=" +
        this.filter.is_active +
        "&position_id=" +
        this.filter.position_id +
        "&search_keyword=" +
        this.filter.search_keyword;
      response = await axios
        .get(`/users/excel_export?${query}`)
        .then((response) => window.open(`${response.data.data}`, "_blank"));
      if (response) {
        console.log(response);
      }
      this.isExcelloading = false;
    },
    async downloadIdCard(user) {
      this.$set(this.isCardLoading, user.id, true);
      let query = "user_id=" + user.id;
      try {
        const response = await axios.get(`/user_id_card?${query}`, {
          responseType: "blob", // Important
        });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "idCard.pdf");
        document.body.appendChild(link);
        link.click();
        this.isCardLoading = false;
      } catch (error) {
        console.error("Error downloading the ID card:", error);
        this.isCardLoading = false;
      } finally {
        this.$set(this.isCardLoading, user.id, false);
      }
    },
    isDeletePage() {
      let response = false;
      if (this.$route.query.open_trash) {
        response = true;
      }
      return response;
    },
    async Restore_this(id) {
      this.restore_user = true;
      await axios
        .post(`/users/restore/${this.encryptIt(id)}`)
        .then(() => {
          this.restore_user = false;
          this.is_restored_user = true;
          this.getData();
        })
        .catch(function (error) {
          console.log(error);
          window.location.href = "/auth/login";
        });
    },
    viewUser(id) {
      // Handle the view action, e.g., redirect to a user details page
      console.log("View user with ID:", id);
    },
  },
};
</script>
<style scoped>
.diagonal-split-background {
  background-color: #013a6b;
  background-image: -webkit-linear-gradient(103deg, #221f1f 52%, #f7f7f7 50%);
}

.text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  /* Adjust as needed */
  display: inline-block;
}
</style>
