<template>
    <v-main>
        <v-container fluid>
            <v-row>
                <v-col md="12">
                    <v-card elevation="6" rounded="xxl">
                        <v-card-title>
                            <h3 class="font-weight-bold"
                                v-text="isDeletePage() != true ? 'User Leaves' : 'Deleted User Leaves'">
                            </h3>
                            <section v-if="isDeletePage() != true">
                                <v-btn class="mx-2 mb-2" fab x-small color="primary" to="/user-leaves/create">
                                    <v-icon dark> mdi-plus </v-icon>
                                </v-btn>
                                <!-- <v-btn class="mx-2 mb-2" fab x-small color="primary" to="/user_leaves/upload">
                                    <v-icon dark> mdi-upload </v-icon>
                                </v-btn> -->
                            </section>
                        </v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col md="6">
                                    <v-text-field solo rounded v-model="filter.search_keyword" label="Search ... "
                                        prepend-inner-icon="mdi-magnify" clearable @click:clear="
                                    (filter.search_keyword = null), (page = 1), getData()
                                    " @keydown.enter="(page = 1), getData()"></v-text-field>
                                </v-col>
                                <v-col sm="3">
                                    <v-autocomplete solo rounded ref="position" v-model="filter.user_id"
                                        :items="Employees" label="Employees" @input="(page = 1), getData()"
                                        prepend-inner-icon="mdi-account" clearable></v-autocomplete>
                                </v-col>
                                <v-col sm="3">
                                    <v-autocomplete ref="status" solo rounded v-model="filter.is_active"
                                        :items="statuses" label="Status" @input="(page = 1), getData()"
                                        prepend-inner-icon="mdi-toggle-switch" clearable></v-autocomplete>
                                </v-col>
                                <v-col sm="2">
                                    <v-menu v-model="month_menu" :close-on-content-click="false" :nudge-right="40"
                                        transition="scale-transition" offset-y min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field v-model="filter.month_year" solo label="Year - Month"
                                                prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"
                                                clearable @click:clear="filter.month_year = null" rounded
                                                :error-messages="errors.month"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="filter.month_year" type="month"
                                            @change="(month_menu = false), getData()"></v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>

            <v-row>
                <v-col md="12">
                    <v-card elevation="6" rounded="xxl">
                        <!-- <v-card-title v-if="user_leaves.length != 0">
                            <v-spacer></v-spacer>
                            <v-btn rounded class="primary mx-4" @click="generateExcel()" v-if="isDeletePage() != true">
                                <span v-if="isExcelloading"> Generating Excel... </span>
                                <span v-else>Generate Excel</span>
                            </v-btn>
                        </v-card-title> -->
                        <v-card-text>
                            <section v-if="isLoading" style="height: 485px">
                                <v-progress-linear indeterminate color="primary"></v-progress-linear>
                            </section>
                            <v-simple-table v-else fixed-header height="485" class="striped-table">
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="text-start text-subtitle-2 font-weight-bold">Sr No</th>
                                            <th class="text-start text-subtitle-2 font-weight-bold" style="min-width: 150px;">Employee Name</th>
                                            <th class="text-start text-subtitle-2 font-weight-bold" style="min-width: 150px;">Employee Code</th>
                                            <th class="text-start text-subtitle-2 font-weight-bold" style="min-width: 150px;">Leave Type</th>
                                            <th class="text-start text-subtitle-2 font-weight-bold" style="min-width: 150px;">Request</th>
                                            <th class="text-start text-subtitle-2 font-weight-bold">Available</th>
                                            <th class="text-start text-subtitle-2 font-weight-bold" style="min-width: 150px;">Session Type</th>
                                            <th class="text-start text-subtitle-2 font-weight-bold">Reason</th>
                                            <th class="text-start text-subtitle-2 font-weight-bold" style="min-width: 150px;">Approve By</th>
                                            <th class="text-start text-subtitle-2 font-weight-bold">Status</th>
                                            <th class="text-start text-subtitle-2 font-weight-bold" style="min-width: 210px;">Take Action</th>
                                            <th class="text-right text-subtitle-2 font-weight-bold action_th">Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="user_leaves.length != 0">
                                        <tr v-for="(user_attendance, i) in user_leaves" :key="`user_attendance${i}`">
                                            <td style="min-width: 85px">
                                                {{ i + 1 }}
                                            </td>
                                            <td>{{ user_attendance.user.full_name || "" }}</td>
                                            <td>{{ user_attendance.user.employee_code || "" }}</td>
                                            <td>{{ user_attendance.leave_type.code || "" }}</td>
                                            <td
                                                v-html="getLeaveDayCounts(user_attendance.leave_from_date, user_attendance.leave_to_date)">
                                            </td>
                                            <td class="green--text font-weight-bold">{{
                                    getLastPendingLeave(user_attendance.user.user_leave_histories) || ""
                                }}</td>
                                            <td>{{ user_attendance.session_type.code || "" }}</td>
                                            <td>{{ user_attendance.remarks || "" }}</td>
                                            <td v-html="user_attendance.approved_by"></td>
                                            <td>
                                                <v-tooltip bottom v-if="user_attendance.is_leave_approved == 1">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-chip v-bind="attrs" v-on="on" small color="teal"
                                                            text-color="white">
                                                            <v-avatar left>
                                                                <v-icon size="15">mdi-checkbox-marked-circle</v-icon>
                                                            </v-avatar>
                                                            Accept
                                                        </v-chip>
                                                    </template>
                                                    <span>User Leave is Approved</span>
                                                </v-tooltip>
                                                <v-tooltip bottom v-if="user_attendance.is_leave_approved == 0">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-chip v-bind="attrs" v-on="on" small color="red"
                                                            text-color="white">
                                                            <v-avatar left>
                                                                <v-icon size="15">mdi-close-circle</v-icon>
                                                            </v-avatar>
                                                            Reject
                                                        </v-chip>
                                                    </template>
                                                    <span>User Leave is Declined</span>
                                                </v-tooltip>
                                                <v-tooltip bottom v-if="user_attendance.is_leave_approved == null">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-chip v-bind="attrs" v-on="on" small color="orange"
                                                            text-color="white">
                                                            <v-avatar left>
                                                                <v-icon size="15">mdi-close-circle</v-icon>
                                                            </v-avatar>
                                                            pending
                                                        </v-chip>
                                                    </template>
                                                    <span>User Leave is Pending</span>
                                                </v-tooltip>
                                            </td>
                                            <td width="250">
                                                <v-autocomplete class="mt-3" solo rounded ref="approve"
                                                    v-model="user_attendance.is_leave_approved" :items="LeaveStatuses" dense
                                                    label="Approve Status"
                                                    @input="update(user_attendance)"></v-autocomplete>
                                            </td>
                                            <td class="text-right" v-if="!user_attendance.is_leave_approved">
                                                <template v-if="isDeletePage() != true">
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn v-bind="attrs" v-on="on" fab x-small class="my-auto"
                                                                color="error" @click="Delete_this(user_attendance.id)">
                                                                <v-icon dark> mdi-delete </v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>Delete this User Leave</span>
                                                    </v-tooltip>
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn v-bind="attrs" v-on="on" class="ml-2" fab x-small
                                                                color="primary"
                                                                :to="`/user-leaves/${encryptIt(user_attendance.id)}`">
                                                                <v-icon dark> mdi-pencil </v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>Edit this User Leave</span>
                                                    </v-tooltip>
                                                </template>
                                                <template v-else>
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn v-bind="attrs" v-on="on" fab x-small class="my-auto"
                                                                color="error" @click="Restore_this(user_attendance.id)">
                                                                <v-icon dark> mdi-delete-restore </v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>Restore this user_attendance</span>
                                                    </v-tooltip>
                                                </template>
                                            </td>
                                            <td v-else class="text-right">

                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody v-else>
                                        <tr>
                                            <td colspan="12" class="text-center">No data found</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                            <v-pagination v-model="page" :length="paginationLength">
                            </v-pagination>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <!-- Clear Success Dialog Box -->
            <v-dialog v-model="is_deleted_user_attendance" width="600">
                <v-card rounded="xxl">
                    <v-container>
                        <v-row align="center">
                            <v-col sm="3" align="center">
                                <v-icon large style="color: green; font-size: 90px; width: 200px !important">
                                    mdi-delete-empty
                                </v-icon>
                            </v-col>
                            <v-col sm="9" align="center">
                                <v-card-text>
                                    <v-row>
                                        <v-col>
                                            <h1 style="color: green">Hurray!</h1>
                                            <br />
                                            <h3>user_attendance deleted successfully.</h3>
                                            <br />
                                            <v-btn color="success" @click="is_deleted_user_attendance = false">
                                                OK
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-dialog>
            <!-- Restore Success Dialog Box -->
            <v-dialog v-model="is_restored_user_attendance" width="600">
                <v-card rounded="xxl">
                    <v-container>
                        <v-row align="center">
                            <v-col sm="3" align="center">
                                <v-icon large style="color: green; font-size: 90px; width: 200px !important">
                                    mdi-delete-restore
                                </v-icon>
                            </v-col>
                            <v-col sm="9" align="center">
                                <v-card-text>
                                    <v-row>
                                        <v-col>
                                            <h1 style="color: green">Hurray!</h1>
                                            <br />
                                            <h3>user_attendance restored successfully.</h3>
                                            <br />
                                            <v-btn color="success" @click="is_restored_user_attendance = false">
                                                OK
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-dialog>
        </v-container>
    </v-main>
</template>

<script>
import axios from "axios";
export default {
    name: "user_leavesPage",
    data() {
        return {
            filter: {
                active: "",
                supervisor_id: "",
                role_id: "",
                position_id: "",
                show_deleted: false,
                month_year: "",
                month: "",
                year: "",
            },
            page: 1,
            serialNoStarting: 1,
            paginationLength: 0,
            count: 0,
            is_deleted_user_attendance: false,
            restore_user_attendance: false,
            is_restored_user_attendance: false,
            month_menu: false,

            user_leaves: [],
            statuses: [
                {
                    id: 0,
                    text: "Select Active Status",
                    value: "",
                },
                { id: "ACTIVE", text: "ACTIVE", value: "1" },
                { id: "INACTIVE", text: "INACTIVE", value: "0" },
            ],
            LeaveStatuses: [
                { id: "PENDING", text: "PENDING", value: null },
                { id: "APPROVED", text: "APPROVED", value: 1 },
                { id: "REJECT", text: "REJECT", value: 0 },
            ],
            Employees: [
                {
                    id: 0,
                    text: "Select Employees",
                    value: "",
                },
            ],
            isLoading: false,
            isExcelloading: false,
            excel_fields: {
                "Sr No": "sr_no",
                Name: "name",
                Email: "email",
                Phone: "phone",
                Supervisor: "supervisor",
                "Is Active": "status",
            },
        };
    },
    watch: {
        page: "getData",
        $route() {
            this.getData();
        },
    },
    mounted() {
        this.getData();
        this.getMasters();
    },
    methods: {
        async getMasters() {
            await axios
                .get(`user_attendances/masters`)
                .then((masters) => {
                    masters = masters.data;
                    //Position
                    masters.employees.forEach((employe) => {
                        this.Employees.push({
                            id: employe.id,
                            text: employe.first_name,
                            value: employe.id,
                        });
                    });
                })
                .catch(function (error) {
                    console.log(error);
                    // window.location.href = "/auth/login";
                });
        },
        async getData() {
            console.log("Get Data");
            this.filter.show_deleted = this.$route.query.open_trash ? 1 : 0;
            this.isLoading = true;
            this.filter.search_keyword = this.filter.search_keyword
                ? this.filter.search_keyword
                : "";
            if (this.filter.month_year) {
                this.filter.year = this.filter.month_year.split("-")[0];
                this.filter.month = this.filter.month_year.split("-")[1];
            }
            this.filter.user_id = this.filter.user_id
                ? this.filter.user_id
                : "";
            this.filter.is_active = this.filter.is_active
                ? this.filter.is_active
                : "";
            let query =
                "page=" +
                this.page +
                "&rowsPerPage=" +
                this.rowsPerPage +
                "&userId=" +
                this.filter.user_id +
                "&month=" +
                this.filter.month +
                "&year=" +
                this.filter.year +
                "&session_type=LEAVE" +
                "&role_id=3" +
                "&show_deleted=" +
                this.filter.show_deleted +
                "&is_active=" +
                this.filter.is_active +
                "&search_keyword=" +
                this.filter.search_keyword;
            await axios
                .get(`user_attendances?${query}`)
                .then((response) => {
                    this.user_leaves = response.data.data;
                    this.count = response.data.count;
                    this.serialNoStarting = (this.page - 1) * this.rowsPerPage;
                    this.paginationLength = Math.ceil(this.count / this.rowsPerPage);
                })
                .catch(function (error) {
                    console.log(error);
                    // window.location.href = "/auth/login";
                });
            this.isLoading = false;
        },
        async Delete_this(id) {
            this.delete_user_attendance = true;
            await axios
                .post(`/user_leaves/delete/${this.encryptIt(id)}`)
                .then(() => {
                    this.delete_user_attendance = false;
                    this.is_deleted_user_attendance = true;
                    this.getData();
                })
                .catch(function (error) {
                    console.log(error);
                    window.location.href = "/auth/login";
                });
        },
        async update(leave) {
            try {
                let payload = {
                    id: leave.id,
                    user_id: leave.user_id,
                    is_leave_approved: leave.is_leave_approved,
                    approved_by: leave.is_leave_approved ? `<strong>${this.roleName}</strong><br/>${new Date().toLocaleDateString()}<br/>approved` : null,
                    SessionTypeLeave: true,
                }
                this.isLoading = true;
                // this.encryptIt(leave.id);
                await axios.patch(`/user_attendances/${this.encryptIt(leave.id)}`, payload);
                let PageModule = 'UPDATED';
                let PageCode = 'USER LEAVES';
                const userIP = await this.getUserIp();
                this.saveAuditTrial(userIP, PageModule, PageCode)
                this.isLoading = false;
            } catch (e) {
                // alert("error");
                this.isLoading = false;
            }
        },
        async addToExcelData() {
            this.isExcelLoading = true;
            this.title = "Company Lists";
            let Exporleaves = [];
            this.search_keyword = this.search_keyword ? this.search_keyword : "";
            let query =
                "search_keyword=" +
                this.search_keyword +
                "&is_active=" +
                this.filter.active +
                "&roleId=" +
                this.filter.role_id;
            Exporleaves = await axios.get(`user_leaves?${query}`);
            this.Exporleaves = Exporleaves.data.data;

            this.Exporleaves.forEach((user_attendance) => {
                this.Exporleaves.push({
                    sr_no: user_attendance.sr_no,
                    name: user_attendance.name ? user_attendance.name.toUpperCase() : "",
                    email: user_attendance.email ? user_attendance.email.toUpperCase() : "",
                    phone: user_attendance.phone ? user_attendance.phone : "",

                    supervisor: user_attendance.supervisor_id
                        ? user_attendance.supervisor.name.toUpperCase()
                        : "",
                    status: user_attendance.active == 1 ? "YES" : "NO",
                });
            });
            this.isExcelLoading = false;
        },
        async generateExcel() {
            this.isExcelLoading = true;
            this.filter.search_keyword = this.filter.search_keyword
                ? this.filter.search_keyword
                : "";
            this.filter.is_active = this.filter.is_active
                ? this.filter.is_active
                : "";
            this.filter.user_id = this.filter.user_id
                ? this.filter.user_id
                : "";
            let response = [];
            let query =
                "role_id=3" +
                "&companyId=" +
                this.company.id +
                "&is_active=" +
                this.filter.is_active +
                "&user_id=" +
                this.filter.user_id +
                "&search_keyword=" +
                this.filter.search_keyword;
            response = await axios
                .get(`/user_leaves/excel_export?${query}`)
                .then((response) => window.open(`${response.data.data}`, "_blank"));
            if (response) {
                console.log(response);
            }
            this.isExcelloading = false;
        },
        isDeletePage() {
            let response = false;
            if (this.$route.query.open_trash) {
                response = true;
            }
            return response;
        },
        getLeaveDayCounts(fromDate, toDate) {
            if (!fromDate || !toDate) {
                return '';
            }

            const startDate = new Date(fromDate);
            const endDate = new Date(toDate);

            // Calculate the difference in time
            const diffTime = Math.abs(endDate - startDate);
            // Calculate the number of days between the two dates
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;

            // Format the dates
            const options = { month: 'short', day: '2-digit' };
            const startDateFormatted = new Intl.DateTimeFormat('en-US', options).format(startDate);
            const endDateFormatted = new Intl.DateTimeFormat('en-US', options).format(endDate);

            return `<b>${startDateFormatted} - ${endDateFormatted}</b><br>${diffDays} days`;
        },
        getLastPendingLeave(leaveHistories) {
            if (leaveHistories && leaveHistories.length > 0) {
                return `${leaveHistories[leaveHistories.length - 1].total_pending_leave} days`;
            }
            return "";
        },
        async Restore_this(id) {
            this.restore_user_attendance = true;
            await axios
                .post(`/user_leaves/restore/${this.encryptIt(id)}`)
                .then(() => {
                    this.restore_user_attendance = false;
                    this.is_restored_user_attendance = true;
                    this.getData();
                })
                .catch(function (error) {
                    console.log(error);
                    window.location.href = "/auth/login";
                });
        },
    },
};
</script>