<template>
  <v-main>
    <v-container fluid>
      <v-card elevation="6" rounded="xxl">
        <v-card-title class="mt-4">
          <v-btn class="mx-2" fab x-small color="primary" @click="$router.back()">
            <v-icon dark>mdi-arrow-left</v-icon>
          </v-btn>
          Edit Hr Report
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="4">
              <v-text-field v-model="form.report_name" outlined label="Report Name" :disabled="isViewOnly"
                prepend-inner-icon="mdi-card-text-outline" :error-messages="errors.report_name"></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-autocomplete ref="position" outlined v-model="form.table_name" :items="Tables" :disabled="isViewOnly"
                label="Select DataBase *" prepend-inner-icon="mdi-account-tie-outline" clearable
                :error-messages="errors.table_name" @change="getMasters"></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <v-card height="600" style="border: 1px solid;">
                <v-col v-if="isLoading" align="center">
                  <v-img :src="require('../../assets/loader.gif')" width="70" />
                </v-col>
                <v-simple-table v-else fixed-header height="600" class="striped-table">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th style="min-width: 20px" class="text-start">Sr No</th>
                        <th style="min-width: 170px" class="text-start">Columns</th>
                        <th class="text-start">Select</th>
                      </tr>
                    </thead>
                    <tbody v-if="TableColumns.length != 0">
                      <tr v-for="(column, i) in TableColumns" :key="i">
                        <td>{{ i + 1 }}</td>
                        <td>{{ column.value }}</td>
                        <td>
                          <v-btn v-if="!isViewOnly" icon @click="toggleColumnSelection(column)">
                            <v-icon>{{ isSelected(column) ? 'mdi-check' : 'mdi-plus' }}</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-else>
                      <tr>
                        <td colspan="3" class="text-center">No data found</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>
            </v-col>
            <v-col cols="4">
              <v-card height="600" style="border: 1px solid;">
                <v-col v-if="isLoading" align="center">
                  <v-img :src="require('../../assets/loader.gif')" width="70" />
                </v-col>
                <v-simple-table v-else fixed-header height="600" class="striped-table">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th style="min-width: 20px" class="text-start">Sr No</th>
                        <th style="min-width: 170px" class="text-start">Header Name</th>
                        <th class="text-start">Column</th>
                      </tr>
                    </thead>
                    <tbody v-if="SelectedColumns.length != 0">
                      <tr v-for="(column, i) in SelectedColumns" :key="i">
                        <td>{{ i + 1 }}</td>
                        <td style="max-width: 130px;">
                          <v-text-field outlined dense v-model="column.header_name" class="mt-4"></v-text-field>
                        </td>
                        <td>{{ column.column_name }}</td>
                      </tr>
                    </tbody>
                    <tbody v-else>
                      <tr>
                        <td colspan="3" class="text-center">No data found</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>
            </v-col>
          </v-row>
          <v-divider class="my-5"></v-divider>
          <v-btn v-if="!isViewOnly" rounded color="primary" :loading="isLoading" :disabled="isLoading" @click="save">
            Edit Report
          </v-btn>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";

export default {
  name: "ReportEditPage",
  data() {
    return {
      form: {
        ReportColumns: [
        ],
      },
      Tables: [],
      TableColumns: [],
      SelectedColumns: [],
      isLoading: false,
      isViewOnly: false,
    };
  },
  async mounted() {
    if (this.$route.query.isViewOnly) {
      this.isViewOnly = true;
    }
    await this.getData();
    this.getMasters();
  },
  methods: {
    async getMasters() {
      this.TableColumns = [];
      this.form.table_name = this.form.table_name != null ? this.form.table_name : "";
      await axios
        .get(`hr_reports/masters?table_name=${this.form.table_name}`)
        .then((masters) => {
          masters = masters.data;
          masters.tables.forEach(table => {
            this.Tables.push({
              text: table,
              value: table
            })
          });
          if (this.form.table_name != null || this.form.table_name != "") {
            masters.columns.forEach(column => {
              this.TableColumns.push({
                text: column,
                value: column
              })
            });
          }
        })
    },
    async getData() {
      this.isLoading = true;
      let encryptedId = this.$route.params.ReportId;
      await axios
        .get(`/hr_reports/${encryptedId}`)
        .then((response) => {
          response.data.data.hr_reports.forEach((el) => {
            this.SelectedColumns.push({
              id: el.id,
              header_name: el.header_name,
              column_name: el.column_name,
            });
          });
          const fetchedData = response.data.data;
          this.form = {
            ...this.form,
            ...fetchedData,
          };
          console.log(this.form);
          this.old_json = response.data.data;
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
      this.isLoading = false;
    },
    async save() {
      try {
        this.isLoading = true;
        console.log(this.form);
        let encryptedId = this.$route.params.ReportId;
        this.form.ReportColumns = this.SelectedColumns;
        await axios.patch(`/hr_reports/${encryptedId}`, this.form);
        await this.saveUserTimestamp(this.old_json, this.form);
        let PageModule = 'UPDATED';
        let PageCode = 'HR REPORT WRITER';
        const userIP = await this.getUserIp();
        this.saveAuditTrial(userIP, PageModule, PageCode)
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    },
    Add_Empty_report_field() {
      let ReportColumns = {
        header_name: "",
        column_name: "",
      };
      this.form.ReportColumns.push(ReportColumns);
    },
    deleteReportField(report) {
      this.form.ReportColumns.splice(
        this.form.ReportColumns.indexOf(report),
        1
      );
    },
    toggleColumnSelection(column) {
      const index = this.SelectedColumns.findIndex(selected => selected.column_name === column.value);
      if (index > -1) {
        this.SelectedColumns.splice(index, 1);
      } else {
        this.SelectedColumns.push({ header_name: '', column_name: column.value });
      }
    },
    isSelected(column) {
      return this.SelectedColumns.some(selected => selected.column_name === column.value);
    },
    async saveUserTimestamp() {
      try {
        let form = {
          from_path: `/module/${this.form.id}`,
          from_name: "ModuleEditPage",
          user_id: this.user.id,
          old_json: JSON.stringify(this.old_json),
          new_json: JSON.stringify(this.form),
        };
        await axios.post(`/user_timestamps`, form);
        this.$router.back();
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
