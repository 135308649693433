<template>
  <v-main>
    <v-container fluid>
      <v-card elevation="6" rounded="xxl">
        <v-card-title class="mt-4">
          <v-btn v-if="roleName == 'SUPER ADMIN' && company.id == null" class="mx-2" fab x-small color="primary"
            @click="$router.back()">
            <v-icon dark> mdi-arrow-left </v-icon> </v-btn>Edit Company
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="4">
              <v-text-field v-model="form.name" outlined label="Company Name *" prepend-inner-icon="mdi-buffet"
                :error-messages="errors.name"></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field v-model="form.tag_line" outlined label="Tag line" prepend-inner-icon="mdi-map-marker"
                :error-messages="errors.tag_line"></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field v-model="form.email" outlined label="Email *" prepend-inner-icon="mdi-email"
                :error-messages="errors.email"></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field v-model="form.phone" outlined label="Phone *" prepend-inner-icon="mdi-phone"
                :error-messages="errors.phone"></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field v-model="form.admin_name" outlined label="Admin Name"
                prepend-inner-icon="mdi-card-account-phone"></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field v-model="form.address" outlined label="Address *"
                prepend-inner-icon="mdi-map-marker-multiple" :error-messages="errors.address"></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field v-model="form.city" outlined label="City" prepend-inner-icon="mdi-map-marker"
                :error-messages="errors.city"></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field v-model="form.state" outlined label="State " prepend-inner-icon="mdi-map-marker"
                :error-messages="errors.state"></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field v-model="form.pincode" outlined label="Pincode" prepend-inner-icon="mdi-map-marker"
                :error-messages="errors.pincode"></v-text-field>
            </v-col>
            <v-col sm="4" v-if="roleName == 'SUPER ADMIN'">
              <v-menu v-model="expire_date_menu" :close-on-content-click="false" :nudge-right="40"
                transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field outlined v-model="form.expire_date" label="Expired Date *"
                    prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" clearable
                    @click:clear="expire_date = null" :error-messages="errors.expire_date"></v-text-field>
                </template>
                <v-date-picker :id="`expire_date`" v-model="form.expire_date"
                  @change="expire_date_menu = false"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col sm="4">
              <v-text-field v-model="form.default_per_month_leave" outlined
                label="Per Month Default Leave for Employees" type="number" prepend-inner-icon="mdi-exit-run"
                :error-messages="errors.default_per_month_leave"></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-autocomplete ref="upline_position" outlined v-model="form.company_leave_type"
                :items="CompaniesLeaveTypes" label="Select Company Leave Type *"
                prepend-inner-icon="mdi-account-tie-hat-outline"
                :error-messages="errors.company_leave_type"></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <input type="file" id="file" name="file" :ref="`file`" @change="onFileChange" />
              <v-btn x-small class="reset_image my-1" color="red" dark
                @click="($refs.file.value = null), (image_url = null)">Remove</v-btn>

              <div id="preview" v-if="image_url" class="mt-5">
                <label for="">Image Preview</label>
                <br />
                <img :src="image_url" style="height: 30%; width: 30% !important" />
              </div>
            </v-col>
          </v-row>
          <v-divider class="my-2"> </v-divider>
          <h3 class="my-4">List of Flag(s) for this Company:</h3>
          <v-row>
            <v-col sm="3">
              <v-switch class="mt-0" v-model="form.is_active" inset
                :label="`Is Active: ${form.is_active ? 'YES' : 'NO'}`"></v-switch>
            </v-col>
            <v-col sm="3">
              <v-switch class="mt-0 ml-5" v-model="form.is_location_tracking_enabled" inset :label="`Is Location tracking Enabled: ${form.is_location_tracking_enabled ? 'YES' : 'NO'
            }`"></v-switch>
            </v-col>
            <v-col sm="3">
              <v-switch class="mt-0 ml-5" v-model="form.is_SUSP_enabled" inset :label="`Is SUSP Enabled: ${form.is_SUSP_enabled ? 'YES' : 'NO'
            }`"></v-switch>
            </v-col>
            <v-col sm="3">
              <v-switch class="mt-0 ml-5" v-model="form.is_SUSL_enabled" inset :label="`Is SUSL Enabled: ${form.is_SUSL_enabled ? 'YES' : 'NO'
            }`"></v-switch>
            </v-col>
            <v-col sm="3">
              <v-switch class="mt-0 " v-model="form.is_carry_forwarded" inset :label="`Is Leaves Carry Forwaded: ${form.is_carry_forwarded ? 'YES' : 'NO'
            }`"></v-switch>
            </v-col>
          </v-row>
          <v-divider class="my-5"> </v-divider>
          <v-btn rounded color="primary" :loading="isLoading" :disabled="isLoading" @click="save">
            Update Company
          </v-btn>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";
export default {
  name: "CompanyEditPage",
  data() {
    return {
      form: {
        name: "",
        is_active: true,
      },
      image_url: "",
      old_json: {},
      isLoading: false,
      expire_date_menu: false,
      CompaniesLeaveTypes: [
        'FL (Financial Year Leave)', 'CL (Calender Leave)'
      ],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      let encryptedId = this.$route.params.companyId;
      axios
        .get(`/companies/${encryptedId}`)
        .then((response) => {
          this.form = response.data.data;
          this.old_json = response.data.data;
          this.image_url = this.form.logo_path
            ? `${this.mediaUrl + this.form.logo_path}`
            : "";
        })
        .catch((error) => {
          // redirect to Home Page
          window.location.href = "/auth/login";
          console.error("Error fetching data:", error);
        });
      this.isLoading = false;
    },
    async save() {
      try {
        this.isLoading = true;
        await axios.patch(
          `/companies/${this.$route.params.companyId}`,
          this.form
        );
        await this.handleFileUpload();
        await this.saveUserTimestamp(this.old_json, this.form);
        this.isLoading = false;
        if (
          this.roleName == "ADMIN" ||
          (this.roleName == "SUPER ADMIN" && this.company.id != null)
        ) {
          window.location.href = "/auth/login";
        } else {
          this.$router.push("/companies");
        }
      } catch (e) {
        console.log(e);
        this.isLoading = false;
      }
    },
    async handleFileUpload() {
      console.log("called");
      if (this.$refs.file != null) {
        console.log("uploading");
        let attachment = this.$refs.file.files[0];
        let formData = new FormData();
        formData.append("id", this.form.id);
        formData.append("logo_path", attachment);
        await axios
          .post("upload_company_logo_path", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .catch(function (error) {
            console.log(error);
            console.log("FAILURE!!");
          });
      }
    },
    onFileChange() {
      this.warningImageLoader = false;
      this.file = this.$refs.file?.files[0];
      let reader = new FileReader();
      reader.readAsDataURL(this.file);
      reader.onload = (evt) => {
        let img = new Image();
        img.onload = () => {
          this.imageLoader = false;
          this.image_url = URL.createObjectURL(this.file);
          this.imageErrors = "";
        };
        img.src = evt.target.result;
      };

      reader.onerror = (evt) => {
        console.error(evt);
      };
      // this.image_url = URL.createObjectURL(this.file);
    },
  },
};
</script>
