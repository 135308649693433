<template>
  <v-main>
    <v-container fluid>
      <v-card elevation="6">
        <v-card-title class="mt-4 ">
          <v-btn class="mr-2" fab x-small color="primary" @click="$router.back()">
            <v-icon dark> mdi-arrow-left </v-icon> </v-btn>Add Regularization
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-row>
                <v-col sm="4">
                  <v-autocomplete outlined ref="employee" v-model="form.user_id" :items="employees" label="Employee"
                    append-icon="mdi-account" :error-messages="errors.user_id"
                    @change="checkUserRegularization"></v-autocomplete>
                </v-col>
                <v-col sm="4">
                  <v-menu v-model="date_menu" :close-on-content-click="false" :nudge-right="40"
                    transition="scale-transition" offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field outlined v-model="form.date" label="Date" append-icon="mdi-calendar" readonly
                        v-bind="attrs" v-on="on" clearable @click:clear="form.date = null"
                        :error-messages="errors.date"></v-text-field>
                    </template>
                    <v-date-picker v-model="form.date" @change="date_menu = false"></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-col>
            <v-col sm="4">
              <v-menu ref="login time" v-model="login_time_menu" :close-on-content-click="false" :nudge-right="40"
                :return-value.sync="time" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field outlined v-model="form.login_time" label="Login time"
                    append-icon="mdi-clock-time-nine-outline" readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-time-picker v-if="login_time_menu" v-model="form.login_time" full-width
                  @click:minute="$refs.menu.save(time)"></v-time-picker>
              </v-menu>
            </v-col>
            <v-col sm="4">
              <v-menu ref="logout time" v-model="logout_time_menu" :close-on-content-click="false" :nudge-right="40"
                :return-value.sync="time" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field outlined v-model="form.logout_time" label="Logout time"
                    append-icon="mdi-clock-time-nine-outline" readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-time-picker v-if="logout_time_menu" v-model="form.logout_time" full-width
                  @click:minute="$refs.menu.save(time)"></v-time-picker>
              </v-menu>
            </v-col>
            <v-col sm="4">
              <v-autocomplete outlined ref="session type" v-model="form.session_type_id" :items="sessionTypes"
                label="Session Type" append-icon="mdi-account"
                :error-messages="errors.session_type_id"></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-autocomplete outlined ref="session type" v-model="form.remarks" :items="regularizationReasonTypes"
                label="Remark" append-icon="mdi-card-text-outline"
                :error-messages="errors.session_type_id"></v-autocomplete>
            </v-col>
          </v-row>
          <v-divider class="mt-5 mb-5"> </v-divider>

          <v-progress-circular v-if="isLoading" indeterminate color="primary"></v-progress-circular>
          <v-btn v-else color="primary" dark @click="save">
            Save User Regularisations
          </v-btn>
        </v-card-text>
        <v-dialog v-model="RegularizationLimitDialog" width="450">
          <v-card>
            <v-card-title>
              <v-col cols="4" class="pl-0">
                <v-icon size="100" color="red">mdi-alert</v-icon>
              </v-col>
              <v-col cols="8" class="text-h6">
                <v-col cols="12">
                  Oops You Crossed the Regularization Limit
                </v-col>
              </v-col>
              <v-col>
                <v-btn class="float-end ml-3" @click="closeDialog" color="primary"> ok </v-btn>
              </v-col>
            </v-card-title>
          </v-card>
        </v-dialog>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";
export default {
  name: "UserCreatePage",
  data() {
    return {
      form: {
        login_time: "",
        logout_time: "",
        is_regularization: true,
      },
      employees: [
        {
          id: 0,
          text: "Select Employee",
          value: "",
        },
      ],
      sessionTypes: [
        {
          id: 0,
          text: "Select Session Type",
          value: "",
        },
      ],
      regularizationReasonTypes: [
        {
          id: 0,
          text: "Select Regularization Type",
          value: "",
        },
      ],
      ComppanyAttendanceType: [
      ],
      isLoading: false,
      date_menu: false,
      login_time_menu: false,
      logout_time_menu: false,
      RegularizationLimitDialog: false,
    };
  },
  mounted() {
    this.getMasters();
  },
  methods: {
    async getMasters() {
      let masters = await axios.get(`user_attendances/masters`);
      masters = masters.data;
      // Employees
      masters.employees.forEach((employee) => {
        this.employees.push({
          id: employee.id,
          text: employee.full_name,
          value: employee.id,
        });
      });
      // Session Types
      masters.sessionTypes.forEach((sessionType) => {
        this.sessionTypes.push({
          id: sessionType.id,
          text: sessionType.code,
          value: sessionType.id,
        });
      });
      // User regularizationTypes
      masters.regularizationReasonTypes.forEach((Regularization) => {
        this.regularizationReasonTypes.push({
          id: Regularization.id,
          text: Regularization.code,
          value: Regularization.id,
        });
      });
      this.ComppanyAttendanceType = masters.CompanyattendanceSetting;
    },
    async save() {
      try {
        this.isLoading = true;
        await axios.post("/user_attendances", this.form);
        await this.saveUserTimestamp(null, this.form);
        let PageModule = 'CREATE';
        let PageCode = 'REGULARIZATOINS';
        const userIP = await this.getUserIp();
        this.saveAuditTrial(userIP, PageModule, PageCode)
        this.isLoading = false;
        this.$router.push("/user-regularizations");
      } catch (e) {
        // alert("error");
        this.isLoading = false;
      }
    },
    async checkUserRegularization() {
      console.log(this.ComppanyAttendanceType.regularization_limit);
      let response = await axios.get(`/user_attendances?userId=${this.form.user_id}&is_regularization=1&is_regularization_approve=1`);
      console.log(response.data.data.length);
      if (response.data.data.length >= this.ComppanyAttendanceType.regularization_limit) {
        this.RegularizationLimitDialog = true;
      }
    },
    closeDialog() {
      this.RegularizationLimitDialog = false;
      this.form.user_id = '';
    }
  },
};
</script>
