<template>
  <v-main>
    <v-container fluid>
      <v-card elevation="6">
        <v-card-title class="mt-4">
          <v-btn class="mr-2" fab x-small color="primary" @click="$router.back()">
            <v-icon dark> mdi-arrow-left </v-icon> </v-btn>Edit Leave
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="4">
              <v-autocomplete ref="employee" v-model="form.user_id" outlined :items="employees" label="Employee"
                append-icon="mdi-account" :error-messages="errors.user_id" @input="UpdateUpline"></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-autocomplete ref="upline_position" outlined readonly v-model="form.upline_position_id"
                :items="positionItems" label="Upline Position *" prepend-inner-icon="mdi-account-tie-hat-outline"
                messages="this will be automatically select based on your position."
                :error-messages="errors.upline_position_id"></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-autocomplete ref="upline_user" outlined v-model="form.supervisor_id" :items="uplineUserItems"
                label="Upline User" prepend-inner-icon="mdi-account" clearable
                :error-messages="errors.supervisor_id"></v-autocomplete>
            </v-col>
            <v-col sm="3">
              <v-menu v-model="leave_from_date_menu" :close-on-content-click="false" :nudge-right="40"
                transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="form.leave_from_date" outlined label="Leave from date"
                    append-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" clearable
                    @click:clear="form.leave_from_date = null" :error-messages="errors.leave_from_date"></v-text-field>
                </template>
                <v-date-picker v-model="form.leave_from_date" @change="leave_from_date_menu = false"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col sm="3">
              <v-menu v-model="leave_to_date_menu" :close-on-content-click="false" :nudge-right="40"
                transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="form.leave_to_date" outlined label="Leave to date" append-icon="mdi-calendar"
                    readonly v-bind="attrs" v-on="on" clearable @click:clear="form.leave_to_date = null"
                    :error-messages="errors.leave_to_date"></v-text-field>
                </template>
                <v-date-picker v-model="form.leave_to_date" @change="leave_to_date_menu = false"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col sm="3">
              <v-autocomplete ref="leave type" v-model="form.leave_type_id" outlined :items="leaveTypes"
                label="Leave Type" append-icon="mdi-account" :error-messages="errors.leave_type_id"></v-autocomplete>
            </v-col>
            <v-col sm="3">
              <v-text-field v-model="form.remarks" outlined label="Remark"
                prepend-inner-icon="mdi-card-text-outline"></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field v-model="form.leave_note" outlined label="Note"
                prepend-inner-icon="mdi-card-text-outline"></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-row>
                <v-col sm="3">
                  <input class="mt-3" type="file" id="file" name="file" ref="file" accept="image/*" multiple />
                </v-col>
                <v-col sm="4">
                  <v-switch v-model="form.is_leave_approved" inset :label="`Is Approved: ${form.is_leave_approved == 1 ? 'YES' : 'NO'
            }`"></v-switch>
                </v-col>
              </v-row>
              <v-divider class="mt-5 mb-5"> </v-divider>
              <v-btn class="float-end" :loading="isLoading" :dark="!isLoading" :disabled="isLoading" color="primary"
                @click="save">
                Edit User Leave

              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";
export default {
  name: "UserLeaveCreatePage",
  data() {
    return {
      form: {
        leave_from_date: "",
        leave_to_date: "",
        is_leave_approved: false,
      },
      employees: [
        {
          id: 0,
          text: "Select Employee",
          value: "",
        },
      ],
      leaveTypes: [
        {
          id: 0,
          text: "Select Leave Type",
          value: "",
        },
      ],

      supervisors: [
        {
          id: 0,
          text: "Select Supervisors",
          value: "",
        },
      ],
      uplineUserItems: [
        {
          id: 0,
          text: "Select Upline",
          value: "",
        },
      ],
      supervisorDownlined: [
        "Field Executive",
        "Merchandiser",
        "Promoter",
        "Van Sales",
      ],
      positionItems: [],
      isLoading: false,
      leave_from_date_menu: false,
      leave_to_date_menu: false,
      note: "",
      // clnote: "",
    };
  },
  async mounted() {
    await this.getMasters();
    this.getData();
  },

  methods: {
    async getMasters() {
      let masters = await axios.get(`user_attendances/masters`);
      masters = masters.data;
      // Employees
      masters.employees.forEach((employee) => {
        this.employees.push({
          id: employee.id,
          text: employee.full_name,
          position_id: employee.position_id,
          value: employee.id,
        });
      });
      // Supervisors
      masters.supervisors.forEach((supervisor) => {
        this.supervisors.push({
          id: supervisor.id,
          text: supervisor.full_name,
          value: supervisor.id,
        });
      });
      // Session Types Is Default Leave
      this.form.session_type_id = masters.sessionTypes.find(
        (st) => st.description == "LEAVE"
      ).id;
      // Leave Types
      masters.leaveTypes.forEach((leaveType) => {
        this.leaveTypes.push({
          id: leaveType.id,
          text: leaveType.code,
          value: leaveType.id,
        });
      });
      masters.positions.forEach((user) => {
        this.positionItems.push({
          id: user.id,
          text: user.name,
          value: user.id,
        });
      });
    },
    async getData() {
      this.isLoading = true;
      let encryptedId = this.$route.params.UserLeaveId;
      await axios
        .get(`/user_attendances/${encryptedId}`)
        .then((response) => {
          const fetchedData = response.data.data;
          this.form = {
            ...this.form,
            ...fetchedData,
          };
          this.old_json = response.data.data;
          // this.getUplineUsers();
        })
        .catch((error) => {
          // redirect to Home Page
          // window.location.href = "/auth/login";
          console.error("Error fetching data:", error);
        });
      this.UpdateUpline();
      this.isLoading = false;
    },
    UpdateUpline() {
      let positionId = this.employees.find(
        (employee) => employee.id == this.form.user_id
      ).position_id;
      console.log("Position:" + positionId);

      let current = this.positionItems.find(
        (position) => position.id == positionId
      );
      console.log("current", current);
      if (
        this.supervisorDownlined.find((position) => position == current.text)
      ) {
        let upline = this.positionItems.find(
          (position) => position.text == "Supervisor"
        );
        if (upline) {
          this.form.upline_position_id = upline ? upline.id : null;
          this.getUplineUsers();
        }
      } else {
        let upline =
          this.positionItems[this.positionItems.indexOf(current) - 1];
        if (upline) {
          this.form.upline_position_id = upline ? upline.id : null;
          console.log("upline_position_id", this.form.upline_position_id);
          console.log("positionItems", this.positionItems);
          this.getUplineUsers();
        }
      }
    },
    async getUplineUsers() {
      this.uplineUserItems = [];
      let query = "position_id=" + this.form.upline_position_id;
      await axios
        .get(`users?${query}`)
        .then((users) => {
          //User
          users.data.data.forEach((user) => {
            this.uplineUserItems.push({
              id: user.id,
              text: user.full_name,
              value: user.id,
            });
          });
        })
        .catch(function (error) {
          console.log(error);
          // redirect to Home Page
          window.location.href = "/auth/login";
        });
    },
    async save() {
      try {
        this.isLoading = true;
        let encryptedId = this.$route.params.UserLeaveId;
        if (this.form.is_leave_approved == true) {
          this.form.approved_by = `<strong>${this.roleName}</strong><br/>${new Date().toLocaleDateString()}<br/>approved`;
        }
        let leave = await axios.patch(`/user_attendances/${encryptedId}`, this.form);
        this.leave = leave.data.data;
        let PageModule = 'UPDATED';
        let PageCode = 'USER LEAVES';
        const userIP = await this.getUserIp();
        this.saveAuditTrial(userIP, PageModule, PageCode)
        // this.handleFileUpload();
        this.isLoading = false;
        this.$router.push("/user-leaves");
      } catch (e) {
        // alert("error");
        this.isLoading = false;
      }
    },
    async handleFileUpload() {
      let attachment = this.$refs.file.files[0];
      const user_attendances_id = this.leave.id;
      let formData = new FormData();
      formData.append("id", user_attendances_id);
      formData.append("attachment", attachment);
      await axios
        .post("upload_user_leave_attachment", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .catch(function () {
          console.log("FAILURE!!");
        });
    },
  },
};
</script>
