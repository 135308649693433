<template>
  <v-main>
    <v-container fluid>
      <v-row>
        <v-col md="12">
          <v-card elevation="4">
            <v-card-text>
              <v-row class="d-flex align-center">
                <v-btn color="primary" fab x-small class="mt-3" @click="$router.back()">
                  <v-icon>mdi-arrow-left</v-icon>
                </v-btn>
                <v-btn
                  class="mt-2 mx-2 text-caption"
                  color="primary"
                  :to="`/letters/create/${$route.params.userId}/${letter_type_id}?type=${letterType}`"
                >
                  <span>
                    Add {{ $route.query.type }}
                    <v-icon dark> mdi-plus </v-icon></span
                  >
                </v-btn>
              </v-row>
              <v-row>
                <v-col md="6">
                  <v-text-field
                    v-model="search_keyword"
                    label="Search ... "
                    prepend-inner-icon="mdi-magnify"
                    @keydown.enter="getData"
                    @change="page = 1"
                  ></v-text-field>
                </v-col>
                <v-col md="3">
                  <v-pagination v-model="page" :length="paginationLength">
                  </v-pagination>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- / Filters -->
      <v-row>
        <v-col md="12">
          <v-card elevation="6">
            <v-card-title v-if="letters.length > 0"
              ><v-spacer></v-spacer>
              <!-- Download Excel -->
              <v-btn
                target="_blank"
                class="primary mx-4"
                :loading="isExcelloading"
                @click="generateExcel()"
              >
                Generate Excel</v-btn
              >
            </v-card-title>
            <v-progress-linear
              v-if="isLoading"
              indeterminate
              color="primary"
            ></v-progress-linear>
            <v-simple-table fixed-header>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-start">SR. NO.</th>
                    <th
                      class="text-start"
                      v-if="$route.query.type == 'offer letter'"
                    >
                      START DATE
                    </th>
                    <th
                      class="text-start"
                      v-if="$route.query.type == 'offer letter'"
                    >
                      END DATE
                    </th>
                    <th
                      class="text-start"
                      v-if="$route.query.type == 'offer letter'"
                    >
                      STC ISSUE DATE
                    </th>
                    <th class="text-start" style="min-width: 200px">
                      Document Path
                    </th>
                    <th class="text-start">ACTION</th>
                  </tr>
                </thead>
                <tbody v-if="letters.length != 0">
                  <tr v-for="(letter, i) in letters" :key="`ticket${i}`">
                    <td class="text-start" style="min-width: 85px">
                      &nbsp;
                      {{ i + 1 }}
                    </td>
                    <td v-if="$route.query.type == 'offer letter'">
                      {{ letter.start_date || "" }}
                    </td>
                    <td
                      class="text-start"
                      v-if="$route.query.type == 'offer letter'"
                    >
                      {{ letter.end_date || "" }}
                    </td>
                    <td
                      class="text-start"
                      v-if="$route.query.type == 'offer letter'"
                    >
                      {{ letter.stc_issue_date || "" }}
                    </td>
                    <td class="text-start" v-if="letter.letter_path != null">
                      <a target="_blank" :href="mediaUrl + letter.letter_path"
                        >view document</a
                      >
                    </td>
                    <td class="text-start" v-else></td>
                    <td class="text-start">
                      <v-btn
                        class="mx-2"
                        fab
                        x-small
                        color="primary"
                        :to="`/letters/${letter.id}/edit?type=${letterType}`"
                      >
                        <v-icon dark> mdi-pencil </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="9" class="text-center">No data found</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <!--End of Table -->
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
export default {
  name: "lettersPage",
  data() {
    return {
      letters: [],
      page: 1,
      serialNoStarting: 1,
      paginationLength: 0,
      count: 0,
      search_keyword: "",
      isLoading: false,
    };
  },
  watch: {
    page: "getData",
    month_menu: "getMonthYear",
  },
  mounted() {
    this.letterType = this.$route.query.type;
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      this.search_keyword = this.search_keyword ? this.search_keyword : "";
      let query =
        "page=" +
        this.page +
        "&rowsPerPage=" +
        this.rowsPerPage +
        "&search_keyword=" +
        this.search_keyword +
        "&user_id=" +
        this.$route.params.userId +
        "&letter_type=" +
        this.letterType;
      let response = await axios.get(`letters?${query}`);
      this.letters = response.data.data;
      this.count = response.data.count;
      this.letter_type_id = response.data.letter_type_id;
      this.serialNoStarting = (this.page - 1) * this.rowsPerPage;
      this.paginationLength = Math.ceil(this.count / this.rowsPerPage);
      this.isLoading = false;
    },
  },
};
</script>
