import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import axios from "axios";

Vue.use(VueRouter);

// Layout
import UnAuthLayout from "../views/layouts/unauth-layout.vue";
import AuthLayout from "../views/layouts/auth-layout.vue";

// Static
import HomePage from "../views/home";

// Auth
import LoginPage from "../views/auth/login.vue";
import ForgotPasswordPage from "../views/auth/forgot-password.vue";

// Companies
import CompaniesPage from "../views/companies/index.vue";
import CompanyCreatePage from "../views/companies/create.vue";
import CompanyIdPage from "../views/companies/id.vue";
import CompanyAttendanceSettingPage from "../views/companies/attendancesetting.vue";

// Users
import UserLeaveBalancePage from "../views/users/balance.vue";
import UsersPage from "../views/users/index.vue";
import UserCreatePage from "../views/users/create.vue";
import UserIdPage from "../views/users/id.vue";
import ProfilePage from "../views/users/id.vue";
import DeviceResetRequestPage from "../views/users/device-reset-requests.vue";
import LocationResetRequestPage from "../views/users/location-reset-requests.vue";
import UsersUploadPage from "../views/users/upload.vue";
import UsersWisePermissionPage from "../views/users/user_permission.vue";
import AdminPage from "../views/users/admin.vue";

// User Regularizations
import UserRegularizationsPage from "../views/user-regularizations/index.vue";
import UserRegularizationCreatePage from "../views/user-regularizations/create.vue";
import UserRegularizationIdPage from "../views/user-regularizations/id.vue";
// Positions
import PositionsPage from "../views/positions/index.vue";
import PositionCreatePage from "../views/positions/create.vue";
import PositionIdPage from "../views/positions/id.vue";

// Roles
import RolesPage from "../views/roles/index.vue";
import RoleIdPage from "../views/roles/id.vue";

// Modules
import ModulesPage from "../views/modules/index.vue";
import ModuleCreatePage from "../views/modules/create.vue";
import ModuleIdPage from "../views/modules/id.vue";

// Assets
import AssetsPage from "../views/assets/index.vue";
import AssetCreatePage from "../views/assets/create.vue";
import AssetIdPage from "../views/assets/id.vue";

// Outlets
import OutletsPage from "../views/outlets/index.vue";
import OutletCreatePage from "../views/outlets/create.vue";
import OutletIdPage from "../views/outlets/id.vue";
import OutletsUploadPage from "../views/outlets/upload.vue";

// Outlet Users
import OutletUsersPage from "../views/outlets/outlet-users/index.vue";
import OutletUserCreatePage from "../views/outlets/outlet-users/create.vue";
import OutletUserIdPage from "../views/outlets/outlet-users/id.vue";

// Pjps
import PjpsPage from "../views/pjps/index.vue";
import PjpCreatePage from "../views/pjps/create.vue";
import PjpIdPage from "../views/pjps/id.vue";
import PjpsUploadPage from "../views/pjps/upload.vue";
import PjpCopyReferencesPage from "../views/pjps/copy-pjp-references.vue";
import PjpCopyPreviousMonth from "../views/pjps/copy-pjp-prev-month.vue";

// PjpReferences
import PjpReferencesPage from "../views/pjp-references/index.vue";
import PjpReferenceCreatePage from "../views/pjp-references/create.vue";
import PjpReferenceIdPage from "../views/pjp-references/id.vue";

//pjpExpense
import PjpExpensesPage from "../views/pjp-expenses/index.vue";
import PjpExpenseCreatePage from "../views/pjp-expenses/create.vue";
import PjpExpenseIdPage from "../views/pjp-expenses/id.vue";
import DirectExpensePage from "../views/pjp-expenses/direct_expense.vue";

// Permissions
import PermissionsPage from "../views/permissions/index.vue";
import PermissionCreatePage from "../views/permissions/create.vue";
import PermissionIdPage from "../views/permissions/id.vue";

// Values
import Values from "@/views/values/index.vue";
import ValueCreate from "@/views/values/create.vue";
import ValueId from "@/views/values/id.vue";

// ValueLists
import ValueLists from "@/views/value-lists/index.vue";
import ValueListUpload from "@/views/value-lists/upload.vue";
import ValueListCreate from "@/views/value-lists/create.vue";
import ValueListId from "@/views/value-lists/id.vue";

// User Timestamps
import UserTimestamps from "@/views/user-timestamps/index.vue";

//User Attendance 
import UserAttendances from "@/views/user-attendances/index.vue";
import UserAttendancesCreate from "@/views/user-attendances/create.vue";
import UserAttendanceIdPage from "@/views/user-attendances/id.vue";

//User Tasts 
import Usertasks from "@/views/tasks/index.vue";
import UsertasksCreate from "@/views/tasks/create.vue";
import UsertaskIdPage from "@/views/tasks/id.vue";

//User LEaves 
import UserLeaves from "@/views/user-leaves/index.vue";
import UserLeavesCreate from "@/views/user-leaves/create.vue";
import UserLeaveIdPage from "@/views/user-leaves/id.vue";

//Shift Managements 
import ShiftManagementsPage from "@/views/shift-managements/index.vue";
import ShiftManagementCreate from "@/views/shift-managements/create.vue";
import ShiftManagementIdPage from "@/views/shift-managements/id.vue";
import ShiftChangeRequestsPage from "@/views/shift-managements/shift-change-request.vue";

//Hr Reports 
import HrReportsPage from "@/views/hr-reports/index.vue";
import HrReportCreate from "@/views/hr-reports/create.vue";
import HrReportIdPage from "@/views/hr-reports/id.vue";

//Hr Reports 
import WfhsPage from "@/views/wfh-request/index.vue";
import WfhCreate from "@/views/wfh-request/create.vue";
import WfhIdPage from "@/views/wfh-request/id.vue";

//Sample Files 
import SampleFilesPage from "@/views/sample-files/index.vue";
import SampleFileCreate from "@/views/sample-files/create.vue";
import SampleFileIdPage from "@/views/sample-files/id.vue";


//reports
import ReportsPage from "@/views/reports/index.vue";

// Timestamps
import TimestampsPage from "../views/timestamps/index.vue";

//tickets
import TicketsCreataePage from "@/views/tickets/create.vue";
import TicketsIdPage from "@/views/tickets/id.vue";
import TicketsIndexPage from "@/views/tickets/index.vue";
import TicketsFollowUpsPage from "@/views/tickets/followups.vue";
import TicketsFollowUpsCreatePage from "@/views/tickets/create-followups.vue";
import TicketsFollowUpsIdPage from "@/views/tickets/id-followups.vue";

//salries
import SalariesUploadPage from "@/views/salaries/upload.vue";
import SalariesIndexPage from "@/views/salaries/index.vue";

//renew request
import RenewRequestsPage from "@/views/companies/renew.vue";

//latters
import LettersIndexPage from "@/views/letters/index.vue";
import LettersCreatePage from "@/views/letters/create.vue";
import LettersIdPage from "@/views/letters/id.vue";

//external website
import ExternalSitePage from "@/views/external-site/page.vue";

//Beats
import BeatsPage from "@/views/beats/index.vue";
import BeatCreatePage from "@/views/beats/create.vue";
import BeatIdPage from "@/views/beats/id.vue";

//Stores
import StoresPage from "@/views/stores/index.vue";
import StoreCreatePage from "@/views/stores/create.vue";
import StoreIdPage from "@/views/stores/id.vue";

//Categories
import CategoriesPage from "@/views/categories/index.vue";
import CategoryCreatePage from "@/views/categories/create.vue";
import CategoryIdPage from "@/views/categories/id.vue";


//Classifications
import ClassificationsPage from "@/views/classifications/index.vue";
import ClassificationCreatePage from "@/views/classifications/create.vue";
import ClassificationIdPage from "@/views/classifications/id.vue";
//Skus
import SkusPage from "@/views/skus/index.vue";
import SkuCreatePage from "@/views/skus/create.vue";
import SkuIdPage from "@/views/skus/id.vue";

//map
import MapPage from "@/views/map/index.vue";

const routes = [
  // AuthLayout
  {
    path: "/auth",
    name: "AuthLayout",
    component: AuthLayout,
    children: [
      {
        path: "/home",
        name: "HomePage",
        component: HomePage,
      },
      // Comapnies
      {
        path: "/companies",
        name: "CompaniesPage",
        component: CompaniesPage,
      },
      {
        path: "/companies/create",
        name: "CompanyCreatePage",
        component: CompanyCreatePage,
      },
      {
        path: "/companies/:companyId",
        name: "CompanyIdPage",
        component: CompanyIdPage,
      },
      {
        path: "/attendance-settings",
        name: "CompanyAttendanceSettingPage",
        component: CompanyAttendanceSettingPage,
      },
      // Timestamps
      {
        path: "/timestamps",
        name: "TimestampsPage",
        component: TimestampsPage,
      },
      // Users
      {
        path: "/users",
        name: "UsersPage",
        component: UsersPage,
      },
      {
        path: "/users/create",
        name: "UserCreatePage",
        component: UserCreatePage,
      },
      {
        path: "/users/upload",
        name: "UsersUploadPage",
        component: UsersUploadPage,
      },
      {
        path: "/profile/:userId",
        name: "ProfilePage",
        component: ProfilePage,
      },
      {
        path: "/users/:userId",
        name: "UserIdPage",
        component: UserIdPage,
      },
      {
        path: "/admins",
        name: "AdminPage",
        component: AdminPage,
      },
      {
        path: "/leave-balance",
        name: "UserLeaveBalancePage",
        component: UserLeaveBalancePage,
      },
      {
        path: "/user-permissions/:userId",
        name: "UsersWisePermissionPage",
        component: UsersWisePermissionPage,
      },
      {
        path: "/device-reset-requests",
        name: "DeviceResetRequestPage",
        component: DeviceResetRequestPage,
      },
      {
        path: "/location-reset-requests",
        name: "LocationResetRequestPage",
        component: LocationResetRequestPage,
      },
      // Positions
      {
        path: "/positions",
        name: "PositionsPage",
        component: PositionsPage,
      },
      {
        path: "/positions/create",
        name: "PositionCreatePage",
        component: PositionCreatePage,
      },
      {
        path: "/positions/:positionId",
        name: "PositionIdPage",
        component: PositionIdPage,
      },
      // Roles
      {
        path: "/roles",
        name: "RolesPage",
        component: RolesPage,
      },
      {
        path: "/roles/:roleId",
        name: "RoleIdPage",
        component: RoleIdPage,
      },
      // Modules
      {
        path: "/modules",
        name: "ModulesPage",
        component: ModulesPage,
      },
      {
        path: "/modules/create",
        name: "ModuleCreatePage",
        component: ModuleCreatePage,
      },
      {
        path: "/modules/:moduleId",
        name: "ModuleIdPage",
        component: ModuleIdPage,
      },
      // Assets
      {
        path: "/assets",
        name: "AssetsPage",
        component: AssetsPage,
      },
      {
        path: "/assets/create",
        name: "AssetCreatePage",
        component: AssetCreatePage,
      },
      {
        path: "/assets/:assetsId",
        name: "AssetIdPage",
        component: AssetIdPage,
      },
      // Permissions
      {
        path: "/permissions",
        name: "PermissionsPage",
        component: PermissionsPage,
      },
      {
        path: "/permissions/create",
        name: "PermissionCreatePage",
        component: PermissionCreatePage,
      },
      {
        path: "/permissions/:permissionId",
        name: "PermissionIdPage",
        component: PermissionIdPage,
      },
      // Values
      {
        path: "/values",
        name: "Values",
        component: Values,
      },
      {
        path: "/values/create",
        name: "ValueCreate",
        component: ValueCreate,
      },
      {
        path: "/values/:valueId",
        name: "ValueId",
        component: ValueId,
      },
      // ValueLists
      {
        path: "/value-lists",
        name: "ValueLists",
        component: ValueLists,
      },
      {
        path: "/value-lists/upload",
        name: "ValueListUpload",
        component: ValueListUpload,
      },
      {
        path: "/value-lists/create",
        name: "ValueListCreate",
        component: ValueListCreate,
      },
      {
        path: "/value-lists/:valueListId",
        name: "ValueListId",
        component: ValueListId,
      },
      // Outlets
      {
        path: "/outlets",
        name: "OutletsPage",
        component: OutletsPage,
      },
      {
        path: "/outlets/upload",
        name: "OutletsUploadPage",
        component: OutletsUploadPage,
      },
      {
        path: "/outlets/create",
        name: "OutletCreatePage",
        component: OutletCreatePage,
      },
      {
        path: "/outlets/:outletId",
        name: "OutletIdPage",
        component: OutletIdPage,
      },
      // OutletUsers
      {
        path: "/outlets/:outletId/outlet-users",
        name: "OutletUsersPage",
        component: OutletUsersPage,
      },
      {
        path: "/outlets/:outletId/outlet-users/create",
        name: "OutletUserCreatePage",
        component: OutletUserCreatePage,
      },
      {
        path: "/outlet-users/:outletUserId",
        name: "OutletUserIdPage",
        component: OutletUserIdPage,
      },
      // Pjps
      {
        path: "/pjps",
        name: "PjpsPage",
        component: PjpsPage,
      },
      {
        path: "/pjps/upload",
        name: "PjpsUploadPage",
        component: PjpsUploadPage,
      },
      {
        path: "/pjps/copy-pjp-references",
        name: "PjpCopyReferencesPage",
        component: PjpCopyReferencesPage,
      },
      {
        path: "/pjps/copy-pjp-prev-month",
        name: "PjpCopyPreviousMonth",
        component: PjpCopyPreviousMonth,
      },
      {
        path: "/pjps/create",
        name: "PjpCreatePage",
        component: PjpCreatePage,
      },
      {
        path: "/pjps/:pjpId",
        name: "PjpIdPage",
        component: PjpIdPage,
      },
      // Pjps expense
      {
        path: "/pjps/:pjpId/expenses",
        name: "PjpExpensesPage",
        component: PjpExpensesPage,
      },
      {
        path: "/pjps/:pjpId/expenses/create",
        name: "PjpExpenseCreatePage",
        component: PjpExpenseCreatePage,
      },
      {
        path: "/pjps/:pjpId/expenses/",
        name: "PjpExpenseIdPage",
        component: PjpExpenseIdPage,
      },
      {
        path: "/direct-expenses",
        name: "DirectExpensePage",
        component: DirectExpensePage,
      },
      // PjpReferences
      {
        path: "/pjp-references",
        name: "PjpReferencesPage",
        component: PjpReferencesPage,
      },
      {
        path: "/pjp-references/create",
        name: "PjpReferenceCreatePage",
        component: PjpReferenceCreatePage,
      },
      {
        path: "/pjp-references/:pjpReferenceId",
        name: "PjpReferenceIdPage",
        component: PjpReferenceIdPage,
      },
      // UserTimestamps
      {
        path: "/user-timestamps",
        name: "UserTimestamps",
        component: UserTimestamps,
      },
      // UserAttendance
      {
        path: "/user-attendances",
        name: "UserAttendances",
        component: UserAttendances,
      },
      {
        path: "/user-attendances/create",
        name: "UserAttendancesCreate",
        component: UserAttendancesCreate,
      },
      {
        path: "/user-attendances/:UserAttendanceId",
        name: "UserAttendanceIdPage",
        component: UserAttendanceIdPage,
      },
      //external
      {
        path: "/external-site",
        name: "ExternalSitePage",
        component: ExternalSitePage,
      },
      //map
      {
        path: "/map",
        name: "MapPage",
        component: MapPage,
      },
      // UserAttendance
      {
        path: "/tasks",
        name: "Usertasks",
        component: Usertasks,
      },
      {
        path: "/tasks/create",
        name: "UsertasksCreate",
        component: UsertasksCreate,
      },
      {
        path: "/tasks/:taskId",
        name: "UsertaskIdPage",
        component: UsertaskIdPage,
      },
      // UserLEave
      {
        path: "/user-leaves",
        name: "UserLeaves",
        component: UserLeaves,
      },
      {
        path: "/user-leaves/create",
        name: "UserLeavesCreate",
        component: UserLeavesCreate,
      },
      {
        path: "/user-leaves/:UserLeaveId",
        name: "UserLeaveIdPage",
        component: UserLeaveIdPage,
      },
      // User Regularizations
      {
        path: "/user-regularizations",
        name: "UserRegularizationsPage",
        component: UserRegularizationsPage,
      },
      {
        path: "/user-regularizations/create",
        name: "UserRegularizationCreatePage",
        component: UserRegularizationCreatePage,
      },
      {
        path: "/user-regularizations/:userRegularizationId",
        name: "UserRegularizationIdPage",
        component: UserRegularizationIdPage,
      },
      // User Shift Management
      {
        path: "/shift-managements",
        name: "ShiftManagementsPage",
        component: ShiftManagementsPage,
      },
      {
        path: "/shift-managements/create",
        name: "ShiftManagementCreate",
        component: ShiftManagementCreate,
      },
      {
        path: "/shift-managements/:ShiftId",
        name: "ShiftManagementIdPage",
        component: ShiftManagementIdPage,
      },
      {
        path: "/shift-change-requests",
        name: "ShiftChangeRequestsPage",
        component: ShiftChangeRequestsPage,
      },
      // User Shift Management
      {
        path: "/hr-reports",
        name: "HrReportsPage",
        component: HrReportsPage,
      },
      {
        path: "/hr-reports/create",
        name: "HrReportCreate",
        component: HrReportCreate,
      },
      {
        path: "/hr-reports/:ReportId",
        name: "HrReportIdPage",
        component: HrReportIdPage,
      },
      // Wfh request
      {
        path: "/work-from-home-requests",
        name: "WfhsPage",
        component: WfhsPage,
      },
      {
        path: "/work-from-home-requests/create",
        name: "WfhCreate",
        component: WfhCreate,
      },
      {
        path: "/work-from-home-requests/:WfhId",
        name: "WfhIdPage",
        component: WfhIdPage,
      },
      // Wfh request
      {
        path: "/sample-files",
        name: "SampleFilesPage",
        component: SampleFilesPage,
      },
      {
        path: "/sample-files/create",
        name: "SampleFileCreate",
        component: SampleFileCreate,
      },
      {
        path: "/sample-files/:FileId",
        name: "SampleFileIdPage",
        component: SampleFileIdPage,
      },
      //upload salaries
      {
        path: "/upload-salaries",
        name: "SalariesUploadPage",
        component: SalariesUploadPage,
      },
      {
        path: "/salaries/:userId",
        name: "SalariesIndexPage",
        component: SalariesIndexPage,
      },
      //tickets
      {
        path: "/tickets/create",
        name: "TicketsCreataePage",
        component: TicketsCreataePage,
      },
      {
        path: "/tickets/:ticketId",
        name: "TicketsIdPage",
        component: TicketsIdPage,
      },
      {
        path: "/tickets",
        name: "TicketsIndexPage",
        component: TicketsIndexPage,
      },
      {
        path: "/tickets/:ticketId/follow-ups",
        name: "TicketsFollowUpsPage",
        component: TicketsFollowUpsPage,
      },
      {
        path: "/tickets/:ticketId/follow-ups/create",
        name: "TicketsFollowUpsCreatePage",
        component: TicketsFollowUpsCreatePage,
      },
      {
        path: "/tickets/:ticketId/follow-ups/:followupId",
        name: "TicketsFollowUpsIdPage",
        component: TicketsFollowUpsIdPage,
      },
      //reports page
      {
        path: "/reports/:index",
        name: "ReportsPage",
        component: ReportsPage,
      },
      //renew requests
      {
        path: "/renew-requests",
        name: "RenewRequestsPage",
        component: RenewRequestsPage,
      },
      //letters
      {
        path: "/letters/:userId",
        name: "LettersIndexPage",
        component: LettersIndexPage,
      },
      {
        path: "/letters/create/:userId/:letterTypeId",
        name: "LettersCreatePage",
        component: LettersCreatePage,
      },
      {
        path: "/letters/:letterId/edit",
        name: "LettersIdPage",
        component: LettersIdPage,
      },
      // Beats
      {
        path: "/beats",
        name: "BeatsPage",
        component: BeatsPage,
      },
      {
        path: "/beats/create",
        name: "BeatCreatePage",
        component: BeatCreatePage,
      },
      {
        path: "/beats/:beatId",
        name: "BeatIdPage",
        component: BeatIdPage,
      },
      // Stores
      {
        path: "/stores",
        name: "StoresPage",
        component: StoresPage,
      },
      {
        path: "/stores/create",
        name: "StoreCreatePage",
        component: StoreCreatePage,
      },
      {
        path: "/stores/:storeId",
        name: "StoreIdPage",
        component: StoreIdPage,
      },
      // Categories
      {
        path: "/categories",
        name: "CategoriesPage",
        component: CategoriesPage,
      },
      {
        path: "/categories/create",
        name: "CategoryCreatePage",
        component: CategoryCreatePage,
      },
      {
        path: "/categories/:categoryId",
        name: "CategoryIdPage",
        component: CategoryIdPage,
      },
      // Classifications
      {
        path: "/classifications",
        name: "ClassificationsPage",
        component: ClassificationsPage,
      },
      {
        path: "/classifications/create",
        name: "ClassificationCreatePage",
        component: ClassificationCreatePage,
      },
      {
        path: "/classifications/:classificationId",
        name: "ClassificationIdPage",
        component: ClassificationIdPage,
      },
      // Skus
      {
        path: "/skus",
        name: "SkusPage",
        component: SkusPage,
      },
      {
        path: "/skus/create",
        name: "SkuCreatePage",
        component: SkuCreatePage,
      },
      {
        path: "/skus/:skuId",
        name: "SkuIdPage",
        component: SkuIdPage,
      },
    ],
  },
  // End AuthLayout

  // UnAuthLayout
  {
    path: "/unauth",
    name: "UnAuthLayout",
    component: UnAuthLayout,
    children: [
      {
        path: "/login",
        name: "LoginPage",
        component: LoginPage,
      },
      {
        path: "/forgot-password",
        name: "ForgotPasswordPage",
        component: ForgotPasswordPage,
      },
    ],
  },
  //End UnAuthLayout
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
export { routes };
router.beforeEach((to, from, next) => {
  if (store.getters["auth/authenticated"] && from.name) {
    to.params.startTime = new Date();
  }
  if (
    to.name === "LoginPage" ||
    to.name === "RegisterPage" ||
    to.name === "ForgotPasswordPage"
  ) {
    console.log("1");
    if (store.getters["auth/authenticated"]) {
      console.log("2");
      let company_id = store.getters["auth/company"].id;
      if (company_id) {
        console.log("3");
        console.log(company_id);
      }
      return next({
        name: "HomePage",
      });
    }
    next();
  } else if (!store.getters["auth/authenticated"]) {
    console.log("4");
    return next({
      name: "LoginPage",
    });
  } else if (to.name === null) {
    console.log("5");
    return next({
      name: "HomePage",
    });
  }
  next();
});

router.afterEach(async (to, from) => {
  if (
    store.getters["auth/authenticated"] &&
    from.name &&
    !to.name.includes("Create") &&
    !to.name.includes("Edit")
  ) {
    let endTime = new Date();
    let startTime = from.params.startTime;
    let timeSpentInSeconds = "0";
    if (startTime) {
      timeSpentInSeconds = (endTime - startTime) / 1000;
    }
    if (timeSpentInSeconds > 1200) {
      timeSpentInSeconds = 1200;
    }
    console.log(
      `Time spent on "${to.name}" route: ${timeSpentInSeconds} seconds`
    );
    let form = {
      from_path: from.path,
      from_name: from.name,
      user_id: store.getters["auth/user"].id,
      timespent: timeSpentInSeconds,
    };
    await axios.post(`/user_timestamps`, form);
  }
});

export default router;
