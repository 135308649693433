<template>
  <v-container>
    <!-- Filters -->
    <v-row align="center">
      <v-col md="6">Location data of
        <span class="text-h6 font-weight-bold" v-text="`${this.selectedUser
          ? ' ' + this.selectedUser?.full_name
          : '!!! Please select an User !!!'
          }`
          "></span>
      </v-col>
      <v-col md="4">
        <v-menu v-model="date_menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
          offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="filter.date" label="YYYY-MM-DD" prepend-icon="mdi-calendar" readonly v-bind="attrs"
              v-on="on" clearable @click:clear="filter.date = null"></v-text-field>
          </template>
          <v-date-picker v-model="filter.date" type="date" @change="(date_menu = false), refreshMap()"></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <!-- Vitals -->
    <v-card>
      <v-container>
        <v-row align="center">
          <v-col> <b>Lat-Lng:</b> {{ selectedLatLng }} </v-col>
          <v-col> <b>Timestamp: </b>{{ timestamp }} </v-col>
          <v-col> <b>Mode:</b> {{ mode }} </v-col>
          <v-col> <b>Battery Level:</b> {{ battery }} </v-col>
          <v-col> <b>Distance Travelled:</b> {{ odometer }} meters </v-col>
        </v-row>
      </v-container>
    </v-card>
    <br />
    <!-- Map -->
    <v-row>
      <v-col v-if="isLoadingTimestamps"> Loading... </v-col>
      <v-col v-else>
        <GmapMap ref="mapRef" :center="center" :zoom="18" map-type-id="terrain" style="width: 100%; height: 500px">
          <!-- Start Marker -->
          <GmapMarker :key="`s_${index}`" v-for="(m, index) in startMarkers" :position="m.position" :clickable="true"
            :draggable="true" @click="center = m.position" :icon="startMarkerOptions" />
          <!-- End Marker -->
          <GmapMarker :key="`e_${index}`" v-for="(m, index) in endMarkers" :position="m.position" :clickable="true"
            :draggable="true" @click="center = m.position" :icon="endMarkerOptions" />
          <!-- icon="https://maps.gstatic.com/mapfiles/ms2/micons/blue-dot.png" -->
          <!-- :icon="`${require('../../assets/circleA.png')}`" -->
          <!-- Person Marker -->
          <GmapMarker :key="`m_${index}`" :icon="personMarkerOptions" v-for="(m, index) in personMarkers" :position="m"
            :clickable="true" :draggable="true" @mouseover="MarkerClicked(m)" />
          <!-- @click="MarkerClicked(m)" -->
          <GmapInfoWindow :position="path_detail" :opened="infoWindowOpen" @closeclick="infoWindowOpen = false"
            @mouseleave="infoWindowOpen = false">
            <b>Location</b> :{{ parseFloat(path_detail.lat).toFixed(4) }},
            {{ parseFloat(path_detail.lng).toFixed(4) }}
            <br />
            <b>Speed</b> :{{ path_detail.speed }} km/h
            <br />
            <b>Heading</b> :{{ path_detail.heading }}
            <br />
            <b>Battery level</b> :{{ path_detail.battery_level }}%
            <br />
            <b>Timestamp</b> :{{ path_detail.timestamp }}
            <br />
            <b>Odometer</b> :{{ parseInt(path_detail.odometer / 1000) }}
            <br />
            <b>Mode</b> :{{ path_detail.mode }}
          </GmapInfoWindow>
          <!-- PAth -->
          <!-- Path -->
          <GmapPolyline :path.sync="path" :options="polylineOptions" @mousemove="polylineHover" />
        </GmapMap>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import moment from "moment";
import Vue from "vue";
import * as VueGoogleMaps from "vue2-google-maps";

Vue.use(VueGoogleMaps, {
  load: {
    // key: "AIzaSyAm0FfE4ijnd7gx8dQ6KHp2rtOWNs9rYZk",
    key: "AIzaSyAm0FfE4ijnd7gx8dQ6KHp2rtOWNs9rYZk",
    libraries: "places", // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    //// If you want to set the version, you can do so:
    // v: '3.26',
  },

  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,

  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then disable the following:
  // installComponents: true,
});

export default {
  name: "Timestamps",
  data() {
    return {
      userId: "",
      timestamps: [],
      filter: {
        month_year: "",
        month: "",
        year: "",
        date: "",
      },
      infoWindowOpen: false,
      date_menu: false,
      path_detail: [],
      isLoadingTimestamps: false,
      polylineOptions: {},
      path: [],
      center: {
        lat: 19.047321,
        lng: 73.069908,
      },
      startMarkers: [],
      endMarkers: [],
      personMarkers: [],
      // Start Marker Options
      startMarkerOptions: {
        url: "http://maps.google.com/mapfiles/kml/paddle/orange-circle.png",
      },
      // Start Marker Options
      endMarkerOptions: {
        url: "http://maps.google.com/mapfiles/kml/paddle/red-circle.png",
      },
      // Person Marker Options
      personMarkerOptions: {
        url: require("../../assets/red-circle-icon.png"),
      },
      selectedLatLng: "",
      timestamp: "",
      mode: "",
      battery: "",
      odometer: "",
    };
  },
  props: ["selectedUser"],
  computed: {
    google: VueGoogleMaps.gmapApi,
  },
  watch: {
    selectedUser: "refreshMap",
  },
  mounted() {
    this.filter.date = moment().format("YYYY-MM-DD");

    this.$refs.mapRef.$mapPromise.then(() => {
      // Polyline Path Options
      this.polylineOptions = {
        strokeColor: "red",
        geodesic: true,
        strokeOpacity: 0.4,
        strokeWeight: 3,
        icons: [
          {
            icon: { path: this.google.maps.SymbolPath.FORWARD_CLOSED_ARROW },
            offset: "100%",
            repeat: "250px",
            color: "black",
          },
        ],
      };

      //   Marker Icon
      this.$refs.mapRef.$mapPromise.then(() => {
        this.personMarkerOptions = {
          url: require("../../assets/red-circle-icon.png"),
        };
      });
    });
  },
  methods: {
    polylineHover(event) {
      let selectedPath = this.timestamps.filter(
        (p) =>
          p.latitude.toFixed(4) == event.latLng.lat().toFixed(4) &&
          p.longitude.toFixed(4) == event.latLng.lng().toFixed(4)
      );
      if (selectedPath.length > 0) {
        this.path_detail = {
          lat: selectedPath[0].latitude,
          lng: selectedPath[0].longitude,
          speed: selectedPath[0].speed,
          heading: selectedPath[0].heading,
          battery_level: selectedPath[0].battery_level,
          timestamp: selectedPath[0].created_at,
          odometer: selectedPath[0].odometer,
          mode: selectedPath[0].type,
        };
        this.infoWindowOpen = true;
      }
    },
    MarkerClicked(path) {
      if (path) {
        this.infoWindowOpen = true;
        this.path_detail = path;
      }
      console.log("Click");
    },
    showArrays(event) {
      console.log(123);
      console.log(event.latLng.lat());
      // var contentString = "Content here";
      // // Replace the info window's content and position.
      // infoWindow.setContent(contentString);
      // infoWindow.setPosition(event.latLng);
      // infoWindow.open(map);
    },
    async refreshMap() {
      this.isLoadingTimestamps = true;
      this.timestamps = [];
      this.userId = this.selectedUser.id;
      let timestamps = await axios.get(
        `/timestamps?user_id=${this.userId}&date=${this.filter.date}`
      );
      this.timestamps = timestamps.data.timestamp;
      this.isLoadingTimestamps = false;
      this.path = [];
      this.startMarkers = [];
      this.endMarkers = [];
      this.personMarkers = [];

      this.timestamps.forEach(async (timestamp, t) => {
        let coords = {
          lat: timestamp.latitude,
          lng: timestamp.longitude,
          speed: timestamp.speed,
          heading: timestamp.heading,
          battery_level: timestamp.battery_level,
          timestamp: timestamp.created_at,
          odometer: timestamp.odometer,
          mode: timestamp.type,
        };
        // Start marker
        if (t == 0) {
          console.log("this.startMarkers", this.startMarkers);
          this.startMarkers.push({
            position: coords,
          });
          this.center = coords;
        }
        // End Marker
        else if (t == this.timestamps.length - 1) {
          console.log("endMarkers", this.endMarkers);
          this.endMarkers.push({
            position: coords,
          });
        }
        // Person Marker
        else if (t % 25 == 0) {
          console.log("personMarkers", this.personMarkers);
          this.personMarkers.push(coords);
        }
        console.log(this.personMarkers);
        this.path.push(coords);
      });
    },
  },
};
</script>
