<template>
  <v-main>
    <v-container fluid>
      <v-card elevation="6" rounded="xxl">
        <v-card-title class="mt-4">
          <v-btn class="mx-2" fab x-small color="primary" @click="$router.back()">
            <v-icon dark> mdi-arrow-left </v-icon> </v-btn>Add Outlet
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="4">
              <v-text-field v-model="form.name" outlined label="Name"
                prepend-inner-icon="mdi-card-text-outline"></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field v-model="form.brand" outlined label="Brand"
                prepend-inner-icon="mdi-card-text-outline"></v-text-field>
            </v-col>
          </v-row>
          <v-divider class="my-5"> </v-divider>
          <v-btn rounded color="primary" :loading="isLoading" :disabled="isLoading" @click="save">
            Save Outlet
          </v-btn>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";
export default {
  name: "OutletCreatePage",
  data() {
    return {
      form: {
        role_id: 3,
        name: "",
      },
      isLoading: false,
    };
  },
  methods: {
    async save() {
      try {
        this.isLoading = true;
        await axios
          .post("/outlets", this.form)
          .then(async () => {
            await this.saveUserTimestamp(null, this.form);
            let PageModule = 'CREATE';
            let PageCode = 'OUTLETS';
            const userIP = await this.getUserIp();
            this.saveAuditTrial(userIP, PageModule, PageCode)
            this.isLoading = false;
            this.$router.push("/outlets");
          })
          .catch((error) => {
            // redirect to Home Page
            window.location.href = "/auth/login";
            console.error("Error fetching data:", error);
          });
      } catch (e) {
        // alert("error");
        console.log(e);
        this.isLoading = false;
      }
    },
  },
};
</script>
