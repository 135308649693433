<template>
  <v-main>
    <v-container fluid>
      <v-card elevation="6">
        <v-card-title class="mt-4 ml-4">
          <v-btn class="mx-2" fab x-small color="primary" @click="$router.back()">
            <v-icon dark> mdi-arrow-left </v-icon> </v-btn>Add a PJP Expense
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="4">
              <v-autocomplete ref="employee" v-model="form.user_id" :items="employees" label="Employee"
                prepend-icon="mdi-account"></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-autocomplete ref="Expense type" v-model="form.expense_type_id" :items="ExpenseTypes"
                label="Expense Type" prepend-icon="mdi-cash-100"></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-autocomplete ref="travel mode" v-model="form.travel_mode_id" :items="travelModes" label="Travel Mode"
                prepend-icon="mdi-train-car"></v-autocomplete>
            </v-col>

            <v-col sm="4">
              <v-text-field v-model="form.from" outlined label="From"
                prepend-inner-icon="mdi-arrow-left-bold"></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field v-model="form.to" outlined label="To"
                prepend-inner-icon="mdi-arrow-right-bold"></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field v-model="form.amount" outlined label="Amount" prepend-inner-icon="mdi-cash"></v-text-field>
            </v-col>
          </v-row>
          <v-divider class="mt-5 mb-5"> </v-divider>

          <v-progress-circular v-if="isLoading" indeterminate color="primary"></v-progress-circular>
          <v-btn v-else color="primary" dark @click="save">
            Save PJP Expense
          </v-btn>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";
export default {
  name: "UserCreatePage",
  data() {
    return {
      form: {

      },
      employees: [
        {
          id: 0,
          text: "Select Employee",
          value: "",
        },
      ],
      ExpenseTypes: [
        {
          id: 0,
          text: "Select Expense Type",
          value: "",
        },
      ],
      travelModes: [
        {
          id: 0,
          text: "Select Travel Mode",
          value: "",
        },
      ],
      supervisors: [
        {
          id: 0,
          text: "Select Supervisor",
          value: "",
        },
      ],
      isLoading: false,
    };
  },
  mounted() {
    this.getMasters();
    this.getData();
  },
  methods: {
    async getMasters() {
      let masters = await axios.get(`pjp_expenses/masters`);
      masters = masters.data;
      // Employees
      masters.employees.forEach((employee) => {
        this.employees.push({
          id: employee.id,
          text: employee.full_name,
          value: employee.id,
        });
      });
      // Trave Modes
      masters.travel_modes.forEach((travelMode) => {
        this.travelModes.push({
          id: travelMode.id,
          text: travelMode.code,
          value: travelMode.id,
        });
      });
      // Expense Types
      masters.expense_types.forEach((ExpenseType) => {
        this.ExpenseTypes.push({
          id: ExpenseType.id,
          text: ExpenseType.code,
          value: ExpenseType.id,
        });
      });
    },
    async getData() {
      this.isLoading = true;
      let pjp = await axios.get(`/pjps/${this.$route.params.pjpId}`);
      this.pjp = pjp.data.data;
      this.form.user_id = this.pjp.user_id;
      this.form.supervisor_id = this.pjp.user.supervisor_id;
      this.isLoading = false;
    },
    async save() {
      try {
        this.isLoading = true;
        this.form.pjp_id = this.$route.params.pjpId;
        console.log(this.form);
        await axios.post("/pjp_expenses_store", this.form);
        await this.saveUserTimestamp(null, this.form);
        let PageModule = 'CREATE';
        let PageCode = 'PJP EXPENSES';
        const userIP = await this.getUserIp();
        this.saveAuditTrial(userIP, PageModule, PageCode)
        this.isLoading = false;
        // this.$router.back();
      } catch (e) {
        // alert("error");
        this.isLoading = false;
      }
    },
  },
};
</script>
