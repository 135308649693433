<template>
  <v-main>
    <v-container fluid>
      <v-row>
        <v-col md="12">
          <v-card elevation="4">
            <v-card-text>
              <v-row class="d-flex align-center">
                <v-btn elevation="1" class="mr-2 mt-2" fab x-small color="primary" @click="$router.back()">
                  <v-icon dark> mdi-arrow-left </v-icon>
                </v-btn>
                <v-btn class="mt-2 mx-2 text-caption" color="primary"
                  :to="`/tickets/${$route.params.ticketId}/follow-ups/create`">
                  <span> add follow-ups <v-icon dark> mdi-plus </v-icon></span>
                </v-btn>
              </v-row>
              <v-row>
                <v-col cols="8" class="my-0">
                  <v-col cols="12" class="pt-1 pb-0 px-0">
                    <span class="text-h5">Ticket Description</span>
                  </v-col>
                  <v-col cols="12" class="my-0 px-0">
                    <span class="text-subtitle-1">{{
                  tickets.description || ""
                }}</span>
                  </v-col>
                </v-col>
                <v-col cols="4">
                  <v-col cols="12" class="py-0">
                    <span class="text-subtitle-1">
                      <span class="font-weight-bold">
                        Ticket Generated Date
                      </span>
                      : {{ tickets.created_at.split(" ")[0] }}</span>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <span class="text-subtitle-1"><span class="font-weight-bold"> Ticket Closed Date </span>:
                      <span v-if="tickets.status == 'CLOSED'">
                        {{ tickets.updated_at }}</span></span>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <span class="text-subtitle-1">
                      <span class="font-weight-bold">Ticket Status </span>:
                      <span :class="tickets.status == 'CLOSED'
                  ? 'green--text'
                  : 'red--text'
                  ">
                        {{ tickets.status }}</span></span>
                  </v-col>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- / Filters -->
      <v-progress-linear v-if="isLoading" indeterminate color="primary"></v-progress-linear>
      <v-row v-else>
        <v-col md="12">
          <v-card elevation="6">
            <v-card-title v-if="tickets_followups.length > 0"><v-spacer></v-spacer>
            </v-card-title>
            <!-- Table -->
            <v-simple-table fixed-header>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left font-weight-bold">SR. NO.</th>
                    <th class="text-left" style="min-width: 150px">DATE</th>
                    <th class="text-left" style="min-width: 600px">
                      DESCRIPTION
                    </th>
                    <th class="text-left" min-width="400">REPLIED TO</th>
                    <th class="text-left">ACTION</th>
                  </tr>
                </thead>
                <tbody v-if="tickets_followups.length != 0">
                  <tr v-for="(Followup, i) in tickets_followups" :key="`ticket${i}`">
                    <td style="min-width: 85px">
                      <v-tooltip bottom v-if="Followup.is_approved == 1">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on" small dark color="green">
                            mdi-circle
                          </v-icon>
                        </template>
                        <span>Active</span>
                      </v-tooltip>
                      <v-tooltip bottom v-if="Followup.is_approved == 0">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on" small dark color="red">
                            mdi-circle
                          </v-icon>
                        </template>
                        <span>In-Active</span>
                      </v-tooltip>
                      &nbsp;
                      {{ i + 1 }}
                    </td>
                    <td>
                      {{
                  Followup.created_at
                    ? Followup.created_at.split(" ")[0]
                    : ""
                }}
                    </td>
                    <td>{{ Followup.description || "" }}</td>
                    <td>
                      {{
                  Followup.replied_by_id != null
                    ? Followup.replied_by.full_name
                    : ""
                }}
                    </td>
                    <td>
                      <v-btn class="mx-2" fab x-small color="primary"
                        :to="`/tickets/${$route.params.ticketId}/follow-ups/${Followup.id}`">
                        <v-icon dark> mdi-pencil </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="4"></td>
                    <td>
                      <v-pagination class="float-right" v-model="page" :length="paginationLength">
                      </v-pagination>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="9" class="text-center">No data found</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <!--End of Table -->
          </v-card>
        </v-col>
      </v-row>
      <v-dialog width="700" v-model="TicketsImageDialog">
        <v-carousel cycle height="300" hide-delimiter-background show-arrows-on-hover>
          <v-carousel-item v-for="(slide, i) in slides" :key="i">
            <v-sheet height="100%">
              <v-row class="fill-height" align="center" justify="center">
                <v-img :src="mediaUrl + slide" />
              </v-row>
            </v-sheet>
          </v-carousel-item>
        </v-carousel>
      </v-dialog>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
export default {
  name: "ticketsPage",
  data() {
    return {
      filter: {
        month_year: "",
        month: "",
        year: "",
        expense_id: "",
      },
      tickets: [],
      page: 1,
      serialNoStarting: 1,
      paginationLength: 0,
      count: 0,
      search_keyword: "",
      month_menu: false,
      isLoading: false,
      isExcelloading: false,
      TicketsImageDialog: false,
      slides: [],
      tickets_followups: [],
    };
  },
  watch: {
    page: "getData",
    month_menu: "getMonthYear",
  },
  mounted() {
    this.getTicketData();
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      let query =
        "page=" +
        this.page +
        "&rowsPerPage=" +
        this.rowsPerPage +
        "&ticketId=" +
        this.$route.params.ticketId;
      let response = await axios.get(`ticket_followups?${query}`);
      this.tickets_followups = response.data.data;
      let PageModule = 'CREATE';
      let PageCode = 'TICKET FOLLOWUPS';
      const userIP = await this.getUserIp();
      this.saveAuditTrial(userIP, PageModule, PageCode)
      this.count = response.data.count;
      this.serialNoStarting = (this.page - 1) * this.rowsPerPage;
      this.paginationLength = Math.ceil(this.count / this.rowsPerPage);
      this.isLoading = false;
    },
    async getTicketData() {
      let response = await axios.get(`tickets/${this.$route.params.ticketId}`);
      this.tickets = response.data.data;
    },
  },
};
</script>
